/* global require, module */

var Backbone = require('backbone'),
		SourcePossibleResponse = require('../models/SourcePossibleResponse.js');

module.exports = Backbone.Collection.extend({
	model: SourcePossibleResponse,
	url: function() {
		return '/api1/source_possible_response/all_for_source_question/' + this.srcQID;
	},

	initialize: function(models, options) {
		this.srcQID = options.srcQID;
	}
});