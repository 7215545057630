/* global require, module */

var Backbone = require('backbone'),
_ = require('underscore'),
$ = require('jquery'),
Mustache = require('mustache'),
SUPRQLiteAnalytic = require('../models/SUPRQLiteAnalytic.js'),
NavCompMixin      = require('../mixins/NavigationComponentMixin.js'),
AlertView         = require('./AlertView.js'),
GColView           = require('./GColView.js'),
SUPRQWebsitesView           = require('./SUPRQWebsitesView.js'),
RatingQuestion    = require('../models/RatingQuestion.js'),
Questions         = require('../collections/Questions.js'),
PossibleResponses = require('../collections/PossibleResponses.js'),
Responses         = require('../collections/Responses.js'),
Participants      = require('../collections/Participants.js'),
SUPRQWebsites      = require('../collections/SUPRQWebsites.js'),
app               = require('../app.js');

/**
   @class SUPRQLiteAnalyticsView
   @constructor
   @extends Backbone.View
   @module Views
*/
module.exports = Backbone.View.extend(
    _.extend({}, NavCompMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl         : $('#SUPRQAnalyticView-tpl').html(),
	infoTpl     : $('#SUPRQAnalyticView-info-tpl').html(),
	editInfoTpl : $('#SUPRQAnalyticView-edit-tpl').html(),

	/**
	 * Special template for options in the question list.
	 *
	 * @property optionTpl
	 * @type {String}
	 */
	optionTpl : $('#SUPRQAnalyticView-option-tpl').html(),

	events: {
	    'click .back'                 : 'navigateBack',

	    'click .edit-analytic'        : 'editAnalytic',
	    'click .data-download'        : 'addDataToExcel',
	    'click .cancel-edit-analytic' : 'cancelEditAnalytic',
	    'click .save-edit-analytic'   : 'saveEditAnalytic',

	    'click .delete-analytic'      : 'deleteAnalytic',
	    'click .recalcButton'		  : 'renderInfo',
	},

	/**
	   @method initialize
	   @param {object} opts Config object following the format:
	*/
	initialize: function(opts) {
	    /**
	       The Analytic model that is being viewed
	       @property suprq
	       @type Analytic
	    */
	    this.suprq = opts.suprq;

	    /**
	       Object for storing data for export to xls
	       @property eData
	       @type generic js object
	    */
	    this.eData =
                {
                    name: 'SUPR-Q Lite',
		    type: 'values',
                    data:
                    {
                        participants: [],
                        questions: []
                    }
                }

	    /**
	       Taken from suprq.
	       @property projID
	       @type String/Number
	    */
	    this.projID = this.suprq.get('project_id');

	    /**
	     * All questions for this project
	     * @property qs
	     * @type Collection
	     */
	    this.qs     = new Questions([], {projID : this.projID});

	    /**
	       Title for the page.
	       @property title
	       @type String
	       @default The question name
	       @final
	    */
	    this.title = this.suprq.get('name');

	    /**
	       Previous page title
	       @property prevTitle
	       @type String
	       @default 'Back'
	    */
	    this.prevTitle = 'Back';

	    /**
	       Set's the confidence level to use when viewing the graph and computing the confidence interval.
	       @property alpha
	       @type Number
	       @default 0.1
	    */
	    this.alpha     = opts.alpha || 0.1;

	    /**
	       @property inserted
	       @type jQuery deferred
	    */
	    this.inserted = $.Deferred();

	    //get view being rendered
	    var view = this.suprq.toTpl();

	    /**
	       @property qsProm
	       @type Collection of question models
	    */
	    this.qsProm = this.qs.fetch({
		reset: true,
		error: function(one, two, three) {
		    app.ge.trigger('alert', new AlertView({
			m: 'Error loading questions:' + two.responseText,
			type: 'alert-danger'
		    }));
		}
	    });

	    /**
	       @property superQLite
	       @type SUPRQLiteAnalytic model
	    */
	    this.superQLite = new SUPRQLiteAnalytic({analyticID:this.suprq.get('id')}).fetch({
		reset: true,
		error: function(one, two, three) {
		    app.ge.trigger('alert', new AlertView({
			m: 'Error loading suprqlite data:' + two.responseText,
			type: 'alert-danger'
		    }));
		}
	    });

	    //wait till questions and analytic model haved loaded then render templates
	    $.when(this.qsProm,this.superQLite).then(_.bind(function() {
		this.renderInfo();
		this.listenTo(this.suprq, 'change', function() {
		    this.renderInfo();
		    this.renderNavHeader();
		});
	    }, this));


	},

	/**
	   Set up the page structure, call `renderNavHeader()` (`renderInfo()` must be called deferred)
	   @method render
	   @chainable
	*/
	render: function() {
	    var view = this.suprq.toTpl();
	    this.$el.html(Mustache.render(this.tpl, view, app.partials()));
	    this.renderNavHeader();

	    return this;
	},

	renderNavHeader: function() {
	    var view       = {};
	    view.title     = this.title;
	    view.prevTitle = this.prevTitle;

	    view.panel_controls = [
		{
		    loner      : true,
		    label      : 'Add to Excel',
		    class_name : 'data-download'
		},
		{
		    loner      : true,
		    label      : 'Edit',
		    class_name : 'edit-analytic'
		}
	    ];

	    this.$('.panel-nav .panel-heading').replaceWith(
		Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
	    );
	},

        getQuestionData: function(question) {
	    var deferred = $.Deferred();

	    var qID = this.questions()[question].qid,
	    id = this.questions()[question].id,

	    questions = new RatingQuestion({id : qID}),
	    possibleResponses = new PossibleResponses([], {qID: qID}),
	    responses = new Responses([], {qID: qID}),
	    participants = new Participants([], {projID: this.projID});

	    questionsPromise = questions.fetch({reset: true});
	    possibleResponsesPromise = possibleResponses.fetch({reset: true});
	    responsesPromise = responses.fetch({reset: true});
	    participantsPromise = participants.fetch({reset: true});

	    $.when(questionsPromise, possibleResponsesPromise, responsesPromise, participantsPromise)
             .then(
                 function() {
                     deferred.resolve({
                         questions: questions,
                         possibleResponses: possibleResponses,
                         responses: responses,
                         participants: participants,
                         qID: qID,
                         id: id}
                     );
                 }
             )

            return deferred.promise();
        },

        questions: function() {
            return [
		{
		    name : 'Q1 : This website is easy to use',
		    id   : 'q1',
		    qid  : this.suprq.get('q1_id')
		}, {
		    name : 'Q2 : It is easy to navigate within the website.',
		    id   : 'q2',
		    qid  : this.suprq.get('q2_id')
		}, {
		    name : 'Q3 : The website keeps the promises it makes on its website.',
		    id   : 'q3',
		    qid  : this.suprq.get('q3_id')
		}, {
		    name : 'Q4 : I feel confident conducting business with this website.',
		    id   : 'q4',
		    qid  : this.suprq.get('q4_id')
		}, {
		    name : 'Q5 : How likely are you to recommend this website to a friend or colleague?',
		    id   : 'q5',
		    qid  : this.suprq.get('q5_id')
		}, {
		    name : 'Q6 : I will likely visit this website in the future.',
		    id   : 'q6',
		    qid  : this.suprq.get('q6_id')
		}, {
		    name : 'Q7 : I find the website to be attractive.',
		    id   : 'q7',
		    qid  : this.suprq.get('q7_id')
		}, {
		    name : 'Q8 : The website has a clean and simple presentation.',
		    id   : 'q8',
		    qid  : this.suprq.get('q8_id')
		}
	    ]
        },

        prepareSuprqAverages: function(questionList, questionToResponseMap, options){
            var participantList =
                _.compact(
                    _.map(questionToResponseMap[questionList[0]].rs.models,
                          function(response){
                              return response.get('participant_id');
                          })
                )

            // link the responses by participantId, we arbitrarily
            // use the participants from the first question passed in.
            // This has the potential downside that the first question
            // had a limited number of participants
            var promoterValues = [];
            var detractorValues = [];
            var averages = _.compact(
                _.map(participantList, function(participantId){
                    var responseValues = _.map(questionList, function(questionId){
                        var scaleFactor = 1;
                        var responses = questionToResponseMap[questionId].rs.models;
                        var response = Number.parseInt(_.find(responses, function(responseModel){
                            return responseModel.get('participant_id') == participantId;
                        }).get('response'))
                        if (questionId == options['loyaltyId']) {
                            scaleFactor = 2;
                            if (response < 7)
                                detractorValues.push(response);
                            if (response > 8)
                                promoterValues.push(response);
                        }
                        return response / scaleFactor
                    });
                    return  _.reduce(responseValues, function(memo, value){
                        return memo + value
                    }, 0) / questionList.length
                })
            )
            return {averages, promoterValues, detractorValues}
        },

	renderInfo: function() {
	    var view = this.suprq.toTpl();
	    view.questions = this.questions();
	    var qToRSMap = {};

	    (_.bind(function (){
		var deferred = $.Deferred();
		//loop through SUPRQ questions getting data and rendering charts
		i = 0;
		for(var question in this.questions()) {
                    data = this.getQuestionData(question);
		    $.when(data)
                     .done(
                         _.bind(
			     function(data){
			         qToRSMap[data.qID] = data.questions;
			         qToRSMap[data.qID].prs = data.possibleResponses;
			         qToRSMap.parts = data.participants;
			         qToRSMap[data.qID].rs = data.responses;

			         this.renderChart(this.alpha,
                                                  data.questions,
                                                  data.participants,
                                                  data.possibleResponses,
                                                  data.responses,
                                                  '#graph-mount-' + data.id);

			         i++;
			         if(i == view.questions.length) deferred.resolve();

			     }, this));
		}
		return deferred.promise();

		//done processing questions now create chart
	    }, this))().then(_.bind(function(){
		var i = 0,
		//store iterated questions
		loadedQuestions = [];

		var chartDataPoints = [],
		labels = { 1 : 'Usability', 3 : 'Trust', 5 : 'Loyalty', 7: 'Appearance'},
		responseCount = _.countBy(qToRSMap[this.suprq.get('q5_id')].rs.models, function(response){
                    return response.get('response') !== null
                })[true]

		_.each(qToRSMap['parts'].models, function(participant){
		    this.eData['data']['participants'].push(participant.get('id'))
		}, this);

		for(var question in this.questions()) {
		    var questionId = this.questions()[question].qid;
		    loadedQuestions.push(qToRSMap[questionId]);

		    if(i % 2 == 1){
			var q1Id = loadedQuestions[i - 1].get('id');
			var q2Id = qToRSMap[questionId].get('id');
                        var aggregates = this.prepareSuprqAverages([q1Id, q2Id],
                                                                 qToRSMap,
                                                                 {loyaltyId: this.suprq.get('q5_id')});
			var calcObj = this.calcSUPRQ(aggregates.averages, labels[i]);

			this.eData['data']['questions'].push(
			    {
				question_name : labels[i],
				values : aggregates.averages
			    }
			);

			chartDataPoints.push({
			    value  : calcObj.percent,
			    low    : calcObj.uLow,
			    high   : calcObj.uHigh,
                            n      : aggregates.averages.length,
			    label  : labels[i],
			    series : 'Series'
			});

		    }
		    i++;
		}
                var questionIds = _.map(this.questions(), function(question){
                    return question.qid
                });
                var aggregates = this.prepareSuprqAverages(questionIds,
                                                         qToRSMap,
                                                         {loyaltyId: this.suprq.get('q5_id')});
		var calcObj = this.calcSUPRQ(aggregates.averages, 'SUPRQ');
		chartDataPoints.push({
		    value  : calcObj.percent,
		    low    : calcObj.uLow,
		    high   : calcObj.uHigh,
                    n      : aggregates.averages.length,
		    label  : 'SUPR-Q',
		    series : 'Series'
		});

		//NPS
		calcNPSObj = this.calcNPS(aggregates.promoterValues,aggregates.detractorValues,responseCount);
		chartDataPoints.push({
		    value  : calcNPSObj.percent,
		    low    : calcNPSObj.low,
		    high   : calcNPSObj.high,
                    n      : responseCount,
		    label  : 'NPS %',
		    series : 'Series'
		});

		var chartData = {
		    data         : chartDataPoints,
		    min          : -100,
		    max          : 100,
		    formatString : '.1f',
		    title        : 'test',
                    negativeFlag : true
		};

		chartData.el = "#graph-mount";
		chartData.alpha = .1;

		var chart = new GColView(chartData);
		this.listenTo(chart, 'changealpha', this.changeAlpha);
		this.listenTo(chart, 'GColView:addtoexcel', this.addSUPRQGraphToExcel);
		chart.render();
	    },this));

	    this.$('.panel-body').html(
		Mustache.render(this.infoTpl, view, app.partials())
	    );
	},

	calcNPS : function(promoters, detractors, totalResponses){
	    var percent = ((promoters.length - detractors.length)/totalResponses) * 100,
	    x1 = detractors.length,
	    x2 = promoters.length,
	    N = totalResponses,
	    p1 = x1/N,
	    p2 = x2/N,
	    diff = p2 - p1,
	    plus = p1 + p2,
	    diffSquared = diff * diff,
	    sem = Math.sqrt((plus - diffSquared)/N),
	    z = this.z.scoreFromAlpha(.1,2),
	    margin = sem * z,
	    low = (diff - margin) * 100,
	    high = (diff + margin) * 100;

	    return {percent : percent, low : low, high : high};

	},

	calcSUPRQ : function(averages,label){
	    var
	    maxScore = 5,
	    globalMean =  Number.parseFloat($('.'+label+'Mean').val()),
	    studyMean =  this.average(averages),
	    //need log of this value
	    studyMeanRef = Math.log(maxScore - studyMean),
	    globalSD = Number.parseFloat($('.'+label+'SD').val()),
	    studySD = this.standardDeviation(averages),
	    zReflect = (studyMeanRef - globalMean)/globalSD,
	    percentReflect = this.normdist(zReflect),
	    percent = (1 - percentReflect) * 100,
	    N = averages.length,
	    SE = studySD/Math.sqrt(N),
	    t = this.scoreFromAlpha(N - 1,.1,2),
	    margin = SE * t,
	    low = studyMean - margin,
	    high = Number.parseFloat(studyMean) + Number.parseFloat(margin),
	    lowLNReflect = Math.log(maxScore - low),
	    lowZReflect = (lowLNReflect - globalMean)/globalSD,
	    lowPercentReflect = this.normdist(lowZReflect),
	    lowPercent = (1 - lowPercentReflect) * 100,
	    highLNReflect = Math.log(maxScore - high),
	    highZReflect = (highLNReflect - globalMean)/globalSD,
	    highPercentReflect = this.normdist(highZReflect),
	    highPercent = (1 - highPercentReflect) * 100;

	    return {percent : percent, uLow : lowPercent, uHigh : highPercent};
	},

	scoreFromAlpha: function(df, alpha, tails) {
	    // convert a two-tailed alpha to a single tail
	    if (Number(tails) == 2) {
		alpha = alpha/2;
	    }

	    var z = this.z.scoreFromAlpha(alpha);

	    // t distribution matches z distribution at large df
	    if (df > 10000) {
		return z;
	    }

	    var g1 = Math.pow(z, 3) + z;
	    g1 = g1/4;

	    var g2 = (5 * Math.pow(z, 5)) +
		(16 * Math.pow(z, 3)) +
		(3 * z);
	    g2 = g2/96;

	    var g3 = (3 * Math.pow(z, 7)) +
		(19 * Math.pow(z, 5)) +
		(17 * Math.pow(z, 3)) -
		(15 * z);
	    g3 = g3/384;

	    var g4 = (79 * Math.pow(z, 9)) +
		(776 * Math.pow(z, 7)) +
		(1482 * Math.pow(z, 5)) -
		(1920 * Math.pow(z, 3)) -
		(945 * z);
	    g4 = g4/92160;

	    var t = z +
		(g1/df) +
		(g2/Math.pow(df, 2)) +
		(g3/Math.pow(df, 3)) +
		(g4/Math.pow(df, 4));

	    return t;
	},

	z: {
	    scoreFromAlpha: function(alpha, tails) {
		// convert a two-tailed alpha to a single tail
		if (Number(tails) == 2) {
		    alpha = alpha/2;
		}

		if (alpha == 1) {
		    return 3;
		}

		if (alpha > 0.5) {
		    alpha = 1 - alpha;
		}

		var t = Math.sqrt( Math.log( 1/Math.pow(alpha, 2) ) );

		var c = [
		    2.515517,
		    0.802853,
		    0.010328
		];
		var d = [
		    1.432788,
		    0.1898269,
		    0.001308
		];

		var num = c[0] + ((c[1] + (c[2] * t)) * t);
		var den = 1 + ((d[0] + (d[1] + (d[2] * t)) * t) * t);

		return t - (num/den);
	    },
	},

	erf : function(x){
	    //A&S formula 7.1.26
	    var a1 = 0.254829592,
	    a2 = -0.284496736,
	    a3 = 1.421413741,
	    a4 = -1.453152027,
	    a5 = 1.061405429,
	    p = 0.3275911,
	    x = Math.abs(x),
	    t = 1 / (1 + p * x);
	    //Direct calculation using formula 7.1.26 is absolutely correct
	    //But calculation of nth order polynomial takes O(n^2) operations
	    //return 1 - (a1 * t + a2 * t * t + a3 * t * t * t + a4 * t * t * t * t + a5 * t * t * t * t * t) * Math.Exp(-1 * x * x);

	    //Horner's method, takes O(n) operations for nth order polynomial
	    return 1 - ((((((a5 * t + a4) * t) + a3) * t + a2) * t) + a1) * t * Math.exp(-1 * x * x);
	},

	normdist : function(z){
            sign = 1;
            if (z < 0) sign = -1;
            return 0.5 * (1.0 + sign * this.erf(Math.abs(z)/Math.sqrt(2)));
        },

	standardDeviation : function(values){
	    var avg = this.average(values);

	    var squareDiffs = values.map(function(value){
		var diff = value - avg;
		var sqrDiff = diff * diff;
		return sqrDiff;
	    });
            var sumOfSquareDiffs = _.reduce(squareDiffs, function(diff, memo){return memo + diff}, 0);

	    var avgSquareDiff = sumOfSquareDiffs / (squareDiffs.length - 1);

	    var stdDev = Math.sqrt(avgSquareDiff);
	    return stdDev;

	},

	average : function(data){
	    var sum = data.reduce(function(sum, value){
		return sum + value;
	    }, 0);

	    var avg = sum / data.length;
	    return avg;
	},

	/**
	   Renders a new chart or updates an existing one.
	   @method renderChart
	   @param {Number} alpha The confidence level to use
	*/
	renderChart: function(alpha, q, parts, prs, rs, elemName) {
	    parts.projID = this.projID;
	    q.toSeriesSet({parts: parts, prs: prs, rs: rs})
		.then(function(seriesSet){
		    return seriesSet.toChartData(q.getCalculator(), q.getCICalculator(), alpha);
		}.bind(this))
		.then(_.bind(function(chartData) {
		    chartData.el = elemName;
		    chartData.alpha = alpha;
		    this.chart = new GColView(chartData);
		    this.listenTo(this.chart, 'GColView:addtoexcel', function(data, type, properties) {
			var jsonData = {
			    name: q.get('name'),
			    type: 'graph',
			    data: data,
			    graph_type: type,
			    properties: properties,
			    graph_theme_id: null
			};
			app.ge.trigger('addtoexcel', jsonData);
		    });
		    this.chart.render();
		}, this));
	},

	/**
	   Handle excel data being passed up from the graph (GColView).
	   @method addGraphToExcel
	   @param {Object} data  Plain object passed up by GColView that just needs to get wrapped and
	   passed up to the parent view that handles adding things to excel.
	   @param {String} type  The type of graph, currently takes 'vertical' or 'horizontal' but I
	   don't think it makes a difference right now.
	*/
	addSUPRQGraphToExcel: function(data, type, properties) {
	    var jsonData = {
		name: 'SUPR-Q',
		type: 'graph',
		data: data,
		graph_type: type,
		properties: properties,
		graph_theme_id: null
	    };
	    app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	   Handle excel data being passed up from the graph (GColView).
	   @method addGraphToExcel
	   @param {Object} data  Plain object passed up by GColView that just needs to get wrapped and
	   passed up to the parent view that handles adding things to excel.
	   @param {String} type  The type of graph, currently takes 'vertical' or 'horizontal' but I
	   don't think it makes a difference right now.
	*/
	addGraphToExcel: function(data, type, properties) {
	    var jsonData = {
		name: 'SUPR-Q',
		type: 'graph',
		data: data,
		graph_type: type,
		properties: properties,
		graph_theme_id: null
	    };
	    app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	   Fill out the info panel with editing controls
	   @method renderEditInfo
	*/
	renderEditInfo: function() {
	    var view       = this.suprq.toTpl();
	    view.title     = this.title;
	    view.prevTitle = this.prevTitle;
	    this.$('.panel-body').html(Mustache.render(this.editInfoTpl, view, app.partials()));
	    this.addAllQs();
	},

	/**
	 * Adds all possible questions to the drop-down lists.
	 * @method addAllQs
	 */
	addAllQs: function() {
	    var view = {
		title     : this.title,
		prevTitle : this.prevTitle,
		questions : this.questions()
	    }; //view
	    //get the list of rating-type questions
	    var allowed    = [2];
	    var allQuestionsInfo = {
		options: this.qs.chain()
		    .filter(function(q) {
			return _.contains(allowed, +q.get('type_id'));
		    })
		    .map(function(q) {
			return {
			    id      : +q.get('id'),
			    type    : 'question',
			    name    : q.get('name'),
			    native_id : q.get('native_id'),
			    type_id : q.get('type_id')
			};
		    })
		    .value()
	    };

	    //fill each option box with the list of questions
	    for(var awesome in view.questions) {
		this.$('[name="' + view.questions[awesome].id + '_id"]').html(
		    Mustache.render(this.optionTpl, allQuestionsInfo, app.partials()));

		//select the proper option
		var theID = view.questions[awesome].qid;
		this.$('[name="' + view.questions[awesome].id + '_id"] [data-id="' + theID + '"]').attr('selected','selected');
	    }
	},

	/**
	   Basic close. Except it also triggers a 'close' event, which it probably shouldn't be.
	   @method close
	*/
	close: function() {
	    this.remove();
	},

	/**
	 * Begins the editing of this analytic. Switches the nav header to cancel/save buttons.
	 * @medtod editAnalytic
	 */
	editAnalytic: function() {
	    var view            = {};
	    view.title          = this.title;
	    view.prevTitle      = this.prevTitle;
	    view.panel_controls = [
		{
		    loner      : true,
		    label      : 'Save',
		    class_name : 'save-edit-analytic',
		    btn_type   : 'primary'
		}, {
		    loner      : true,
		    label      : 'Cancel',
		    class_name : 'cancel-edit-analytic',
		    btn_type   : 'link'
		}, {
		    loner      : true,
		    label      : 'Delete',
		    class_name : 'delete-analytic',
		    btn_type   : 'danger'
		}
	    ];
	    this.$('.panel-nav .panel-heading').replaceWith(
		Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
	    );

	    this.renderEditInfo();
	},

	/**
	 * Cancel edits made to this analytic.
	 * @method cancelEditAnalytic
	 */
	cancelEditAnalytic: function() {
	    this.renderNavHeader();
	    this.renderInfo();
	},

	/**
	 * Confirm the edits made to this analytic.
	 * @method saveEditAnalytic
	 */
	saveEditAnalytic: function() {
	    var data = _.reduce($('#edit-question-form').serializeArray(), function(memo, val) {
		memo[val.name] = val.value;
		return memo;
	    }, {});
	    this.suprq.save(data, {
		wait: true,
		success: _.bind(function(model, res, opts) {}, this),
		error: function(model, res, opts) {
		    app.ge.trigger('alert', new AlertView({
			m: 'Problem saving the edited analytic',
			type: 'alert-danger'
		    }));
		}
	    });


	},

	/**
	 * Attempts to delete this analytic.
	 * @method deleteAnalytic
	 */
	deleteAnalytic: function() {
	    if (
		window.confirm("Are you sure you want to delete this SUPR-Q Lite?\n\n" +
			       "This action cannot be undone.")
	    ) {
		this.suprqSub.destroy({
		    wait    : true,
		    success : _.bind(function() {
			//this item no longer exists, so go back to list screen
			this.navigateBack();
		    }, this),
		    error   : function(mod, res, opts) {
			app.ge.trigger('alert', new AlertView({
			    m: 'Error deleting SUPR-QLite:' + res.responseText,
			    type: 'alert-danger'
			}));
		    }
		});
	    }
	},

	/**
	   @method addDataToExcel
	   @param {event} [e] Potentially passed the triggering event.
	*/
	addDataToExcel: function(e) {
	    if (e) e.preventDefault();
	    app.ge.trigger('addtoexcel', this.eData);
	},
    }));
