/* global require, module */

var _ = require('underscore');

/**
Statistics functions
@class st
@static
*/
var st = (function() {

	return {
		// utility functions
		repeat: function(val, times) {
			var r = [];
			for (var i = 0; i < times; i++) {
				r.push(val);
			}
			return r;
		},

		sum: function(vals) {
			vals = _.chain(vals)
				.map(function(val){ return Number(val); })
				.reduce(function(memo, val){ if (!_.isNaN(val)) memo.push(val); return memo; }, [])
				.value();
			return _.reduce(vals, function(memo, val) {
				return memo + Number(val);
			}, 0);
		},

		fact: _.memoize(function(num) {
			num = Number(num);
			if (!_.isFinite(num)) throw new Error('st.fact requires finite numbers');
			if (num < 0) throw new Error('st.fact works on non-negative numbers only');
			num = parseInt(num, 10);

			var start = 1;
			for (var i = 2; i <= num; i++) {
				start = start * i;
				if (!_.isFinite(start)) throw new Error('st.fact got too big');
			}
			return start;
		}),

		mean: function(vals){
			vals = _.chain(vals)
				.reject(function(val){ return val === null; })
				.map(function(val){ return Number(val); })
				.reduce(function(memo, val){ if (!_.isNaN(val)) memo.push(val); return memo; }, [])
				.value();
			return st.sum(vals) / vals.length;
			},

		/**
		 * Will throw errors if any of the incoming values = 0.
		 *
		 * = (ln(vals[0]) + ln(vals[1]) + ...) / length(vals)
		 *
		 * @method meanLn
		 * @param  {Array of numbers} vals values from which to calculate the natural logarithmic mean
		 * @return {number} the mean of the natural logarithms of each value.
		 */
		meanLn: function(vals) {
			vals = _.map(vals, function(val) {
				return Math.log(Number(val));
			});

			return st.mean(vals);
		},

		meanGeo: function(vals){ return Math.pow(Math.E, st.meanLn(vals)); },

		sd: function(vals) {
			if (vals.length === 1) return 0;

			var mean = st.mean(vals);

			var diffs = _.chain(vals)
						.reject(function(val){ return val === null; })
						.map(function(val) {
							return Math.pow(Number(val) - mean, 2);
						})
						.value();

			return Math.sqrt(st.sum(diffs)/(diffs.length - 1));
		},

		sdLn: function(vals) {
			vals = _.map(vals, function(val) {
				return Math.log(Number(val));
			});
			return st.sd(vals);
		},

		stdErr: function(sd, n) {
			return Number(sd) / Math.sqrt(Number(n));
		},

		zScore: function(alpha, tails) {
			if (tails == 2) {
				switch (alpha) {
					case 0.1:
						return 1.644853627;
				}
			}
		},

		lowBound: function(x, min){ return x < min ? min : x; },

		highBound: function(x, max){ return x > max ? max : x; },

		ci: {
			adjWald: function(x, n, alpha) {
				x     = Number(x);
				n     = Number(n);
				var z = st.z.scoreFromAlpha(alpha, 2);

				var p    = x/n;
				var adjX = x + (Math.pow(z, 2) / 2);
				var adjN = n + Math.pow(z, 2);
				var adjP = adjX / adjN;

				var margin = Math.sqrt( (adjP * (1 - adjP)) / adjN ) * z;

				var low  = st.lowBound(adjP - margin, 0);
				var high = st.highBound(adjP + margin, 1);
				console.log(x, n, z, p, adjX, adjN, adjP, margin, low, high);
				return [low, high, x];
			},

			tCont: function(mean, sd, n, alpha) {
				// n -1 degrees of freedom
				// two tailed alpha
				var tScore = st.t.scoreFromAlpha(n - 1, alpha, 2);
				var diff = tScore * st.stdErr(sd, n);

				var low = mean - diff;
				var high = mean + diff;
				console.log(high);
				return [low, high, n, sd];
			},

			// this expects the meanLn and sdLn
			tContGeo: function(mean, sd, n, alpha) {
				var ci = st.ci.tCont(mean, sd, n, alpha);
				return _.map(ci, function(bound){ return Math.pow(Math.E, bound); });
			}
		},

		t: {
			scoreFromAlpha: function(df, alpha, tails) {
				// convert a two-tailed alpha to a single tail
				if (Number(tails) == 2) {
					alpha = alpha/2;
				}

				var z = st.z.scoreFromAlpha(alpha);

				// t distribution matches z distribution at large df
				if (df > 10000) {
					return z;
				}

				var g1 = Math.pow(z, 3) + z;
				g1 = g1/4;

				var g2 = (5 * Math.pow(z, 5)) +
						 (16 * Math.pow(z, 3)) +
						 (3 * z);
				g2 = g2/96;

				var g3 = (3 * Math.pow(z, 7)) +
						 (19 * Math.pow(z, 5)) +
						 (17 * Math.pow(z, 3)) -
						 (15 * z);
				g3 = g3/384;

				var g4 = (79 * Math.pow(z, 9)) +
						 (776 * Math.pow(z, 7)) +
						 (1482 * Math.pow(z, 5)) -
						 (1920 * Math.pow(z, 3)) -
						 (945 * z);
				g4 = g4/92160;

				var t = z +
						(g1/df) +
						(g2/Math.pow(df, 2)) +
						(g3/Math.pow(df, 3)) +
						(g4/Math.pow(df, 4));

				return t;
			},

			/**
			Converts a t-score into the equivalent two-tailed area under the t-distribution curve
			@method .t.twoTailsFromScore
			@param {Number} score the t-score to convert
			@param {Number} df The degrees of freedom
			*/
			twoTailsFromScore: function(score, df) {
				score = Math.abs(score);

				if (df === 0) throw new Error('df can never be 0');

				var ACONST = 0.36338023,
						w = Math.atan(score / Math.sqrt(df)),
						sinW = Math.sin(w),
						cosW = Math.cos(w),
						t1, t2, j1, j2, k2;

				// short circuit in special cases
				if (df == 2) return (1 - (0.5 * (1 + sinW))) * 2;
				if (df == 1) return (1 - (0.5 * (1 + (w * (1 - ACONST))))) * 2;
				if (df == 3) return (1 - (0.5 * (1 + ((w + sinW * cosW) * (1 - ACONST))))) * 2;

				if (df % 2 === 0) {
					t1 = sinW;
					t2 = sinW;
					j1 = -1;
					j2 = 0;
					k2 = (df - 2) / 2;
				}
				else {
					t2 = sinW * cosW;
					t1 = w + t2;
					j1 = 0;
					j2 = 1;
					k2 = (df - 3) / 2;
				}

				for (var i = 1; i <= k2; i++) {
					j1 = j1 + 2;
					j2 = j2 + 2;
					t2 = t2 * cosW * cosW * j1 / j2;
					t1 = t1 + t2;
				}

				return (1 - (0.5 * (1 + (t1 * (1 - ACONST * (df % 2)))))) * 2;
			},

			/**
			Computes the Welch-Satterthwaite degrees of freedom, used for the 2-sample t test
			@method welchSatterthwaiteDF
			@param {Number} s1 Sample one standard deviation
			@param {Number} n1 Sample one size
			@param {Number} s2 Sample two standard deviation
			@param {Number} n2 Sample two size
			*/
			welchSatterthwaiteDF: function(s1, n1, s2, n2) {
				s1 = Number(s1);
				n1 = Number(n1);
				s2 = Number(s2);
				n2 = Number(n2);

				var part1 = Math.pow(s1, 2) / n1;
				var part2 = Math.pow(s2, 2) / n2;

				var num = Math.pow(part1 + part2, 2);
				var den = (Math.pow(part1, 2) / (n1 - 1)) + (Math.pow(part2, 2) / (n2 - 1));

				return Math.floor(num / den);
			}
		},

		z: {
			scoreFromAlpha: function(alpha, tails) {
				// convert a two-tailed alpha to a single tail
				if (Number(tails) == 2) {
					alpha = alpha/2;
				}

				if (alpha == 1) {
					return 3;
				}

				if (alpha > 0.5) {
					alpha = 1 - alpha;
				}

				var t = Math.sqrt( Math.log( 1/Math.pow(alpha, 2) ) );

				var c = [
					2.515517,
					0.802853,
					0.010328
				];
				var d = [
					1.432788,
					0.1898269,
					0.001308
				];

				var num = c[0] + ((c[1] + (c[2] * t)) * t);
				var den = 1 + ((d[0] + (d[1] + (d[2] * t)) * t) * t);

				return t - (num/den);
			},

			/**
			Converts a z-score into a percentile for the area of one tail. Equivalent to
			Excel's 1-NORMSDIST function.
			@method oneTailFromScore
			@param {Number} score The z-score you're converting
			@return Number
			*/
			oneTailFromScore: function(score) {
				var z = Math.abs(score);
				var l1 = 0.0000488906 + z * 0.0000053830;
				var l2 = 0.0000380036 + z * l1;
				var l3 = 0.0032776263 + z * l2;
				var l4 = 0.0211410061 + z * l3;
				var l5 = 0.0498673470 + z * l4;

				var base = 1 + (z * l5);
				var exp = -16;

				var result = 1 - Math.pow(base, exp) / 2;

				if (score < 0) result = 1 - result;

				return 1 - result;
			},

			/**
			Converts a z-score into a percentile for the area of one tails. Equivalent to
			Excel's 2*(1-NORMSDIST) function.
			@method twoTailsFromScore
			@param {Number} score The z-score you're converting
			@return Number
			*/
			twoTailsFromScore: function(score){ return 2 * st.z.oneTailFromScore(Math.abs(score)); },
		},

		p: {
			/**
			Computes the p-value for statistical significance between two proportions.
			@method .p.nMinus1TwoProportion
			@param {Number} x1 Sample one completing
			@param {Number} n1 Sample one attempting
			@param {Number} x2 Sample two completing
			@param {Number} n2 Sample two attempting
			@return Number
			*/
			nMinus1TwoProportion: function(x1, n1, x2, n2) {
				x1 = Number(x1);
				n1 = Number(n1);
				x2 = Number(x2);
				n2 = Number(n2);

				var P = (x1 + x2) / (n1 + n2),
						Q = 1 - P,
						N = n1 + n2,
						p1 = x1 / n1,
						p2 = x2 / n2;

				var num = (p1 - p2) * Math.sqrt( (N - 1) / N );

				var den = Math.sqrt( P * Q * ((1/n1) + (1/n2)) );

				var z = num / den;

				return st.z.twoTailsFromScore(z);
			},

			/**
			@method .p.twoSampleT
			@param {Number} x1 Sample one mean
			@param {Number} s2 Sample one standard deviation
			@param {Number} n1 Sample one size
			@param {Number} x2 Sample two mean
			@param {Number} s2 Sample two standard deviation
			@param {Number} n2 Sample two size
			@return Number
			*/
			twoSampleT: function(x1, s1, n1, x2, s2, n2) {
				x1 = Number(x1);
				s1 = Number(s1);
				n1 = Number(n1);
				x2 = Number(x2);
				s2 = Number(s2);
				n2 = Number(n2);

				var num = x1 - x2;

				var part1 = Math.pow(s1, 2) / n1;
				var part2 = Math.pow(s2, 2) / n2;

				var den = Math.sqrt(part1 + part2);

				var t = num / den;
				var df = st.t.welchSatterthwaiteDF(s1, n1, s2, n2);
				return st.t.twoTailsFromScore(t, df);
			},

			/**
			@method .p.pairedT
			@param {Number} d Mean of difference scores
			@param {Number} sd Standard deviation of difference scores
			@param {Number} n Sample size
			@return Number
			*/
			pairedT: function(d, sd, n) {
				var t = d / (sd / Math.sqrt(n)),
						df = n - 1;

				return st.t.twoTailsFromScore(t, df);
			},

			/**
			@method .p.mcnemarExact
			@param {Number} x Minimum discordant pairs
			@param {Number} n Total discordant pairs
			@return Number
			*/
			mcnemarExact: function(x, n) {
				// decide to use the exact or approximate method
				if (x > 50 || n > 50) {
					// too big to use factorials, approximate
					var phat = x / n,
							p = 0.5;
					var zScore = (phat - p) / Math.sqrt( (p*(1-p)) / n);
					return st.z.twoTailsFromScore(zScore);
				}
				else {
					// use the exact method
					var binProbability = function(x, n, p) {
						p = p || 0.5;
						var part1 = st.fact(n) / (st.fact(x) * st.fact(n - x));
						var part2 = Math.pow(p, x) * Math.pow(1 - p, n - x);
						return part1*part2;
					};

					var targetProb = binProbability(x, n);
					var cumProb = targetProb / 2;

					var iterateOver = n - x > x ? _.range(0, x) : _.range(x+1, n+1);
					_.each(iterateOver, function(x){
						cumProb += binProbability(x, n);
					});

					// make two tailed
					return 2 * cumProb;
				}
			}
		}
	};
})();
window.st = st;
module.exports = st;