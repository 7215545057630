/* global require, module */

var Backbone = require('backbone'),
		_        = require('underscore'),
		$        = require('jquery'),
		app      = require('../app.js');

/**
Model used to manage user sessions
@class User
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend({
	getters: {
		/**
		@method get_full_name
		@return {String} Either the concatenated name or 'Anonymous' if not set
		*/
		full_name: function() {
			if (this.first_name && this.last_name) {
				return this.first_name + ' ' + this.last_name;
			} else {
				return 'Anonymous';
			}
		}
	},

	defaults: {
		/**
		@property logged_in
		@type Boolean
		@default false
		*/
		logged_in  : false,

		/**
		@property first_name
		@type String
		@default '' (empty string)
		*/
		first_name : '',

		/**
		@property last_name
		@type String
		@default '' (empty string)
		*/
		last_name  : '',

		/**
		@property username
		@type String
		@default '' (empty string)
		*/
		username   : '',

		/**
		@property team_id
		@type String
		@default null
		*/
		team_id    : null,

		/**
		@property license_type
		@type String
		@default null
		*/
		license_type    : null,

		/**
		@property user_id
		@type String
		@default null
		*/
		user_id    : null
	},

	/**
	Tries to sign in and returns the ajax request's promise. If successful it sets the returned
	user data on itself.
	@method attemptSignIn
	@param {String} email_username Either the email or username to log in with
	@param {String} password The password
	@return {jqXhr promise}
	*/
	attemptSignIn: function(email_username, password) {
		return $.ajax({
			url     : '/api1/authenticate',
			type    : 'POST',
			data    : JSON.stringify({email_username:email_username, password:password}),
			success : _.bind(function(res){ this.set(res.data); }, this)
		});
	},

	/**
	Make a GET request to the signout endpoint, redirect to navigate if it goes through
	@method signOut
	*/
	signOut: function() {
		$.ajax({
			url : 'api1/authenticate/signout',
			success: function(){ app.routes.navigate('/login', {trigger:true}); }
		});
	}
});