/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		ExcludeParticipantsView = require('./ExcludeParticipantsView.js'),
		ParticipantItemView     = require('./ParticipantItemView.js'),
		ParticipantView         = require('./ParticipantView.js'),

		app = require('../app.js');

/**
 * Shows a list of all participants in a given project, and allows them to be included or excluded
 * from the project.
 *
 * @class ProjectParticipantsView
 * @extends Backbone.View
 * @module Views
 */
module.exports = Backbone.View.extend({
	tagName: 'div',

	tpl: $('#ProjectParticipantsView-tpl').html(),

	events: {
		'click .exclude': 'exclude',
		'click .download-participant-list': 'downloadParticipantList'
	},

	/**
	 * Constructs class
	 * 
	 * @method initialize
	 * @param {Object} opts, requires parts key of collection Participants, initializing parameters 
	 */
	initialize: function(opts) {
		/**
		 * Collection of all participants of all in the current project
		 * @property parts 
		 * @type Participants
		 */
		this.parts       = opts.parts;
		/** Text of current project name 
		 * @property projectName 
		 * @type String
		 *
		 */
		this.projectName = opts.projectName;
		this.projectID   = opts.projectID;
		this.title       = 'All Project Participants';
		this.prevTitle   = 'Back';

		this.partsItemViews = [];

		this.listenTo(this.parts, 'reset', this.addAllParts);
		this.parts.fetch({reset: true});
	},

	/**
	 * Renders the main template area and passes itself back up the stack. Creates the "Exclude
	 * Participants" panel control in upper right. Adds a list of all participants in this project.
	 *
	 * @method render
	 * @return {Object} This object to be passed up the render stack
	 */
	render: function() {
		var view = {
			title          : this.title,
			panel_controls : [{
				label: 'Download List',
				loner : true,
				class_name : 'download-participant-list'
			}, {
				label : 'Exclude Participants',
				loner : true,
				class_name : 'exclude'
			}]
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	close: function() {
		this.remove();
		this.trigger('close');
	},

	getTitle: function() {return this.title;},
	setPrevTitle: function(prev) {this.prevTitle = prev;},
	hide: function() {this.$el.hide();},
	show: function() {this.$el.show();},

	exclude: function() {
		this.trigger('navigateinto', new ExcludeParticipantsView({
			parts: this.parts,
			projectID: this.projectID
		}));
	},

	addAllParts: function() {
		this.$('.loading').remove();

		var frag = document.createDocumentFragment();
		if (this.parts.length > 0) {
			this.parts.each(function(p) {
				var partItemView = new ParticipantItemView({model: p, projectID : this.projectID});
				this.partsItemViews.push(partItemView);
				this.listenTo(partItemView, 'view', this.viewP);
				$(frag).append(partItemView.render().el);
			}, this);
		} else {
			$(frag).append(' \
				<tr class="tr-no-cursor"> \
					<td colspan="4" align="center">No participants</td> \
				</tr> \
			');
		}

		this.$('#part-item-mount').append(frag);
	},

	/**
	 * Drills down to view all the responses from a single participant
	 *
	 * @method viewP
	 * @param  {Object} p the participant model
	 */
	viewP: function(p) {
		var q = new ParticipantView({model: p, p: p, projectID : this.projectID});
		this.trigger('navigateinto', q);
	},

	/**
	 * Downloads participants into xls spread sheet
	 *
	 * @method downloadParticipantList
	 * @param {Event} [e] the initiating event
	 */
	downloadParticipantList: function(e){
		if(e) e.preventDefault();

		var d = this.parts;

		var rawData = {'participants' : d.map(function(value){return value.get('id')}),
									 'questions' : [{question_name : 'Names',
																	values: d.map(function(value){return value.get('name');})
																  },
																  {question_name : 'Is Excluded',
																	values: d.map(function(value){return value.get('excluded');})
																  }]
									};

		var dataToSend = [{name : 'Participants List',
												 type : 'values',
												 data : rawData}];

		$.ajax({
			url: '/api1/create_file',
			method: 'POST',
			data: JSON.stringify(dataToSend),
			success: function(xhr, res, three) {
				// comment out line below to debug this
				window.location.href = '/api1/download/' + xhr.filename;
			},
			error: function(xhr, data) {
				console.log(xhr);
				console.log(data);
			},
		});
	}
});