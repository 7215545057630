/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),

		app = require('../app.js');

module.exports = Backbone.View.extend({

	tagName: 'tr',

	tpl: $('#SourcePossibleResponseItemView-tpl').html(),

	events: {
		'click .edit-p-response'      : 'edit',
		'click .original-p-response'  : 'original',
		'click .discard-p-response'   : 'discard',
		'click .saved-flag'           : 'clearFlag',
		'keydown .editing-p-response' : 'keyEdit',	// key pressed while editing response
		'blur .editing-p-response'    : 'doneEdit'	// focus lost - we're done editing this field
	},

	initialize: function(opts) {
		this.markedAs = false;
		this.cells = opts.cells;
	},

	render: function() {
		var isEmpty    = parseInt(this.model.get('is_empty'), 10) == 1;
		var isResponse = parseInt(this.model.get('is_response'), 10) == 1;
		var model = this.model;

		var valInModel = function(theVal) {
			if(model.get(theVal)) {
				return model.get(theVal);
			}
			return false;
		};

		_.each(this.cells, function(c) {
			var contents, spanClass, cellClass;

			if (c.showControls) {
				cellClass += ' save-p-response-as';
			} else if (c.center) {
				cellClass += ' td-centered';
			}

			if (!isEmpty && isResponse && c.valText) {
				contents  = c.valText;
				spanClass = '';
			} else if (valInModel(c.val)) {
				contents  = valInModel(c.val);
				spanClass = c.val == 'source_response' ? 'pr-src-res' : '';
			} else if (c.showControls) {
				contents = '';
			} else if (isEmpty) {
				contents  = c.explain ? 'Empty (no response)' : '\u2013';
				spanClass = 'pr-empty';
			} else if (!isResponse) {
				contents  = c.explain ? 'Not presented' : '\u2013';
				spanClass = 'pr-no-response';
			}
			c.contents  = contents;
			c.spanClass = spanClass;
			c.cellClass = cellClass;
		});


		var view = {
			title     : this.title,
			prevTitle : this.prevTitle,
			cells     : this.cells,
			model     : this.model.toJSON()
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.$('.editing-p-response').hide();
		return this;
	},

	original: function(e) {
		this.clearFlag(e);
		this.$('.save-p-response-as').prepend(
			'<span class="label saved-flag label-info content-orig pull-left"></span>'
		);
		this.$el.addClass('info');
		this.markedAs = 'original';
	},

	originalIfBlank: function(e) {
		if(!this.markedAs) {
			this.original(e);
		}
	},

	discard: function(e) {
		this.clearFlag(e);
		this.$('.save-p-response-as').prepend(
			'<span class="label saved-flag label-danger content-discard pull-left"></span>'
		);
		this.$el.addClass('danger');
		this.markedAs = 'discard';
	},

	discardIfBlank: function(e) {
		if(!this.markedAs) {
			this.discard(e);
		}
	},

	edit: function() {
		//clear the displayed value that had been input
		this.clearFlag();

		this.$('.editing-p-response').show(); //show the edit box (input field)
		this.$('.p-response-controls-container').hide(); //hide control buttons
		this.$('.editing-p-response').parent().css({ //change row height to fit field better
			'padding-top': '1px',
			'padding-bottom': '1px'
		});
		this.$('.editing-p-response').focus(); //start editing
	},

	attemptSaveEdit: function(e) {
		var v = this.$('.editing-p-response').val();
		if(v !== '') { //only save the value if there is a value to save currently in the field
			this.$('.save-p-response-as').prepend( //add two elements:
				'<span class="label saved-flag label-warning content-edit pull-left"></span>' +  //flag showing manually edited
				'<span class="pr-edited-res pull-left">' + v + '</span>' //display the entered value
			);
			this.$el.addClass('warning'); //color the flag
			this.markedAs = 'edited'; //text for the flag
		}
		this.doneEdit(e);
	},


	keyEdit: function(e) {
		var enterHit = e.which === 13;
		var escHit = e.which == 27;
		if (enterHit) {
			this.attemptSaveEdit(e);
		}
		else if(escHit) {
			this.doneEdit(e);
		}
	},

	doneEdit: function(e) {
		this.$('.editing-p-response').parent().removeAttr('style'); //clear styles that make edit box fit
		this.$('.editing-p-response').hide(); //hide the edit box
		this.$('.p-response-controls-container').removeAttr('style'); //restore controls hover actions
	},

	clearFlag: function() {
		this.$el.removeClass('danger info warning');
		this.$('.label, .pr-edited-res').remove();
		this.markedAs = false;
	},

	buildCanonical: function() {
		if (!this.markedAs) {
			return false;
		}

		if (this.markedAs == 'discard') {
			return;
		}

		var m = this.model.toJSON();
		var pr = {
			has_value: m.has_value,
			has_text: m.has_text,
			is_empty: m.is_empty,
			is_response: m.is_response,
			source: 'uz',
			source_id: m.id
		};

		if (this.markedAs == 'original') {
			if (parseInt(m.is_empty, 10) === 1 ||
				parseInt(m.is_response, 10) === 0 ||
				parseInt(m.has_value, 10) === 1 ||
				parseInt(m.has_text, 10) === 1
			) {
				pr.response = null;
			} else {
				pr.response = this.$('.pr-src-res').html();
			}
		}

		if (this.markedAs == 'edited') {
			pr.response = this.$('.pr-edited-res').html();
		}
		return pr;
	},

	close: function() {
		this.remove();
	}

});
