/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin        = require('../mixins/NavigationComponentMixin.js'),
		PossibleResponses               = require('../collections/PossibleResponses.js'),
		SmartPossibleResponses          = require('../collections/SmartPossibleResponses.js'),
		CreateSmartPossibleResponseView = require('./CreateSmartPossibleResponseView.js'),
		AlertView                       = require('./AlertView.js'),

		app = require('../app.js');

/**
@class CreateSmartQuestionView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#CreateSmartQuestionView-tpl').html(),

	events: {
		'click .back'                             : 'navigateBack',
		'click .create-smart'                     : 'createSmart',

		'change select[name="type_id"]'           : 'changeType',
		'change input[name="sample_size_method"]' : 'changeMethod'
	},

	/**
	Pass in/initialize properties, start listening to child views.
	@method initialize
	@param {Object} opts Configuration object, follow the format:

		{
			projID   : ID of current project,
			qs       : {{#crossLink "Questions"}}Questions{{/crossLink}},
			prs      : {{#crossLink "PossibleResponses"}}PossibleResponses{{/crossLink}},
			smartQs  : {{#crossLink "SmartQuestions"}}SmartQuestions{{/crossLink}},
			smartPrs : {{#crossLink "SmartPossibleResponses"}}SmartPossibleResponses{{/crossLink}}
		}

	All fields currently required.
	*/
	initialize: function(opts) {
		/**
		@property title
		@type String
		@default 'New Smart Question'
		@final
		*/
		this.title = 'New Smart Question';

		/**
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		Must be passed in.
		@property projID
		@type Number
		*/
		this.projID = opts.projID;

		/**
		Must be passed in.
		@property qs
		@type Questions
		*/
		this.qs = opts.qs;

		/**
		Must be passed in.
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs = opts.smartQs;

		/**
		Initialized by view.
		@property prs
		@type PossibleResponses
		*/
		this.prs = new PossibleResponses([], {projID: this.projID});

		/**
		Initialized by view.
		@property smartPrs
		@type SmartPossibleResponses
		*/
		this.smartPrs = new SmartPossibleResponses([], {projID: this.projID});

		/**
		Array to keep track of child views.
		@property smartPrViews
		@type Array (of CreateSmartPossibleResponseViews)
		*/
		this.smartPrViews = [new CreateSmartPossibleResponseView({
			projID   : this.projID,
			qs       : this.qs,
			smartQs  : this.smartQs,
			prs      : this.prs,
			smartPrs : this.smartPrs
		})];
		_.each(this.smartPrViews, function(view) {
			this.listenTo(view, 'addresponse', this.addResponse);
			this.listenTo(view, 'remove', this.removeResponse);
		}, this);

		this.smartPrs.fetch({reset: true});
		this.prs.fetch({reset: true});
	},

	/**
	Render the header, main Smart Question creation forms (name, type, etc.), then go through each
	child CreateSmartPossibleResponseView rendering it into a fragment and appending to dom.
	@method render
	@chainable
	*/
	render: function() {
		var view            = {};
		view.title          = this.title;
		view.prevTitle      = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				label      : 'Cancel',
				btn_type   : 'link',
				class_name : 'back'
			},
			{
				loner      : true,
				label      : 'Save',
				btn_type   : 'primary',
				class_name : 'create-smart'
			}
		];

		view.q_types = _.chain(app.qTypeHash)
			.map(function(type, id) {
				return {
					type        : type,
					type_id     : id,
					is_selected : false
				};
			})
			.reject(function(val){ return +val.type_id === 4 || +val.type_id === 5; })
			.value();

		view.sample_size_methods = _.map(app.sampleMethodHash, function(md, i) {
			return {
				method_name : md.name,
				method_id   : i,
				is_selected : i == 1,
				disabled    : _.contains([2], +i) // responses not supported
			};
		});

		view.questions = this.qs.map(function(q){ return {id:q.get('id'), name:q.get('name')}; });

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		var frag = document.createDocumentFragment();
		_.each(this.smartPrViews, function(view) {
			$(frag).append(view.render().el);
		});
		this.$el.append(frag);

		return this;
	},

	/**
	Closes the view.
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Emits an event causing the parent view to navigate back in the stack. Part of an interface.
	@method navigateBack
	@param {event object} [e] Potentially passed an event object from clicking an a tag or similar.
	*/
	navigateBack: function(e) {
		if (e) {
			e.preventDefault();
		}
		/**
		Tells a parent view to close this one and navigate back in the stack. Part of an interface.
		@event navigateback
		*/
		this.trigger('navigateback');
	},

	/**
	Initializes a new CreateSmartPossibleResponseView, starts listening to it, adds it to the
	smartPrViews array, renders it and appends it's content to the page.
	@method addResponse
	*/
	addResponse: function() {
		var newView = new CreateSmartPossibleResponseView({
			qs       : this.qs,
			prs      : this.prs,
			smartQs  : this.smartQs,
			smartPrs : this.smartPrs,
			projID   : this.projID
		});
		this.listenTo(newView, 'addresponse', this.addResponse);
		this.listenTo(newView, 'remove', this.removeResponse);
		this.smartPrViews.push(newView);
		this.$el.append(newView.render().el);
	},

	/**
	Stop listening to a child view, close it, then remove it from the smartPrViews array.
	@method removeResponse
	@param {CreateSmartPossibleResponseView} view The child view to remove
	*/
	removeResponse: function(view) {
		this.stopListening(view);
		view.close();
		this.smartPrViews = _.reject(this.smartPrViews, function(v) {
			return v.cid == view.cid;
		});
	},

	/**
	Figure out the type to change to, if necessary trim the array of child views down, then call
	the `changeType()` method on each child.
	@method changeType
	@param {event} e Event object from changing the select element
	*/
	changeType: function(e) {
		var newType = +$(e.target).val();
		if (newType == 3 || newType == 2) { // non-categorical
			// clean up the extraneous views
			_.chain(this.smartPrViews)
				.rest()
				.each(function(sprview) {
					this.stopListening(sprview);
					sprview.close();
				}, this);

			this.smartPrViews = this.smartPrViews.slice(0, 1);
		}

		_.each(this.smartPrViews, function(view) {
			view.changeType(newType);
		});

		this.changeMethod();
	},

	/**
	Called when changing the sample size method, shows/hides the sample_size_question_id select
	element
	@method changeMethod
	@param {event} [e] Potentially passed user event
	*/
	changeMethod: function(e) {
		var
		newMethod = this.$('input[name="sample_size_method"]:checked').val(),
		type      = this.$('select.q-type').val();

		if (newMethod == 3 && type == 1) {
			this.$('.form-group.ssize-id').removeClass('hidden');
		} else {
			this.$('.form-group.ssize-id').addClass('hidden');
		}
	},

	/**
	Read the form to get the JSON for a SmartQuestion, call `generateSmartPrJSON()` on child views to
	build an array of SmartPossibleResponse JSON objects, call `generateFilter()` on each child to
	build an array of Filter JSON objects.

	Manually make an ajax request to the `/smart_question/save_with_assoc` endpoint because we want
	only want to make one request but save a lot of data.

	If the request succeeds add the new ID to the smartQ JSON and add it to the smartQs property.
	@method createSmart
	*/
	createSmart: function() {
		var smartQForm = $('#create-smart-form').serializeArray();
		var smartQ = _.reduce(smartQForm, function(memo, val) {
			memo[val.name] = val.value;
			return memo;
		}, {});
		smartQ.project_id = this.projID;

		if (smartQ.sample_size_method != 3 || smartQ.type_id != 1) {
			smartQ.sample_size_question_id = null;
		}

		var smartPrs = _.map(this.smartPrViews, function(view) { return view.generateSmartPrJSON(); });

		var filters = _.map(this.smartPrViews, function(view) { return view.generateFilter(); });
		var d = JSON.stringify({
				smart_question           : smartQ,
				smart_possible_responses : smartPrs,
				filters                  : filters
		});

		$.ajax({
			url         : '/api1/smart_question/save_with_assoc',
			type        : 'POST',
			contentType : 'application/json',
			data        : d,
			success     : _.bind(function(data) {
				smartQ.id = data.data.smart_question_id;
				smartQ.num_participants = data.data.num_participants;

				if (smartQ.type_id == 1 && smartQ.sample_size_question_id) {
					smartQ.num_presentations = this.qs
						.get(smartQ.sample_size_question_id)
						.get('num_presentations');
				}

				if (smartQ.type_id == 3 || smartQ.type_id == 2) {
					smartQ.num_presentations = this.qs
						.get(smartPrs[0].target_question_id)
						.get('num_presentations');
				}

				this.smartQs.add(smartQ);
				this.navigateBack();
			}, this),
			error: function(res) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem saving question: ' + res.responseJSON.m,
					type: 'alert-danger'
				}));
			}
		});
	}
}));