/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		ListHeaderView = require('./ListHeaderView.js');

/**
Justs adds the correct tpl to use.

@class QuestionsHeaderView
@constructor
@extends HeaderView
@module Views
*/
module.exports = ListHeaderView.extend({
	tpl: $('#QuestionsHeaderView-tpl').html()
});