/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Analytics = require('../collections/Analytics.js'),

		AlertView                  = require('./AlertView.js'),
		AnalyticItemView           = require('./AnalyticItemView.js'),
		CrosstabAnalyticView       = require('./CrosstabAnalyticView.js'),
		CreateCrosstabAnalyticView = require('./CreateCrosstabAnalyticView.js'),
		CreateSUPRQLiteView        = require('./CreateSUPRQLiteView.js'),
		CreateSUPRQFullView        = require('./CreateSUPRQFullView.js'),
		CreateSUSView              = require('./CreateSUSView.js'),
		SUPRQLiteAnalyticView      = require('./SUPRQLiteAnalyticView.js'),
		SUPRQFullAnalyticView      = require('./SUPRQFullAnalyticView.js'),
		SUSAnalyticView            = require('./SUSAnalyticView.js'),

		app = require('../app.js');

/**
@class ProjectAnalyticsView
@constructor
@extends Backbone.View
@uses NavigationComponentMixin
@module Views
*/
module.exports = Backbone.View.extend(_.extend({}, NavigationComponentMixin, {
	/**
	@property tagName
	@type String
	@default 'div'
	@final
	*/
	tagName: 'div',

	/**
	@property tpl
	@type String
	@default Template String
	@final
	*/
	tpl: $('#ProjectAnalyticsView-tpl').html(),

	/**
	@property events
	@type Object
	*/
	events: {
		//drop-down create menu
		'click .create-crosstab'  : 'createCrosstab',
		'click .create-suprqlite' : 'createSUPRQLite',
		'click .create-suprqfull' : 'createSUPRQFull',
		'click .create-sus'       : 'createSUS'
	}, //events

	/**
	@method initialize
	@param {Object} opts
	*/
	initialize: function(opts) {
		/**
		@property project
		@type Project
		*/
		this.project = opts.project;

		/**
		@property title
		@type String
		@default 'All Analyses'
		@final
		*/
		this.title = 'All Analyses';

		/**
		@property projID
		@type String
		*/
		this.projID = this.project.get('id');

		/**
		@property aItemViews
		@type Array
		*/
		this.aItemViews = [];

		/**
		@property as
		@type Analytics
		*/
		this.as = new Analytics([], {projID: this.projID});

		this.as.fetch(
			{
				success: _.bind(function(one, two, three) {
					this.addAllAs();
					this.listenTo(this.as, 'add', this.addA);
				}, this),
				error: _.bind(function(one, two, three) {
					console.log(one);
					console.log(two);
					console.log(three);
					console.log(two.responseText);
					app.ge.trigger('alert', new AlertView({
						m: 'Error loading questions:' + two.responseText,
						type: 'alert-danger'
					}));
				})
			}
		);
	}, //initialize

	/**
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	@method render
	@chainable
	*/
	render: function() {
		var view = {title:this.title};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	}, //render

	/**
	@method addAllAs
	*/
	addAllAs: function() {
		var frag = document.createDocumentFragment();
		if (this.as.length > 0) {
			this.as.each(function(analytic) {
				var aItemView = new AnalyticItemView({model: analytic});
				this.aItemViews.push(aItemView);
				this.listenTo(aItemView, 'view', this.viewAnalytic);
				this.listenTo(aItemView, 'deleted', this.deletedAItemView);
				$(frag).append(aItemView.render().el);
			}, this);
		}

		this.$('.a-item-mount').html(frag);
	}, //addAllAs

	/**
	@method addA
	@param {Analytic} newA The new model being added to the collection
	*/
	addA: function(newA) {
		var aItemView = new AnalyticItemView({model: newA});
		this.aItemViews.push(aItemView);
		this.listenTo(aItemView, 'view', this.viewAnalytic);
		this.listenTo(aItemView, 'deleted', this.deletedAItemView);
		this.$('.a-item-mount').append(aItemView.render().el);
	},

	/**
	@method viewAnalytic
	@param {Analytic} a The analytic to inspect
	*/
	viewAnalytic: function(a) {
		switch (a.get('type')) {
			case 'crosstab':
				this.trigger('navigateinto', new CrosstabAnalyticView({
					crosstab : a,
					project  : this.project
				}));
				break;
			case 'suprqlite':
				this.trigger('navigateinto', new SUPRQLiteAnalyticView({
					suprq: a,
					projID   : this.projID
				}));
				break;
			case 'suprqfull':
				this.trigger('navigateinto', new SUPRQFullAnalyticView({
					suprq: a,
					projID   : this.projID
				}));
				break;
			case 'sus':
				this.trigger('navigateinto', new SUSAnalyticView({
					sus: a,
					projID   : this.projID
				}));
				break;

			default:
		}
	},

	/**
	@method deletedAItemView
	*/
	deletedAItemView: function(view) {
		this.aItemViews = _.reject(this.aItemViews, function(aiv){ return aiv == view; });
	},

	/**
	@method createCrosstab
	@param {event} [e] Potentially passed event
	*/
	createCrosstab: function(e) {
		if (e) e.preventDefault();

		this.trigger('navigateinto', new CreateCrosstabAnalyticView({
			projID : this.projID,
			as     : this.as
		}));
	}, //createCrosstab

	/**
	@method createSUPRQLite
	@param {event} [e] Potentially passed event
	*/
	createSUPRQLite: function(e) {
		if (e) e.preventDefault();

		this.trigger('navigateinto', new CreateSUPRQLiteView({
			projID : this.projID,
			as     : this.as
		}));
	}, //createSUPRQLite

	/**
	@method createSUPRQFull
	@param {event} [e] Potentially passed event
	*/
	createSUPRQFull: function(e) {
		if (e) e.preventDefault();

		this.trigger('navigateinto', new CreateSUPRQFullView({
			projID : this.projID,
			as     : this.as
		}));
	}, //createSUPRQFull

	/**
	@method createSUS
	@param {event} [e] Potentially passed event
	*/
	createSUS: function(e) {
		if (e) e.preventDefault();

		this.trigger('navigateinto', new CreateSUSView({
			projID : this.projID,
			as     : this.as
		}));
	}, //createSUS
})); //app.ProjectAnalyticsView