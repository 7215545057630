/* global require, module */

var Backbone = require('backbone'),
		app      = require('../app.js'),

		ProjectPageView    = require('../views/ProjectPageView.js'),
		ProjectsPageView   = require('../views/ProjectsPageView.js'),
		AccountPageView    = require('../views/AccountPageView.js'),
		AdminPageView    = require('../views/AdminPageView.js'),
		NewProjectPageView = require('../views/NewProjectPageView.js'),
		LoginPageView      = require('../views/LoginPageView.js');

module.exports = Backbone.Router.extend({
	routes: {
		'projects/new(/:colID)': 'newProject', // optional `/` on the end
		'projects(/:colID)': 'showProjects',
		'project/(:projID)(/:feature)': 'showProject',
		'account': 'showAccount',
		'admin': 'showAdmin',
		'*path': 'default'
	},

	showProjects: function(colID) {
		document.title = 'All Projects';
		app.clearCurrentPage();

		// set the current page view
		app.currentPage = new ProjectsPageView();
		app.currentPage.render();
	},

	showProject: function(id, f) {
		app.clearCurrentPage();
		document.title = 'Loading project...';
		app.currentPage = new ProjectPageView({id: id, activate: f});
		app.currentPage.render();
	},

	showAccount: function() {
		app.clearCurrentPage();
		document.title = 'Loading account...';
		app.currentPage = new AccountPageView({});
		app.currentPage.render();
	},

	showAdmin: function() {
		app.clearCurrentPage();
		document.title = 'Loading admin...';
		app.currentPage = new AdminPageView({});
		app.currentPage.render();
	},

	newProject: function(colID) {
		colID = colID || false;

		document.title = 'New Project';
		app.clearCurrentPage();

		app.currentPage = new NewProjectPageView({
			target: colID
		});
		app.currentPage.render();
	},

	default: function() {
		app.clearCurrentPage();
		if (app.user.get('logged_in')) {
			this.showProjects();
		} else {
			app.currentPage = new LoginPageView({});
			app.currentPage.render();
		}
	}
});