/* global require, module */

var _ = require('underscore'),
		st = require('../st.js'),

		SSUtils  = require('./SeriesSetUtilities.js'),
		CICalculator = require('./CICalculator.js');

/**
@class TContCICalculator
@constructor
@extends CICalculator
@module Models
*/
module.exports = CICalculator.extend({
	defaults: function() {
		return _.extend(CICalculator.prototype.defaults(), {type: 'tcont', name: 'T-Continuous'});
	},


	/**
	Compute the confidence interval for a DataPoint
	@method computeCI
	@param {DataPoint} dp
	@param {Number} alpha The confidence level to use
	@return {Array<Number>} [Low value, high value]
	*/
	computeCI: function(dp, alpha) {
		var raw  = SSUtils.getRs(dp),
				mean = st.mean(raw),
				sd   = st.sd(raw),
				n    = raw.length;
		return st.ci.tCont(mean, sd, n, alpha);
	}
});