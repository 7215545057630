/* global require, module */

var _ = require('underscore'),
		$ = require('jquery'),

		SmartQuestion = require('./SmartQuestion.js'),
		SeriesSet = require('./SeriesSet.js'),
		app = require('../app.js');

/**
@class CategoricalSmartQuestion
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = SmartQuestion.extend({
	urlRoot: '/api1/smart_question',

	getters: _.extend(SmartQuestion.prototype.getters, {
		// only for categorical
		is_pr_editable: function(){ return true; }
	}),

	/**
	@method toSeriesSet
	@param {object} args Requires parts, prs, rs, smartPrs, and filters
	@return SeriesSet Promise
	*/
	toSeriesSet: function(args) {
		var qName = this.get('name'),
				qID   = +this.get('id'),

				smartPrs = args.smartPrs.where({smart_question_id: qID.toString()}),
				prs      = args.prs,
				parts    = args.parts,
				rs       = args.rs,
				filters  = args.filters,

				// maps sprID => object of matching partIDs
				matchingParts = {},
				dfd           = $.Deferred();

		var samplePartIDs;
		switch (+this.get('sample_size_method')) {
			case 1:
				samplePartIDs = parts.reduce(function(memo, part) {
					var partID = +part.get('id');
					if (partID in memo) throw new Error('Expected unique parts');
					memo[partID] = true;
					return memo;
				}, {});
				break;

			case 3:
				var rsForSample = rs.filter(function(r){
					return !!+r.get('is_response') && r.get('question_id') == this.get('sample_size_question_id');
				}, this);
				samplePartIDs = rsForSample.reduce(function(memo, r) {
					var partID = +r.get('participant_id');
					memo[partID] = true;
					return memo;
				}, {});
				break;
		}

		// start by applying the filters for each smart_possible_response
		$.when.apply(null, smartPrs.map(function(spr){
			return filters.get(spr.get('filter_id')).applyFilter(parts.toTpl(), prs.toTpl(), rs.toTpl());
		}))
		.then(function(/* matchingParts */){
			var args = _.toArray(arguments);
			// store the matching participants for each smart_possible_response
			smartPrs.forEach(function(spr, index){
				matchingParts[spr.get('id')] = args[index].reduce(function(memo, part) {
					if (part.id in memo) throw new Error('Expected unique participants');
					memo[part.id] = true;
					return memo;
				}, {});
			});

			var dps = smartPrs.map(function(spr) {
				var sprID = +spr.get('id');

				return {
					label: spr.get('response'),
					source_type: 'smart_possible_response',
					source_id: sprID,
					parent_id: qID,
					type: 'categorical',
					seq: null,
					responses: parts.map(function(part) {
						var partID = +part.get('id'),
								hadResponse = partID in samplePartIDs,
								hadThisResponse = partID in matchingParts[sprID];

						return {
							part_id     : partID,
							is_excluded : !!+part.get('excluded'),
							is_response : hadResponse,
							is_empty    : hadResponse && !hadThisResponse,
							response    : hadThisResponse ? spr.get('response') : null
						};
					})
				};
			});

			var dataSeries = {
				name : qName,
				seq  : null,
				type : 'categorical',
				data : dps
			};

			dfd.resolve(new SeriesSet({
				series : [dataSeries],
				name   : qName,
				type   : 'categorical'
			}));
		});

		return dfd;
	},

	generatePrsList: function(smartPrs) {
		return smartPrs.map(function(pr) {
			if (+pr.get('is_empty') === 1) return {id: pr.get('id'), response:'Empty'};
			if (+pr.get('is_response') === 0) return {id: pr.get('id'), response:'No response'};
			return {id: pr.get('id'), response:pr.get('response')};
		});
	}
});
