/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache');

module.exports = Backbone.View.extend({
	tagName: 'tr',

	tpl: _.template($('#CheckableSourceQuestionItemView-tpl').html()),

	initialize: function(opts) {
		this.selectedIDs = opts.selectedIDs;
		this.primaryQID = opts.primaryQID;
		this.disabled = false;
		this.disabledCheckbox = false;
		this.checked = false;

		if (_.findWhere(this.selectedIDs, this.model.get('id'))) {
			this.checked = true;
		}
		if (this.model.get('is_imported') == 1) {
			this.disabled = true;
			this.disabledCheckbox = true;
		}
		if (this.primaryQID == this.model.get('id')) {
			this.checked = true;
			this.disabledCheckbox = true;
		}
	},

	render: function() {
		var data  = this.model.toJSON();
		data.disabled = this.disabled;
		data.disabledCheckbox = this.disabledCheckbox;
		data.checked = this.checked;

		if (this.disabled) {
			this.$el.addClass('disabled');
		}
		if (this.primaryQID == this.model.get('id')) {
			this.$el.addClass('info');
		}

		this.$el.html(this.tpl(data));
		return this;
	},

	close: function() {
		this.remove();
	},

	isChecked: function() {
		if (this.disabledCheckbox) {
			return false;
		} else{
			return this.$('input').prop('checked');
		}
	},

	getID: function() {
		return this.model.get('id');
	}
});