/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Analytic = require('./Analytic.js');

/**
@class SUPRQFullAnalytic
@constructor
@extends Analytic
@module Models
*/
module.exports = Analytic.extend({
	defaults: {
		id                   : null,
		target_question_id   : null,
		target_question_type : '',
		description          : '',
		filters              : {},
		filtering_questions  : {},
		type                 : 'suprqfull',
		name                 : '',
		project_id           : null,
		q1_id                : null,
		q2_id                : null,
		q3_id                : null,
		q4_id                : null,
		q5_id                : null,
		q6_id                : null,
		q7_id                : null,
		q8_id                : null,
		q9_id                : null,
		q10_id               : null,
		q11_id               : null,
		q12_id               : null,
		q13_id               : null
	},

	getters: {

	},
});