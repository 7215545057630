/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		FilterContainerView = require('./FilterContainerView.js'),
		app = require('../app.js');

/**
@class CreateSmartPossibleResponseView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',

	tpl     : $('#CreateSmartPossibleResponseView-tpl').html(),
	headTpl : $('#CreateSmartPossibleResponseView-head-tpl').html(),
	footTpl : $('#CreateSmartPossibleResponseView-foot-tpl').html(),

	events: {
		'click .add-response'    : 'addResponse',
		'click .remove-response' : 'removeResponse'
	},

	initialize: function(opts) {
		this.type   = opts.type || 1;
		this.qs     = opts.qs;
		this.prs    = opts.prs;
		this.projID = opts.projID;

		this.smartQs  = opts.smartQs;
		this.smartPrs = opts.smartPrs;

		this.filter = new FilterContainerView({
			qs       : this.qs,
			prs      : this.prs,
			smartQs  : this.smartQs,
			smartPrs : this.smartPrs,
			isTop    : true
		});
	},

	render: function() {
		var view = {};

		switch (this.type) {
			case 1: // categorical
				view.is_categorical = true;
				break;
			case 2: // rating
				view.is_rating = true;
				view.rating_qs = this.qs.reduce(function(memo, q) {
					if (+q.get('type_id') == 2) {
						memo.push({
							q_id: q.get('id'),
							name: q.get('name')
						});
					}
					return memo;
				}, []);
				break;
			case 3: // time
				view.is_time = true;
				view.time_qs = this.qs.reduce(function(memo, q) {
					if (+q.get('type_id') == 3) {
						memo.push({
							q_id: q.get('id'),
							name: q.get('name')
						});
					}
					return memo;
				}, []);
				break;
		}
		this.$el.html(Mustache.render(this.tpl, view, app.partials({
			type_header: this.headTpl,
			type_footer: this.footTpl
		})));
		this.$('#filters').html(this.filter.render().el);
		return this;
	},

	changeType: function(newType) {
		this.type = newType;
		this.render();
	},

	close: function() { this.remove(); },
	addResponse: function(e) { this.trigger('addresponse'); },
	removeResponse: function(e) { this.trigger('remove', this); },

	generateSmartPrJSON: function() {
		switch (this.type) {
			case 1: // categorical
				return {
					response  : this.$('input[name="response_name"]').val(),
					has_value : false
				};
			case 2: // rating
				return {
					target_question_id : this.$('select[name="target_q_id"]').val() || null,
					has_value          : false
				};
			case 3: //time
				return {
					target_question_id : this.$('select[name="target_q_id"]').val() || null,
					has_value          : true
				};
		}
	},

	generateFilter: function() {
		return {tree: this.filter.toJSONGraph(), project_id: this.projID};
	}
});