/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
@class FilterItemView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',
	className: 'hover-to-show row',

	tpl: $('#FilterItemView-tpl').html(),
	prTpl: $('#FilterItemView-pr-tpl').html(),
	valTpl: $('#FilterItemView-val-tpl').html(),
	selTpl: $('#FilterItemView-sel-tpl').html(),

	events: {
		'change #questions': 'changeQuestion',
		'change #operator': 'changeOperator',
		'change #possible-responses': 'changePossibleResponse',
		'blur #value': 'changeValue',
		'click .add-sibling': 'addSibling',
		'click .remove-me': 'removeMe'
	},

	initialize: function(opts) {
		this.viewType = 'FilterItemView';
		this.qs       = opts.qs;
		this.prs      = opts.prs;
		this.smartQs  = opts.smartQs;
		this.smartPrs = opts.smartPrs;

		this.qType = opts.qType || 1;

		this.selectedQID      = opts.selectedQID      || null;
		this.selectedPrID     = opts.selectedPrID     || null;
		this.selectedOperator = opts.selectedOperator || null;
		this.selectedValue    = opts.selectedValue    || null;

		this.listenTo(this.prs, 'reset', this.renderPrs);
		this.listenTo(this.smartPrs, 'reset', this.renderPrs);
	},

	render: function() {
		this.delegateEvents();
		var view = {};
		view.questions = this.qs.map(function(q) {
			return {
				id: q.get('id'),
				name: q.get('name')
			};
		});
		view.smart_questions = this.smartQs.chain()
			.reject(function(sq){ return +sq.get('type_id') != 1; })
			.map(function(sq) {
				return {
					id: sq.get('id'),
					name: sq.get('name')
				};
			})
			.value();
		this.$el.html(Mustache.render(this.tpl, view, app.partials({
			possible_responses: this.prTpl
		})));

		// can't include an open ended question in a filter
		var disabledQuestionTypes = [4];
		this.qs.each(function(q) {
			if (_.indexOf(disabledQuestionTypes, +q.get('type_id')) >= 0) {
				this.$('#questions option[value="' + q.get('id') + '"]')
					.attr('disabled', 'disabled');
			}
		}, this);

		if (this.selectedQID) {
			this.$('#questions').val(this.selectedQID);
		}
		if (this.selectedOperator) {
			this.$('#operator').val(this.selectedOperator);
		}

		this.updateOperator();
		this.updateValueType();
		this.renderPrs();

		if (this.selectedPrID) {
			this.$('#possible-responses').val(this.selectedPrID);
		}
		if (this.selectedValue) {
			this.$('#value').val(this.selectedValue);
		}
		return this;
	},

	renderPrs: function() {
		var view = {};
		var prs;

		switch (this.getQuestionType()) {
			case 'question':
				prs = this.prs.where(
					{question_id: this.getQuestionID()}
				);
				break;
			case 'smart_question':
				prs = this.smartPrs.where(
					{smart_question_id: this.getQuestionID()}
				);
				break;
		}

		view.possible_responses = _.map(prs, function(pr) {
			return {
				id: pr.get('id'),
				response: pr.get_response_text()
			};
		});
		this.$('#possible-responses').html(
			Mustache.render(this.prTpl, view, app.partials())
		);
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	getQuestionType: function() {
		var qVal = this.$('#questions').val();
		if(!qVal) {
			return null;
		}
		if (qVal.indexOf('smart-') > -1) {
			return 'smart_question';
		} else {
			return 'question';
		}
	},

	getQuestionID: function() {
		var qVal = this.$('#questions').val();
		if (qVal.indexOf('smart-') > -1) {
			return qVal.replace('smart-', '');
		} else {
			return this.$('#questions').val();
		}
	},

	getQuestion: function() {
		var qVal = this.$('#questions').val();
		//if(qVal === "") {
		//	return null;
		//}

		if (qVal.indexOf('smart-') > -1) {
			return this.smartQs.get(qVal.replace('smart-', ''));
		} else {
			return this.qs.get(this.$('#questions').val());
		}
	},

	changeQuestion: function(e) {
		this.selectedQID = this.$('#questions').val();
		this.updateOperator();
		this.updateValueType();
		this.renderPrs();

		/**
		Editing any persisted part of the item will trigger the 'isnew' event for the top
		FilterContainerView to capture and report.

		@event isnew
		*/
		this.trigger('isnew');
	},

	changePossibleResponse: function(e) {
		this.selectedPrID = this.$('#possible-responses').val();

		/* Event defined under changeQuestion method */
		this.trigger('isnew');
	},

	changeValue: function(e) {
		this.selectedValue = this.$('#value').val();

		/* Event defined under changeQuestion method */
		this.trigger('isnew');
	},

	changeOperator: function(e) {
		this.selectedOperator = this.$('#operator').val();

		/* Event defined under changeQuestion method */
		this.trigger('isnew');
	},

	updateValueType: function() {
		var q = this.getQuestion();
		var valueQuestions = [3,5];

		if (_.indexOf(valueQuestions, +q.get('type_id')) >= 0) {
			this.selectedPrID = null;
			this.$('#compare-to').html(Mustache.render(this.valTpl));
		} else {
			this.selectedValue = null;
			this.$('#compare-to').html(Mustache.render(this.selTpl));
		}
	},

	updateOperator: function() {
		var matchOnlyQIDs = [1];

		var q = this.getQuestion();
		if(q !== null) {
			if (_.indexOf(matchOnlyQIDs, +q.get('type_id')) >= 0) {
				this.$('#operator option[value=">"]').attr('disabled', 'disabled');
				this.$('#operator option[value="<"]').attr('disabled', 'disabled');
				var value = this.$('#operator').val();
				if (value == '>' || value == '<' || !value) {
					this.$('#operator').val('==');
				}
			} else {
				this.$('#operator option').removeAttr('disabled');
			}
		}
	},

	toJSONGraph: function() {
		var qID = this.getQuestionID();
		var q = this.getQuestion();
		var prID;
		if (this.getQuestionType == 'question') {
			var has_value = _.contains([3], +q.get('type_id'));
			if (has_value) {
				prID = this.prs.findWhere({
					question_id: qID,
					has_value: "1"})
					.get('id');
			} else {
				prID = null;
			}
		}
		return {
			operator: this.$('#operator').val(),
			question_id: qID,
			question_type: this.getQuestionType(),
			possible_response_id: this.$('#possible-responses').val() || prID,
			value: this.$('#value').val() || null
		};
	},

	addSibling: function() {
		this.trigger('sibling', this);
	},

	removeMe: function() {
		this.trigger('removeme', this);
	}
});