/* global require, module */

var Question = require('./Question.js'),
		SeriesSet = require('./SeriesSet.js'),
		$ = require('jquery'),
		_ = require('underscore');

/**
Basic extension of the core Question model that implements categorical-specific features.
@class CategoricalQuestion
@constructor
@extends Question
@module Models
*/
module.exports = Question.extend({
	urlRoot: '/api1/question',

	defaults: function() {
		return _.extend(Question.prototype.defaults(), {
			type_id: 1
		});
	},

	getters: {
		type_name: function(){ return 'Categorical'; }
	},

	/**
	@method toSeriesSet
	@param {object} args Hash of the required inputs, parts, prs, rs. Prs/rs can be for the q or proj.
	@return SeriesSet
	*/
	toSeriesSet: function(args) {
		var qName = this.get('name'),
				qID   = +this.get('id'),

				parts = args.parts,
				prs   = args.prs.where({question_id: qID.toString()}),
				rs    = args.rs.where({question_id: qID.toString()}),

				nonResponsePrID = _.find(prs, function(pr){ return !+pr.get('is_response'); }).get('id'),

				// array of DataPoints for the single DataSeries we'll create
				dps = [];

		// Key response by participant_id then possible_response_id
		var indexedRs = rs.reduce(function(memo, r) {
			var partID = r.get('participant_id'), prID = r.get('possible_response_id');
			if (partID in memo) {
				memo[partID][prID] = r;
			} else {
				var newObj = {};
				newObj[prID] = r;
				memo[partID] = newObj;
			}
			return memo;
		}, {});


		// We'll have to create a DataPoint for each possible_response to the question
		prs.forEach(function(pr) {
			if (!!+pr.get('is_empty') || !+pr.get('is_response')) return;

			var prID = +pr.get('id');
			dps.push({
				label       : pr.get('response'),
				source_type : 'possible_response',
				source_id   : prID,
				parent_id   : qID,
				type        : 'categorical',
				seq         : null,
				responses   : parts.map(function(part) {
					
					// is_response=FALSE if part had an original non-response
					// is_empty=TRUE if part had a response, but it wasn't this one
					var partID          = +part.get('id'),
							rsForPart       = indexedRs[partID],
							hadResponse     = null,
							hadThisResponse = null;
					
					if(rsForPart != undefined){
						hadResponse     = !(nonResponsePrID in rsForPart);
						hadThisResponse = prID in rsForPart;
					}else{
						console.log('no response found for ' + partID);
						hadResponse = false;
						hadThisResponse = false;
					}

					return {
						part_id     : partID,
						is_excluded : !!+part.get('excluded'),
						is_response : hadResponse,
						is_empty    : hadResponse && !hadThisResponse,
						response    : hadThisResponse ? rsForPart[prID].get('response') : null
					};
				})
			});
		});

		var dataSeries = {
			name: qName,
			seq: null,
			type: 'categorical',
			data: dps
		};

		return $.Deferred().resolve(new SeriesSet({
			series : [dataSeries],
			name   : qName,
			type   : 'categorical'
		}));
	}

});