/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		FilterItemView = require('./FilterItemView.js'),
		app = require('../app.js');

/**
@class FilterContainerView
@constructor
@extends Backbone.View
@module Views
*/
var FilterContainerView = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',
	className: 'panel panel-default filter-container hover-to-unfade',

	tpl: $('#FilterContainerView-tpl').html(),
	opTpl: $('#FilterContainerView-op-tpl').html(),

	events: function() {
		var childDepth = this.depth+1;
		var opSel = 'change .master-operator:not(.depth' + childDepth + ' .master-operator)';
		var res = {
			'click .enclose': 'encloseAndAddSibling',
			'click .add-container-sibling': 'addSibling',
			'click .remove-container': 'removeMe',
			'mousemove': 'mouseMove'
		};
		res[opSel] = 'changeOperator';
		return res;
	},

	initialize: function(opts) {
		/**
		@property viewType
		@type String
		@default 'FilterContainerView'
		@final
		*/
		this.viewType = 'FilterContainerView';

		/**
		@property qs
		@type Questions
		*/
		this.qs       = opts.qs;

		/**
		@property prs
		@type PossibleResponses
		*/
		this.prs      = opts.prs;

		/**
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs  = opts.smartQs;

		/**
		@property smartPrs
		@type SmartPossibleResponses
		*/
		this.smartPrs = opts.smartPrs;

		/**
		Flag to indicate whether this view is the top in the heirarchy. Defaults to false and should
		not be changed once set at initialization.

		@property isTop
		@type Boolean
		@default false
		@final
		*/
		this.isTop = opts.isTop || false;

		/**
		Indicates how many levels deep the view is in the heirarchy. A 1-indexed list.

		@property depth
		@type Number
		@default 1
		*/
		this.depth = opts.depth || 1;

		/**
		Specifies the boolean operator to apply to children. 'AND' or 'OR' are the only valid options.

		@property selectedOperator
		@type String
		@default 'AND'
		*/
		this.selectedOperator = opts.op || "AND";

		/**
		Array to hold all child views, which can be either FilterItemViews or FilterContainerViews, but
		not a mixture.

		@property children
		@type Array
		@default Array with single new FilterItemView
		*/
		this.children = opts.children || [new FilterItemView({
			qs       : this.qs,
			prs      : this.prs,
			smartQs  : this.smartQs,
			smartPrs : this.smartPrs
		})];


		_.each(this.children, function(child) {
			this.listenTo(child, 'sibling', this.addItemSibling);
			this.listenTo(child, 'removeme', this.removeChild);
			this.listenTo(child, 'removealloutlines', this.removeAllOutlines);
			this.listenTo(child, 'isnew', this.handleNewData);
		}, this);
		this.listenTo(this.prs, 'reset', this.renderPrs);

		if (this.isTop) this.$el.addClass('is-top');
	},

	/**
	Closes the view, stops listening to child views.
	@method close
	*/
	close: function() {
		_.each(this.children, function(child) {
			child.close();
		});
		this.stopListening();
		this.remove();
	},

	/**
	Fills out the `children` property based on the contents of the filter. Should be called before
	`render()` or it should be followed with a call to `renderChildren()` (I think).

	@method populateWithFilter
	@param {Filter} filter The filter to populate with.
	*/
	populateWithFilter: function(filter) {
		this.selectedOperator = filter.get ? filter.get('operator') : filter.operator;

		var filterChildren = filter.get ? filter.get('children') : filter.children;
		this.children = _.map(filterChildren, function(child) {
			if (child.children) {
				var fcv = new FilterContainerView({
					qs       : this.qs,
					prs      : this.prs,
					smartQs  : this.smartQs,
					smartPrs : this.smartPrs
				});
				fcv.populateWithFilter(child);
				this.listenTo(fcv, 'sibling',           this.addItemSibling);
				this.listenTo(fcv, 'removeme',          this.removeChild);
				this.listenTo(fcv, 'removealloutlines', this.removeAllOutlines);
				this.listenTo(fcv, 'isnew',             this.handleNewData);
				return fcv;
			} else {
				var fiv = new FilterItemView({
					qs               : this.qs,
					prs              : this.prs,
					smartQs          : this.smartQs,
					smartPrs         : this.smartPrs,
					selectedQID      : child.question_id,
					selectedPrID     : child.possible_response_id,
					selectedOperator : child.operator,
					selectedValue    : child.value
				});
				this.listenTo(fiv, 'sibling',  this.addItemSibling);
				this.listenTo(fiv, 'removeme', this.removeChild);
				this.listenTo(fiv, 'isnew',    this.handleNewData);
				return fiv;
			}
		}, this);
	},

	/**
	@method render
	@chainable
	*/
	render: function() {
		this.delegateEvents();
		var
		view   = {is_top: this.isTop},
		counts = _.countBy(this.children, function(child){ return child.viewType; });

		view.depth        = this.depth;
		view.show_sibling = counts.FilterContainerView > 1;

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		if (this.isTop) this.$el.removeClass('panel panel-default');

		this.renderChildren();

		if (this.selectedOperator != 'AND') {
			this.handleNewData = _.after(2, this.handleNewData); // TODO this line is really hacky
			this.$('.master-operator:not(.depth' + (this.depth+1) +
			' .master-operator) input[value="' + this.selectedOperator + '"]')
				.prop('checked', 'checked')
				.parent()
				.button('toggle');
		}

		return this;
	},

	renderChildren: function() {
		var $frag = $(document.createDocumentFragment());
		_.each(this.children, function(child, index, list) {
			$frag.append(child.render().el);
			child.$el.addClass('child');
			if (index !== list.length - 1) {
				var view = {};
				if (index > 0) {
					view.fake_operator = true;
				}
				$frag.append(Mustache.render(this.opTpl, view));
			}
		}, this);
		this.$('#children').html($frag);
	},

	changeOperator: function(e) {
		if (e) e.stopPropagation();
		var val = $(e.target).val();
		this.selectedOperator = val;
		var $dirDescBtns = this.$('.half-disable input[value="' + val + '"]' +
			':not(.depth' + (this.depth+1) + ' input)').parent();
		_.each($dirDescBtns, function(btn){ $(btn).button('toggle'); });
		this.handleNewData();
	},

	addItemSibling: function(view) {
		var index = _.reduce(this.children, function(memo, child, index) {
			if (child.cid == view.cid) {
				return index;
			} else {
				return memo;
			}
		}, null);

		var newChild = new FilterItemView({
			qs: this.qs,
			prs: this.prs,
			smartQs: this.smartQs,
			smartPrs: this.smartPrs
		});
		this.listenTo(newChild, 'sibling', this.addItemSibling);
		this.listenTo(newChild, 'removeme', this.removeChild);
		this.children.splice(index+1, 0, newChild);
		this.handleNewData();

		this.renderChildren();
	},

	removeChild: function(view) {
		if (this.children.length != 1) {
			view.close();
			this.children = _.reject(this.children, (function(child) {
				return child.cid == view.cid;
			}));
			if (this.children.length == 1) {
				var singleChild = this.children[0];
				if (
					singleChild.hasOwnProperty('children') &&
					singleChild.children.length > 0
				) {
					this.children = singleChild.children;
					_.each(this.children, function(child) {
						this.listenTo(
							child,
							'removealloutlines',
							this.removealloutlines
						);
						this.listenTo(child, 'removeme', this.removeChild);
						this.listenTo(child, 'sibling', this.addItemSibling);
					}, this);
					singleChild.close();
				}
			}
			this.render();
		}
		this.handleNewData();
	},

	removeMe: function() {
		this.trigger('removeme', this);
		this.handleNewData();
	},

	addSibling: function(e) {
		if (e) { e.stopPropagation(); }
		var counts = _.countBy(this.children, function(child) {
			return child.viewType;
		});

		this.children.push(new FilterContainerView({
			qs: this.qs,
			prs: this.prs,
			smartQs: this.smartQs,
			smartPrs: this.smartPrs,
			depth: this.depth
		}));
		this.listenTo(_.last(this.children), 'removealloutlines', this.removeAllOutlines);
		this.listenTo(_.last(this.children), 'removeme', this.removeChild);
		this.render();
		this.handleNewData();
	},

	encloseAndAddSibling: function() {
		_.each(this.children, function(child) {
			this.stopListening(child);
		}, this);
		this.children = [
			new FilterContainerView({
				qs: this.qs,
				prs: this.prs,
				smartQs: this.smartQs,
				smartPrs: this.smartPrs,
				depth: this.depth+1,
				op: this.selectedOperator,
				children: this.children}),
			new FilterContainerView({
				qs: this.qs,
				prs: this.prs,
				smartQs: this.smartQs,
				smartPrs: this.smartPrs,
				depth: this.depth+1
			})];
		_.each(this.children, function(child) {
			this.listenTo(child, 'removealloutlines', this.removeAllOutlines);
			this.listenTo(child, 'removeme', this.removeChild);
		}, this);
		this.selectedOperator = "AND";
		this.render();
		this.handleNewData();
	},

	mouseMove: function(e) {
		e.stopPropagation();
		if (!$(e.delegateTarget).hasClass('panel-primary')) {
			this.trigger('removealloutlines');

			if (this.isTop && this.$('.panel-primary').length > 0) {
				this.removeAllOutlines();
			} else {
				this.addOutline();
			}
		}
	},

	addOutline: function(e) {
		if (!this.isTop) {
			this.$el.addClass('panel-primary').removeClass('panel-default');
		}
	},

	removeAllOutlines: function(e) {
		if (this.isTop) {
			this.$('.panel')
				.addClass('panel-default')
				.removeClass('panel-primary');
		} else {
			this.trigger('removealloutlines');
		}
	},

	/**
	Passes 'isnew' events up the heirarchy.
	@method handleNewData
	*/
	handleNewData: function(){ this.trigger('isnew'); },

	toJSONGraph: function() {
		return {
			operator: this.selectedOperator,
			children: _.map(this.children, function(child) {
				return child.toJSONGraph();
			})
		};
	}
});

module.exports = FilterContainerView;