/* global require, module */

var Backbone = require('backbone'),
		SourceQuestion = require('../models/SourceQuestion.js');

module.exports = Backbone.Collection.extend({
	model: SourceQuestion,
	url: function(){ return '/api1/source_question/for_project/' + this.projectID; },

	initialize: function(models, options) {
		this.projectID = options.projectID;
	}
});