/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		CalcMixin = require('../mixins/CalculatorMixin.js');
/**
 * A Comparison (aptly) compares two questions, smart questions, or other comparisons to each other.
 * There are many types of comparisons, depending on what data sources are used. For example,
 * the time to complete two tasks can be compared (Simple Time comparison), or the comparisons
 * between the Success and Timeout rates of two different tasks can be compared (Meta Categorical
 * Comparison).
 *
 * @class Comparison
 * @constructor
 * @extends Backbone.Model
 * @module Models
 */
module.exports = Backbone.Model.extend(
	_.extend({}, CalcMixin, {
	urlRoot: '/api1/comparison',

	defaults: {
		name : 'Untitled Comparison',
		type: null,
		project_id: null,
		comparing: [],
		calc_type: null,
		ci_calc_type: null
	},

	parse: function(res, opts){ return res.hasOwnProperty('data') ? res.data : res; },


	/**
	 * Generates a human-readable list of the data presented by this comparison.
	 *
	 * @method comparing_readable
	 * @param  {Object} args auxiliary model data that is required to adequately represent the
	 * data. For example, a comparison does not store the names of the questions whose responses are
	 * being graphed, so it must be passed.
	 * @return {jQuery deferred} Returns undefined if toSeriesSet is not defined for this comparison.
	 * If toSeriesSet is defined, this returns a collection of the form:
	 * {
	 *   info: 'type of things being compared'
	 *   members: [
	 *     {
	 *       response: 'no', //possible response to the prompt
	 *       response_raw: '--' //raw version of response (non-human readable)
	 *       prompt: 'Is this statement false?', //prompt given to original user in study
	 *       label: 'contradiction' //name given to the response in the comparison definition
	 *     }
	 *     {
	 *       //...
	 *     }
	 *   ]
	 * }
	 *
	 * A suggested way to present this data is as a list of:
	 * [response] from [prompt] as [label]
	 */
}));