/* global require, module */

var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
	urlRoot: '/api1/smart_possible_response',

	getters: {
		response_text: function() {
			if (+this.get('has_value') === 1) {
				return 'Value';
			}
			return this.get('response');
		}
	}
});