/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		CheckableSourceQuestionItemView = require('./CheckableSourceQuestionItemView.js');

module.exports = Backbone.View.extend({
	tagName: 'div',

	tpl: _.template($('#CombineQuestionsListView-tpl').html()),

	events: {
		'click .back': 'navigateBack',
		'click .combine': 'combine'
	},

	initialize: function(opts) {
		this.selectedIDs = opts.selectedIDs;
		this.primaryQID = opts.primaryQID;
		this.title = 'Combine With';
		this.prevTitle = 'Back';

		this.itemViews = [];
	},

	render: function() {
		this.$el.html(this.tpl({
			title: this.title,
			prevTitle: this.prevTitle
		}));

		this.collection.each(function(q) {
			var item = new CheckableSourceQuestionItemView({
				model: q,
				selectedIDs: this.selectedIDs,
				primaryQID: this.primaryQID
			});
			this.itemViews.push(item);
			this.$('tbody').append(item.render().el);
		}, this);
		return this;
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	getTitle: function() {return this.title;},
	setPrevTitle: function(prev) {this.prevTitle = prev;},

	navigateBack: function(e) {
		e.preventDefault();
		this.trigger('navigateback');
	},

	combine: function() {
		this.selectedIDs = [];
		_.each(this.itemViews, function(item) {
			if (item.isChecked()) {
				this.selectedIDs.push(item.getID());
			}
		}, this);

		this.trigger('combochosen', this.selectedIDs);
		this.trigger('navigateback');
	}
});