/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		SlantedTableView = require('./SlantedTableView.js'),
		StatAnalyzer = require('../models/StatAnalyzer.js'),
		app = require('../app.js');

/**
@class StatsAnalysisView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',

	tpl: $('#StatsAnalysisView-tpl').html(),

	events: {
	},

	initialize: function(opts) {
		/**
		@property ss
		@type SeriesSet
		*/
		this.ss = opts.ss;

		/**
		@property statAnalyzer
		@type StatAnalyzer
		*/
		this.statAnalyzer = new StatAnalyzer({ss: this.ss});

		/**
		@property slantTabView
		@type SlantedTableView
		*/
		this.slantTabView = null;


		this.statAnalyzer.set({method: this.selectAnalysisMethod()});
		console.log(this.selectAnalysisMethod());
		this.slantTabView = new SlantedTableView({data: this.statAnalyzer.toTable()});
	},

	render: function() {
		var betweenWithin = this.ss.getBetweenWithin();
		var view = {
			method    : app.statsMethodHash[this.selectAnalysisMethod()],
			isBetween : betweenWithin == 'between',
			isWithin  : betweenWithin == 'within'
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.$('.table').html(this.slantTabView.render().el);
		return this;
	},

	/**
	@method selectAnalysisMethod
	@return String
	*/
	selectAnalysisMethod: function() {

		var betweenWithin = this.ss.getBetweenWithin();
		var statType;
		switch (this.ss.get('type')) {
			case 'categorical':
				statType = 'discrete';
				break;
			case 'rating':
			case 'time':
			case 'continuous':
				statType = 'continuous';
				break;
			case 'open_ended':
				throw new Error('Cannot call StatsAnalysisView with an open_ended SeriesSet');
			default:
				throw new Error('Unexpected SeriesSet type')	;
		}

		if (betweenWithin == 'between') {
			if (statType == 'discrete') return 'n1twoprop';
			if (statType == 'continuous') return 'twosamplet';
		}

		if (betweenWithin == 'within') {
			if (statType == 'discrete') return 'mcnemarexact';
			if (statType == 'continuous') return 'pairedt';
		}
	}

});