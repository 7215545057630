/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		//spreadsheet = require('../lib/jquery.handsontable.full.js'),
		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Collections = require('../collections/Collections.js'),
		Participants = require('../collections/Participants.js'),
		app = require('../app.js');

/**
@class ProjectQuestionsView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {

	// the standard page `el`
	tagName: 'div',

	tpl: $('#CreateQuestionView-tpl').html(),

	events: {
		'click .upload-question': 'saveQuestions',
		'click .download-template': 'downloadTemplate'
	},

	/**
	Sets up the instance properties, starts loading up the questions, smart_questions, and
	comparisons.
	@method initialize
	@param {object} opts The constructor options
	*/
	initialize: function(opts) {
		/**
		Reference to the current project model
		@property project
		@type Project
		*/
		console.log(opts);
		this.project = opts.project;
		this.projectID = opts.projID;
		/**
		The current project name
		@property projectName
		@type string
		*/
		//this.projectName = this.project.get('name');

		/**
		The current project ID
		@property projID
		@type Number
		*/
		//this.projID      = this.project.get('id');

		/**
		@property title
		@type string
		@default 'All Saved Questions'
		@final
		*/
		this.title = 'Create New Question(s)';
	},

	/**
	Sticks the basic skeleton for the page in the `el`.
	@method render
	@chainable
	*/
	render: function() {
		var view = {};
		view.title			= this.title;
		view.prevTitle      = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				label      : 'Upload',
				btn_type   : 'primary',
				class_name : 'upload-question'
			},
			{
				loner      : true,
				label      : 'Download Template',
				btn_type   : 'default',
				class_name : 'download-template'
			}
		];
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	saveQuestions: function(e) {
		if (e) e.preventDefault();
		var tpl = this.loadTpl;
		var toTpl = this.project.toTpl();
		var projID = this.projectID;
		// Get the entered form data. Note that we'll have extraneous keys because we can't delete
		// existing keys when we move the other parameters into a project object.
		var fdata = new FormData($('#new-question-form')[0]);

		var collectionID = this.target;

		fdata.append('project', JSON.stringify(toTpl));
		fdata.append('projectId', this.projectID);
		
		// replace the form with a loading message
		var $section = $('section');

		var view = {
			percent: 0,
			crunching: false
		};

		
		$section.html(
			Mustache.render(tpl, view, app.partials())
		);

		// do an ajax upload
		$.ajax('/api1/question/create_from_custom', {
			processData: false,
			contentType: false,
			data: fdata,
			type: 'POST',
			xhr: function() {
				var xhr = $.ajaxSettings.xhr();
				xhr.upload.addEventListener('progress', function(e) {
					if (e.lengthComputable) {
						var percent = e.loaded / e.total * 100;
						var progress = Mustache.render(
							tpl,
							{percent: percent, crunching: percent > 99},
							app.partials()
						);
						$section.html(progress);
					}
				}, false);
				return xhr;
			},
			error: function(xhr, textStatus, thrownError) {
				console.log(xhr);
				console.log(xhr.responseText);
				app.ge.trigger('alert', new AlertView({
					m: 'Problem uploading the file: ' + xhr.status,
					type: 'alert-danger'
				}));
			},
			success: function(data, textStatus, xhr) {
				console.log(data);
				window.location.href = '/project/' + projID + '/questions';
			}
		});
	},
	downloadTemplate: function(e){
		if(e) e.preventDefault();

		$.ajax({
			url: '/api1/create_file',
			method: 'POST',
			data: JSON.stringify({'type':'question template',
								  'projID':this.projectID}),
			success: function(xhr, res, three) {		
				//console.log(xhr);
				//console.log(res);

				// comment out line below to debug this
				window.location.href = '/api1/download/' + xhr.filename;
			},
			error: function(xhr, data) {
				console.log(xhr);
				console.log(data);
			},
		});


	}

}));
