/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		st                       = require('../st.js'),
		d3                       = require('d3'),
		Participants             = require('../collections/Participants.js'),
		Questions            	 = require('../collections/Questions.js'),
		PossibleResponses        = require('../collections/PossibleResponses.js'),
		SmartPossibleResponses   = require('../collections/SmartPossibleResponses.js'),
		Responses                = require('../collections/Responses.js'),
		Filters                  = require('../collections/Filters.js'),
		Comparisons              = require('../collections/Comparisons.js'),

		Filter                   = require('../models/Filter.js'),

		GColView                 = require('./GColView.js'),
		AlertView                = require('./AlertView.js'),
		StatsAnalysisView        = require('./StatsAnalysisView.js'),

		app                      = require('../app.js');

/**
 * Shows a comparison's details, a graph of the data, and allows the user to edit or delete the
 * comparison.
 *
 * @class CompView
 * @constructor
 * @extends Backbone.View
 * @module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	/**
	 * Main template for this view. Includes empty containers for latent-loaded components.
	 *
	 * @property tpl
	 * @type String
	 */
	tpl: $('#CompView-tpl').html(),
	/**
	 * Template that shows generic information about this comparison
	 *
	 * @property infoTpl
	 * @type String
	 */
	infoTpl: $('#CompView-info-tpl').html(),
	/**
	 * Template that shows information about this comparison, specifically the human-readable list
	 * of items being compared by this comparison.
	 *
	 * @property compListTpl
	 * @type String
	 */
	compListTpl: $('#CompView-comp-list-tpl').html(),

	/**
	 * Template containing a form with edit controls for the comparison. Mirrors CreateComparisonView.
	 *
	 * @property editTpl
	 * @type String
	 */
	editTpl: $('#CompView-edit-tpl').html(),
	/**
	 * Template for an option box in the edit screen
	 *
	 * @property selectorTpl
	 * @type String
	 */
	selectorTpl: $('#CompView-selector-tpl').html(),
	/**
	 * Template for a text input box in the edit screen
	 *
	 * @property optionTpl
	 * @type String
	 */
	optionTpl: $('#CompView-option-tpl').html(),

	/**
	 * List of events that this view subscribes to:
	 *   -Back
	 *   -Edit/Cancel/Save/Delete comparisons
	 *   -Changes made while editing the comparison (mirrors CreateComparisonView's events)
	 *
	 * @property events
	 * @type collection of Strings
	 */
	events: {
		//stanard behavior
		'click .back'          : 'navigateBack',

		//add tabulated data to excel
		'click .data-download' : 'addDataToExcel',

		//begin editing
		'click .edit-question' : 'editComparison',

		//while editing: change primary question selection
		'change .question-selector-primary select[name="question"]'                         :
			'changePrimary',

		//while editing: change primary question response
		'change .question-selector-primary select[name="relation"]'                         :
			'changePrimaryRelation',

		//while editing: change a secondary question selection
		'change .question-selector:not(.question-selector-primary) select[name="question"]' :
			'changeAdditional',

		//while editing: change a secondary question response
		'change .question-selector:not(.question-selector-primary) select[name="relation"]' :
			'changeAdditionalRelation',

		//while editing, add another item to compare
		'click .add-additional'         : 'addAdditional',

		//while editing, remove an item being compared
		'click .remove-me'              : 'removeAdditional',

		//while editing, check length of label inputs
		'keyup input[type="text"]'      : 'validateInput',

		//while editing, cancel editing
		'click .cancel-edit-comparison' : 'cancelEditComparison',

		//while editing, save edits made
		'click .save-edit-comparison'   : 'saveEditComparison',

		//while editing, delete this comparison
		'click .delete-comparison'      : 'deleteComparison'
	},

	/**
	 * Uses the navigation controller to return to the previous view.
	 *
	 * @method navigateBack
	 * @param {Event} e The event that caused this backwards navigation
	 */
	navigateBack: function(e) {
		if (e) {e.preventDefault();}
		this.trigger('navigateback');
	},

	/**
	 * Makes local variables and binds listeners.
	 *
	 * @method initialize
	 * @constructor
	 * @param opts
	 */
	initialize: function(opts) {
		// variables
		this.project     = opts.project;
		this.comp        = opts.comp;
		this.comps       = opts.comps;
		//this.qs          = opts.qs;
		this.smartQs     = opts.smartQs;
		this.projID      = this.comp.get('project_id');
		this.title       = this.comp.get('name');
		this.prevTitle   = 'Back';
		this.alpha       = 0.1;
		this.seriesSet   = null;
		this.qIds = '';
		console.log(this.comp.get('comparing'));
		_.each(this.comp.get('comparing'), _.bind(function(item) {
			this.qIds = this.qIds + item.related_id + '-';
		},this));


		this.rs       = new Responses(              [], {compID: this.comp.get('id')});

		this.qs = new Questions([], {qIDs: this.qIds});
		this.qsProm  = this.qs.fetch({reset: true});
		this.projIDs = '';
		$.when(this.qsProm).then(_.bind(function(qsRes) {
			console.log(qsRes);
			for (var i = qsRes.data.length - 1; i >= 0; i--) {
				this.projIDs += qsRes.data[i].project_id + '-';
			
			};
			console.log(this.projIDs);
			// views/collections
			this.chart    = null;
			this.parts    = new Participants(           [], {projID: this.projID, projIDs: this.projIDs});
			this.prs      = new PossibleResponses(      [], {projID: this.projID, projIDs: this.projIDs});
			this.smartPrs = new SmartPossibleResponses( [], {projID: this.projID, projIDs: this.projIDs});
			this.filters  = new Filters(                [], {projID: this.projID, projIDs: this.projIDs});

			//questions

			// promises
			this.partsProm    = this.parts   .fetch({
				reset: true,
				error: _.bind(function(one, two, three) {
					console.log(one);
					console.log(two);
					console.log(three);
					console.log(two.responseText);
				}, this)
			});
			this.prsProm      = this.prs     .fetch({
				reset: true,
				error: _.bind(function(one, two, three) {
					console.log(one);
					console.log(two);
					console.log(three);
					console.log(two.responseText);
				}, this)
			});
			this.smartPrsProm = this.smartPrs.fetch({
				reset: true,
				error: _.bind(function(one, two, three) {
					console.log(one);
					console.log(two);
					console.log(three);
					console.log(two.responseText);
				}, this)
			});
			this.rsProm       = this.rs      .fetch({
				reset: true,
				error: _.bind(function(one, two, three) {
					console.log(one);
					console.log(two);
					console.log(three);
					console.log(two.responseText);
				}, this)
			});
			this.filtersProm  = this.filters .fetch({
				reset: true,
				error: _.bind(function(one, two, three) {
					console.log(one);
					console.log(two);
					console.log(three);
					console.log(two.responseText);
				}, this)
			});

			this.inserted = $.Deferred();

			$.when(
				this.partsProm,
				this.prsProm,
				this.smartPrsProm,
				this.rsProm,
				this.filtersProm
			).then(_.bind(function(args) {
                                this.qs.reset(qsProm.responseJSON.data);
				return this.comp.toSeriesSet({
					prs      : this.prs,
					smartPrs : this.smartPrs,
					parts    : this.parts,
					rs       : this.rs,
					qs       : this.qs,
					smartQs  : this.smartQs,
					comps    : this.comps,
					filters  : this.filters
				});
			}, this))
			.then(_.bind(function(ss) {
				this.renderStats(ss);
				return ss.toChartData(this.comp.getCalculator(), this.comp.getCICalculator(), this.alpha);
			}, this))
			.then(_.bind(function(cd) {
				this.chartData = cd;
				this.renderChart(this.alpha);
			}, this));

			this.listenTo(this.comp, 'change', function() {
				this.partsProm    = this.parts   .fetch({reset: true});
				this.prsProm      = this.prs     .fetch({reset: true});
				this.smartPrsProm = this.smartPrs.fetch({reset: true});
				this.rsProm       = this.rs      .fetch({reset: true});
				this.compsProm    = this.comps   .fetch({reset: true});
				this.filtersProm  = this.filters .fetch({reset: true});
				$.when(
					this.partsProm,
					this.prsProm,
					this.smartPrsProm,
					this.rsProm,
					this.compsProm,
					this.filtersProm
				).then(_.bind(function() {
					this.renderInfo();
					this.renderComparisonList();
					this.renderNavHeader();
				}, this));
			});

		}, this));
	},

	render: function() {
		var view             = this.comp.toTpl();
		view.title           = this.title;
		view.prevTitle       = this.prevTitle;

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		this.renderNavHeader();
		this.renderInfo();

		return this;
	},

	/**
	 * Creates a new chart if one does not exist, or re-renders with new data if chart already exists.
	 *
	 * @method renderChart
	 */
	renderChart: function() {
		if (!this.chart) {
			this.chartData.el = '.graph-mount';
			this.chartData.alpha = this.alpha;
			this.chart = new GColView(this.chartData);
			this.listenTo(this.chart, 'changealpha', this.changeAlpha);
			this.listenTo(this.chart, 'GColView:addtoexcel', this.addToExcel);
			this.chart.render();
		} else { //re-render, data change
			this.chart.setData(this.chartData.data).reRender();
		}
	},

	renderStats: function(ss) {
		// does the current data type support stats analysis?
		var type = ss.get('type');
		if (type == 'open_end') return;

		this.statView = new StatsAnalysisView({ss: ss});
		this.$('.stats-mount').html(this.statView.render().el);
	},

	/**
	 * Re-creates a new data set for graphing using the newly chosen alpha-value.
	 *
	 * @method changeALpha
	 * @param {float} alpha The alpha-value to use when creating error bars
	 */
	changeAlpha: function(alpha) {
		this.alpha = alpha;
		this.comp.toSeriesSet({
			prs      : this.prs,
			smartPrs : this.smartPrs,
			parts    : this.parts,
			rs       : this.rs,
			qs       : this.qs,
			smartQs  : this.smartQs,
			comps    : this.comps,
			filters  : this.filters
		})
			.then(function(ss){
				return ss.toChartData(this.comp.getCalculator(), this.comp.getCICalculator(), alpha);
			}.bind(this))
			.then(_.bind(function(chartData) {
				this.chartData = chartData;
				this.renderChart();
			}, this));
	},

	/**
	 * Close this view. Remove it from the user's sight.
	 *
	 * @method close
	 */
	close: function() {
		this.stopListening();
		this.remove();
	},

	fetchAndProcessData: function(comp) {
		var supportedTypes = [
			'single_categorical',
			'multiple_categorical',
			'rating',
			'multiple_categorical',
			'meta_categorical',
			'time',
			'continuous'
		];
		var type = comp.get('type');
		if (supportedTypes.indexOf(type) < 0) throw new Error('Unsupported comparison type');

		return this.fetchData({
				comp  : comp,
				rs    : this.rs,
				prs   : this.prs,
				parts : this.parts,
				comps : this.comps
			})
			.then(_.bind(function(data) {
				return this.processData({
					rawData  : data,
					parts    : this.parts,
					prs      : this.prs,
					smartPrs : this.smartPrs,
					rs       : this.rs,
					alpha    : this.alpha,
					comp     : this.comp,
					qs       : this.qs,
					smartQs  : this.smartQs
				});
			}, this));
	},

	fetchData: function(args) {
		var
			dfd        = $.Deferred(),
			comp       = args.comp,
			type       = comp.get('type'),
			returnData = {},

			parts      = args.parts,
			prs        = args.prs,
			rs         = args.rs,
			comps      = args.comps;

		var	included = parts.chain()
			.reject(function(pr){ return +pr.get('excluded') === 1; })
			.map(function(pr){ return pr.get('id'); })
			.value();

			var single_categorical = {
				fetch: function(args) {
					var
						comp        = args.comp || comp,
						dfd         = $.Deferred(),

						filterProms = [],
						rsMap       = {},
						filters     = {},
						d; // holds temp deferreds


					_.each(comp.get('comparing'), function(item) {
						switch (item.related_to) {
						case 'possible_response':
							rsMap[item.related_id] = rs.where({possible_response_id: item.related_id});
							break;

						case 'smart_possible_response':
							d = $.ajax({url: '/api1/filter/for_smart_possible_response/'+item.related_id});
							d.done(function(res){ filters[item.related_id] = res.data; });
							filterProms.push(d);
							break;
						}
					});

					$.when.apply(null, filterProms)
						.done(function(){ dfd.resolve({rs:rsMap, filters:filters}); })
						.fail(function(){ dfd.reject(); });

					return dfd;
				}
			};

		if (type == 'single_categorical') {
			return single_categorical.fetch(args);
		}

		else if (type == 'rating') {
			_.each(comp.get('comparing'), function(item) {
				var prIDs = prs.chain()
					.filter(function(pr){ return pr.get('question_id') == item.related_id; })
					.filter(function(pr){ return _.isFinite(pr.get('response')); })
					.map(function(pr){ return pr.get('id'); })
					.value();

				var rawRatings = rs.chain()
					.filter(function(r){
						return prIDs.indexOf(r.get('possible_response_id')) >= 0;
					})
					.filter(function(r){ return _.contains(included, r.get('participant_id')); })
					.countBy(function(r){ return r.get('possible_response_id'); })
					.reduce(function(memo, count, prID) {
						memo[prs.get(prID).get('response')] = count;
						return memo;
					}, {})
					.reduce(function(memo, count, val) {
						return memo.concat(st.repeat(parseInt(val, 10), count));
					}, [])
					.value();
				returnData[item.related_id] = rawRatings;
			});

			dfd.resolve(returnData);
			return dfd;
		}

		else if (type == 'time') {
			_.each(comp.get('comparing'), function(item) {
				var prID = prs.chain()
					.filter(function(pr){ return pr.get('id') == item.related_id; })
					.map(function(pr){ return +pr.get('id'); })
					.first()
					.value();

				var rawRatings = rs.chain()
					.filter(function(r){ return prID === +r.get('possible_response_id'); })
					.filter(function(r){ return _.contains(included, r.get('participant_id')); })
					.map(function(r){ return +r.get('value'); })
					.value();
				returnData[item.related_id] = rawRatings;
			});

			dfd.resolve(returnData);
			return dfd;
		}

		else if (type == 'continuous') {
			_.each(comp.get('comparing'), function(item) {
				var prID = prs.chain()
					.filter(function(pr){ return pr.get('id') == item.related_id; })
					.map(function(pr){ return +pr.get('id'); })
					.first()
					.value();

				var rawRatings = rs.chain()
					.filter(function(r){ return prID === +r.get('possible_response_id'); })
					.filter(function(r){ return _.contains(included, r.get('participant_id')); })
					.map(function(r){ return +r.get('value'); })
					.value();
				returnData[item.related_id] = rawRatings;
			});

			dfd.resolve(returnData);
			return dfd;
		}

		else if (type == 'multiple_categorical') {
			_.each(comp.get('comparing'), function(item) {
				if (item.related_to == 'question') {
					var qPrs = prs.chain()
						.filter(function(pr){ return pr.get('question_id') == item.related_id; })
						.reject(function(pr){ return +pr.get('is_response') === 0; })
						.reject(function(pr){ return +pr.get('is_empty') === 1; })
						.value();

					returnData[item.related_id] = _.reduce(qPrs, function(memo, qPr) {
						memo[qPr.get('id')] = rs.where({possible_response_id: qPr.get('id')});
						return memo;
					}, {});
				}
			});

			dfd.resolve(returnData);
			return dfd;
		}

		else if (type == 'meta_categorical') {
			var singleProms = [];

			_.each(comp.get('comparing'), _.bind(function(item) {
				// need
				// 1. prs for each comp
				// 2. number of rs for each pr
				var prom;
				item = comps.get(item.related_id);

				if (item.get('type') == 'single_categorical') {
					// get fetch categorical data
					prom = single_categorical.fetch(_.extend(args, {comp: item}));
					singleProms.push(prom);
					prom.done(function(rawData){ returnData[item.get('id')] = rawData; });
				}
			}, this));
			$.when.apply(null, singleProms).done(function(){ dfd.resolve(returnData); });
			return dfd;
		}

		else {
			throw new Error('Unsupported comparison type');
		}
	},

	processData: function(args) {
		var
			// unique data for comp
			dfd        = $.Deferred(),
			comp       = args.comp,
			type       = comp.get('type'),
			data       = args.rawData,

			// general reference data
			parts      = args.parts,
			prs        = args.prs,
			smartPrs   = args.smartPrs,
			rs         = args.rs,
			alpha      = args.alpha,
			qs         = args.qs,
			smartQs    = args.smartQs,

			// containers
			returnData = [],
			proms      = [];

		var single_categorical = {
			process: function(args) {
				var
					data   = args.rawData || data,
					comp   = args.comp || comp,
					series = args.series || 'first';

				_.each(comp.get('comparing'), function(item) {
					var x, n, adjWald, f, prom;

					if (item.related_to == 'possible_response') {
						n = prs.chain()
							.filter(function(pr){ return pr.get('id') == item.related_id; })
							.map(function(pr){ return qs.findWhere({id: pr.get('question_id')}); })
							.map(function(q){ return q.get('num_participants'); })
							.first()
							.value();

						x       = data.rs[item.related_id].length;
						adjWald = st.ci.adjWald(x, n, alpha);

						returnData.push({
							value: x/n,
							low: adjWald[0],
							high: adjWald[1],
							label: item.label,
							series: series
						});
					}

					else if (item.related_to == 'smart_possible_response') {
						f = new Filter(data.filters[item.related_id]);
						prom = f.applyFilter(parts.toTpl(), prs.toTpl(), rs.toTpl());
						prom
							.done(function(matching) {
								n = smartPrs.chain()
									.filter(function(pr){ return pr.get('id') == item.related_id; })
									.map(function(pr){
										return smartQs.findWhere({id: pr.get('smart_question_id')});
									})
									.map(function(q){ return q.get('num_participants'); })
									.first()
									.value();

								x       = matching.length;
								adjWald = st.ci.adjWald(x, n, alpha);

								returnData.push({
									value  : x/n,
									low    : adjWald[0],
									high   : adjWald[1],
									label  : item.label,
									series : series
								});
							});
						proms.push(prom);
					}
				});

				$.when.apply(null, proms)
					.done(function(){ dfd.resolve({data:returnData,min:0, max:1, format:d3.format('%')}); })
					.fail(function(){ dfd.reject(); });

				return dfd;
			}
		};

		if (type == 'single_categorical') {
			return single_categorical.process(args);
		}

		else if (type == 'rating') {

			_.each(comp.get('comparing'), function(item){
				var itemData = data[item.related_id],
						mean     = st.mean(itemData),
						sd       = st.sd(itemData),
						ci       = st.ci.tCont(mean, sd, itemData.length, args.alpha);

				returnData.push({
						value  : st.mean(itemData),
						low    : ci[0],
						high   : ci[1],
						label  : item.label,
						series : 'first'
				});
			});

			var validResponses = prs.chain()
						.filter(function(pr){ return _.isFinite(pr.get('response')); })
						.map(function(pr){ return +pr.get('response'); })
						.value(),
					minVal = d3.min(validResponses),
					maxVal = d3.max(validResponses);

			dfd.resolve({data:returnData, min:minVal, max:maxVal, format:d3.format('.1f')});
			return dfd;
		}

		else if (type == 'time') {
			_.each(comp.get('comparing'), function(item) {
				var itemData = data[item.related_id];
				var	mid      = st.meanGeo(itemData);
				var	meanLn   = st.meanLn(itemData);
				var	sdLn     = st.sdLn(itemData);
				var	ci       = st.ci.tContGeo(meanLn, sdLn, itemData.length, alpha);

				returnData.push({
						value: mid,
						low: ci[0],
						high: ci[1],
						label: item.label,
						series: 'first'
				});
			});

			dfd.resolve({data:returnData, min:0, format:d3.format(',.0f')});
			return dfd;
		}

		else if (type == 'continuous') {
			_.each(comp.get('comparing'), function(item) {
				var itemData = data[item.related_id];
				var	mid      = st.meanGeo(itemData);
				var	meanLn   = st.meanLn(itemData);
				var	sdLn     = st.sdLn(itemData);
				var	ci       = st.ci.tContGeo(meanLn, sdLn, itemData.length, alpha);

				returnData.push({
						value: mid,
						low: ci[0],
						high: ci[1],
						label: item.label,
						series: 'first'
				});
			});

			dfd.resolve({data:returnData, min:0, format:d3.format(',.0f')});
			return dfd;
		}

		else if (type == 'multiple_categorical') {
			_.each(comp.get('comparing'), function(item) {
				var series = item.label,
					x = 30,
					n = 200,
					adjWald = [0.45, 0.56];

				if (item.related_to == 'question') {
					var qPrs = prs.chain()
						.filter(function(pr){ return pr.get('question_id') == item.related_id; })
						.reject(function(pr){ return +pr.get('is_response') === 0; })
						.reject(function(pr){ return +pr.get('is_empty') === 1; })
						.value();

					_.each(qPrs, function(qPr, key) {
						n       = qs.findWhere({id: qPr.get('question_id')})
									.get('num_participants');
						x       = data[item.related_id][qPr.get('id')].length;
						adjWald = st.ci.adjWald(x, n, alpha);

						returnData.push({
							value: x/n,
							low: adjWald[0],
							high: adjWald[1],
							label: qPr.get('response'),
							series: series
						});
					});
				}
			});

			dfd.resolve({data:returnData, min:0, max:1, format:d3.format('%')});
			return dfd;
		}

		else if (type == 'meta_categorical') {
			var runningData = [];
			_.each(comp.get('comparing'), function(item, index) {
				var itemComp = this.comps.get(item.related_id);
				var itemArgs = _.extend(args, {
					rawData: data[item.related_id],
					comp:itemComp,
					series: item.label
				});
				var prom = single_categorical.process(itemArgs);
				proms.push(prom);
				prom.done(function(data){ runningData.push(data.data); });
			}, this);

			$.when.apply(null, proms)
				.done(function(){ dfd.resolve({data:runningData, min:0, max:1, format:d3.format('%')}); });

			return dfd;
		}
	},

	/**
	 * TODO: adds the graph of this comparison's data to the list of Excel export items.
	 *
	 * @method addToExcel
	 */
	addToExcel: function(data, type, properties) {
		var jsonData = {
			name : this.comp.get('name'),
			type : 'graph',
			data : data,
			properties: properties,
			graph_type: type,
			graph_theme_id: this.project.get('theme_id')
		};
		app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	@method addDataToExcel
	@param {event} [e] Potentially passed the triggering event.
	*/
	addDataToExcel: function(e) {
		if (e) e.preventDefault();

		this.comp.toSeriesSet({
			smartPrs : this.smartPrs,
			prs      : this.prs,
			qs       : this.qs,
			smartQs  : this.smartQs,
			parts    : this.parts,
			rs       : this.rs,
			filters  : this.filters,
			comps    : this.comps
		})
			.then(_.bind(function(ss){ return ss.toExcelData({parts: this.parts, condense : false}); }, this))
			.then(function(eData){ console.log(eData); app.ge.trigger('addtoexcel', eData); });
	},

	/**
	 * Attempts to delete a comparison from the dataset after prompting the user.
	 * If dependencies exist, format the error message and display the list of dependencies.
	 * @method deleteComparison
	 */
	deleteComparison: function() {
		if (
			window.confirm("Are you sure you want to delete this comparison?\n\n" +
				"This action cannot be undone.")
		) {
			this.comp.destroy({
				wait    : true,
				success : _.bind(function() {
					//this item no longer exists, so go back to list screen
					this.navigateBack();
				}, this),
				error   : function(mod, res, opts) {
					var message = "";
					if(!res.responseJSON.gt_code) {
						message = "Error " + mod;
					}
					else if (res.responseJSON.gt_code == 'sq432') {
						var data = res.responseJSON.data;
						message = _.reduce(data, function(memo, dep, key) {

							if (dep.length === 0) return memo;

							else if (key == 'smart_questions') {
								memo += '<strong>Smart Questions</strong><ul>';
								memo = _.reduce(dep, function(memo, item) {
									return memo += '<li>' + item.name + '</li>';
								}, memo);
								memo += '</ul>';
							}

							else if (key == 'comparisons') {
								memo += '<strong>Comparisons</strong><ul>';
								memo = _.reduce(dep, function(memo, item) {
									return memo += '<li>' + item.name + '</li>';
								}, memo);
								memo += '</ul>';
							}

							//additional dependencies (i.e. analyses) will go here

							return memo;
						}, '');

						//show the user the message
						app.ge.trigger('alert', new AlertView({
							m : '<p>Cannot delete because other items depend on this comparison:</p>' +
								message + '<p>Delete the other items and then you can delete this one',
							type: 'alert-warning'
						}));
					} //if sq432 error
				} //error
			}); //destroy
		} //confirmation dialog
	}, //deleteComparison

	/**
	 * Changes the view to allow for editing of the comparison's properties. The navigation header
	 * buttons are replaced with Cancel/Delete/Save buttons, and the data in the main part of the
	 * view is changed from a details display to a form, to allow the details to be edited.
	 *
	 * @method editComparison
	 */
	editComparison: function() {
		var view = {
			title          : this.title,
			prevTitle      : this.prevTitle,
			panel_controls : [
				{
					loner      : true,
					label      : 'Save',
					class_name : 'save-edit-comparison',
					btn_type   : 'primary'
				}, {
					loner      : true,
					label      : 'Cancel',
					class_name : 'cancel-edit-comparison',
					btn_type   : 'link'
				}, {
					loner      : true,
					label      : 'Delete',
					class_name : 'delete-comparison',
					btn_type   : 'danger'
				}
			]
		};

		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);

		this.renderEditInfo();
	},
/**
	 * Renders the full list of possible primary questions into the form.
	 *
	 * @method renderPrimaryQuestions
	 * @param {Object} view_a preset view template params to pass to child views.
	 */
	renderPrimaryQuestions: function(view_a) {
		var unallowed    = [4];
		var view         = {};
		if(view_a) view  = view_a;
		view.is_question = true;
		view.optgroups   = [
			{
				label: 'Questions',
				options: this.qs.chain()
					.reject(function(q) {
						return _.contains(unallowed, +q.get('type_id'));
					})
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Smart Questions',
				options: this.smartQs.chain()
					.reject(function(q) {
						return _.contains(unallowed, +q.get('type_id'));
					})
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'smart_question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Comparisons',
				options: _.map(this.comps.getBasic(), function(c) {
					return {
						id     : c.get('id'),
						type   : 'comparison',
						flavor : c.get('type'),
						name   : c.get('name')
					};
				})
			}
		];
		this.$('.question-selector-primary select[name="question"]').html(
			Mustache.render(this.optionTpl, view, app.partials())
		);
	},

	/**
	 * Does all the legwork necessary to display the full list of additional (secondary) questions,
	 * with proper available options for questions and responses.
	 *
	 * @method renderAdditionalQuestions
	 * @param  {Object} data     Additional data needed to fully define the comparison.
	 * @param  {String} selector jQuery selector for which element into which to render the questions.
	 * @param  {Object} view_a   View template presets to be passed down into child templates.
	 */
	renderAdditionalQuestions: function(data, selector, view_a) {
		selector = selector || null;
		var optgroups = [],
			primary,
			primaryNum;

		var isAll = this.$('.question-selector-primary select[name="relation"]')
			.val() == 'All';

		if (isAll) {
			switch (data.type) {
				case 'question':
					primaryNum = this.prs.chain()
						.filter(function(pr){
							return pr.get('question_id') == data.id && !+pr.get('is_empty') && !!+pr.get('is_response');
						})
						.size()
						.value();
					break;
				case 'smart_question':
					primaryNum = this.smartPrs.chain()
						.filter(function(pr) { return pr.get('smart_question_id') == data.id; })
						.size()
						.value();
					break;
			}
		}

		switch (data.type) {
			case 'question':
			case 'smart_question':
				if (data.type == 'question') {
					primary = this.qs.get(data.id);
				} else if (data.type == 'smart_question') {
					primary = this.smartQs.get(data.id);
				}
				optgroups.push({
					label: 'Questions',
					options: this.qs.chain()
						.filter(function(q) {
							var matchNum = true;
							if (isAll) {
								var num = this.prs.chain()
									.filter(function(pr) { return pr.get('question_id') == q.get('id'); })
									.size()
									.value();
								matchNum = num == primaryNum;
							}
							var matchType = q.get('type_id') == primary.get('type_id');
							var diffQ     = q.get('id') != data.id;

							return matchType && matchNum && diffQ;
						}, this)
						.map(function(q) {
							return {
								id      : q.get('id'),
								type    : 'question',
								name    : q.get('name'),
								type_id : q.get('type_id')
							};
						})
						.value()
				});
				optgroups.push({
					label: 'Smart Questions',
					options: this.smartQs.chain()
						.filter(function(q) {
							var matchNum = true;
							if (isAll) {
								var num = this.smartPrs.chain()
									.filter(function(pr) { return pr.get('smart_question_id') == q.get('id'); })
									.size()
									.value();
								matchNum = num == primaryNum;
							}
							var matchType = q.get('type_id') == primary.get('type_id');
							var diffQ     = q.get('id') != data.id;

							return matchType && matchNum && diffQ;
						}, this)
						.map(function(q) {
							return {
								id      : q.get('id'),
								type    : 'smart_question',
								name    : q.get('name'),
								type_id : q.get('type_id')
							};
						})
						.value()
				});
				break;

			case 'comparison':
				primary = this.comps.get(data.id);
				optgroups.push({
					label: 'Comparisons',
					options: _.chain(this.comps.getBasic())
						.filter(function(c) {
							var matchNum = (c.get('comparing').length ==	primary.get('comparing').length);
							var diffQ    = c.get('id') != data.id;
							var sameType = c.get('type') == primary.get('type');

							return matchNum && diffQ && sameType;
						})
						.map(function(c) {
							return {
								id   : c.get('id'),
								type : 'comparison',
								name : c.get('name'),
								flavor: c.get('flavor')
							};
						})
						.value()
				});
				break;
		}

		var view = {};
		if(view_a) {
			view = view_a;
		}
		view.is_question = true;
		view.optgroups = optgroups;
		if (selector) {
			$(selector).find('select[name="question"]').html(
				Mustache.render(this.optionTpl, view, app.partials())
			);
		} else {
			this.$(
				'.question-selector:not(.question-selector-primary) ' + 'select[name="question"]'
			).html(Mustache.render(this.optionTpl, view, app.partials()));
		}

		view = {};
		view.is_relation = true;
		if (selector) {
			$(selector).find('select[name="relation"]').html(
				Mustache.render(this.optionTpl, view, app.partials())
			);
		} else {
			this.$(
				'.question-selector:not(.question-selector-primary) ' + 'select[name="relation"]'
			).html(Mustache.render(this.optionTpl, view, app.partials()));
		}
	},

	/**
	 * This method is designed to be used when editing a comparison that has dependents that prevent
	 * the question type from changing. Renders only like-type questions into the primary question
	 * selector
	 *
	 * @method limitToLikePrimaryQuestions
	 * @param  {Object} data     Additional data needed to fully define the comparison.
	 * @param  {Object} view_a   View template presets to be passed down into child templates.
	 */
	limitToLikePrimaryQuestions: function(data, view_a) {
		var view = {};
		if(view_a) {
			view = view_a;
		}
		var optgroups = [],
			primary,
			primaryNum;

		var isAll = this.$('.question-selector-primary select[name="relation"]')
			.val() == 'All';

		if (isAll) {
			switch (data.type) {
				case 'question':
					primaryNum = this.prs.chain()
						.filter(function(pr) { return pr.get('question_id') == data.id; })
						.size()
						.value();
					break;
				case 'smart_question':
					primaryNum = this.smartPrs.chain()
						.filter(function(pr) { return pr.get('smart_question_id') == data.id; })
						.size()
						.value();
					break;
			}
		}

		switch (data.type) {
			case 'question':
			case 'smart_question':
				if (data.type == 'question') {
					primary = this.qs.get(data.id);
				} else if (data.type == 'smart_question') {
					primary = this.smartQs.get(data.id);
				}
				optgroups.push({
					label: 'Questions',
					options: this.qs.chain()
						.filter(function(q) {
							var matchNum = true;
							if (isAll) {
								var num = this.prs.chain()
									.filter(function(pr) { return pr.get('question_id') == q.get('id'); })
									.size()
									.value();
								matchNum = num == primaryNum;
							}
							var matchType = q.get('type_id') == primary.get('type_id');

							return matchType && matchNum;
						}, this)
						.map(function(q) {
							return {
								id      : q.get('id'),
								type    : 'question',
								name    : q.get('name'),
								type_id : q.get('type_id')
							};
						})
						.value()
				});
				optgroups.push({
					label: 'Smart Questions',
					options: this.smartQs.chain()
						.filter(function(q) {
							var matchNum = true;
							if (isAll) {
								var num = this.smartPrs.chain()
									.filter(function(pr) { return pr.get('smart_question_id') == q.get('id'); })
									.size()
									.value();
								matchNum = num == primaryNum;
							}
							var matchType = q.get('type_id') == primary.get('type_id');

							return matchType && matchNum;
						}, this)
						.map(function(q) {
							return {
								id      : q.get('id'),
								type    : 'smart_question',
								name    : q.get('name'),
								type_id : q.get('type_id')
							};
						})
						.value()
				});
				break;

			case 'comparison':
				primary = this.comps.get(data.id);
				optgroups.push({
					label: 'Comparisons',
					options: _.chain(this.comps.getBasic())
						.filter(function(c) {
							var matchNum = (c.get('comparing').length ==	primary.get('comparing').length);
							var sameType = c.get('type') == primary.get('type');

							return matchNum && sameType;
						})
						.map(function(c) {
							return {
								id     : c.get('id'),
								type   : 'comparison',
								name   : c.get('name'),
								flavor : c.get('type')
							};
						})
						.value()
				});
				break;
		}

		view.is_question = true;
		view.optgroups = optgroups;
		this.$(
			'.question-selector.question-selector-primary ' + 'select[name="question"]'
		).html(Mustache.render(this.optionTpl, view, app.partials()));
	},

	/**
	 * Renders out the names of the possible relations to compare, based on the selections that have
	 * already been made.
	 *
	 * @method renderRelations
	 * @param  {Object} data Auxiliary data needed to fully define the comparison, such as question
	 * names and prompts.
	 * @param  {jQuery element} node The relations element into which this function will render.
	 */
	renderRelations: function(data, node) {
		var view = {};
		var relations,
			type;

		switch (data.type) {
			case 'question':
				relations = this.prs.chain()
					.filter(function(pr){
						return pr.get('question_id') == data.id.toString();
					})
					.reject(function(pr) {
						return +pr.get('is_response') === 0 || +pr.get('is_empty') === 1;
					})
					.map(function(pr) {
						if (+pr.get('has_value') === 1) {
							return {
								name: '--',
								id: pr.get('id'),
								type: 'possible_response'
							};
						} else {
							return {
								name: pr.get('response'),
								id: pr.get('id'),
								type: type
							};
						}
					})
					.value();
				type = 'possible_response';
				break;
			case 'smart_question':
				relations = this.smartPrs.chain()
					.filter(function(pr){
						return pr.get('smart_question_id') == data.id.toString();
					})
					.reject(function(pr) {
						return +pr.get('is_response') === 0 || +pr.get('is_empty') === 1;
					})
					.map(function(pr) {
						if (+pr.get('has_value') === 1) {
							return {
								name: '--',
								id: pr.get('id'),
								type: 'possible_response'
							};
						} else {
							return {
								name: pr.get('response'),
								id: pr.get('id'),
								type: type
							};
						}
					})
					.value();
				type = 'smart_possible_response';
				break;
			case 'comparison':
				relations = [];
				type = 'comparison';
				break;
		}

		if (data.type_id == 2) {
			relations = [];
		}

		view.options = relations;

		var isAllSelectedOnPrimary =
			this.$('.question-selector-primary select[name="relation"]').val() == 'All';

		var isPrimary = $(node).closest('.question-selector').hasClass('question-selector-primary');

		if(isPrimary) { //is the primary field
			if(this.hasMetaDependents()) { //there are meta dependents, every option still acceptable here
				if(isAllSelectedOnPrimary) { //All is the ONLY option
					view.options = [{name: 'All'}]; //clears out other options as well
				}
				else { //All is NOT allowed because it's not selected.
					if(view.options.length > 0) {
						view.options[0].name = '--'; //make it clear that there is no option.
					} else {
						view.options[0] = {name:'--'};
					}
				}
			}
			else if (data.type_id == 1) { //no meta dependents, every option still acceptable here
				view.options.unshift({name: 'All'}); //add All to list of options
			}
			else { //No meta dependents, All not OK, no choices
				if(view.options.length > 0) {
					view.options[0].name = '--'; //make it clear that there is no option.
				} else {
					view.options[0] = {name:'--'};
				}
			}
		}
		else { //NOT primary field
			if(view.options.length < 1 || isAllSelectedOnPrimary) { //no choice, we're locked in
				view.options = [{ name: '--' }]; //make it clear that there is no option.
			}
			else { //we have options. All is NOT acceptable because it's not selected on primary.
				if (data.type_id == 1) {
					view.is_relation = true; //allow displaying of options in template
				}
				else {
					// All still not acceptable, so do nothing.
				}
			}
		}

		$(node).html(
			Mustache.render(this.optionTpl, view, app.partials())
		);

		if (view.options[0].name == '--') {
			$(node).closest('.form-group').removeClass('has-error');
		}
	},

	/**
	 * Fired when the primary question choice is changed. Updates the primary response dropdown
	 * the new possible options given the new choice.
	 *
	 * @method changePrimary
	 * @param  {Event} e The input event
	 */
	changePrimary: function(e) {
		var $target = $(e.target);
		$target.closest('.form-group').removeClass('has-error');

		var node = $target
			.closest('.question-selector')
			.find('select[name="relation"]')
			.get(0);
		var data = $target.find('option:selected').data();

		this.renderRelations(data, node);

		if (!this.selected) {
			this.activateAdditional();
			this.selected = true;
		}

		this.renderAdditionalQuestions(data);
		this.commitLocalComparison();
	},

	/**
	 * Fired when the primary question response type (i.e. all reponses, success, timeout) is
	 * changed. Updates the other fields in the form to reflect the new possible options given this
	 * change.
	 *
	 * @method changePrimaryRelation
	 * @param  {Event} e The input event
	 */
	changePrimaryRelation: function(e) {
		var $target = $(e.target);
		$target.closest('.form-group').removeClass('has-error');

		var data = this.$('.question-selector-primary ' +
			'select[name="question"] option:selected').data();
		this.renderAdditionalQuestions(data);
		this.commitLocalComparison();
	},

	/**
	 * Fired when the secondary question response type (i.e. all reponses, success, timeout) is
	 * changed. If something is selected, remove the error flag on this field.
	 *
	 * @method changeAdditionalRelation
	 * @param  {Event} e The input event
	 */
	changeAdditionalRelation: function(e) {
		var $target = $(e.target);
		$target.closest('.form-group').removeClass('has-error');
		this.commitLocalComparison();
	},

	/**
	 * Fired when the secondary question selection is changed. Updates the selector next to this
	 * question to reflect the possible response choices.
	 *
	 * @method changeAdditional
	 * @param  {Event} e The input event
	 */
	changeAdditional: function(e) {
		var $target = $(e.target);
		$target.closest('.form-group').removeClass('has-error');

		var node = $target
			.closest('.question-selector')
			.find('select[name="relation"]')
			.get(0);
		var data = $target.find('option:selected').data();

		this.renderRelations(data, node);
		this.commitLocalComparison();
	},

	/**
	 * Activates an additional question (when the one before it is fully defined, this one can
	 * be edited).
	 *
	 * @method activateAdditional
	 */
	activateAdditional: function() {
		this.$('button.disabled').removeClass('disabled');
		this.$('select[disabled="disabled"]').removeAttr('disabled');
		this.$('input[disabled="disabled"]').removeAttr('disabled');
	},

	/**
	 * Validates the input to a "label" text input field. Currently only checks for a reasonable
	 * length for the label.
	 *
	 * @method validateInput
	 * @param  {Event} e the input event
	 */
	validateInput: function(e) {
		var $target = $(e.target);
		var len = $target.val().length;
		if (len >= 1 && len <= 120) {
			$target.closest('.form-group').removeClass('has-error');

			if(e.which === 13) { // enter pressed
				this.commitLocalComparison();
			}
		}
	},


	/**
	 * Validates all input fields in the form
	 *
	 * @method validateInputs
	 * @param  {Event} e the input event
	 */
	validateInputs: function() {
		var valid = true;
		_.each(this.$('input[type="text"]').get(), function(input) {
			var $input = $(input);
			var len = $input.val().length;
			if ((len < 1 || len > 120) && $input.is(':not(:disabled)')) {
				valid = false;
				$input.closest('.form-group').addClass('has-error');
			}
		});

		_.each(this.$('select').get(), function(select) {
			var $select = $(select);
			if ($select.val() === null && $select.is(':not(:disabled)')) {
				valid = false;
				$select.closest('.form-group').addClass('has-error');
			}
		});

		return valid;
	},

	/**
	 * Adds another comparison item to the list of secondary items and adds the appropriate form
	 * template to the view. Opposite of removeAdditional
	 *
	 * @method addAdditional
	 * @param {Object} view_a Preset template view values to be passed down to the line item
	 * templates.
	 */
	addAdditional: function(view_a) {
		var view = {};
		if(view_a) {
			view = view_a;
		}

		//put new view.attributes here

		var selector = $(Mustache.render(this.selectorTpl, view, app.partials()))
			.appendTo(this.$('.question-selectors'));

		var data = this.$('.question-selector-primary select[name="question"] option:selected')
			.data();
		this.renderAdditionalQuestions(data, selector, view);
	},

	/**
	 * Removes an item from the list of secondary comparison terms
	 *
	 * @method removeAdditional
	 * @param  {event} e The input event
	 */
	removeAdditional: function(e) {
		$(e.target).closest('.question-selector').remove();
		this.commitLocalComparison();
	},

	/**
	 * Validates the fields, and if all is well, confirms changes made in the comparison edit
	 * form and commits them to the model.
	 *
	 * @method saveEditComparison
	 */
	saveEditComparison: function() {
		if (!this.validateInputs()) {
			app.ge.trigger('alert', new AlertView({
				m: 'Must complete all fields',
				type: 'alert-danger'
			}));
			return;
		}

		var type;
		var name = this.$('input[name="name"]').val();
		var data = this.$('.question-selector-primary select[name="question"] option:selected').data();

		switch (data.type) {
			case 'comparison':
				//the ternary operator is required because if the type 'meta_single_categorical' needs
				//does not exist - it's 'meta_categorical'.
				type = data.flavor == 'single_categorical' ? 'meta_categorical' : 'meta_' + data.flavor;
				break;
			case 'question':
			case 'smart_question':
				if (data.type_id == 2) {
					type = 'rating';
					break;
				}

				if (data.type_id == 3) {
					type = 'time';
					break;
				}

				if (data.type_id == 5) {
					type = 'continuous';
					break;
				}

				if (data.type_id == 1) {
					var isAll = this.$('.question-selector-primary ' +
					'select[name="relation"] option:selected').val() == 'All';
					type = isAll ? 'multiple_categorical' :'single_categorical';
				}
		}

		var comparisons = this.$('.question-selector').get();
		var comp = {
			name: name,
			type: type,
			project_id: this.projID,
			comparing: _.map(comparisons, function(node) {
				var relatedTo,
					relatedID;
				var nodeData = $(node)
					.find('select[name="question"] option:selected')
					.data();
				var isSmart = nodeData.type == 'smart_question';
				switch (type) {
					case 'meta_categorical':
					case 'meta_rating':
					case 'meta_time':
						relatedTo = 'comparison';
						relatedID = nodeData.id;
						break;
					case 'rating':
					case 'multiple_categorical':
						relatedTo = isSmart ? 'smart_question': 'question';
						relatedID = nodeData.id;
						break;
					case 'time':
					case 'continuous':
					case 'single_categorical':
						relatedTo = isSmart ? 'smart_possible_response' :
							'possible_response';
						relatedID = $(node)
							.find('select[name="relation"] option:selected')
							.data()
							.id;
						break;
				}
				return {
					label: $(node).find('input').val(),
					related_to: relatedTo,
					related_id: relatedID,
					is_primary: $(node).hasClass('question-selector-primary')
				};
			})
		};

		this.comp.comparing = comp.comparing;
		this.comp.save(comp, {
			wait: true,
			success: _.bind(function(model) {
				this.partsProm    = this.parts   .fetch({reset: true});
				this.prsProm      = this.prs     .fetch({reset: true});
				this.smartPrsProm = this.smartPrs.fetch({reset: true});
				this.rsProm       = this.rs      .fetch({reset: true});
				this.compsProm    = this.comps   .fetch({reset: true});
				this.filtersProm  = this.filters .fetch({reset: true});
				$.when(
					this.partsProm,
					this.prsProm,
					this.smartPrsProm,
					this.rsProm,
					this.compsProm,
					this.filtersProm
				).then(_.bind(function() {
					return this.comp.toSeriesSet({
						prs      : this.prs,
						smartPrs : this.smartPrs,
						parts    : this.parts,
						rs       : this.rs,
						qs       : this.qs,
						smartQs  : this.smartQs,
						comps    : this.comps,
						filters  : this.filters
					});
				}, this))
				.then(_.bind(function(ss) {
					return ss.toChartData(this.comp.getCalculator(), this.comp.getCICalculator(), this.alpha);
				}, this))
				.then(_.bind(function(cd) {
					this.trigger('comparison:saved', model);
					this.renderNavHeader();
					this.renderInfo();
					this.renderComparisonList();
					this.chartData = cd;
					this.renderChart(this.alpha);
				}, this));
			}, this),
			error   : function(mod, res, opts) {
					var message = "";
					if(!res.responseJSON.gt_code) {
						message = "Error " + mod;
					}
					if (res.responseJSON.gt_code == 'sq432') {
						var data = res.responseJSON.data;
						message = _.reduce(data, function(memo, dep, key) {

							if (dep.length === 0) return memo;

							else if (key == 'smart_questions') {
								memo += '<strong>Smart Questions</strong><ul>';
								memo = _.reduce(dep, function(memo, item) {
									return memo += '<li>' + item.name + '</li>';
								}, memo);
								memo += '</ul>';
							}

							else if (key == 'comparisons') {
								memo += '<strong>Comparisons</strong><ul>';
								memo = _.reduce(dep, function(memo, item) {
									return memo += '<li>' + item.name + '</li>';
								}, memo);
								memo += '</ul>';
							}

							//additional dependencies (i.e. analyses) will go here

							return memo;
						}, '');

						//show the user the message
						app.ge.trigger('alert', new AlertView({
							m : '<p>Cannot edit because other items depend on this comparison:</p>' +
								message + '<p>Delete the other items and then you can edit this one',
							type: 'alert-warning'
						}));
					} //if sq432 error
				} //error
			});
	},

	/**
	 * Attempts to commit the currently chosen comparison to the local (non-persistent) model. This
	 * is so you can see the graph changing as you edit the comparison details.
	 *
	 * @method commitLocalComparison
	 */
	commitLocalComparison: function() {
		if (!this.validateInputs()) { //don't throw an error, per se, just don't update the graph yet.
			return;
		}

		var type;
		var name = this.$('input[name="name"]').val();
		var data = this.$('.question-selector-primary select[name="question"] option:selected').data();

		switch (data.type) {
			case 'comparison':
				//the ternary operator is required because if the type 'meta_single_categorical' needs
				//does not exist - it's 'meta_categorical'.
				type = data.flavor == 'single_categorical' ? 'meta_categorical' : 'meta_' + data.flavor;
				break;
			case 'question':
			case 'smart_question':
				if (data.type_id == 2) {
					type = 'rating';
					break;
				}

				if (data.type_id == 3) {
					type = 'time';
					break;
				}

				if (data.type_id == 5) {
					type = 'continuous';
					break;
				}

				if (data.type_id == 1) {
					var isAll = this.$('.question-selector-primary ' +
					'select[name="relation"] option:selected').val() == 'All';
					type = isAll ? 'multiple_categorical' :'single_categorical';
				}
		}

		var comparisons = this.$('.question-selector').get();
		var comp = {
			name: name,
			type: type,
			project_id: this.projID,
			comparing: _.map(comparisons, function(node) {
				var relatedTo,
					relatedID;
				var nodeData = $(node)
					.find('select[name="question"] option:selected')
					.data();
				var isSmart = nodeData.type == 'smart_question';
				switch (type) {
					case 'meta_categorical':
					case 'meta_rating':
					case 'meta_time':
						relatedTo = 'comparison';
						relatedID = nodeData.id;
						break;
					case 'rating':
					case 'multiple_categorical':
						relatedTo = isSmart ? 'smart_question': 'question';
						relatedID = nodeData.id;
						break;
					case 'time':
					case 'continuous':
					case 'single_categorical':
						relatedTo = isSmart ? 'smart_possible_response' :
							'possible_response';
						relatedID = $(node)
							.find('select[name="relation"] option:selected')
							.data()
							.id;
						break;
				}
				return {
					label: $(node).find('input').val(),
					related_to: relatedTo,
					related_id: relatedID,
					is_primary: $(node).hasClass('question-selector-primary')
				};
			})
		};

		this.comp.comparing = comp.comparing;
		this.comp.set(comp, {silent: true} );


		this.partsProm    = this.parts   .fetch({reset: true});
		this.prsProm      = this.prs     .fetch({reset: true});
		this.smartPrsProm = this.smartPrs.fetch({reset: true});
		this.rsProm       = this.rs      .fetch({reset: true});
		this.compsProm    = this.comps   .fetch({reset: true});
		this.filtersProm  = this.filters .fetch({reset: true});
		$.when(
			this.partsProm,
			this.prsProm,
			this.smartPrsProm,
			this.rsProm,
			this.compsProm,
			this.filtersProm
		).then(_.bind(function() {
			this.renderComparisonList();
			return this.comp.toSeriesSet({
				prs      : this.prs,
				smartPrs : this.smartPrs,
				parts    : this.parts,
				rs       : this.rs,
				qs       : this.qs,
				smartQs  : this.smartQs,
				comps    : this.comps,
				filters  : this.filters
			});
		}, this))
		.then(_.bind(function(ss) {
			return ss.toChartData(this.comp.getCalculator(), this.comp.getCICalculator(), this.alpha);
		}, this))
		.then(_.bind(function(cd) {
			this.chartData = cd;
			this.renderChart(this.alpha);
		}, this));
	},

	/**
	 * Stops editing the comparison and goes back to the detail view screen by calling the default
	 * navigation header and showing the (non-editable) details of the comparison on screen.
	 *
	 * @method cancelEditComparison
	 */
	cancelEditComparison: function() {
		this.renderNavHeader();
		this.renderInfo();
	},

	/**
	 * Renders the navigation header with appropriate panel controls for editing and adding the
	 * data to Excel.
	 *
	 * This function is required so that the nav header can dynamically change when the comparison
	 * is being edited.
	 *
	 * @method renderNavHeader
	 */
	renderNavHeader: function() {
		var view = this.comp.toTpl();
		view.title = this.title;
		view.prevTitle = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				label      : 'Add to Excel',
				class_name : 'data-download'
			},
			{
				loner      : true,
				label      : 'Edit',
				class_name : 'edit-question'
			}
		];
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);
	},

	/**
	 * TODO: Displays generic information about this comparison by loading the info display template.
	 *
	 * @method renderInfo
	 */
	renderInfo: function() {
		var view               = this.comp.toTpl();
		view.numDependents     = this.getNumDeps();
		view.hasDependents     = view.numDependents > 0;
		view.hasMetaDependents = this.hasMetaDependents();
		view.depsList          = this.generateDepsList(this.comp.get('dependents'));

		this.$('.panel-nav .panel-body').html(
			Mustache.render(this.infoTpl, view, app.partials())
		);
	},

	/**
	 * Displays a form that allows the comparison's details to be edited.
	 *
	 * @method renderEditInfo
	 */
	renderEditInfo: function() {
		var comparisons        = this.$('.question-selector').get();

		var view               = this.comp.toTpl();
		view.numDependents     = this.getNumDeps();
		view.hasDependents     = view.numDependents > 0;
		view.hasMetaDependents = this.hasMetaDependents();
		view.depsList          = this.generateDepsList(this.comp.get('dependents'));
		view.description       = this.comp.comparing_readable({
			prs      : this.prs,
			smartPrs : this.smartPrs,
			parts    : this.parts,
			rs       : this.rs,
			qs       : this.qs,
			smartQs  : this.smartQs,
			comps    : this.comps,
			filters  : this.filters,
		});
		view.comparing         = _.map(comparisons, function(node) {
			return {
				label: $(node).find('input').val(),
				is_primary: $(node).hasClass('question-selector-primary')
			};
		});

		this.$('.panel-nav .panel-body').html(Mustache.render(this.editTpl, view, app.partials()));

		var numQ = this.comp.comparing_readable({
			prs      : this.prs,
			smartPrs : this.smartPrs,
			parts    : this.parts,
			rs       : this.rs,
			qs       : this.qs,
			smartQs  : this.smartQs,
			comps    : this.comps,
			filters  : this.filters,
		}).members.length;

		for(var i=0; i<numQ-2; i++) {
			this.addAdditional(view);
		}

		this.renderPrimaryQuestions(view);
		this.selectCurrentOptions();
	},

	/**
	 * generates a human-readable list of dependencies given the JavaScript array that comes from
	 * a PHP response. This lists comparisons that depend on this comparison in a way that is easy
	 * to understand
	 *
	 * @method generateDepsList
	 * @return {String} a human-readable list of dependents, formatted for HTML.
	 * @param {Array} deps the list of dependents to iterate over.
	 */
	generateDepsList: function(deps) {
		var message = '';
		message = _.reduce(deps, function(memo, dep, key) {
			if (key == 'smart_questions') {
				memo += '<strong>Smart Questions</strong><ul>';
				memo = _.reduce(dep, function(mem, item) {
			return mem += '<li>' + item.name + '</li>';
				}, memo);
				memo += '</ul>';
			}

			else if (key == 'comparisons') {
				memo += '<strong>Comparisons</strong><ul>';
				memo = _.reduce(dep, function(mem, item) {
					return mem += '<li>' + item.name + '</li>';
				}, memo);
				memo += '</ul>';
			}

			//additional dependencies (i.e. analyses) will go here

			return memo;
		}, message);
		return message;
	},

	/**
	 * Iterates through this comparison's dependents and adds up the total number. This is a generic
	 * function that should work for any kind of passed dependents. The function is required because
	 * PHP returns an array of dependents with various keys, allowing for separate lists of dependent
	 * comparions, smart questions, etc.
	 *
	 * @method getNumDeps
	 * @return {int} The total number of dependent items for this comparison.
	 */
	getNumDeps: function() {
		var numSoFar = 0;
		numSoFar = _.reduce(this.comp.get('dependents'), function(memo,dep,key) {
			return memo + dep.length;
		}, numSoFar);

		return numSoFar;
	},

	/**
	 * Iterates through the list of this comparison's dependents and checks for meta_* dependents.
	 * If there is a meta_* dependent, then the form has to restrict the user's editing capabilities.
	 *
	 * NOTE: theoretically, if any comparisons depend on this one, they are automatically meta_*. But
	 * there may be other dependents in the future (i.e. cross tabs), which would make the
	 * validation procedure more complicated by having to check for more dependents.
	 *
	 * @method hasMetaDependents
	 * @return true if one or more meta_*-type dependents use this comparison, false otherwise.
	 */
	hasMetaDependents: function() {
		var hasMetas = false;
		if(!this.comp.get('dependents') || !this.comp.get('dependents').comparisons) return false;
		for(var i=0; i< this.comp.get('dependents').comparisons.length; i++) {
			if(this.comp.get('dependents').comparisons[i].type.substring(0,4) == 'meta') {
				return true;
			}
		}
		return false;
	},

	/**
	 * Uses the current properties of this comparison to select the correct values in the edit fields.
	 *
	 * @method selectCurrentOptions
	 */
	selectCurrentOptions: function() {

		var $target    = this.$('.question-selector-primary select[name="question"]');
		var fieldFills = this.comp.get('comparing');
		var node       = $target
			.closest('.question-selector')
			.find('select[name="relation"]')
			.get(0);

		var comps = this.comp.comparing_readable({
			prs      : this.prs,
			smartPrs : this.smartPrs,
			parts    : this.parts,
			rs       : this.rs,
			qs       : this.qs,
			smartQs  : this.smartQs,
			comps    : this.comps,
			filters  : this.filters,
		});

		//fill primary question
		$target.val(comps.members[0].prompt);

		//make the rest of the questions appear based on the primary one
		var data = $target.find('option:selected').data();
		this.renderAdditionalQuestions(data);
		for(var i=0; i<this.$('.question-selector').get().length; i++) {
			this.activateAdditional();
		}

		//choose additional questions
		var qFields = this.$('.question-selector select[name="question"]');
		var sup = this;
		qFields.each(function(index) {
			$(this).val(comps.members[index].prompt);
			data = $(this).find('option:selected').data();
			node = $(this)
				.closest('.question-selector')
				.find('select[name="relation"]');

			//show possible relations
			sup.renderRelations(data, node.get(0));

			//select the correct relation
			if($.map(node.find('option'), function(e) { return e.value; }).length > 1) {
				node.val(comps.members[index].response_raw);
			}
		});

		//fill the labels
		$('.question-selector input.input-sm').each(function(index) {
			$(this).val(fieldFills[index].label);
		});
		this.limitToLikePrimaryQuestions(data);
		$target.val(comps.members[0].prompt);
	},

	/**
	 * Renders the list of items being compared into the view (non-editable).
	 * This needs to be its own function, because the lists of possible responses, etc. are loaded
	 * asynchronously, making this a callback that waits for those variables to load.
	 *
	 * @method renderComparisonList
	 */
	renderComparisonList: function() {
		var view         = this.comp.toTpl();

		view.panel_title = "Items Compared";
		console.log(this.qs);
		view.description = this.comp.comparing_readable({
			prs      : this.prs,
			smartPrs : this.smartPrs,
			parts    : this.parts,
			rs       : this.rs,
			qs       : this.qs,
			smartQs  : this.smartQs,
			comps    : this.comps,
			filters  : this.filters,
		});
		this.$('.comparison-list').html(
			Mustache.render(this.compListTpl, view, app.partials())
		);
	}

}));