/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		Questions               = require('../collections/Questions.js'),
		AlertView               = require('./AlertView.js'),
		ExcludeParticipantView  = require('./ExcludeParticipantView.js'),
		ExcludeQuestionItemView = require('./ExcludeQuestionItemView.js'),

		app = require('../app.js');

module.exports = Backbone.View.extend({
	tagName: 'div',
	tpl: $('#ExcludeParticipantsView-tpl').html(),

	events: {
		'click .back': 'navigateBack',
		'click .toggle-all': 'toggleAll',
		'click .continue': 'navigateInto',
	},

	initialize: function(opts) {
		this.projectID = opts.projectID;

		this.parts = opts.parts;
		this.rs = null;
		this.prs = null;
		this.qs = new Questions([], {projID: this.projectID});

		this.qItemViews = [];
		this.checkedQIDs = [];

		this.title = 'Inspect Questions';
		this.prevTitle = 'Back';

		this.inspecting = false;

		this.qs.fetch({
			reset: true,
			success: _.bind(this.addAllQs, this),
			error: function(col, res, opts) {
				app.trigger('alert', new AlertView({
					m: 'Problem loading questions',
					type: 'alert-danger'
				}));
			}
		});
	},

	render: function() {
		var view = {
			title          : this.title,
			prevTitle      : this.prevTitle,
			panel_controls : {
					label        : 'Continue',
					loner        : true,
					class_name   : 'continue'
			}
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	close: function() {
		this.trigger('close');
		this.stopListening();
		this.remove();
	},

	getTitle: function() {return this.title;},
	setPrevTitle: function(prev) {this.prevTitle = prev;},
	hide: function() {this.$el.hide();},
	show: function() {this.$el.show();},

	navigateBack: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.trigger('navigateback');
	},

	navigateInto: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.trigger('navigateinto', new ExcludeParticipantView({
			projectID: this.projectID,
			parts: this.parts,
			qs: this.qs,
			checkedQIDs: this.checkedQIDs
		}));
	},

	addAllQs: function() {
		this.qs.each(function(q) {
			var qItemView = new ExcludeQuestionItemView({model: q});
			this.listenTo(qItemView, 'checkchange', this.toggleQ);
			this.qItemViews.push(qItemView);
			this.$('.q-item-mount').append(qItemView.render().el);
		}, this);
	},

	toggleQ: function(id, checked) {
		if (checked) {
			if (!_.contains(this.checkedQIDs, id)) {
				this.checkedQIDs.push(id);
			}
		} else {
			this.checkedQIDs = _.reject(this.checkedQIDs, function(currentID) {
				return id == currentID ? true : false;
			});
		}
	},

	toggleAll: function(e) {
		if (e.currentTarget.checked) {
			_.each(this.qItemViews, function(q) {
				q.check();
			});
		} else {
			_.each(this.qItemViews, function(q) {
				q.uncheck();
			});
		}
	},
});