/* global require, GT */

// Entry point for the client app
(function() {
	var Backbone = require('backbone'),
			_ = require('underscore');

	// extend models to create getters on initialization and to add
	// a toTpl() method that returns an object that includes computed props
	Backbone.Model = (function(Model) {
		return Model.extend({
			constructor: function(/* arguments */) {
				_.each(this.getters, function(getter, name) {
					this['get_' + name] = _.bind(getter, this);
				}, this);
				Model.apply(this, arguments);
			},
			toTpl: function() {
				var j = this.toJSON();
				_.each(this.getters, function(getter, name) {
					j[name] = getter.apply(this);
				}, this);
				return j;
			}
		});
	})(Backbone.Model);

	Backbone.Collection = (function(Collection) {
		return Collection.extend({
			toTpl: function() {
				return this.map(function(model){ return model.toTpl(); });
			}
		});
	})(Backbone.Collection);

	// setup underscore template settings
	_.templateSettings = {
		evaluate:    /\{\{#([\s\S]+?)\}\}/g,            // {{# console.log("blah") }}
		interpolate: /\{\{[^#\{]([\s\S]+?)[^\}]\}\}/g,  // {{ title }}
		escape:      /\{\{\{([\s\S]+?)\}\}\}/g,         // {{{ title }}}
	};

	var $          = require('jquery'),
			app        = require('./app.js'),
			GTRouter   = require('./routers/GTRouter.js'),
			HeaderView = require('./views/HeaderView.js');

	// Override the default Backbone.Ajax function to log users out on a 401 response
	Backbone.ajax = function(ajaxOptions) {
		var
			args          = _.toArray(arguments),
			oldHandler    = args[0].error,
			globalHandler = function(xhr) {
				if (xhr.status == 401) {
					app.user.clear();
					app.routes.navigate('/login', {trigger:true});
				} else if (oldHandler) {
					oldHandler.apply(null, arguments);
				}
		};

		args[0].error = globalHandler;
		return Backbone.$.ajax.apply(Backbone.$, args);
	};

	// set backbone to use jQuery
	Backbone.$ = $;

	if (GT && GT.userJSON) app.user.set(GT.userJSON);

	// draw the header
	var header = new HeaderView();
	$('body').prepend(header.render().el);

	// get the show on the road
	app.routes = new GTRouter();
	Backbone.history.start({pushState: true});
})();