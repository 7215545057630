/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		Projects                 = require('../collections/Projects.js'),
		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		AlertView                = require('./AlertView.js'),
		ProjectItemView          = require('./ProjectItemView.js'),

		app = require('../app.js');

/**
 * Shows a single collection and a list of all projects in the collection. Allows the
 * collection to be renamed.
 *
 * @class CollectionView
 * @extends Backbone.View
 * @constructor
 */
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {

	/**
	 * The standard top-level HTML element type.
	 *
	 * @property tagName
	 * @type {String}
	 * @default 'div'
	 */
	tagName: 'div',

	/**
	 * Additional classes to be added to the top-level element.
	 *
	 * @property className
	 * @default 'panel panel-default'
	 * @type {String}
	 */
	className: 'panel panel-default',

	/**
	 * Mustache template for this view. String of HTML.
	 *
	 * @property tpl
	 * @type {String}
	 */
	tpl: $('#CollectionView-tpl').html(),

	/**
	 * A map of events that this view subscribes to and the corresponding methods called
	 * when those events occur.
	 *
	 * @property events
	 * @type {Collection of Strings}
	 */
	events: {
		//add a new project to this collection
		'click .addProject'               : 'createProject',

		//delete a project entirely and remove it from this collection
		'click .deleteCollection'         : 'initiateDelete',

		//rename this collection
		'click .renameCollection'         : 'initiateRename',

		//cancel the rename operation
		'click .cancelCollectionRename'   : 'cancelRename',

		//try to commit a rename to the database
		'click .finalizeCollectionRename' : 'finalizeRename',
	}, //events

	/**
	 * Basic initializer
	 *
	 * @method initialize
	 * @param  {Object} opts input variables
	 */
	initialize: function(opts) {
		this.id = opts.collection_id;
		this.projects      = new Projects([], {collection_id: opts.collection_id });
		this.projItemViews = [];
		this.collections   = opts.collections;

		this.listenTo(this.projects, 'add', this.addProject);
		this.listenTo(this.projects, 'remove', this.removeProject);

		var projProm = this.projects.fetch({
			reset   : true,
			success : _.bind(this.addAllProjects, this),
			error   : function(collection, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem loading the projects in collection ' + opts.collection_id +
					": " + res.status,
					type: 'alert-danger'
				}));
			}
		});
		$.when(projProm).done(_.bind(function() {}, this));

		this.open = opts.open || false;
	}, //initialize

	/**
	 * Renders this view from the Mustache template and renders the sub-views (project list items).
	 *
	 * @method render
	 * @return {Object} This element for passing back up the render stack.
	 */
	render: function() {
		var view = this.model.toJSON();
		view.in = this.open ? 'in' : '';

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		this.$('.renameCollectionControls').hide();

		//render the list of projectviews for all the projects in the collection
		/*_.each(this.projItemViews, function(item) {
			this.$('.list-group').append(itemView.render().el);
		}, this);*/

		return this;
	}, //render

	/**
	 * Standard behavior; removes this CollectionView.
	 *
	 * @method close
	 */
	close: function() {
		_.each(this.projItemViews, function(v){ v.close(); });
		this.stopListening();
		this.remove();
	}, //close

	/**
	 * Enters the new project view to add a project to this collection.
	 *
	 * @method createProject
	 * @param {Event} e The input event that triggered this method, if one exists.
	 */
	createProject: function(e) {
		e.preventDefault();
		app.routes.navigate('projects/new/' + this.model.get('id'), {trigger: true});
	}, //createProject

	/**
	 * On a new project added to the project list, add the new project item view to this view.
	 *
	 * @method addProject
	 * @param {Project} proj The project to add.
	 */
	addProject: function(proj) {
		var projItemView = new ProjectItemView({model: proj, colls: this.collections});
		this.projItemViews.push(projItemView);
		this.$el.find('.list-group').append(projItemView.render().el);
		this.listenTo(projItemView, 'deleted', this.deletedProjItemView);
		this.listenTo(projItemView, 'removed', this.removeProject);
		this.listenTo(projItemView, 'moved', this.moveProject);
		this.listenTo(projItemView, 'added', this.addProject);

		this.$el.closest('.panel').find('.collapse').collapse('show'); //expand this view
	}, //addProject

	/**
	 * On a project removed to the list, remove the project item view from this view.
	 *
	 * @method removeProject
	 * @param {Project} proj The project to remove.
	 */
	removeProject: function(proj) {
		this.$el.closest('.panel').find('.in').collapse('hide'); //collapse this view
		
		this.projItemViews = _.reject(this.projItemViews, function(piv){
			if(piv.model == proj) {
				this.stopListening();
				piv.close();
				return true;
			}
			return false;
		}, this);
	},

	/**
	 * When a project is moving out of the list. Remove the project and then trigger the move event
	 * for up-the-stack control of the traveling project's movement between collections.
	 *
	 * @method moveProject
	 * @param {Project} proj The moving project.
	 */
	moveProject: function(proj) {
		removeProject(proj);
		this.trigger('projectMoving', proj);
	},

	/**
	 * Adds all projects from the project list for this collection.
	 *
	 * @method addAllProjects
	 */
	addAllProjects: function() {
		$('.loading-area').remove();
		this.projects = _.filter(this.projects.toArray(),
			function(cid) {
				return (+cid.get('collection_id')) == this.id;
		}, this);
		if (this.projects.length > 0) {
			_.each(this.projects, function(q) {
				var projItemView = new ProjectItemView({model: q, colls: this.collections});
				this.listenTo(projItemView, 'deleted', this.deletedProjItemView);
				this.listenTo(projItemView, 'removed', this.removeProject);
				this.listenTo(projItemView, 'moved', this.moveProject);
				this.listenTo(projItemView, 'added', this.addProject);
				this.projItemViews.push(projItemView);
				this.$el.find('.list-group').append(projItemView.render().el);
			}, this);
		} else {
			//$(frag).append(Mustache.render(this.noQsTpl));
		}
	}, //addAllProjects

	/**
	 * Triggered when a project is removed from the list of projects (i.e. deleted).
	 *
	 * NOTE that this does NOT fire when a project is moved out of the collection, but only when
	 * the 'deleted' event is triggered. 'deleted' is only triggered on actual project deletion,
	 * not moving.
	 * 
	 * @param  {ProjectItemView} view The project to be removed.
	 */
	deletedProjItemView: function(view) {
		this.projItemViews = _.reject(this.projItemViews, function(piv) {return piv.model == view; });
	}, //deletedProjItemView

	/**
	 * Prompts the user to be sure that they want to delete a collection, and if so, calls
	 * deleteCollection().
	 *
	 * @method initiateDelete
	 * @param {Event} e The input event that triggered this method, if one exists.
	 */
	initiateDelete: function(e) {
		e.preventDefault();
		var msg = 'Are you sure you want to delete the collection:\n\n' + this.model.get('name') +
			'\n\nThis operation cannot be undone and ALL PROJECTS in the collection will also ' +
			'be DELETED.';
		if (window.confirm(msg)) {
			this.deleteCollection(e);
		}
	},  //initiateDelete

	/**
	 * Removes a collection from the database.
	 *
	 * @method deleteCollection
	 * @param {Event} e The input event that triggered this method, if one exists.
	 */
	deleteCollection: function(e) {
		e.preventDefault();
		this.model.destroy({
			wait: true,
			success: _.bind(function(model, response, options) {
				this.stopListening();
				this.trigger('deleted');
				this.remove(e);
			}, this),
			error: _.bind(function(model, xhr, options) {
				console.log(model);
				console.log(xhr);
				console.log(options);
				app.ge.trigger('alert', new app.AlertView({
					m: 'There was an error communicating with the server (' + xhr.status +
						', ' + xhr.responseJSON.gt_code + '). ' +
						'The collection was not deleted.',
					type: 'alert-danger'
				}));
			}, this)
		}, this);
	}, //deleteCollection

	/**
	 * Begins the rename process by showing the rename form to the user.
	 *
	 * @method initiateRename
	 * @param  {Event} e The input event that triggered this method, if one exists.
	 */
	initiateRename: function(e) {
		e.preventDefault();
		e.stopPropagation();
		this.$('.mainCollectionControls').hide();
		this.$('.collection-name').hide();
		this.$('.renameCollectionControls').show(); //show the edit box (input field)
		this.$('.editCollectionName').focus();
	}, //initiateRename

	/**
	 * Attempts to commit a new collection name to the database.
	 *
	 * @method finalizeRename
	 * @param {Event} e The input event that triggered this method, if one exists.
	 */
	finalizeRename: function(e) {
		e.preventDefault();
		e.stopPropagation();
		this.targetName = this.$('.editCollectionName').val();
		this.model.save({name: this.targetName}, {
			wait: true,
			success: _.bind(function(modell, xhr, options) {
				this.model.set('id', +xhr.data.id);
				this.$('.collection-name').html(this.targetName);
				this.cleanUpRename();
			}, this), //success
			error: _.bind(function(modell, xhr, options) {
				console.log(modell);
				console.log(xhr);
				console.log(options);
				this.cleanUpRename();
				app.ge.trigger('alert', new AlertView({
					m: 'There was an error communicating with the server (' + xhr.status +
						'). ' +
						'The collection was not created.',
					type: 'alert-warning'
				}));
			}, this) //error
		}); //this.model.save
	}, //finalizeRename

	/**
	 * Cancels a rename(/creation) operation on a collection
	 *
	 * @method cancelRename
	 * @param {Event} e The input event that triggered this method, if one exists.
	 */
	cancelRename: function(e) {
		if(this.$('.finalizeCollectionRename').html().indexOf("Create") > -1) {
			//canceled the rename while in creation mode -> remove this collection entirely.
			this.trigger('deleted');
			this.cleanUpRename();
			this.close();
		}
	}, //cancelRename

	/**
	 * Cleans up after a renaming operation, regardless of whether or not the rename
	 * was successful.
	 *
	 * @method cleanUpRename
	 * @param  {Event} e The input event that triggered this method, if one exists.
	 */
	cleanUpRename: function(e) {
		if(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.$('.renameCollectionControls').hide();
		this.$('.finalizeCollectionRename').html(
			'<span class="glyphicon glyphicon-pencil"></span> Rename');
		this.$('.collection-name').show();
		this.$('.mainCollectionControls').removeAttr('style');
		this.$('.renameCollectionControls .editCollection').val(this.$('.collection-name').html());
	}, //cleanUpRename

})); //app.CollectionView
