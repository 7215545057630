/* global require, module */

var Backbone = require('backbone'),
		Project = require('../models/Project.js');

module.exports = Backbone.Collection.extend({
	model: Project,
	url: function() {
		if (this.collectionID) return '/api1/project/all_for_collection/' + this.collectionID;
		return '/api1/project/all_for_team';
	},

	// see http://stackoverflow.com/questions/11510490/sort-backbone-collection-based-on-model-attributes for sorting details
	initialize: function(opts) {
		this.sortKey = 'mod_date';
		this.collectionID = opts.collectionID || null;
	},

	comparator: function(a, b) {
		a = a.get(this.sortKey);
		b = b.get(this.sortKey);

		switch (this.sortKey) {
			case 'mod_date':
				return -1 * (Date.parse(a.split(' ').join('T')) - Date.parse(b.split(' ').join('T')));
		}
	}
});