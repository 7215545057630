/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		PossibleResponses        = require('../collections/PossibleResponses.js'),
		SmartPossibleResponses   = require('../collections/SmartPossibleResponses.js'),
		Comparisons              = require('../collections/Comparisons.js'),

		MetaTimeComparison = require('../models/MetaTimeComparison.js'),
		MetaRatingComparison = require('../models/MetaRatingComparison.js'),
		MetaCategoricalComparison = require('../models/MetaCategoricalComparison.js'),
		TimeComparison = require('../models/TimeComparison.js'),
		RatingComparison = require('../models/RatingComparison.js'),
		MultipleCategoricalComparison = require('../models/MultipleCategoricalComparison.js'),
		SingleCategoricalComparison = require('../models/SingleCategoricalComparison.js'),
		ContinuousComparison = require('../models/ContinuousComparison.js'),
		MetaContinuousComparison = require('../models/MetaContinuousComparison.js'),

		AlertView                = require('./AlertView.js'),
		app                      = require('../app.js');

/**
 *
 * @class CreateComparisonView
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#CreateComparisonView-tpl').html(),
	selectorTpl: $('#CreateComparisonView-selector-tpl').html(),
	optionTpl: $('#CreateComparisonView-option-tpl').html(),

	events: {
		//standard behavior
		'click .back': 'navigateBack',

		//change primary question selection
		'change .question-selector-primary select[name="question"]': 'changePrimary',

		//change primary question response
		'change .question-selector-primary select[name="relation"]': 'changePrimaryRelation',

		//change a secondary question selection
		'change .question-selector:not(.question-selector-primary) select[name="question"]':
			'changeAdditional',

		//change a secondary question response
		'change .question-selector:not(.question-selector-primary) select[name="relation"]':
			'changeAdditionalRelation',

		//add another question
		'click .add-additional': 'addAdditional',

		//remove a question
		'click .remove-me': 'removeSelector',

		//save the new comparison
		'click .save': 'saveComparison',

		//check length of label inputs
		'keyup input[type="text"]': 'validateInput'
	},

	initialize: function(opts) {
		this.qs          = opts.qs;
		this.smartQs     = opts.smartQs;

		this.projID      = opts.projID;
		this.title       = 'Create Comparison';
		this.prevTitle   = 'Back';

		this.numQs       = 1;
		this.selected    = false;

		this.prs         = new PossibleResponses([], {projID: this.projID});
		var prsProm      = this.prs.fetch({reset: true});

		this.smartPrs    = new SmartPossibleResponses(
			[], {projID : this.projID}
		);
		var smartPrsProm = this.smartPrs.fetch({reset: true});

		this.comps       = new Comparisons([], {projID: this.projID});
		var compsProm    = this.comps.fetch({reset: true});

		$.when(prsProm, smartPrsProm, compsProm)
			.done(_.bind(function() {
				this.renderPrimaryQuestions();
			}, this))
			.fail(function() {
				console.log('Error downloading stuff'); //todo
			});
		

	},

	render: function() {
		var view = {
			title          : this.title,
			prevTitle      : this.prevTitle,
			panel_controls : [
				{
					loner      : true,
					class_name : 'save',
					btn_type   : 'primary',
					label      : 'Save'
				}
			]
		};
		view.questions = this.qs.map(function(q) {
			return {
				id   : q.get('id'),
				name : q.get('name')
			};
		});
		this.$el.html(Mustache.render(this.tpl, view, app.partials({
			options_partial: this.optionTpl
		})));
		this.$primaryQ = this.$('.question-selector-primary select');
		return this;
	},

	/**
	 * Renders the full list of possible primary questions into the form.
	 *
	 * @method renderPrimaryQuestions
	 */
	renderPrimaryQuestions: function() {
		var unallowed    = [4];
		var view         = {};
		view.is_question = true;
		view.optgroups   = [
			{
				label: 'Questions',
				options: this.qs.chain()
					.reject(function(q) {
						return _.contains(unallowed, +q.get('type_id'));
					})
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Smart Questions',
				options: this.smartQs.chain()
					.reject(function(q) {
						return _.contains(unallowed, +q.get('type_id'));
					})
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'smart_question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Comparisons',
				options: _.map(this.comps.getBasic(), function(c) {
					return {
						id     : c.get('id'),
						type   : 'comparison',
						flavor : c.get('type'),
						name   : c.get('name')
					};
				})
			}
		];
		this.$('.question-selector-primary select[name="question"]').html(
			Mustache.render(this.optionTpl, view, app.partials())
		);
	},

	/**
	 * Does all the legwork necessary to display the full list of additional (secondary) questions,
	 * with proper available options for questions and responses.
	 *
	 * @method renderAdditionalQuestions
	 * @param  {Object} data     additional data needed to fully define the comparison.
	 * @param  {String} selector jQuery selector for which element into which to render the questions.
	 */
	renderAdditionalQuestions: function(data, selector) {
		selector = selector || null;
		var optgroups = [],
			primary,
			primaryNum;

		var isAll = this.$('.question-selector-primary select[name="relation"]')
			.val() == 'All';

		if (isAll) {
			switch (data.type) {
				case 'question':
					primaryNum = this.prs.chain()
						.filter(function(pr){
							return pr.get('question_id') == data.id && !+pr.get('is_empty') && !!+pr.get('is_response');
						})
						.size()
						.value();
					break;
				case 'smart_question':
					primaryNum = this.smartPrs.chain()
						.filter(function(pr) { return pr.get('smart_question_id') == data.id; })
						.size()
						.value();
					break;
			}
		}

		switch (data.type) {
			case 'question':
			case 'smart_question':
				if (data.type == 'question') {
					primary = this.qs.get(data.id);
				} else if (data.type == 'smart_question') {
					primary = this.smartQs.get(data.id);
				}
				optgroups.push({
					label: 'Questions',
					options: this.qs.chain()
						.filter(function(q) {
							var matchNum = true;
							if (isAll) {
								var num = this.prs.chain()
									.filter(function(pr){
										return pr.get('question_id') == data.id && !+pr.get('is_empty') && !!+pr.get('is_response');
									})
									.size()
									.value();
								matchNum = num == primaryNum;
							}
							var matchType = q.get('type_id') == primary.get('type_id');
							var diffQ     = q.get('id') != data.id;

							return matchType && matchNum && diffQ;
						}, this)
						.map(function(q) {
							return {
								id      : q.get('id'),
								type    : 'question',
								name    : q.get('name'),
								type_id : q.get('type_id')
							};
						})
						.value()
				});
				optgroups.push({
					label: 'Smart Questions',
					options: this.smartQs.chain()
						.filter(function(q) {
							var matchNum = true;
							if (isAll) {
								var num = this.smartPrs.chain()
									.filter(function(pr) { return pr.get('smart_question_id') == q.get('id'); })
									.size()
									.value();
								matchNum = num == primaryNum;
							}
							var matchType = q.get('type_id') == primary.get('type_id');
							var diffQ     = q.get('id') != data.id;

							return matchType && matchNum && diffQ;
						}, this)
						.map(function(q) {
							return {
								id      : q.get('id'),
								type    : 'smart_question',
								name    : q.get('name'),
								type_id : q.get('type_id')
							};
						})
						.value()
				});
				break;

			case 'comparison':
				primary = this.comps.get(data.id);
				optgroups.push({
					label: 'Comparisons',
					options: _.chain(this.comps.getBasic())
						.filter(function(c) {
							var matchNum = (c.get('comparing').length ==	primary.get('comparing').length);
							var diffQ    = c.get('id') != data.id;
							var sameType = c.get('type') == primary.get('type');

							return matchNum && diffQ && sameType;
						})
						.map(function(c) {
							return {
								id   : c.get('id'),
								type : 'comparison',
								name : c.get('name')
							};
						})
						.value()
				});
				break;
		}

		var view = {};
		view.is_question = true;
		view.optgroups = optgroups;
		if (selector) {
			$(selector).find('select[name="question"]').html(
				Mustache.render(this.optionTpl, view, app.partials())
			);
		} else {
			this.$(
				'.question-selector:not(.question-selector-primary) ' + 'select[name="question"]'
			).html(Mustache.render(this.optionTpl, view, app.partials()));
		}

		view = {};
		view.is_relation = true;
		if (selector) {
			$(selector).find('select[name="relation"]').html(
				Mustache.render(this.optionTpl, view, app.partials())
			);
		} else {
			this.$(
				'.question-selector:not(.question-selector-primary) ' + 'select[name="relation"]'
			).html(Mustache.render(this.optionTpl, view, app.partials()));
		}
	},

	/**
	 * Renders out the names of the possible relations to compare, based on the selections that have
	 * already been made.
	 *
	 * @method renderRelations
	 * @param  {Object} data Auxiliary data needed to fully define the comparison, such as question
	 * names and prompts.
	 * @param  {jQuery element} node The relations element into which this function will render.
	 */
	renderRelations: function(data, node) {
		var view = {};
		var relations,
			type;

		console.log(data);
		switch (data.type) {
			case 'question':
				relations = this.prs.chain()
					.filter(function(pr){
						return pr.get('question_id') == data.id.toString();
					})
					.reject(function(pr) {
						return +pr.get('is_response') === 0 || +pr.get('is_empty') === 1;
					})
					.map(function(pr) {
						if (+pr.get('has_value') === 1) {
							return {
								name: '--',
								id: pr.get('id'),
								type: 'possible_response'
							};
						} else {
							return {
								name: pr.get('response'),
								id: pr.get('id'),
								type: type
							};
						}
					})
					.value();
				type = 'possible_response';
				break;
			case 'smart_question':
				relations = this.smartPrs.chain()
					.filter(function(pr){
						return pr.get('smart_question_id') == data.id.toString();
					})
					.reject(function(pr) {
						return +pr.get('is_response') === 0 || +pr.get('is_empty') === 1;
					})
					.map(function(pr) {
						if (+pr.get('has_value') === 1) {
							return {
								name: '--',
								id: pr.get('id'),
								type: 'possible_response'
							};
						} else {
							return {
								name: pr.get('response'),
								id: pr.get('id'),
								type: type
							};
						}
					})
					.value();
				type = 'smart_possible_response';
				break;
			case 'comparison':
				relations = [];
				type = 'comparison';
				break;
		}

		console.log('relations', relations);
		if (data.type_id == 2) {
			relations = [];
		}
		console.log('relations', relations);
		console.log('type', type);

		view.options = relations;
		var isAllSelectedOnPrimary =
			this.$('.question-selector-primary select[name="relation"]').val() == 'All';
		var isPrimary = $(node).closest('.question-selector').hasClass('question-selector-primary');

		if(isPrimary) { //is the primary field
			if (data.type_id == 1) { //no meta dependents, every option still acceptable here
				view.options.unshift({name: 'All'}); //add All to list of options
			}
			else { //No meta dependents, All not OK, no choices
				if(view.options.length > 0) {
					view.options[0].name = '--'; //make it clear that there is no option.
				} else {
					view.options[0] = {name:'--'};
				}
			}
		}
		else { //NOT primary field
			if(view.options.length < 1 || isAllSelectedOnPrimary) { //no choice, we're locked in
				view.options = [{ name: '--' }]; //make it clear that there is no option.
			}
			else { //we have options. All is NOT acceptable because it's not selected on primary.
				if (data.type_id == 1) {
					view.is_relation = true; //allow displaying of options in template
				}
				else {
					// All still not acceptable, so do nothing.
				}
			}
		}

		console.log('view', view);

		$(node).html(
			Mustache.render(this.optionTpl, view, app.partials())
		);

		if (view.options[0].name == '--') {
			$(node).closest('.form-group').removeClass('has-error');
		}
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	navigateBack: function(e) {
		if (e) { e.preventDefault(); }
		this.trigger('navigateback');
	},


	/**
	 * Fired when the primary question choice is changed. Updates the other form elements to reflect
	 * the new possible options given the new choice.
	 *
	 * @method changePrimary
	 * @param  {Event} e The input event
	 */
	changePrimary: function(e) {
		var $target = $(e.target);
		var $option = $target.find('option:selected');
		var data = $option.data();

		$target.closest('.form-group').removeClass('has-error');

		var node = $(e.target)
			.closest('.question-selector')
			.find('select[name="relation"]')
			.get(0);

		this.renderRelations(data, node);

		if (!this.selected) {
			this.activateAdditional();
			this.selected = true;
		}

		this.renderAdditionalQuestions(data);
	},

	/**
	 * Fired when the primary question response type (i.e. all reponses, success, timeout) is
	 * changed. Updates the other fields in the form to reflect the new possible options given this
	 * change.
	 *
	 * @method changePrimaryRelation
	 * @param  {Event} e The input event
	 */
	changePrimaryRelation: function(e) {
		var $target = $(e.target);
		$target.closest('.form-group').removeClass('has-error');

		var data = this.$('.question-selector-primary ' +
			'select[name="question"] option:selected').data();
		this.renderAdditionalQuestions(data);
	},

	/**
	 * Fired when the secondary question response type (i.e. all reponses, success, timeout) is
	 * changed. If something is selected, remove the error flag on this field.
	 *
	 * @method changeAdditionalRelation
	 * @param  {Event} e The input event
	 */
	changeAdditionalRelation: function(e) {
		var $target = $(e.target);
		$target.closest('.form-group').removeClass('has-error');
	},

	/**
	 * Fired when the secondary question selection is changed. Updates the selector next to this
	 * question to reflect the possible response choices.
	 *
	 * @method changeAdditional
	 * @param  {Event} e The input event
	 */
	changeAdditional: function(e) {
		var $target = $(e.target);

		$target.closest('.form-group').removeClass('has-error');

		var node = $target
			.closest('.question-selector')
			.find('select[name="relation"]')
			.get(0);
		var data = $target.find('option:selected').data();
		this.renderRelations(data, node);
	},

	/**
	 * Activates an additional question (when the one before it is fully defined, this one can
	 * be edited).
	 *
	 * @method activateAdditional
	 */
	activateAdditional: function() {
		this.$('button.disabled').removeClass('disabled');
		this.$('select[disabled="disabled"]').removeAttr('disabled');
		this.$('input[disabled="disabled"]').removeAttr('disabled');
	},

	/**
	 * Adds another comparison item to the list of secondary items and adds the appropriate form
	 * template to the view. Opposite of removeSelector
	 *
	 * @method addAdditional
	 */
	addAdditional: function() {
		var view = {};
		var selector = $(Mustache.render(this.selectorTpl, view, app.partials()))
			.appendTo(this.$('.question-selectors'));

		var data = this.$('.question-selector-primary select[name="question"] option:selected')
			.data();
		this.renderAdditionalQuestions(data, selector);
	},

	/**
	 * Removes an item from the list of secondary comparison terms
	 *
	 * @method removeSelector
	 * @param  {event} e The input event
	 */
	removeSelector: function(e) {
		$(e.target).closest('.question-selector').remove();
	},

	/**
	 * Validates the input to a "label" text input field. Currently only checks for a reasonable
	 * length for the label.
	 *
	 * @method validateInput
	 * @param  {Event} e the input event
	 */
	validateInput: function(e) {
		var $target = $(e.target);
		var len = $target.val().length;
		if (len >= 1 && len <= 120) {
			$target.closest('.form-group').removeClass('has-error');
		}
	},

	/**
	 * Validates all input fields in the form
	 *
	 * @method validateInputs
	 * @param  {Event} e the input event
	 */
	validateInputs: function() {
		var valid = true;
		_.each(this.$('input[type="text"]').get(), function(input) {
			var $input = $(input);
			var len = $input.val().length;
			if ((len < 1 || len > 120) && $input.is(':not(:disabled)')) {
				valid = false;
				$input.closest('.form-group').addClass('has-error');
			}
		});

		_.each(this.$('select').get(), function(select) {
			var $select = $(select);
			if ($select.val() === null && $select.is(':not(:disabled)')) {
				valid = false;
				$select.closest('.form-group').addClass('has-error');
			}
		});

		return valid;
	},

	/**
	 * Validates the fields, and if all is well, parses the input, creates the new comparison, and
	 * saves it to the database.
	 *
	 * @method saveComparison
	 */
	saveComparison: function() {
		if (!this.validateInputs()) {
			app.ge.trigger('alert', new AlertView({
				m: 'Must complete all fields',
				type: 'alert-danger'
			}));
			return;
		}

		var name = this.$('input[name="name"]').val();
		var type;
		var data = this.$('.question-selector-primary ' +
			'select[name="question"] option:selected').data();

		switch (data.type) {
			case 'comparison':
				//the ternary operator is required because if the type 'meta_single_categorical' needs
				//does not exist - it's 'meta_categorical'.
				type = data.flavor == 'single_categorical' ? 'meta_categorical' : 'meta_' + data.flavor;
				break;
			case 'question':
			case 'smart_question':
				if (data.type_id == 2) {
					type = 'rating';
					break;
				}

				if (data.type_id == 3) {
					type = 'time';
					break;
				}

				if (data.type_id == 5) {
					type = 'continuous';
					break;
				}

				if (data.type_id == 1) {
					var isAll = this.$('.question-selector-primary ' +
					'select[name="relation"] option:selected').val() == 'All';
					type = isAll ? 'multiple_categorical' :'single_categorical';
				}
		}

		var comparisons = this.$('.question-selector').get();
		var comp = {
			name: name,
			type: type,
			project_id: this.projID,
			comparing: _.map(comparisons, function(node) {
				var relatedTo,
					relatedID;
				var nodeData = $(node)
					.find('select[name="question"] option:selected')
					.data();
				var isSmart = nodeData.type == 'smart_question';
				switch (type) {
					case 'meta_categorical':
					case 'meta_rating':
					case 'meta_time':
					case 'meta_continuous':
						relatedTo = 'comparison';
						relatedID = nodeData.id;
						break;
					case 'rating':
					case 'multiple_categorical':
						relatedTo = isSmart ? 'smart_question': 'question';
						relatedID = nodeData.id;
						break;
					case 'time':
					case 'continuous':
					case 'single_categorical':
						relatedTo = isSmart ? 'smart_possible_response' : 'possible_response';
						relatedID = $(node)
							.find('select[name="relation"] option:selected')
							.data()
							.id;
						break;
				}
				return {
					label: $(node).find('input').val(),
					related_to: relatedTo,
					related_id: relatedID,
					is_primary: $(node).hasClass('question-selector-primary')
				};
			})
		};

		var model;
		switch (type) {
			case 'meta_categorical'     : model = new MetaCategoricalComparison(comp); break;
			case 'meta_rating'          : model = new MetaRatingComparison(comp); break;
			case 'meta_time'            : model = new MetaTimeComparison(comp); break;
			case 'rating'               : model = new RatingComparison(comp); break;
			case 'multiple_categorical' : model = new MultipleCategoricalComparison(comp); break;
			case 'single_categorical'   : model = new SingleCategoricalComparison(comp); break;
			case 'time'                 : model = new TimeComparison(comp); break;
			case 'continuous'           : model = new ContinuousComparison(comp); break;
			case 'meta_continuous'      : model = new MetaContinuousComparison(comp); break;
			default                     : throw new Error('Unexpected comparison type');
		}

		model.save({}, {
			success: _.bind(function(model) {
				this.trigger('comparison:saved', model);
				this.trigger('navigateback');
			}, this),
			error: function() {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem saving comparison to db',
					type: 'alert-danger'
				}));
			}
		});
	}

}));
