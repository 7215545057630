/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery')

/**
@class SUPRQLiteAnalytic
@constructor
@extends Analytic
@module Models
*/
module.exports = Backbone.Model.extend({
	/**
	@property urlRoot
	@type String
	@default '/api1/analytic'
	@final
	*/
	url: function() {
		if (this.analyticID) return '/api1/response/all_for_analytic/' + this.analyticID;
	},
	initialize: function(models, opts) {
		this.analyticID = this.get('analyticID') || null;
	},
	defaults: {
		id                   : null,
		target_question_id   : null,
		target_question_type : '',
		description          : '',
		filters              : {},
		filtering_questions  : {},
		type                 : 'suprqlite',
		name                 : '',
		project_id           : null,
		q1_id                : null,
		q2_id                : null,
		q3_id                : null,
		q4_id                : null,
		q5_id                : null,
		q6_id                : null,
		q7_id                : null,
		q8_id                : null
	},

	getters: {

	},
});