/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		d3 = require('d3'),
		$ = require('jquery'),

		AlertView = require('./AlertView.js'),
		app = require('../app.js');
		require('bootstrap');

/**
 * This view displays a graph based on given data, and allows the user some control over how it is
 * displayed (i.e. alpha value, horizontal vs. vertical graphs)
 *
 * @class GColView
 * @constructor
 * @extends Backbone.View
 * @module Views
 */
module.exports = Backbone.View.extend({
	/**
	Main template string
	@property tpl
	@type String
	*/
	tpl: _.template($('#GColView-tpl').html()),

	/**
	 * List of events that this view listens for. The values in the collection are the method names
	 * fired when the events described by the key occur.
	 *
	 * @property events
	 * @type {String collection}
	 */
	events: {
		//switch to vertical bar graph
		'click .vertical': 'toVertical',

		//switch to horizontal bar graph
		'click .horizontal': 'toHorizontal',

		//toggle display of error bars on each bar on the graph
		'click .error-bars': 'toggleErrorBars',

		//user modifies alpha-value
		'keydown .graph-alpha': 'changeAlpha',

		//user adds this chart to excel export
		'click .excel': 'addToExcel'
	},

	/**
	 * Sets up defaults and original values, defines color scheme.
	 *
	 * @param  {Object} opts Initialization arguments
	 * @method initialize
	 */
	initialize: function(opts) {
		if (!opts.hasOwnProperty('el')) {
			throw new Error('Chart view constructors require an `el`.');
		}

		// GRAPH LEVEL OPTIONS //

		/**
		Title for this graph, defaults to null which then isn't displayed
		@property title
		@type String
		@default null
		*/
		this.title = opts.title || null;

		/**
		Whether to actually display the titles, defaults to false
		@property titlesOn
		@type Boolean
		@default false
		*/
		this.titlesOn = opts.titlesOn || false;

		/**
		Not yet implemented
		@property caption
		@type String
		*/
		this.caption = opts.caption || null;

		/**
		Array of hex RGB colors to use for the color scheme
		@property colors
		@type Array
		@default The same 10 colors used in D3's category10 ordinal scale
		*/
		this.colors = opts.colors || ['#5078af', '#749fd5', '#5ccee0', '#41eca3', '#eae980', '#f1ca53',
																  '#f79423', '#db643b', '#be4c4c', '#8f3468', '#46338c', '#32568a'];

		/**
		Set the margins which can be filled with axis information, etc.
		@property margin
		@type Object
		*/
		this.margin = _.extend({}, opts.margin, {top: 40, right: 20, bottom: 50, left: 40});

		/**
		Sets the maximum width of the graph including margins. A value of null indicates the width of
		the containing element
		@property width
		@type Number
		@default null
		*/
		this.width = opts.width || null;

		/**
		Sets the maximum height of the graph including margins. A value of null indicates the height
		will be calculated automatically based on the type and contents of the graph.
		@property height
		@type Number
		@default null
		*/
		this.height = opts.height || null;

		/**
		Sets the preferred height for vertical graphs, overridden by `height` if set.
		@property vHeight
		@type Number
		@default 500
		*/
		this.vHeight = opts.vHeight || 500;

		/**
		Configures the style of the error bars.
		@property err
		@type Object
		@default {style: 'line', w:2, color: '#000000'}
		*/
		this.err = opts.err || {style: 'line', w: 2, color: '#000000'};

		/**
		Flag indicating whether error bars should be drawn
		@property showErrorBars
		@type Boolean
		@default true
		*/
		this.showErrorBars = opts.showErrorBars || true;

		/**
		Sets the maximum value for the numeric axis, a value of null indicates the best value will be
		determined automatically.
		@property max
		@type Number
		@default null
		*/
		this.max = opts.hasOwnProperty('max') ? opts.max : null;

		/**
		Sets the minimum value for the numeric axis, a value of null indicates the best value will be
		determined automatically.
		@property min
		@type Number
		@default null
		*/
		this.min = opts.hasOwnProperty('min') ? opts.min : null;

		/**
		Sets the type of the graph. Legal values are currently 'horizontal' or 'vertical'. Null
		indicates the view will choose the best type based on the data.
		@property type
		@type String
		@default null
		*/
		this.type = opts.type || null;

		/**
		The confidence level used to compute the error bars. Required.
		@property alpha
		@type Number
		*/
		if (!opts.alpha) throw new Error('Must set alpha property on GColView');
		this.alpha = opts.alpha;


		this.sampleSize = opts.sampleSize;

		/**
		Array of objects that each represent a point on the graph. Follow the form:

			{
				value  : Number,
				low    : Number,
				high   : Number,
				label  : String,
				series : String
			}

		All are required, so you'll have to set low and high to 0 if you don't have error bars.
		Required.
		@property data
		@type Array
		*/
		if (!opts.data) throw new Error('Must set data property on GColView');
		this.data = opts.data;

		/**
		String used to create the `d3.format()`er for numeric values.
		@property formatString
		@type String
		@default '.3f'
		*/
		this.formatString = opts.formatString || '.3f';

		/**
		Internal d3.js formatter used to prettify numbers
		@property format
		@type D3.format object
		@private
		*/
		this.format = opts.format || d3.format(this.formatString);
                this.negativeFlag = opts.negativeFlag || null;
	},

	/**
	Setter to update the `data` property.
	@method setData
	@param {Array} data
	@chainable
	*/
	setData: function(data){ this.data = data; return this; },

	/**
	Setter to update the `alpha` property
	@method setAlpha
	@param {Number} alpha
	@chainable
	*/
	setAlpha: function(alpha){
		this.alpha = alpha;
		this.$('.graph-alpha').val(alpha);
		return this;
	},

	/**
	 * If the new alpha value is within acceptable values, assigns it and
	 * triggers the appropriate event
	 *
	 * @method changeAlpha
	 * @param  {Event} e the passed input event
	 */
	changeAlpha: function(e) {
		var enterHit = e.which === 13;
		if (enterHit) {
			this.$('.graph-alpha').blur();
			var val = this.$('.graph-alpha').val();

			if(!this.isAlphaValid(val)) {
				app.ge.trigger('alert', new AlertView({
					m    : 'Your alpha value must satisfy: 0 < alpha < 1',
					type : 'alert-danger'
				}));
				return;
			}
			this.alpha = val;
			/**
			Notifies that the alpha value has been changed and passes the new value up
			@event changealpha
			@param {Number} val The new alpha value
			*/
			this.trigger('changealpha', val);
		}
	},

	/**
	 * Validates an alpha value.
	 *
	 * @method isAlphaValid
	 * @param {Number} alpha The value to validate
	 * @return false if the alpha value is <= 0 or >= 1, neither of which make statistical sense.
	 * Otherwise return true.
	 */
	isAlphaValid: function(alpha) { return alpha > 0 && alpha < 1; },

	/**
	 * Hides or shows the alpha-level error bars on the entire graph, then re-renders the chart.
	 *
	 * @method toggleErrorBars
	 */
	toggleErrorBars: function() {
		var $alphaInput = this.$('.graph-alpha');
		if ($alphaInput.prop('disabled')) {
			$alphaInput.prop('disabled', false);
		} else {
			$alphaInput.prop('disabled', true);
		}

		if (this.showErrorBars) {
			this.showErrorBars = false;
		} else {
			this.showErrorBars = true;
		}

		this.renderChart();
	},

	/**
	 * Converts the graph to a vertical bar graph. Invokes renderChart()
	 *
	 * @method toVertical
	 * @param reRender {Boolean} Whether to redraw the graph too or just change UI. Default is true.
	 */
	toVertical: function(reRender) {
		if (reRender === undefined) reRender = true;

		this.type = 'vertical';
		this.$('.horizontal.active').removeClass('active');
		this.$('.vertical').addClass('active');
		if (reRender) this.renderChart();
	},

	/**
	 * Converts the graph to a horizontal bar graph. Invokes renderChart()
	 *
	 * @method toHorizontal
	 * @param reRender {Boolean} Whether to redraw the graph too or just change UI. Default is true.
	 */
	toHorizontal: function(reRender) {
		if (reRender === undefined) reRender = true;

		this.type = 'horizontal';
		this.$('.vertical.active').removeClass('active');
		this.$('.horizontal').addClass('active');
		if (reRender) this.renderChart();
	},


	/**
	 * Adds this chart's data to the Excel export list.
	 *
	 * @method addToExcel
	 */
	addToExcel: function() {
		var series = _.uniq(_.pluck(this.data, 'series'));
		var data = _.map(series, function(s) {
			var points = _.where(this.data, {series: s});
			return {
				series_name: s,
				series_values: _.pluck(points, 'value'),
				series_labels: _.pluck(points, 'label'),
				series_low: _.map(points, function(p) {
					if (p.hasOwnProperty('low')) {
						return p.value - p.low;
					} else {
						return 0;
					}
				}),
				series_high: _.map(points, function(p) {
					if (p.hasOwnProperty('high')) {
						return p.high - p.value;
					} else {
						return 0;
					}
				}),
			};
		}, this);

		var properties = {};
		if (!_.isNull(this.min)) properties.min = this.min;
		if (!_.isNull(this.max)) properties.max = this.max;
		if (
			!_.isNull(this.min) &&
			!_.isNull(this.max) &&
			this.max - this.min < 15 &&
			this.max - this.min !== 1 // categorical
		) {
			properties.major_unit = 1;
		}

		// convert d3 number format to excel
		properties.num_format = app.convertD3FormatToExcel(this.formatString);


		this.trigger('GColView:addtoexcel', data, this.type, properties);
	},

	/**
	 * Render this view by clearing the view, setting the correct parameters, loading the template,
	 * and then drawing the entire chart.
	 *
	 * @method render
	 */
	render: function() {
		this.$el.html(this.tpl({alpha: this.alpha, orientation: this.type}));
		this.renderChart();
	},

	/**
	 * Only re-renders the chart itself, without reloading the template.
	 *
	 * @method reRender
	 */
	reRender: function() {
		this.renderChart();
	},

	/**
	 * Renders the entire chart using the chart data. This is a very long method.
	 *
	 * @method renderChart
	 */
	renderChart: function() {
		// 1. get the data in a usable format
		// add an id property to each point, store number of columns
		var i       = 1,
				rand    = (Math.random() * 10000).toString().slice(-5),
				data    = _.map(this.data, function(d){ return _.extend(d, {id: i++ + rand}); }),
				numCols = data.length;

		// munge the data into a usable format
		//
		// Right now this is an array of arrays containing data point
		// objects grouped by label. There can be no missing values, and
		// every point needs to have a 'series' and 'label' (or equivalent)
		// property.
		//
		// If you want to have an empty column you'll need to just zero out
		// the relevant data properties

		// extract the series and labels
		var series = _.uniq(_.pluck(data, 'series')),
				labels = _.uniq(_.pluck(data, 'label')),
				mData  = [];

		// for each label push an array of points for that label
		_.each(labels, function(label){ mData.push(_.where(data, {label: label})); });





		// 2. set the appropriate graph type based on options and/or data
		if (!this.type) {
			if (numCols > 10 || numCols == 1) this.toHorizontal(false);
			else                              this.toVertical(false);
		}
		var isVertical = this.type == 'vertical' ? true : false,
				isHorizontal = this.type == 'horizontal' ? true : false;





		// 3. set up the graph drawing surface

		// set up and empty the containing element
		var $container = this.$('#svg-mount');
		$container.html('');

		// a) figure out the correct width to use
		//
		// The idea here is to prevent just a few columns from getting
		// super fat and looking ugly.
		var minimumWidth   = 100,
				minimumHeight  = 180,
				maxWidthPerCol = 80,
				minWidthPerCol = 50,
				targetHeight   = 300,
				width,
				height;

		// If not explicitly set and the graph is vertical, width is either the width of the container
		// or the width created by setting every column to the maxWidthPerCol. If vertical just go with
		// the container width.
		var maxAltWidth = (numCols * maxWidthPerCol) + minimumWidth;
		if (!this.width) {
			if (isVertical)   width = d3.min([$container.width(), maxAltWidth]);
			if (isHorizontal) width = $container.width();
		} else {
			width = this.width;
		}

		// b) figure out the correct height to use
		//
		// If the number of columns is too large to fit in the targetHeight and maintain the
		// minWidthPerCol then expand it. If the number of colums is too small to fill the targetHeight
		// without adhering to maxWidthPerCol then condense it. Otherwise use it.
		if (!this.height) {
			if (isVertical) height = targetHeight;
			if (isHorizontal) {
				// if there are too many columns to fit in the minimum height and maintain the
				// minWidthPerCol then expand the height to fit
				if      (targetHeight / numCols < minWidthPerCol) height = minWidthPerCol * numCols;
				// if there are too few columns to maintain the maxWidthPerCol
				else if (targetHeight / numCols > maxWidthPerCol) height = maxWidthPerCol * numCols;
				else                                              height = targetHeight;
			}
		} else {
			height = this.height;
		}

		if (height < minimumHeight) height = minimumHeight;

		// c) adjust margins based on graph type
		var margin = _.clone(this.margin);
		if (isHorizontal)      margin.left = width / 4;
		if (series.length > 1) margin.right += 90;

		var w = width - margin.left - margin.right,
				h = height - margin.top - margin.bottom;

		// d) create the svg and inner g to handling margins
		var svg = d3.select($container.get(0)).append('svg')
			.attr({
				width  : '100%',
				height : h + margin.top + margin.bottom
			})
		.append('g')
			.attr({ transform: 'translate(' + margin.left + ',' + margin.top + ')' });





		// 4. create scales
		var percentBetweenCols  = 0.3,
				percentOuterPadding = 0.2,
				x,
				x1,
				y,
				y1,
				min,
				max;

		// figure out the min, max values for the numeric axes
		if (!_.isNull(this.min)) min = this.min;
		else                     min = d3.min(data, function(d){ return d.low || 0; });

		if (!_.isNull(this.max)) max = this.max;
		else                     max = d3.max(data, function(d){ return d.high || d.value; });

		// 0 columns are actually displayed as a tiny sliver
		var minimumColHeight = 2;

		if (isVertical) {
			// x is for distributing column groups and labels
			x = d3.scale.ordinal().rangeRoundBands([0, w], percentBetweenCols, percentOuterPadding);
			x.domain(labels);

			// x1 is for distributing columns within a group
			x1 = d3.scale.ordinal();
			x1.domain(series);
			x1.rangeRoundBands([0, x.rangeBand()]);

			// y is just for the height
			y = d3.scale.linear().range([h - minimumColHeight, 0]);
			y.domain([min, max]);
		}

		if (isHorizontal) {
			// y is for distributing bar groups and labels
			y = d3.scale.ordinal().rangeRoundBands([h, 0], percentBetweenCols, percentOuterPadding);
			y.domain(labels);

			// y1 is for distributing bars within a group
			y1 = d3.scale.ordinal();
			y1.domain(series);
			y1.rangeRoundBands([0, y.rangeBand()]);

			// x is just for width of bars
			x = d3.scale.linear().range([minimumColHeight, w]);
			x.domain([min, max]);
		}

		// maps columns within groups to a color
		var color = d3.scale.ordinal().range(this.colors);





		// 5. define the axes
		var xAxis, yAxis;
		if (isVertical) {
			xAxis = d3.svg.axis()
				.scale(x)
				.orient('bottom')
				.outerTickSize(0);
			yAxis = d3.svg.axis()
				.scale(y)
				.orient('left')
				.tickSize(0, 0)
				.tickValues(this.tickVals)
				.tickFormat(this.format);
		}
		if (isHorizontal) {
			yAxis = d3.svg.axis()
				.scale(y)
				.orient('left')
				.outerTickSize(0);
			xAxis = d3.svg.axis()
				.scale(x)
				.orient('bottom')
				.tickSize(0, 0)
				.tickValues(this.tickVals)
				.tickFormat(this.format);
		}





		// 6. call the axes
		if (isVertical) {
			svg.append('g')
				.attr({transform: 'translate(0,' + h + ')'})
				.classed('label-axis', true)
				.call(xAxis)
			.selectAll('.label-axis text')
				.call(_.bind(this.wrap, this), x.rangeBand(), 40);
			svg.append('g')
				.classed('num-axis', true)
				.call(yAxis);
		}

		if (isHorizontal) {
			// padding between the axis and graph
			var yAxisPad = 10;
			svg.append('g')
				.attr({transform: 'translate(0,' + h + ')'})
				.classed('num-axis', true)
				.call(xAxis);
			svg.append('g')
				.attr({transform: 'translate(-' + yAxisPad + ',0)'})
				.classed('label-axis', true)
				.call(yAxis)
				.selectAll('.label-axis text')
					.call(_.bind(this.wrap, this), margin.left - yAxisPad, y.rangeBand());
		}





		// 7. create g elements and bind each label group to them
		var groups;
		if (isVertical) {
			groups = svg.selectAll('.bar-group')
				.data(mData)
			.enter().append('g')
				.attr({transform: function(d){ return 'translate(' + x(d[0].label) + ',0)'; }});
		}
		if (isHorizontal) {
			groups = svg.selectAll('.bar-group')
				.data(mData)
			.enter().append('g')
				.attr({transform: function(d){ return 'translate(0,' + y(d[0].label) + ')'; }});
		}





		// 8. create columns within each label group
		var thisFormat = this.format;
		var n = this.sampleSize;
                var negativeFlag = this.negativeFlag;

		if (isVertical) {
			groups.selectAll('rect')
				.data(function(d){ return d;})
			.enter().append('rect')
				.attr({
					x      : function(d){ return x1(d.series); },
					width  : x1.rangeBand(),
                		        y      : (function(){
                                                   if (negativeFlag){
                                                     var fun = function(d){
                                                                 if (d.value < 0) return (100 + (1/2 * (Math.abs(h - y(d.value) - 100))));
                                                                 else return y(d.value);
                                                               };
                                                   }
                                                   else var fun = function(d){return y(d.value);}
                                                   return fun;
                                        })(),
					height : (function(){
                                                   if (negativeFlag){
                                                     var fun = function(d){ return Math.abs(h - y(d.value) - 100); }
                                                   }
                                                   else var fun = function(d){ return h - y(d.value);}
                                                   return fun;
                                        })(),
					fill   : function(d){ return color(d.series); },
					id     : function(d){ return 'tool'+d.id; }
				})
				.each(function(d,i){
					theTitle = '<div>Value: ' + thisFormat(d.value) + '</div>' +
							'<div>Low: ' + thisFormat(d.low) + '</div>' +
							'<div>High: ' + thisFormat(d.high) + '</div>' +
							'<div>N: ' + d.n + '</div>';

					if(d.sd) theTitle += '<div>SD: ' + d.sd + '</div>';
					$(this).tooltip({
						title: theTitle,
						placement: 'top',
						container: 'body',
						html: true
					});
				});
		}
		if (isHorizontal) {
			groups.selectAll('rect.chart')
				.data(function(d) {
					return d;
				})
			.enter().append('rect')
				.classed('chart', true)
				.attr({
					x: negativeFlag ? 250 : 0,
					width: (function(){
                                                 if (negativeFlag){
                                                   var fun = function(d){ return d3.max([x(d.value) - 250, 0.001])}
                                                 }
                                                 else var fun = function(d){return d3.max([x(d.value), 0.001])}
                                                 return fun;
                                        })(),
					y: function(d){ return y1(d.series); },
					height: y1.rangeBand(),
					fill: function(d){ return color(d.series); },
					id: function(d, i){ return 'tool'+d.id; }
				})
				.each(function(d,i){
					theTitle = '<div>Value: ' + thisFormat(d.value) + '</div>' +
							'<div>Low: ' + thisFormat(d.low) + '</div>' +
							'<div>High: ' + thisFormat(d.high) + '</div>' +
							'<div>N: ' + d.n + '</div>';

					if(d.sd) theTitle += '<div>SD: ' + d.sd + '</div>';

					$(this).tooltip({
						title: theTitle,
						placement: 'top',
						container: 'body',
						html: true
					});
				});
		}





		// 9. draw error bars if they exist
		var hasErrorBars = this.showErrorBars &&
		                   data[0].hasOwnProperty('low') &&
		                   data[0].hasOwnProperty('high');

		if (hasErrorBars) {
			if (isVertical) {
				groups.selectAll('line')
					.data(function(d){ return d; })
				.enter().append('line')
					.attr({
						y1: function(d){ return y(d.high); },
						y2: function(d){ return y(d.low); },
						x1: function(d){ return x1(d.series) + x1.rangeBand()/2; },
						x2: function(d){ return x1(d.series) + x1.rangeBand()/2; },
						'stroke-width': this.err.w,
						stroke: this.err.color
					});
			}
			if (isHorizontal) {
				groups.selectAll('line')
					.data(function(d){ return d; })
				.enter().append('line')
					.attr({
						y1: function(d){ return y1(d.series) + y1.rangeBand()/2; },
						y2: function(d){ return y1(d.series) + y1.rangeBand()/2; },
						x1: function(d){ return x(d.low); },
						x2: function(d){ return x(d.high); },
						'stroke-width': this.err.w,
						stroke: this.err.color
					});
			}
		}





		// 10. draw invisible hover rects
		if (isVertical) {
			groups.selectAll('rect.hovers')
				.data(function(d){ return d; })
			.enter().append('rect')
				.classed('hovers', true)
				.attr({
					x: function(d){ return x1(d.series); },
					width: x1.rangeBand(),
					y: y.range()[1],
					height: y.range()[0],
					'fill-opacity': 0
				})
				.on('mouseover', function(d){ $('#tool'+d.id).tooltip('show'); })
				.on('mouseout', function(d){ $('#tool'+d.id).tooltip('hide'); });
		}

		if (isHorizontal) {
			groups.selectAll('rect.hovers')
				.data(function(d){ return d; })
			.enter().append('rect')
				.classed('hovers', true)
				.attr({
					x: 0,
					width: function(d){ return x.range()[1]; },
					y: function(d){ return y1(d.series); },
					height: function(d){ return y1.rangeBand(); },
					'fill-opacity': 0
				})
				.on('mouseover', function(d, i){ $('#tool'+d.id).tooltip('show'); })
				.on('mouseout', function(d, i){ $('#tool'+d.id).tooltip('hide'); });
		}




		// 11. draw legend
		if (series.length > 1) {
			svg.selectAll('.legend-swatches')
				.data(series)
			.enter().append('rect')
				.classed('legend-color', true)
				.attr({
					x: w + margin.right - 10,
					y: function(d, i){ return i * 20; },
					width: 10,
					height: 10,
					fill: function(d){ return color(d); }
				});

			svg.selectAll('.legend-text')
				.data(series)
			.enter().append('text').append('tspan')
				.text(function(d) {
					if (d.length > 16) {
						d = d.slice(0, 15) + '\u2026';
					}
					return d; })
				.attr({
					x: w + margin.right - 15,
					y: function(d, i){ return i * 20; },
					dy: '0.9em',
					'text-anchor': 'end',
					'font-size': '10px'
				})
				.append('title')
				.text(function(d){ return d; });
		}



		// 12. draw title
		if (this.title && this.titlesOn) {
			svg.selectAll('.title-text')
				.data([this.title])
			.enter().append('text')
				.text(function(d){ return d; })
				.classed('title-text', true);
		}

		// to prevent height flash when re-rendering
		$container.css('height', 'auto');
		$container.css('height', $container.height() + 'px');
	},

	/**
	 * Checks the length of a label and auto-wraps it.
	 *
	 * @method wrap
	 * @param  {D3 selection} text A selection of the text nodes
	 * @param  {int} width The label width in pixels
	 * @param  {int} height The label height in pixels
	 */
	wrap: function(text, width, height) {
		var isVertical = this.type == 'vertical' ? true : false;
		var isHorizontal = this.type == 'horizontal' ? true : false;

		text.each(function() {
			var $text = $(this);
			var string = $text.text();
			var fo = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
			var $fo = $(fo);
			var x, y, typeClass, t;
			if (isVertical) {
				x = width / 2 * -1;
				y = 5;
			}
			if (isHorizontal) {
				x = width * -1;
				y = height / 2 * -1;
			}
			$fo.attr({
				xmlns: 'http://www.w3.org/1999/xhtml',
				x: x,
				y: y,
				width: width,
				height: height
			});

			if (isVertical)   typeClass = 'g-wrapped-label-vertical';
			if (isHorizontal) typeClass = 'g-wrapped-label-horizontal';

			$('<p>' + string + '</p>')
				.addClass('g-wrapped-label ' + typeClass)
				.attr('title', string)
				.appendTo($fo);
			$text.replaceWith($fo);
			var $p = $fo.find('p');
			while ($p.height() >= height) {
				t = $p.text().slice(0, -4);
				t = t.trim();
				$p.text(t + '\u2026');
			}
			if (isHorizontal) {
				$fo.attr('y', $p.height() / 2 * -1);
			}
		});
	},
});
