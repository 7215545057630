/* global require, module */

var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
	urlRoot: '/api1/collection',

	defaults: {
		name: ''
	}
});