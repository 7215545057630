/* global require, module */

var Backbone = require('backbone'),
		SmartPossibleResponse = require('../models/SmartPossibleResponse.js');

/**
@class SmartPossibleResponses
@constructor
@extends Backbone.Collection
@module Collections
*/
module.exports = Backbone.Collection.extend({
	model: SmartPossibleResponse,
	url: function() {
		if (this.smartQID) {
			return '/api1/smart_possible_response/all_for_smart_question/' + this.smartQID;
		}

		if (this.projID) return '/api1/smart_possible_response/all_for_project/' + this.projID;
		if (this.projIDs) return '/api1/smart_possible_response/all_for_projects/' + this.projIDs;
	},

	initialize: function(models, opts) {
		this.smartQID = opts.smartQID || null;
		this.projID   = opts.projID   || null;
		this.projIDs   = opts.projIDs   || null;
	},

	parse: function(res, opts){ return res.data; }
});