/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),

		AlertView = require('./AlertView'),
		SourceQuestionItemView = require('./SourceQuestionItemView.js'),
		SourceQuestionView = require('./SourceQuestionView.js'),
		app = require('../app.js');

module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',

	tpl: $('#ProjectSourcesView-tpl').html(),

	initialize: function() {
		this.srcQItemVs = [];
		this.edView = null;
		this._navigatedViews = [];

		this.title = 'All Source Questions';

		this.collection.fetch({
			reset: true,
			success: _.bind(this.addAllSrcQs, this),
			error: function(col, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem getting source questions: ' + res.status,
					type: 'alert-danger'
				}));
			}
		});
	},

	render: function() {
		var view = {
			title : this.title,
			prevTitle : this.prevTitle
		};
		this.$el.append(Mustache.render(this.tpl, view, app.partials()));
		// $(window).one('scroll', this.startAffix);
		return this;
	},

	close: function() {
		_.each(this.srcQItemVs, function(i) {
			i.close();
		});

		if (this.edView) {
			this.edView.close();
		}

		this.stopListening();
		this.remove();
		this.trigger('close');
	},

	getTitle: function() {
		return this.title;
	},

	hide: function() {
		this.$el.hide();
	},

	show: function() {
		this.$el.show();
	},

	addAllSrcQs: function() {
		this.$('.loading').remove();

		var frag = document.createDocumentFragment();

		this.collection.each(function(sq) {
			var sqItemV = new SourceQuestionItemView({model: sq});
			this.srcQItemVs.push(sqItemV);
			this.listenTo(sqItemV, 'edit', this.choseCombo);
			this.listenTo(sqItemV, 'edit', this.editSrcQuestion);
			$(frag).append(sqItemV.render().el);
		}, this);

		$('#source-q-mount').append(frag);
	},

	editSrcQuestion: function(model) {
		this.trigger(
			'navigateinto',
			new SourceQuestionView({model: model, collection: this.collection})
		);
	},

	startAffix: function() {
		var $affix = this.$('.self-affix');
		var h = $affix.outerHeight(true);
		$affix.parent().height(113);
		$affix.affix({offset:{top: 50}});
	},

	closedSrcQuestion: function() {
		// unset reference to child view
		this.stopListening(this.edView);
		this.edView = null;
		_.each(this.srcQItemVs, function(item) {
			item.enable();
		});
		this.$('#source-q-list').show();
	},

	chooseCombo: function(currentQs) {
		_.each(this.srcQItemVs, function(item) {
			item.alreadyChosen(currentQs);
		});
		this.$('#source-q-list').show();
	},

	choseCombo: function(model) {
		if (this.edView) {
			this.edView.combineWith(model);
			this.$('#source-q-list').hide();
		}
	}

});
