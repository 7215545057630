/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		CalcMixin = require('../mixins/CalculatorMixin.js'),

		app = require('../app.js');

module.exports = Backbone.Model.extend(
	_.extend({}, CalcMixin, {
	urlRoot: '/api1/suprqwebsites',
	initialize: function(models, opts) {
	},
	defaults: function() {
		return {
			StudyType: null,
	        Industry: null,
	        Name: null,
	        SUPRQ: null,
	        Trust: null,
	        Loyalty: null,
	        Appearance: null,
	        NPS: null,
	        NPSPercent: null
		};
	},

	getters: {
	}
}));