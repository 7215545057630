/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Responses               = require('../collections/Responses.js'),
		PossibleResponses       = require('../collections/PossibleResponses.js'),
		Questions               = require('../collections/Questions.js'),
		ResponseItemView        = require('./ResponseItemView.js'),
		AlertView               = require('./AlertView.js'),
		app                     = require('../app.js');

/**
 * This view displays the question responses from a particular participant.
 *
 * @class ParticipantView
 * @extends Backbone.View
 * @module Views
 */
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	//default element name
	tagName: 'div',

	//main display template
	tpl: $('#ParticipantResponsesView-tpl').html(),

	events: {
		//standard nav behavior
		'click .back'             : 'navigateBack',

		//include or exclude user from study
		'click .toggle-exclusion' : 'toggleExclusion'
	},

	initialize: function(opts) {
		this.participantID     = opts.participantID;
		this.projID            = opts.projID;

		this.responses         = new Responses([], { participantID : this.participantID});
		this.responseItemViews = [];

		this.prs               = new PossibleResponses([], { projID : this.projID});

		this.qs                = new Questions([], {projID : this.projID});

		if(opts.comparator) this.responses.comparator = opts.comparator;

		this.repsProm = this.responses.fetch({participantID : this.participantID, reset: true});
		this.prsProm = this.prs.fetch({
			reset: true,
			error: _.bind(function(one, two, three) {
				console.log(one);
				console.log(two);
				console.log(three);
				console.log(two.responseText);
			}, this)
		});
		this.qsProm = this.qs.fetch({
			reset: true,
			error: _.bind(function(one, two, three) {
				console.log(one);
				console.log(two);
				console.log(three);
				console.log(two.responseText);
			}, this)
		});

		$.when(this.repsProm, this.prsProm, this.qsProm).then(_.bind(function(p) {
			this.addAllResponses();
		}, this));
	},

	/**
	 * Renders the main template area and passes itself back up the stack.
	 *
	 * @method render
	 * @return {Object} This object to be passed up the render stack
	 */
	render: function() {
		this.$el.html(Mustache.render(this.tpl, {}, app.partials()));
		return this;
	},

	/**
	 * Standard behavior
	 *
	 * @method navigateBack
	 * @param  {Event} e passed input event
	 */
	navigateBack: function(e) {
		if (e) { e.preventDefault(); }
		this.trigger('navigateback');
	},

	/**
	 * Standard behavior. Removes/destroys this view and triggers the "close" event.
	 *
	 * @method close
	 */
	close: function() {
		this.remove();
		this.trigger('close');
	},

	/**
	 * Iterates through all responses from this participant, and creates line items for each of them
	 * by making new ResponseItemViews.
	 *
	 * @method addAllResponses
	 */
	addAllResponses: function() {
		this.$('.loading').remove();

		var frag = document.createDocumentFragment();
		if (this.responses.length > 0) {
			this.responses.each(function(p) {
				if(+p.get('question_id') !== 0) {
					var respItemView = new ResponseItemView({model: p, prs : this.prs, qs : this.qs});
					this.responseItemViews.push(respItemView);
					$(frag).append(respItemView.render().el);
				}
			}, this);
		} else {
			$(frag).append(
				'<tr class="tr-no-cursor"><td colspan="3" align="center">No responses</td></tr>');
		}
		this.$('#part-item-mount').html(frag);
	},

	/**
	 * This method toggles the excluded state of the participant in question. Participants are
	 * included by default.
	 *
	 * If a user is excluded, the are not taken into account in any part of the data analysis. They
	 * essentially become deleted, although they can be restored (hence exclusion, not deletion).
	 *
	 * @method toggleExclusion
	 * @param  {Event} e [description]
	 * @return {[type]}   [description]
	 */
	toggleExclusion: function(e) {
		if (e) { e.preventDefault(); }

		var isExcluded = +this.model.get('excluded') === 1;

		if (isExcluded) {
			this.model.save('excluded', 0, {
				success: function(model, res, opts) {
					this.$('.excluded-state').html('Included');
					this.$('.toggle-exclusion').html('Exclude');
				}, //success
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					}));
				} //error
			});
		} else {
			this.model.save('excluded', 1, {
				success: function(model, res, opts) {
					this.$('.excluded-state').html('Excluded');
					this.$('.toggle-exclusion').html('Include');
				}, //success
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					}));
				} //error
			});
			this.navigateBack();
		}
	} //toggleExclusion
}));