/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		app = require('../app.js'),
		AddUserView               = require('./AddUserView.js'),
		EditUsersView               = require('./EditUsersView.js'),
		AlertView               = require('./AlertView.js');

module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',
	className: 'container',

	tpl: _.template( $('#AdminPageView-tpl').html() ),

	events: {
		'click .add-user'        : 'addUser',
		'click .edit-users'        : 'editUsers',
	},

	initialize: function(opts) {
		this._navigatedViews = [];
		this.$featureMount = null;

	},

	render: function() {
		document.title = 'My Account';
		this.$el.html(this.tpl);
		// mount the page
		app.$mount.html(this.el);

		$('.account-name').html('User Admin');
		this.addUser();
	},

	addUser: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.changeViewTo('add-user');
		this.startNavigationWith(new AddUserView());
		this.activeViewName = 'add-user';
	},

	editUsers: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.changeViewTo('edit-users');
		this.startNavigationWith(new EditUsersView());
		this.activeViewName = 'edit-users';
	},

	changeViewTo: function(toThis) {

		// switch the active item in the nav
		this.$('a.list-group-item.active').removeClass('active');
		$('.' + toThis).addClass('active');

		// close the current view
		//this.closeNavigationStack();

	},

	startNavigationWith: function(view) {
		this._navigatedViews.push({v:view, top:0});
		this.listenTo(view, 'navigateinto', this.navigateIntoView);
		console.log(view.render().el,this.featureMount);
		$('#adminMount').html(view.render().el);
	},

	close: function() {
		_.each(this.colViews, function(c) {
			c.close();
		});
		this.stopListening();
		this.remove();
	},

});