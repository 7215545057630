/* global require, module */

var ProportionCalculator = require('../models/ProportionCalculator.js'),
		MeanCalculator = require('../models/MeanCalculator.js'),
		MeanGeoCalculator = require('../models/MeanGeoCalculator.js'),

		TContCICalculator = require('../models/TContCICalculator.js'),
		TContGeoCICalculator = require('../models/TContGeoCICalculator.js'),
		AdjWaldCICalculator = require('../models/AdjWaldCICalculator.js');

/**
Standard implementation of methods for returning new Calculators and CICalculators based on the
calc_type and ci_calc_type keys of a model.

@class CalculatorMixin
@static
@module Mixins
*/
module.exports = {

	/**
	Return an instantiated calculator for the question. Defaults to MeanCalculator.
	@method getCalculator
	@return Calculator
	*/
	getCalculator: function() {
		switch (this.get('calc_type')) {
			case 'proportion' : return new ProportionCalculator({});
			case 'mean'       : return new MeanCalculator({});
			case 'meangeo'    : return new MeanGeoCalculator({});
			case null         : return null;
			default           : throw new Error('Unknown calculator type');
		}
	},

	/**
	Return an instantiated CICalculator for the question. Defaults to TContCICalculator.
	@method getCICalculator
	@return CICalculator
	*/
	getCICalculator: function() {
		switch (this.get('ci_calc_type')) {
			case 'tcont'    : return new TContCICalculator({});
			case 'tcontgeo' : return new TContGeoCICalculator({});
			case 'adjwald'  : return new AdjWaldCICalculator({});
			case null       : return null;
			default         : throw new Error('Unknown CICalculator type');
		}
	}
};