/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),
		Questions = require('../collections/Questions.js'),
		PossibleResponses = require('../collections/PossibleResponses.js'),
		NavCompMixin = require('../mixins/NavigationComponentMixin.js'),
		AlertView = require('./AlertView.js'),
		NewPossibleResponseView = require('./NewPossibleResponseView.js'),
		app = require('../app.js');

/**
@class CombineQuestionsView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavCompMixin, {
	tagName: 'div',
	tpl: $('#CombineQuestionsView-tpl').html(),

	events: {
		'click .back': 'navigateBack',
		'click .add-new-response': 'addNewResponse',
		'click .save-combination': 'save',

		'change input[name="combination-preset"]': 'changePreset'
	},

	/**
	Set up a isSelected property, the default selectedClass
	@method initialize
	*/
	initialize: function(opts) {
		/**
		@property title
		@type string
		@default 'Combine Questions'
		@final
		*/
		this.title = 'Combine Questions';

		/**
		@property prevTitle
		@type string
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		@property projID
		@type number
		*/
		this.projID = opts.projID;

		/**
		A reference to the complete questions collection that underlies the questions list.
		@property allQs
		@type Questions
		*/
		this.allQs = opts.allQs;

		/**
		The collection of questions to combine
		@property qs
		@type Questions
		*/
		this.qs = new Questions(opts.qs, {});

		/**
		The project's possible responses, used to identify original possible_responses
		@property prs
		@type PossibleResponses
		*/
		this.prs = new PossibleResponses([], {projID: this.projID});

		/**
		References to the new response views
		@property newResponseViews
		@type array<NewPossibleResponseView>
		*/
		this.newResponseViews = [
			new NewPossibleResponseView({qs: this.qs, prs: this.prs, first: true})];

		/**
		List of DOM nodes that are currently disabled
		@property _disabledItems
		@type array [jQuery selector strings]
		@private
		*/
		this._disabledItems = [];

		this.prs.fetch({reset: true, error: function() {
			app.ge.trigger('alert', new AlertView({
				m: 'Problem fetching possible responses',
				type: 'alert-danger'
			}));
		}});
		this.validateCombination();
	},

	render: function() {
		var firstQ = this.qs.first();
		var view = {
			title: this.title,
			prevTitle: this.prevTitle,
			name: firstQ.get('name'),
			prompt: firstQ.get('prompt'),
			q_types: _.map(app.qTypeHash, function(value, key) {
				return {
					type_id: key,
					type: value,
					is_selected: key == firstQ.get('type_id')
				};
			}),
			sample_size_methods: _.map(app.sampleMethodHash, function(value, key){
				return {
					is_selected: value.key == 'num_presentations',
					method_id: key,
					method_name: value.name
				};
			}),
			panel_controls: [
				{loner: true, label: 'Cancel', class_name: 'back', btn_type: 'link'},
				{loner: true, label: 'Combine', class_name: 'save-combination', btn_type: 'primary'}
			],
			combining_questions: {
				title: 'Questions to combine',
				questions: this.qs.map(function(q){ return q.get('name'); }),
				panel_controls: [{}]
			}
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.$(this._disabledItems.join(', ')).attr('disabled', true);
		var frag = app.renderViewsToFrag(this.newResponseViews);
		this.$('.new-responses').html(frag);
		return this;
	},

	/**
	Disables the 'Combine' button if present and adds to the list of disabled things
	@method disableSave
	*/
	disableSave: function() {
		this.$('.save-combination').attr('disabled', true);
		this._disabledItems.push('.save-combination');
	},

	/**
	Initial smokescreen to ensure the selected questions can be combined. Right now it only checks to
	make sure that all the questions are the same type. The server will do the final checks.
	@method validateCombination
	*/
	validateCombination: function() {
		var qTypes = this.qs.map(function(item){ return item.get('type_id'); });
		if (_.unique(qTypes).length > 1) {
			this.disableSave();
			app.ge.trigger('alert', new AlertView({
				m: 'Questions must be of the same type to combine correctly',
				type: 'alert-warning'
			}));
		}
	},

	/**
	Creates a new NewPossibleResponseView, sticks it in the array and renders it into the end of the
	list
	@method addNewResponse
	*/
	addNewResponse: function() {
		var newView = new NewPossibleResponseView({qs: this.qs, prs: this.prs});
		this.listenTo(newView, 'remove', this.removeView);
		this.newResponseViews.push(newView);
		this.$('.new-responses').append(newView.render().el);
	},

	/**
	Removes the passed NewPossibleResponseView from the page
	@removeView
	@param {NewPossibleResponseView} view
	*/
	removeView: function(view) {
		this.stopListening(view);
		this.newResponseViews = _.without(this.newResponseViews, view);
		view.close();
	},

	/**
	Adds/removes NewPossibleResponseViews and OriginalPossibleResponseSelectors and fills in
	responses/selected original prs as necessary in order to automate what is typically a repetitive
	and error prone process.

	There are two presets currently:

	1. Multi-select

		This will handles cases where the questions represent the various options in a multi-select
		question. The result: one NewPossibleResponse per question you're combining, each with the
		respective question selected and the OriginalPossibleResponseSelector set to 'yes' or  the
		equivalent.

	2. Same logical questions

		In some cases the same logical question was asked in several points in the study due to
		randomization/routing/logic, etc. Here we'll expect each original question to have identical
		possible responses and will create one NewPossibleResponse for each original possible_response
		with every question/possible_response pair selected under it.

	@method changePreset
	@param {event} [e] The intitiating event
	*/
	changePreset: function(e) {
		if (e) e.preventDefault();

		var newPreset = this.$('input[name="combination-preset"]:checked').val(),
				expectedNumber = 1;

		switch (newPreset) {
			case 'multiselect':
				expectedNumber = this.qs.length;
				break;

			case 'samelogical':
				var qID = this.qs.at(0).get('id');
				var relatedPrs = this.prs.filter(function(pr) {
					return pr.get('question_id') == qID && pr.get_should_include();
				});
				expectedNumber = relatedPrs.length;
				break;
		}

		// Reduce number of newResponseViews if there are too many
		while (this.newResponseViews.length > expectedNumber) {
			this.removeView(_.last(this.newResponseViews));
		}

		// recylce existing/remaining
		this.newResponseViews.forEach(function(nprview, index) {
			nprview.activatePreset(newPreset, index);
		});

		// add additional until we have enough for the task
		while (this.newResponseViews.length < expectedNumber) {
			this.addNewResponse();
			_.last(this.newResponseViews).activatePreset(newPreset, this.newResponseViews.length-1);
		}
	},

	/**
	Read all the currently selected information into a JSON object representing a valid question model
	@method serializeQuestion
	@return object
	*/
	serializeQuestion: function() {
		return {
			name               : this.$('input[name="question-name"]').val(),
			prompt             : this.$('textarea[name="question-prompt"]').val(),
			type_id            : this.$('select[name="question-type_id"]').val(),
			source             : 'gt',
			source_id          : null,
			native_id          : null,
			project_id         : this.projID,
			sample_size_method : this.$('input[name="question-sample_size_method"]:checked').val(),
			is_hidden          : false
		};
	},

	/**
	POSTs to the server to combine the questions.
	@method save
	*/
	save: function() {
		var question = this.serializeQuestion();
		var possibleResponses = this.newResponseViews.map(function(v) {
			return v.serializePossibleResponse();
		});

		$.ajax({
			url: '/api1/question/combine',
			type: 'POST',
			contentType: 'application/json',
			data: JSON.stringify({question: question, possible_responses: possibleResponses}),
			success: function(res) {
				question.id                = res.data.question.id;
				question.num_responses     = res.data.question.num_responses;
				question.num_participants  = res.data.question.num_participants;
				question.num_presentations = res.data.question.num_presentations;
				this.qs.each(function(model){ model.save('is_hidden', true)});
				this.allQs.add(question);
				this.navigateBack();
			}.bind(this),
			error: function() {
				app.ge.trigger('alert', new AlertView({
					m: 'There was a problem combining the selected questions. The combination you have ' +
						 'selected may not be possible.',
					type: 'alert-danger'
				}));
			}
		});
	}
}));