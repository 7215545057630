/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		typeahead = require('typeahead'),

		NavCompMixin = require('../mixins/NavigationComponentMixin.js'),
		Questions    = require('../collections/Questions.js'),
		AlertView    = require('./AlertView.js'),
		Analytic     = require('../models/Analytic.js'),
		app          = require('../app.js');

/**
@class CreateSUPRQLiteView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavCompMixin, {
	/**
	 * The standard page 'el'
	 * @property tagName
	 * @type {String}
	 * @default 'div'
	 */
	tagName: 'div',

	/**
	 * This class uses the generic "basic analysis creation view" because it only
	 * requires that the user select a few questions from a list. Generated from HTML template.
	 *
	 * @property tpl
	 * @type {String}
	 */
	tpl       : $('#CreateSUPRQAnalyticView-tpl').html(),


	/**
	 * Special template for options in the question list.
	 *
	 * @property optionTpl
	 * @type {String}
	 */
	optionTpl : $('#CreateSUPRQAnalyticView-option-tpl').html(),

	/**
	 * A map of events that this view subscribes to and the methods called when those events occur.
	 *
	 * @property events
	 * @type {Collection}
	 */
	events: {
		'click .createButton' : 'create'
	}, //events

	/**
	 *
	 */
	qNames: [
		'This website is easy to use',
		 'It is easy to navigate within the website.',
		 'The website keeps the promises it makes on its website.',
		 'I feel confident conducting business with this website.',
		 'How likely are you to recommend this website to a friend or colleague?',
		 'I will likely visit this website in the future.',
		 'I find the website to be attractive.',
		 'The website has a clean and simple presentation.'
	],

	/**
	 * Standard behavior
	 *
	 * @method initialize
	 * @param  {Object} opts input options
	 */
	initialize: function(opts) {
		this.projID = opts.projID;

		/**
		@property as
		@type Analytics
		*/
		this.as = opts.as;

		this.qs     = new Questions([], {projID : this.projID});

		this.qsProm = this.qs.fetch({
			reset: true,
			error: function(one, two, three) {
				app.ge.trigger('alert', new AlertView({
					m: 'Error loading questions:' + two.responseText,
					type: 'alert-danger'
				}));
			}
		});

		$.when(this.qsProm).then(_.bind(function() {
			this.addAllQs();
		}, this));
	}, //initialize

	/**
	 * Standard behavior. Defines the list of questions using the template parameters and loads the
	 * list of questions into the select boxes
	 *
	 * @method render
	 * @return {Object} this rendered element for passing back up the renderstack.
	 */
	render: function() {
		var view = this.createView();

		//main template
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		//return back up the render stack
		return this;

	}, //render

	/**
	 * Adds rating-type questions to every drop-down (option) box so that questions can be selected
	 * for supr-q creation.
	 *
	 * @method addAllQs
	 */
	addAllQs: function() {
		var view = this.createView();

		//get the list of rating-type questions
		var allowed    = [2];
		var allQuestionsInfo = {
			options: this.qs.chain()
				.filter(function(q) {
					return _.contains(allowed, +q.get('type_id'));
				})
				.map(function(q) {
					return {
						id      : q.get('id'),
						type    : 'question',
						name    : q.get('name'),
						type_id : q.get('type_id')
					};
				})
				.value()
		};

		var questionNames = this.qs.chain()
				.filter(function(q) {
					return _.contains(allowed, +q.get('type_id'));
				})
				.map(function(q) {
					return q.get('name') + ' <span class="typeahead-id">ID:' + q.get('id') + '</span>';
				})
				.value();

		//fill each option box with the list of questions
		for(var awesome in view.questions) {
			this.initializeTypeAhead(view.questions[awesome].id, questionNames);
			this.$('[name="' + view.questions[awesome].id + '"]').html(
				Mustache.render(this.optionTpl, allQuestionsInfo, app.partials())
			);
		}
	},

	/**
	 * Standard behavior
	 *
	 * @method close
	 */
	close: function() { this.remove(); }, //close

	/**
	 * Finalize the creation of the SUPR-Q Lite.
	 *
	 * @method create
	 * @param  {Event} e The input event that triggered this method, if one exists.
	 */
	create: function(e) {
		var name = this.$('input[name="name"]').val();

		if(name.length < 3) {
			app.ge.trigger('alert', new AlertView({
				m: 'SUPR-Q name is too short. Please enter a longer name.',
				type: 'alert-danger'
			}));
			return;
		}

		else if(name.length > 140) {
			app.ge.trigger('alert', new AlertView({
				m: 'SUPR-Q name is too long. Please enter a shorter name.',
				type: 'alert-danger'
			}));
			return;
		}

		var options = {
			name: name,
			type: 'suprqlite',
			project_id: this.projID,
			q1_id: this.$('select[name="q1"]').children('option:selected').data('id'),
			q2_id: this.$('select[name="q2"]').children('option:selected').data('id'),
			q3_id: this.$('select[name="q3"]').children('option:selected').data('id'),
			q4_id: this.$('select[name="q4"]').children('option:selected').data('id'),
			q5_id: this.$('select[name="q5"]').children('option:selected').data('id'),
			q6_id: this.$('select[name="q6"]').children('option:selected').data('id'),
			q7_id: this.$('select[name="q7"]').children('option:selected').data('id'),
			q8_id: this.$('select[name="q8"]').children('option:selected').data('id'),
		};

		if(
			options.q1_id === '' ||
			options.q2_id === '' ||
			options.q3_id === '' ||
			options.q4_id === '' ||
			options.q5_id === '' ||
			options.q6_id === '' ||
			options.q7_id === '' ||
			options.q8_id === '') {
			app.ge.trigger('alert', new AlertView({
				m: 'Not all questions have been selected.',
				type: 'alert-danger'
			}));
			return;
		}

		var model = new Analytic(options);
		model.save(options, {
			success: _.bind(function(model) {
				this.trigger('suprqlite:saved', model);
				this.as.add(model);
				this.trigger('navigateback');
			}, this),
			error: _.bind(function(one, two, three) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem saving SUPR-Q Lite to db:' + two.responseText,
					type: 'alert-danger'
				}));
			})
		});
	}, //create

	createView: function(){
		var view = {
			title     : this.title,
			prevTitle : this.prevTitle
		}; //view

		view.questions = [];

		//add quetsions
		for(i = 0; i < this.qNames.length; i++) {
			questionId = i + 1;
			view.questions.push({name: 'Q' + questionId + ' : ' + this.qNames[i],
								id: 'q' + questionId,
								idRaw: questionId});
		}
		return view;
	},
	initializeTypeAhead : function(id, allQNames){

		$('.'+id).typeahead({
		  hint: true,
		  highlight: true,
		  minLength: 1
		},
		{
		  name: 'questions',
		  displayKey: 'value',
		  source: this.substringMatcher(allQNames)
		}).bind('typeahead:selected', function(obj,datum,name){
			console.log(datum.value);
			var selectValue = datum.value.substring(datum.value.lastIndexOf('ID:'),datum.value.length)
								.replace('ID:','')
								.replace('</span>','');
			var qID = $(this).attr('data-id');
			$('[name="' + qID + '"] [data-id="' + selectValue + '"]').attr('selected','selected');
		});

	},
	substringMatcher : function(strs) {
		return function findMatches(q, cb) {
			var matches, substrRegex;

			// an array that will be populated with substring matches
			matches = [];

			// regex used to determine if a string contains the substring `q`
			substrRegex = new RegExp(q, 'i');

			// iterate through the pool of strings and for any string that
			// contains the substring `q`, add it to the `matches` array
			$.each(strs, function(i, str) {
			if (substrRegex.test(str)) {
			// the typeahead jQuery plugin expects suggestions to a
			// JavaScript object, refer to typeahead docs for more info
			matches.push({ value: str });
			}
			});

			cb(matches);
		}

	},

})); //app.CreateSUPRQLiteView