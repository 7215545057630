/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		d3 = require('d3'),
		st = require('../st.js'),

		SSUtils  = require('./SeriesSetUtilities.js'),
		CICalculator = require('./CICalculator.js');

/**
@class AdjWaldCICalculator
@constructor
@extends CICalculator
@module Models
*/
module.exports = CICalculator.extend({
	defaults: function() {
		return _.extend(CICalculator.prototype.defaults(), {type: 'adjwald', name: 'Adjusted Wald'});
	},


	/**
	Compute the confidence interval for a DataPoint
	@method computeCI
	@param {DataPoint} dp
	@param {Number} alpha The confidence level to use
	@return {Array<Number>} [Low value, high value]
	*/
	computeCI: function(dp, alpha) {
		var raw  = SSUtils.getRs(dp),
				n    = SSUtils.getPresentations(dp);
		return st.ci.adjWald(raw.length, n, alpha);
	}
});