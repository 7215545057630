/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		app = require('../app.js'),

		Project         = require('../models/Project.js'),
		SourceQuestions = require('../collections/SourceQuestions'),

		AlertView               = require('./AlertView.js'),
		ProjectSummaryView      = require('./ProjectSummaryView.js'),
		ProjectParticipantsView = require('./ProjectParticipantsView.js'),
		ProjectQuestionsView    = require('./ProjectQuestionsView.js'),
		ProjectAnalyticsView    = require('./ProjectAnalyticsView.js'),
		ProjectSourcesView      = require('./ProjectSourcesView.js'),
		DownloadView            = require('./DownloadView.js'),
		Participants            = require('../collections/Participants.js');

module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',
	className: 'container',

	tpl: _.template( $('#ProjectPageView-tpl').html() ),

	events: {
		'click .summary'        : 'summary',
		'click .questions'      : 'questions',
		'click .participants'   : 'participants',
		'click .analysis'       : 'analysis',
		'click .reports'        : 'reports',
		'click .sources'        : 'sources',

		'click .excel-download' : 'downloadExcel',
		'click .clear-download' : 'clearDownload'
	},

	initialize: function(opts) {
		this.model = new Project({id: opts.id});

		this.baseURL = 'project/' + opts.id;
		this.activeViewName = opts.activate || null;
		this._navigatedViews = [];
		this.$featureMount = null;
		this.downloadView = null;

		this.model.fetch({
			success: _.bind(this.projectLoaded, this),
			error: function(model, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem loading the project model ' + res.status,
					type: 'alert-danger'
				}));
			}
		});

		this.listenTo(app.ge, 'alert', this.showAlert);
		this.listenTo(app.ge, 'addtoexcel', this.addToExcel);
	},

	render: function() {
		this.$el.html(this.tpl({}));
		app.$mount.html(this.el);
		this.$featureMount = this.$('#feature-mount');

		this.$('[data-spy="affix"]').affix({offset: {top: 50}});

		return this;
	},

	close: function() {
		this.closeNavigationStack();
		this.stopListening();
		this.remove();
	},

	showAlert: function(alertView) {
		this.$el.prepend(alertView.render().el);
	},

	projectLoaded: function() {
		document.title = 'Projects \u2192 ' + this.model.get('name');
		this.$('.project-name').html(this.model.get('name'));

		var activateView = this.activeViewName;
		this.activeViewName = null;
		switch (activateView) {
			case 'sources':
				this.sources();
				break;
			case 'questions':
				this.questions();
				break;
			case 'participants':
				this.participants();
				break;
			case 'analysis':
				this.analysis();
				break;
			default:
				this.summary();
				break;
		}
	},

	changeViewTo: function(toThis) {
		// set the URL
		if (toThis != 'summary') {
			app.routes.navigate(this.baseURL + '/' + toThis);
		} else {
			app.routes.navigate(this.baseURL);
		}

		// switch the active item in the nav
		this.$('a.list-group-item.active').removeClass('active');
		$('.' + toThis).addClass('active');

		// close the current view
		this.closeNavigationStack();
	},

	summary: function(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.activeViewName != 'summary') {
			this.changeViewTo('summary');
			this.startNavigationWith(new ProjectSummaryView({proj: this.model}));
			this.activeViewName = 'summary';
		} else {
			this.navigateToFront();
		}
	},

	questions: function(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.activeViewName != 'questions') {
			this.changeViewTo('questions');
			this.startNavigationWith(new ProjectQuestionsView({project: this.model}));
			this.activeViewName = 'questions';
		} else {
			this.navigateToFront();
		}
	},

	participants: function(e) {
		if (e) { e.preventDefault(); }

		if (this.activeViewName != 'participants') {
			this.changeViewTo('participants');

			this.startNavigationWith(new ProjectParticipantsView({
				parts       : new Participants([], {projID: this.model.get('id')}),
				projectName : this.model.get('name'),
				projectID   : this.model.get('id')
			}));
			this.activeViewName = 'participants';
		} else {
			this.navigateToFront();
		}
	},

	reports: function(e) {
		e.preventDefault();
	},

	analysis: function(e) {
		if (e) { e.preventDefault(); }

		if (this.activeViewName != 'analysis') {
			this.changeViewTo('analysis');

			this.startNavigationWith(new ProjectAnalyticsView({project: this.model}));
			this.activeViewName = 'analysis';
		} else {
			this.navigateToFront();
		}
	},

	sources: function(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.activeViewName != 'sources') {
			this.changeViewTo('sources');

			this.startNavigationWith(new ProjectSourcesView({
				collection: new SourceQuestions([], {projectID: this.model.get('id')})
			}));
			this.activeViewName = 'sources';
		} else {
			this.navigateToFront();
		}
	},

	startNavigationWith: function(view) {
		this._navigatedViews.push({v:view, top:0});
		this.listenTo(view, 'navigateinto', this.navigateIntoView);
		this.$featureMount.append(view.render().el);
	},

	navigateIntoView: function(view) {
		var curr = _.last(this._navigatedViews);
		curr.top = $(window).scrollTop();
		curr.v.hide();

		view.setPrevTitle(curr.v.getTitle());
		this.listenTo(view, 'navigateback', this.navigateBack);
		this.listenTo(view, 'navigatetofront', this.navigateToFront);
		this.listenTo(view, 'navigateinto', this.navigateIntoView);
		this._navigatedViews.push({v:view, top:0});

		this.$featureMount.append(view.render().el);
		if (view.hasOwnProperty('inserted')) {
			view.inserted.resolve();
		}
	},

	navigateBack: function() {
		var curr = this._navigatedViews.pop();
		this.stopListening(curr.v);
		curr.v.close();

		var last = _.last(this._navigatedViews);
		last.v.show();
		$(window).scrollTop(last.top);
	},

	navigateToFront: function() {
		while (this._navigatedViews.length > 1) {
			this.navigateBack();
		}
	},

	closeNavigationStack: function() {
		this.navigateToFront();
		var first = this._navigatedViews.pop();
		if (first) {
			this.stopListening(first.v);
			first.v.close();
			this._navigatedViews = [];
		}
	},





	// Need to document the remaining methods
	addToExcel: function(sheet) {
		if (!this.downloadView) {
			this.downloadView = new DownloadView({sheet: sheet});
			this.listenTo(app.ge, 'clearexcel', this.clearDownload);
			this.$('.excel-file-mount').append(this.downloadView.render().el);
		} else {
			this.downloadView.addItem(sheet);
		}
	},

	downloadExcel: function() {
		var graphs = _.map(this.excelGraphs, function(g) {
			var result = {};
			result.name = g.name;

			var series = _.uniq(_.pluck(g.data, 'series'));
			result.data = _.map(series, function(s) {
				var points = _.where(g.data, {series: s});
				return {
					series_name: s,
					series_values: _.pluck(points, 'value'),
					series_labels: _.pluck(points, 'label'),
					series_low: _.map(points, function(p) {
						if (p.hasOwnProperty('low')) {
							return p.value - p.low;
						} else {
							return 0;
						}
					}),
					series_high: _.map(points, function(p) {
						if (p.hasOwnProperty('high')) {
							return p.high - p.value;
						} else {
							return 0;
						}
					}),
				};
			});
			return result;
		});

		$.ajax({
			url: '/api/create_xlsx_for_graphs',
			type: 'POST',
			contentType: 'application/json',
			data: JSON.stringify(graphs),
			error: function(xhr, status, err) {
				console.log(xhr);
			},
			success: function(res, status, xhr) {
				console.log(res);
				window.location.href = '/download/' + res.filename;
			}
		});
	},

	clearDownload: function() {
		this.downloadView = null;
	}

});