/* global require, module */

var Backbone = require('backbone'),
		CategoricalSmartQuestion = require('../models/CategoricalSmartQuestion.js'),
		SmartQuestion = require('../models/SmartQuestion.js'),
		RatingSmartQuestion = require('../models/RatingSmartQuestion.js'),
		TimeSmartQuestion = require('../models/TimeSmartQuestion.js');

module.exports = Backbone.Collection.extend({
	model: function(attrs, opts) {
		switch (+attrs.type_id) {
			case 1: return new CategoricalSmartQuestion(attrs, opts);
			case 2: return new RatingSmartQuestion(attrs, opts);
			case 3: return new TimeSmartQuestion(attrs, opts);
			default: throw new Error('Unknown smart question type');
		}
	},

	url: function() {
		return '/api1/smart_question/all_for_project/' + this.projID;
	},

	initialize: function(models, options) {
		this.projID = options.projID;
	},

	parse: function(res, opts) {
		return res.data;
	}
});