/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		GraphTheme = require('../models/GraphTheme.js'),
		AlertView = require('./AlertView.js'),
		app = require('../app.js');

		require('bootstrap-colorpicker');

/**
@class ProjectSummaryView
@constructor
@exents Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#CreateThemeView-tpl').html(),

	events: {
		'click .back': 'navigateBack',
		'change input[name="data_labels"]': 'changeDataLabels',
		'click .save': 'saveTheme'
	},

	initialize: function(opts) {
		/**
		@property title
		@type String
		@default 'New Graph Theme'
		*/
		this.title = opts.model ? 'Edit Graph Theme' : 'New Graph Theme';

		/**
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';


		/**
		@property project
		@type Project
		*/
		this.proj = opts.proj;

		/**
		Reference to the parent view's GraphThemes collection so that we can add things to it.
		@property gthemes
		@type GraphThemes
		*/
		this.gthemes = opts.gthemes;

		/**
		@property model
		@type GraphTheme
		@default A default GraphTheme
		*/
		this.model = opts.model || new GraphTheme({});
	},

	render: function() {
		var view = this.model.toTpl();
		view.title = this.title;
		view.prevTitle = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				class_name : 'save',
				btn_type   : 'primary',
				label      : 'Save'
			}
		];
		view.isLabelsNone       = view.data_labels.type == 'none';
		view.isLabelsValues     = view.data_labels.type == 'values';
		view.isLabelsCategories = view.data_labels.type == 'categories';
		view.isLabelsSeriesName = view.data_labels.type == 'series_name';

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.$('.ColorCellGroup__cell').colorpicker();
		return this;
	},

	/**
	Handles changed to the data labels segmented control, enabling and disabled the data label
	position select as needed.
	@method changeDataLabels
	@param e Event
	*/
	changeDataLabels: function(e){
		var disablePosition = $(e.target).val() == 'none';
		this.$('select[name="data_labels_position"]').prop('disabled', disablePosition);
	},

	/**
	Sets all the properties of the model and saves it to the database
	@method saveTheme
	*/
	saveTheme: function(){
		// get the name
		var name = this.$('input[name="name"]').val();
		this.model.set('name', name);

		// collection_id
		var collectionOnly = this.$('input[name="collection_id"]:checked').val() == 'true';
		this.model.set('collection_id', collectionOnly ? +this.proj.get('collection_id') : null);

		// team_id
		this.model.set('team_id', this.proj.get('team_id'));

		// get the colors
		var colors = [];
		this.$('input[name="colors"]').each(function(){ colors.push($(this).val()); });
		this.model.set('colors', colors);

		// error_bar_end_style
		var endStyle = parseInt(this.$('input[name="error_bar_end_style"]:checked').val(), 10);
		this.model.set('error_bar_end_style', endStyle);

		// data_labels_type
		var type = this.$('input[name="data_labels"]:checked').val();
		var position = this.$('select[name="data_labels_position"]').val();
		this.model.set('data_labels', {type: type, position: position});

		// legend
		var legend = this.$('select[name="legend"]').val();
		this.model.set('legend', legend);

		// chart_border
		var chartBorder = this.$('input[name="chart_border"]:checked').val() == 'true';
		this.model.set('chart_border', chartBorder);

		// plot_border
		var plotBorder = this.$('input[name="plot_border"]:checked').val() == 'true';
		this.model.set('plot_border', plotBorder);

		// font
		var font = this.$('select[name="font"]').val();
		this.model.set('font', font);

		// major_gridlines
		var majorGridlines = this.$('input[name="major_gridlines"]:checked').val() == 'true';
		this.model.set('major_gridlines', majorGridlines);

		this.model.save(null, {
			wait: true,
			success: _.bind(function(mod, res){
				if (res.data && res.data.id) this.model.set('id', res.data.id);
				this.gthemes.add(this.model);
				this.trigger('navigateback');
			}, this),
			error: function() {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem saving theme',
					type: 'alert-danger'
				}));
			}
		});
	}
}));