/* global require, module */

var _ = require('underscore'),
		$ = require('jquery'),

		SmartQuestion = require('./SmartQuestion.js'),
		SeriesSet = require('./SeriesSet.js'),
		app = require('../app.js');

/**
@class TimeSmartQuestion
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = SmartQuestion.extend({
	urlRoot: '/api1/smart_question',

	getters: _.extend(SmartQuestion.prototype.getters, {
		// only for categorical
		is_pr_editable: function(){ return false; }
	}),

	/**
	@method toSeriesSet
	@param {object} args Requires parts, prs, rs, smartPrs, and filters
	@return SeriesSet Promise
	*/
	toSeriesSet: function(args) {
		var qName = this.get('name'),
				qID   = +this.get('id'),

				smartPrs = args.smartPrs.where({smart_question_id: qID.toString()}),
				smartPr  = smartPrs[0],
				targetID = +smartPr.get('target_question_id'),
				prs      = args.prs,
				parts    = args.parts,
				rs       = args.rs,
				filters  = args.filters,

				// maps sprID => object of matching partIDs
				matchingParts = {},
				dfd    = $.Deferred();

		if (smartPrs.length !== 1) throw new Error('Expected exactly one smart_possible_response');

		filters
			.get(smartPr.get('filter_id'))
			.applyFilter(parts.toTpl(), prs.toTpl(), rs.toTpl())
			.then(function(matchingParts) {
				// store the matching participants for each smart_possible_response
				matchingParts = matchingParts.reduce(function(memo, part) {
					if (part.id in memo) throw new Error('Expected unique participants');
					memo[part.id] = true;
					return memo;
				}, {});

				var rsToTarget      = rs.where({question_id: targetID.toString()}),
						prsToTarget     = prs.where({question_id: targetID.toString()}),
						nonResponsePrID = _.find(prsToTarget, function(pr){ return !+pr.get('is_response'); })
																.get('id'),
						emptyPrID       = _.find(prsToTarget, function(pr){ return !!+pr.get('is_empty'); })
																.get('id');

				var excludedParts = parts.reduce(function(memo, part) {
					if (part.get('id') in memo) throw new Error('Expected unique participants');
					if (!!+part.get('excluded')) memo[part.get('id')] = true;
					return memo;
				}, {});

				var responses = rsToTarget.map(function(r) {
					var partID = +r.get('participant_id'),
							prID = +r.get('possible_response_id'),
							hadResponse = nonResponsePrID != prID,
							hadEmptyResponse = emptyPrID == prID,
							matchedFilter = partID in matchingParts,
							response = +r.get('value');

					if (!_.isFinite(response) && hadResponse && !hadEmptyResponse) {
						throw new Error('Non numeric response');
					}

					return {
						part_id     : partID,
						is_excluded : partID in excludedParts,
						is_empty    : matchedFilter ? hadEmptyResponse : false,
						is_response : matchedFilter ? hadResponse : false,
						response    : matchedFilter && hadResponse && !hadEmptyResponse ? response : null
					};
				});

				var dataPoint = {
					label       : qName,
					source_type : 'smart_possible_response',
					source_id   : +smartPr.get('id'),
					parent_id   : qID,
					type        : 'time',
					min         : 0,
					max         : null,
					seq         : null,
					responses   : responses
				};

				var dataSeries = {
					name : qName,
					seq  : null,
					type : 'categorical',
					data : [dataPoint]
				};

				dfd.resolve(new SeriesSet({
					series : [dataSeries],
					name   : qName,
					type   : 'time'
				}));
			});

		return dfd;
	},

	generatePrsList: function(smartPrs) {
		return smartPrs.map(function(pr) {
			if (+pr.get('is_empty') === 1) return {id: pr.get('id'), response:'Empty'};
			if (+pr.get('is_response') === 0) return {id: pr.get('id'), response:'No response'};
			if (+pr.get('has_value') === 1) return {id: pr.get('id'), response:'Time (in seconds)'};
			return {id: pr.get('id'), response:pr.get('response')};
		});
	}
});