/* global require, module */

var ListView = require('./ListView.js'),
		_ = require('underscore'),
		$ = require('jquery'),

		Questions                = require('../collections/Questions.js'),
		QuestionItemView         = require('./QuestionItemView.js'),
		QuestionsHeaderView      = require('./QuestionsHeaderView.js'),
		CombineQuestionsView     = require('./CombineQuestionsView.js'),
		ExpandPipedQuestionsView = require('./ExpandPipedQuestionsView.js'),
		AlertView                = require('./AlertView.js'),
		app                      = require('../app.js');

/**
Subclass implementation of ListView for displaying a Questions collection. Overrides the className
property to add some features to the table.
@class QuestionsListView
@constructor
@extends ListView
@module Views
*/
module.exports = ListView.extend({

	/**
	Overriden to add hover and cursor features
	@property className
	@type string
	@default super + ' table-hover table-cursor
	*/
	className: function(){ return ListView.prototype.className + ' table-hover table-cursor'; },

	events: function(){ return _.extend(ListView.prototype.events(), {
		'click .combine-questions': 'startCombiningQuestions',
		'click .combine-selected-questions': 'combineSelectedQuestions',

		'click .expand-questions': 'startExpandingQuestions',
		'click .expand-selected-questions': 'expandSelectedQuestions',
	}); },

	/**
	Calls the super initializer, sets the project, sets up a new Questions collection, fetches it, and
	populates the list when that completes
	@method initialize
	@param {object} opts Initial options, must set projID
	*/
	initialize: function(opts) {
		ListView.prototype.initialize.apply(this, arguments);

		/**
		@property projID
		@type number
		*/
		this.projID = opts.projID;

		/**
		@property headerView
		@type QuestionsHeaderView
		*/
		this.headerView = new QuestionsHeaderView({});

		/**
		A collection of models used to populate the view.
		@property collection
		@type Questions
		*/
		if (!this.collection) this.collection = new Questions([], {projID: this.projID});

		/**
		@property tplData
		@type {object}
		*/
		this.tplData = _.extend(this.tplData, {
			has_header: true,
			hoverable: true,
			cursorable: true,
			title: 'Normal Questions',
			panel_controls: [{
				loner: true,
				label: 'Show hidden',
				class_name: 'show-hidden',
				btn_type: 'link'
			}, {
				loner: true,
				label: 'Hide',
				class_name: 'hide-items'
			}, {
				group: true,
				contents: [
					{label: 'Combine', class_name: 'combine-questions'},
					{label: 'Unpipe', class_name: 'expand-questions'}
				]
			}, {
				group: true,
				contents: [
					{label: 'New', class_name: 'create-question'},
					{label: 'Update', class_name: 'update-question'}
				]
			}]
		});

		this.listenTo(this.collection, 'reset', this.fetchedQuestions.bind(this));
		this.listenTo(this.collection, 'add', this.addedQuestion.bind(this));
	},

	/**
	Once the Questions collection has loaded make QuestionItemViews and populate the list with them
	@method fetchedQuestions
	*/
	fetchedQuestions: function() {
		this.populate(this.collection.map(function(q){ return new QuestionItemView({model: q}); }));
	},

	/**
	Create a new QuestionItemView and then call the super's implementation of addView
	@method addedQuestion
	@param {Question} q The added model
	*/
	addedQuestion: function(q) {
		this.addView(new QuestionItemView({model: q}));
	},

	/**
	Begins the startCombiningQuestions mode where you select the questions to combine.
	@method startCombiningQuestions
	@param {event} [e] The initiating event
	*/
	startCombiningQuestions: function(e) {
		if (e) e.preventDefault();

		this.renderHeader({
			title: 'Select the questions you wish to combine',
			panel_controls: [
				{loner: {label: 'Cancel', btn_type: 'link', class_name: 'cancel-selection'}},
				{loner: {label: 'Combine Selected', btn_type: 'primary',
								 class_name: 'combine-selected-questions'}}
			]
		});
		this.enableSelection();
	},

	/**
	@method combineSelectedQuestions
	@param {event} [e] The initiating event
	*/
	combineSelectedQuestions: function(e) {
		var qs = this.getCurrentSelection()[0];
		if (qs.length > 1) {
			this.cancelSelection();
			this.trigger('navigateinto', new CombineQuestionsView({
				qs: qs, projID: this.projID, allQs: this.collection}));
		} else {
			app.ge.trigger('alert', new AlertView({
				m: 'You must select two or more questions to combine',
				type: 'alert-warning'
			}));
		}
	},

	/**
	Begins the mode where you select the questions to expand
	@method startExpandingQuestions
	@param {event} [e] The initiating event
	*/
	startExpandingQuestions: function(e) {
		if (e) e.preventDefault();

		this.renderHeader({
			title: 'Select questions that used the same piping code (not the source question)',
			panel_controls: [
				{loner: {label: 'Cancel', btn_type: 'link', class_name: 'cancel-selection'}},
				{loner: {label: 'Unpipe Selected', btn_type: 'primary',
								 class_name: 'expand-selected-questions'}}
			]
		});

		this.enableSelection();
	},

	/**
	Send the selected questions into a ExpandPipedQuestionsView
	@method expandSelectedQuestions
	@param {Event} [e] The initiating event
	*/
	expandSelectedQuestions: function(e) {
		if (e) e.preventDefault();

		var qs = this.getCurrentSelection()[0];
		if (qs.length > 0) {
			this.cancelSelection();
			this.trigger('navigateinto', new ExpandPipedQuestionsView({qs: qs, allQs: this.collection}));
		} else {
			app.ge.trigger('alert', new AlertView({
				m: 'You must select at least one question to expand',
				type: 'alert-warning'
			}));
		}
	}
});