/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),

		AlertView   = require('./AlertView.js'),
		Collections = require('../collections/Collections.js'),

		app = require('../app.js');

module.exports = Backbone.View.extend({

	tagName: 'a',
	className: 'list-group-item hover-to-show main-link',

	tpl: $('#ProjectItemView-tpl').html(),

	events: {
		'click'                  : 'viewProject',

		'click .renameProject'   : 'initiateRename',
		'click .cancelRename'    : 'cleanUpRename',
		'click .finalizeRename'  : 'finalizeRename',

		'click .deleteProject'   : 'initiateDelete',

		'click .destinations a ' : 'finalizeMove',

		'click .destCollection'  : 'noPropagation',
		'click .editProjectName' : 'noPropagation',
		'click .moveProject'     : 'moveProjectDropDown'
	},

	initialize: function(opts) {
		this.colls = opts.colls;
	},

	render: function() {
		var view = this.model.toTpl();
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.$el.attr('href', '#');

		view.collection = this.colls.reject(function(e) {
			return +e.get('id') == +this.model.get('collection_id');
		}, this);
		view.collection = _.map(view.collection, function(e) {
			return {
				id       : e.get('id'),
				collName : e.get('name')
			};
		});

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.$('.moveControls').hide();
		this.$('.renameControls').hide();
		return this;
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	viewProject: function(e) {
		e.preventDefault();
		app.routes.navigate('project/' + this.model.get('id'), {trigger: true});
	},

	initiateDelete: function(e) {
		e.preventDefault();
		e.stopPropagation();
		var msg = 'Are you sure you want to delete the project:\n\n' + this.model.get('name');
		if (window.confirm(msg)) {
			this.deleteProject(e);
		}
	},

	deleteProject: function(e) {
		this.model.destroy({
			wait: true,
			success: _.bind(function(model, response, options) {
				this.trigger('deleted', this.model);
				this.trigger('close', this.model);
			}, this),
			error: _.bind(function(model, xhr, options) {
				app.ge.trigger('alert', new AlertView({
					m: 'There was an error communicating with the server (' + xhr.status +
						', ' + xhr.responseJSON.gt_code + '). ' +
						'The project was not deleted.',
					type: 'alert-danger'
				}));
			}, this)
		});
	},

	initiateRename: function(e) {
		e.preventDefault();
		e.stopPropagation();
		this.$('.mainControls').hide();
		this.$('.project-name').hide();
		this.$('.renameControls').show(); //show the edit box (input field)
		this.$('.editProjectName').focus();
	},

	finalizeRename: function(e) {
		e.preventDefault();
		e.stopPropagation();
		this.targetName = this.$('.editProjectName').val();
		this.model.save({name: this.targetName}, {
			wait: true,
			success: _.bind(function(model, response, options) {
				this.$('.project-name').html(this.targetName); //collapse current

				this.cleanUpRename(e);
			}, this),
			error: _.bind(function(model, xhr, options) {
				app.ge.trigger('alert', new app.AlertView({
					m: 'There was an error communicating with the server (' + xhr.status +
						'). ' +
						'The project was not renamed.',
					type: 'alert-warning'
				}));
			}, this)
		});
	},

	cleanUpRename: function(e) {
		e.preventDefault();
		e.stopPropagation();
		this.$('.renameControls').hide();
		this.$('.mainControls').removeAttr('style');
		this.$('.project-name').show();
		this.$('.renameControls .editProjectName').val(this.$('.project-name').html());
	},

	/**
	 * Moves a project to a different collection.
	 *
	 * @method finalizeMove
	 * @param {Event} e the event that triggered this function, if one exists.
	 */
	finalizeMove: function(e) {
		e.preventDefault();
		e.stopPropagation();
		var targetCollection = $(e.target).data('coll-id');
		this.model.save({collection_id: targetCollection}, {
			wait: true,
			success: _.bind(function(model, response, options) {
				this.$el.detach(); //detach
				this.trigger('moved', this.model);
			}, this),
			error: _.bind(function(model, xhr, options) {
				app.ge.trigger('alert', new AlertView({
					m: 'There was an error communicating with the server (' + xhr.status +
						'). ' +
						'The project was not moved.',
					type: 'alert-warning'
				}));
			}, this)
		});
	},

	/**
	 * Catchall function for events that should not propagate down the DOM stack.
	 *
	 * @method noPropagation
	 * @param  {Event} e The event that triggered this function call, if one exists.
	 */
	noPropagation: function(e) {
		if(e) {
			e.stopPropagation();
		}
	},

	/**
	 * Required because of funkiness with noPropagation and Bootstrap dropdowns.
	 * See also noPropagation()
	 *
	 * @method moveProjectDropDown
	 * @param  {Event} e The event that triggered this function call, if one exists.
	 */
	moveProjectDropDown: function(e) {
		if(e) {
			e.stopPropagation();
			$(e.target).parent().find('.dropdown-menu').toggle();
		}
	}
});