/* global require, module */

var Backbone = require('backbone'),
		Mustache = require('mustache'),
		_ = require('underscore'),
		$ = require('jquery'),

		GraphThemes = require('../collections/GraphThemes.js'),
		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		CreateThemeView = require('./CreateThemeView.js'),
		AlertView = require('./AlertView'),
		app = require('../app.js');

/**
@class ProjectSummaryView
@constructor
@exents Backbone.View
@module Views
@uses NavigationComponentMixin
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	/**
	Basic Mustache template for the page skeleton
	@property tpl
	@type string
	*/
	tpl: $('#ProjectSummaryView-tpl').html(),

	/**
	Template for the list of themes
	@property themesListTpl
	@type string
	*/
	themesListTpl: $('#ProjectSummaryView-themes-list-tpl').html(),

	events: {
		'click .add-theme'    : 'addTheme',
		'click .edit-theme'   : 'editTheme',
		'click .delete-theme' : 'deleteTheme',

		'change select[name="graph_theme"]': 'saveGraphTheme'
	},

	type: 'summary',

	initialize: function(opts) {
		/**
		@property title
		@type string
		@default 'Project Details'
		@final
		*/
		this.title = 'Project Details';

		/**
		The current project, passed in by parent view
		@property proj
		@type Project
		*/
		this.proj = opts.proj;
		this.listenTo(this.proj, 'change:theme_id', this.renderThemesList);

		/**
		The collection of themes associated with this project's collection
		@property gthemes
		@type GraphThemes
		*/
		this.gthemes = new GraphThemes({collID: this.proj.get('collection_id')});
		this.listenTo(this.gthemes, 'reset', this.renderThemesList);
		this.listenTo(this.gthemes, 'destroy', this.renderThemesList);
		this.listenTo(this.gthemes, 'add', _.bind(function(model){
			this.proj.set('theme_id', model.get('id'));
			this.renderThemesList();
			this.saveGraphTheme(null, model.get('id'));
		}, this));
		this.gthemes.fetch({reset: true});

	},

	/**
	Draws the basic view skeleton into the page
	@method render
	*/
	render: function() {
		this.$el.html(Mustache.render(this.tpl, {}, app.partials()));
		return this;
	},

	/**
	Populates the list of themes with the current GraphThemes collection
	@method renderThemesList
	*/
	renderThemesList: function() {
		var view = {themes:this.gthemes.toTpl()};

		// if there's not theme selected mark the view as such, otherwise mark the selected theme
		if (!this.proj.has('theme_id')) {
			view.noTheme = true;
		} else {
			// _.some b/c want to short circuit
			_.some(view.themes, function(theme){
				if (theme.id == this.proj.get('theme_id')) {
					theme.isSelected = true;
					return true;
				} else {
					return false;
				}
			}, this);
		}

		// actually do the rendering
		this.$('select[name="graph_theme"]')
			.html(Mustache.render(this.themesListTpl, view, app.partials()));
	},

	/**
	Starts the process of adding a theme
	@method addTheme
	@param {event} [e] Initiating event
	*/
	addTheme: function(e) {
		if (e) e.preventDefault();
		this.trigger('navigateinto', new CreateThemeView({proj: this.proj, gthemes: this.gthemes}));
	},

	/**
	Starts the process of editing the currently selected theme
	@method editTheme
	@param {event} [e] Initiating event
	*/
	editTheme: function(e) {
		if (e) e.preventDefault();
		if (_.isNull(this.proj.get('theme_id'))) {
			app.ge.trigger('alert', new AlertView({
				m: 'You cannot edit the system default theme',
				type: 'alert-warning',
				duration: 3000
			}));
		}
		else {
			this.trigger('navigateinto', new CreateThemeView({
				proj: this.proj,
				model: this.gthemes.get(this.proj.get('theme_id')),
				gthemes: this.gthemes
			}));
		}
	},

	/**
	Updates the project to use the new theme and saves that setting
	@method saveGraphTheme
	@param {event} [e] The event that triggered the change
	@param {Number} id The GraphTheme ID to set on the project
	*/
	saveGraphTheme: function(e, id) {
		var themeID;
		if (e) themeID = $(e.target).val() == 'null' ? null : $(e.target).val();
		else themeID = id;
		this.proj.save('theme_id', themeID, {
			// because the view update is what triggered this change
			silent: true,
			error: _.bind(function(model) {
				this.proj.set(model.previousAttributes());
				app.ge.trigger('alert', new AlertView({
					m: 'Unable to save changes to server.',
					type: 'alert-danger',
					duration: 3500
				}));
			}, this),
			success: _.bind(function(){
				app.ge.trigger('alert', new AlertView({
					m: 'Theme saved',
					type: 'alert-success',
					duration: 1500
				}));
			})
		});
	},

	/**
	@method deleteTheme
	@param {event} [e] initiating event
	*/
	deleteTheme: function(e) {
		if (e) e.preventDefault();

		if (!window.confirm('Are you sure you want to delete the selected theme?\n\n' +
												'This action cannot be reverted.')) {
			return;
		}

		console.log(this.proj.get('theme_id'));

		if (_.isNull(this.proj.get('theme_id'))) {
			app.ge.trigger('alert', new AlertView({
				m: 'You cannot delete the system default theme',
				type: 'alert-warning',
				duration: 3000
			}));
		}
		else {
			this.gthemes.get(this.proj.get('theme_id')).destroy({
				wait: true,
				error: function(mod, res) {
					console.log(arguments);
					app.ge.trigger('alert', new AlertView({m: res.responseJSON.m, type: 'alert-danger'}));
				},
				success: _.bind(function(){
					this.proj.set('theme_id', null);
				}, this)
			});
		}
	}

}));