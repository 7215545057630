/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		CalcMixin = require('../mixins/CalculatorMixin.js'),

		app = require('../app.js');

/**
@class SmartQuestion
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend(
	_.extend({}, CalcMixin, {
	urlRoot: '/api1/smart_question',

	defaults: function() {
		return {
			name                    : 'Untitle smart question',
			type_id                 : null,
			project_id              : null,
			sample_size_method      : 3,
			prompt                  : '',
			description             : null,
			sample_size_question_id : null,
			calc_type               : null,
			ci_calc_type            : null
		};
	},

	getters: {
		type_name: function() {
			return app.qTypeHash[+this.get('type_id')];
		},

		sample_size: function() {
			var method = +this.get('sample_size_method');

			if (method == 1) return this.get('num_participants');
			if (method == 2) return this.get('num_participants'); // not supported right now
			if (method == 3) return this.get('num_presentations');
		}
	},

	parse: function(res, opts) {
		if (res.hasOwnProperty('data')) {
			return res.data;
		} else {
			return res;
		}
	}
}));