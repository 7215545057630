/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		PossibleResponseSelector = require('./PossibleResponseSelector.js'),
		AlertView = require('./AlertView.js'),
		app = require('../app.js');

/**
Used by ExpandQuestionView. Collects the information needed to create a new question (name, prompt,
sample_size_method, etc.) including which exisiting possible_responses should be copied over into
the new one.
@class NewQuestionDetailsView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',
	className: 'panel panel-default',

	tpl: $('#NewQuestionDetailsView-tpl').html(),

	events: {
		'click .GTPanelCloseButton': 'removeView',
		'click .add-pr-selector': 'addSelector',

		'change select[name="type_id"]': 'validateNewType'
	},

	initialize: function(opts) {
		/**
		@property projID
		@type number
		*/
		this.projID = opts.projID;

		/**
		The model for the original question that's getting expanded
		@property origQ
		@type Question
		*/
		this.origQ = opts.origQ;

		/**
		Flag indicating whether the view is the first in the list
		@property isFirst
		@type boolean
		*/
		this.isFirst = opts.first || false;

		/**
		@property prs
		@type PossibleResponses
		*/
		this.prs = opts.prs;

		this.prSelectors = [new PossibleResponseSelector({prs: this.prs})];
		this.listenTo(this.prSelectors[0], 'change', this.validateNewType);
	},

	render: function() {
		var view = this.origQ.toTpl();
		view.q_types = _.map(app.qTypeHash, function(val, key) {
			return {
				is_selected : key == +this.origQ.get('type_id'),
				type_id     : key,
				type        : val
			};
		}, this);
		view.sample_size_methods = _.map(app.sampleMethodHash, function(method, key) {
			return {
				is_selected : key == +this.origQ.get('sample_size_method'),
				method_id   : key,
				method_name : method.name
			};
		}, this);
		view.is_first = this.isFirst;
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		var frag = app.renderViewsToFrag(this.prSelectors);
		this.$('.possible-responses').html(frag);
		return this;
	},

	/**
	Default implementation
	@method close
	*/
	close: function() {
		this.prSelectors.forEach(function(prSel){ prSel.close(); });
		this.remove();
	},

	/**
	@method remove
	@param {event} [e] The initiating event
	*/
	removeView: function(e) {
		if (e) e.preventDefault();
		/**
		Triggers a remove event so the parent can properly dispose of the child
		@event remove
		@param {NewQuestionDetailsView} view The view that triggered the event
		*/
		this.trigger('remove', this);
	},

	/**
	Add another PossibleResponseSelector
	@method addSelector
	@param {event} [e] The initiating event
	*/
	addSelector: function(e) {
		if (e) e.preventDefault();
		var newView = new PossibleResponseSelector({prs: this.prs, removable: true});
		this.listenTo(newView, 'remove', this.removeSelector);
		this.listenTo(newView, 'change', this.validateNewType);
		this.prSelectors.push(newView);
		this.$('.possible-responses').append(newView.render().el);
	},

	/**
	Handle remove events on child PossibleResponseSelectors
	@method removeSelector
	@param {PossibleResponseSelector} view The view that triggered the remove event
	*/
	removeSelector: function(view) {
		this.stopListening(view);
		this.prSelectors = _.without(this.prSelectors, view);
		view.close();
	},

	/**
	When the user changes the question type check to ensure the selected possible_responses are
	compatible with the new type.
	@method validateNewType
	@param {event} [e] The initiating event
	*/
	validateNewType: function(e) {
		var $typeSelect = this.$('select[name="type_id"]');
		switch (+$typeSelect.val()) {
			case 1: // categorical
				// pretty much anything can be categorical
				break;

			case 2: // rating scale
				if (!this.prSelectors.every(function(pr){ return pr.isNumeric(); })) {
					$typeSelect.val(1);
					app.ge.trigger('alert', new AlertView({
						m: 'You can only choose a rating scale type if all the possible responses are numeric',
						type: 'alert-warning'
					}));
				}
				break;

			case 3: // time
			case 5: // continuous
				if (!this.prSelectors.every(function(pr){ return pr.hasValue(); })) {
					$typeSelect.val(1);
					app.ge.trigger('alert', new AlertView({
						m: 'You can only choose a time or continuous type if all the possible responses have ' +
							 'unique numeric responses from each participant',
						type: 'alert-warning'
					}));
				}
				break;

			case 4: // open-end
				if (!this.prSelectors.every(function(pr){ return pr.hasText(); })) {
					$typeSelect.val(1);
					app.ge.trigger('alert', new AlertView({
						m: 'You can only choose an open end type if all the possible responses have ' +
							 'unique textual responses from each participant',
						type: 'alert-warning'
					}));
				}
				break;
		}
	},

	/**
	@method serialize
	@return object A valid question model object plus a original_possible_response_ids key
	*/
	serialize: function() {
		return {
			name                           : this.$('input[name="name"]').val(),
			prompt                         : this.$('textarea[name="prompt"]').val(),
			type_id                        : this.$('select[name="type_id"]').val(),
			source                         : 'gt',
			source_id                      : this.origQ.get('id'),
			native_id                      : null,
			project_id                     : this.projID,
			sample_size_method             : this.$('input[name="sample_size_method"]:checked').val(),
			original_possible_response_ids : this.prSelectors.map(function(s){ return s.getSelectedID(); }),
			is_hidden                      : false
		};
	}

});