/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
@class PossibleResponseSelector
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	tagName: 'div',
	className: 'row PossibleResponseSelector',
	tpl: $('#PossibleResponseSelector-tpl').html(),

	events: {
		'click .remove-me': 'removeView',
		'change select[name="PossibleResponseSelector"]': 'changeSelect'
	},

	/**
	Set up a isSelected property, the default selectedClass
	@method initialize
	*/
	initialize: function(opts) {
		/**
		ID of the question to display reponses for.
		@property qID
		@type number?
		@default null
		*/
		this.qID = opts.qID || null;

		/**
		Whether to include the meta possible_responses (is_empty=TRUE or is_response=FALSE)
		@property includeMeta
		@type boolean
		@default false
		*/
		this.includeMeta = opts.includeMeta || false;

		/**
		Whether the selector should render a remove button
		@property removable
		@type boolean
		@default false
		*/
		this.removable = opts.removable || false;

		/**
		The collection of possible_response models to draw from
		@property prs
		@type PossibleResponses
		*/
		this.prs = opts.prs;

		/**
		Array of functions that take a PossibleResponse and return a boolean indicating whether it
		should be included in the list.
		@property filters
		@type array<pr => boolean>
		*/
		this.filters = [];

		// stick some default filters in under certain conditions
		if (this.qID) {
			this.filters.push(function(pr){ return pr.get('id') == this.qID; }.bind(this));
		}

		if (!this.includeMeta) {
			this.filters.push(function(pr) {
				return +pr.get('is_response') === 1 && +pr.get('is_empty') === 0;
			});
		}
	},

	/**
	Filter and render all the possible_responses that can be selected
	@method render
	@chainable
	*/
	render: function() {
		var validPrs = this.getValidPrs();
		var view = {
			possible_responses: validPrs.map(function(pr){
				return {id:pr.get('id'), response:pr.get('response')};
			}),
			is_removable: this.removable
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	/**
	Default implementation
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Handles change events on the main selector, triggers a 'change' event on the view
	@method changeSelect
	*/
	changeSelect: function() {
		/**
		@event change
		@param {PossibleResponseSelector} view The view that was changes
		*/
		this.trigger('change', this);
	},

	/**
	If necessary filters the possible_responses that got loaded up into the view.
	@method getValidPrs
	@return array<PossibleResponse>
	*/
	getValidPrs: function() {
		// start with a shallow copy of the prs
		var matchingPrs = this.prs.slice();

		// call all the filters
		matchingPrs = matchingPrs.reduce(function(memo, pr) {
			if (this.filters.every(function(filter){ return filter.call(null, pr); })) {
				memo.push(pr);
			}
			return memo;
		}.bind(this), []);

		// return the results of the various filters
		return matchingPrs;
	},

	/**
	@method getSelectedID
	@return Number The currently selected ID
	*/
	getSelectedID: function() {
		return +this.$('select[name="PossibleResponseSelector"]').val();
	},

	/**
	Whether the currently selected possible_response has_value=TRUE
	@method hasValue
	@return boolean
	*/
	hasValue: function() {
		return +this.prs.get(this.getSelectedID()).get('has_value') === 1;
	},

	/**
	Whether the currently selected possible_response has_text=TRUE
	@method hasText
	@return boolean
	*/
	hasText: function() {
		return +this.prs.get(this.getSelectedID()).get('has_text') === 1;
	},

	/**
	@method isNumeric
	@return boolean
	*/
	isNumeric: function() {
		return !_.isNaN(Number(this.prs.get(this.getSelectedID()).get('response')));
	},

	/**
	Trigger a remove event for the parent to dispose of the view
	@method removeView
	@property {event} [e] The initiating event
	*/
	removeView: function(e) {
		if (e) e.preventDefault();
		this.trigger('remove', this);
	}
});