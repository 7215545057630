/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavCompMixin       = require('../mixins/NavigationComponentMixin.js'),
		PossibleResponses  = require('../collections/PossibleResponses.js'),
		Responses          = require('../collections/Responses.js'),
		Participants       = require('../collections/Participants.js'),
		AlertView          = require('./AlertView.js'),
		GColView           = require('./GColView.js'),
		QuestionView  	   = require('./QuestionView.js'),
		StatsAnalysisView  = require('./StatsAnalysisView.js'),
		ExpandQuestionView = require('./ExpandQuestionView.js'),
		app                = require('../app.js');

/**
@class QuestionView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavCompMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl         : $('#QuestionView-tpl').html(),
	infoTpl     : $('#QuestionView-info-tpl').html(),
	editInfoTpl : $('#QuestionView-edit-info-tpl').html(),

	events: {
		'click .back'                 : 'navigateBack',

		'click .data-download'        : 'addDataToExcel',

		'click .edit-question'        : 'editQuestion',
		'click .cancel-edit-question' : 'cancelEditQuestion',
		'click .save-edit-question'   : 'saveEditQuestion',

		'click .expand-question'      : 'expandQuestion',

		'click .edit-prs'             : 'editPrs',
		'click .cancel-edit-prs'      : 'renderPrs',
		'click .save-edit-prs'        : 'saveEditPrs',
		'click .convert-to-rating'	  : 'convertToRating'
	},

	/**
	@method initialize
	@param {object} opts Config object following the format:
	*/
	initialize: function(opts) {
		this.opts = opts;

		/**
		The Question model that is being viewed
		@property q
		@type Question
		*/
		this.q = opts.q;

		/**
		A reference to the collection of all questions
		@property allQs
		@type Questions
		*/
		this.allQs = opts.allQs;

		/**
		The project model
		@property project
		@type Project
		*/
		this.project = opts.project;

		/**
		Taken from project.
		@property projID
		@type String/Number
		*/
		this.projID = this.project.get('id');

		/**
		A PossibleResponses collection for the selected question
		@property prs
		@type PossibleResponses
		*/
		this.prs = new PossibleResponses([], {qID: this.q.get('id')});

		/**
		A Responses collection for the selected question
		@property rs
		@type Responses
		*/
		this.rs = new Responses([], {qID: this.q.get('id')});

		/**
		A Participants collection for the project. Initialized by self;
		@property parts
		@type Participants
		*/
		this.parts = new Participants([], {projID: this.projID});

		/**
		Title for the page.
		@property title
		@type String
		@default The question name
		@final
		*/
		this.title = this.q.get('name');

		/**
		Previous page title
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		@property inserted
		@type jQuery deferred
		*/
		this.inserted = $.Deferred();

		/**
		Reference to the child GColView
		@property chart
		@type GColView
		@default null
		*/
		this.chart = null;

		/**
		@property statView
		@type StatsAnalysisView
		*/
		this.statView = null;


		/**
		Set's the confidence level to use when viewing the graph and computing the confidence interval.
		@property alpha
		@type Number
		@default 0.1
		*/
		this.alpha     = opts.alpha || 0.1;

		this.typeMethods = this.qt[this.q.get('type_id')];

		this.isConvertingToRating = false;

		var
			prsProm   = this.prs.fetch({reset: true}),
			rsProm    = this.rs.fetch({reset: true}),
			partsProm = this.parts.fetch({reset: true});

		$.when(prsProm, rsProm).done(_.bind(function() {
			this.renderPrs();
			this.renderRs();
		}, this));

		$.when(prsProm, rsProm, partsProm, this.inserted)
			.done(_.bind(function(){
				this.renderChart(this.alpha);
				this.renderStats();
			}, this))
			.fail(function(){
				app.ge.trigger('alert', new AlertView({
					m: 'Problem loading prs, rs, or calling the deferred',
					type: 'alert-danger'
				}));
			});

		this.listenTo(this.q, 'change', function() {
			this.renderInfo();
			this.renderNavHeader();
		});

		this.listenTo(this.q, 'change:sample_size_method', function() {
			this.renderChart(this.alpha);
		});

		this.listenTo(this.prs, 'change:response', _.debounce(function() {
			this.renderPrs();
			this.renderChart(this.alpha);
		}, 50));
	},

	/**
	Set up the page structure, call `renderNavHeader()` and `renderInfo()`
	@method render
	@chainable
	*/
	render: function() {
		var view = this.q.toTpl();
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.renderNavHeader();
		this.renderInfo();
		return this;
	},

	/**
	Basic NavHeader rendering stuff
	@method renderNavHeader
	*/
	renderNavHeader: function() {
		var view       = {};
		view.title     = this.title;
		view.prevTitle = this.prevTitle;

		view.panel_controls = [
			{
				loner      : true,
				label      : 'Add to Excel',
				class_name : 'data-download'
			}, {
				loner      : true,
				label      : 'Split',
				class_name : 'expand-question'
			}, {
				loner      : true,
				label      : 'Edit',
				class_name : 'edit-question'
			}
		];
		console.log(this.q.get('type_id'));
		
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);

	},

	/**
	Fill out the information panel based on the current question model state
	@method renderInfo
	*/
	renderInfo: function() {
		//render convert to rating scale button if categorical
		if(this.q.get('type_id') == 1){
			this.q.set('isCategorical', true);
			console.log(this.q);
			
		}

		this.$('.panel-nav .panel-body').html(
			Mustache.render(this.infoTpl, this.q.toTpl(), app.partials())
		);

	},

	/**
	Fill out the info panel with editing controls
	@method renderEditInfo
	*/
	renderEditInfo: function() {
		var view = this.q.toTpl();
		view.q_types = _.map(app.qTypeHash, function(val, key) {
			return {
				is_selected : key == +this.q.get('type_id'),
				type_id     : key,
				type        : val
			};
		}, this);
		view.sample_size_methods = _.map(app.sampleMethodHash, function(method, key) {
			return {
				is_selected : key == +this.q.get('sample_size_method'),
				method_id   : key,
				method_name : method.name
			};
		}, this);
		this.$('.panel-nav .panel-body').html(Mustache.render(this.editInfoTpl, view, app.partials()));
	},

	/**
	Render the list of possible responses, if the method is defined. Delegates through typeMethods
	@method renderPrs
	*/
	renderPrs: function() {
		if (this.typeMethods.hasOwnProperty('renderPrs')) {
			this.typeMethods.renderPrs(this);
		}
	},

	/**
	Delegate down to render possible response editing tools if the method is defined
	@method renderEditPrs
	*/
	renderEditPrs: function() {
		if (this.typeMethods.hasOwnProperty('renderEditPrs')) {
			this.typeMethods.renderEditPrs(this);
		}
	},

	/**
	Delegate rendering the responses if the method is defined
	@method renderRs
	*/
	renderRs: function() {
		if (this.typeMethods.hasOwnProperty('renderRs')) {
			this.typeMethods.renderRs(this);
		}
	},

	/**
	Renders a new chart or updates an existing one.
	@method renderChart
	@param {Number} alpha The confidence level to use
	*/
	renderChart: function(alpha) {
		this.q.toSeriesSet({parts: this.parts, prs: this.prs, rs: this.rs})
			.then(function(seriesSet){
				console.log(this.q.getCalculator(), this.q.getCICalculator(), alpha);
				return seriesSet.toChartData(this.q.getCalculator(), this.q.getCICalculator(), alpha);
			}.bind(this))
			.then(_.bind(function(chartData) {
				if (!this.chart) {
					chartData.el = '.graph-mount';
					chartData.alpha = alpha;
					console.log(this.parts.length);
					chartData.sampleSize = this.parts.length;
					console.log(chartData);
					this.chart = new GColView(chartData);

					this.listenTo(this.chart, 'changealpha', this.changeAlpha);
					this.listenTo(this.chart, 'GColView:addtoexcel', this.addGraphToExcel);
					this.chart.render();
				} else {
					this.chart.setData(chartData.data).setAlpha(alpha).reRender();
				}
			}, this));
	},

	renderStats: function() {
		this.q.toSeriesSet({parts:this.parts, prs:this.prs, rs:this.rs})
			.then(_.bind(function(ss){
				// first figure out if this ss supports analysis
				var type = ss.get('type');
				if (type == 'open_end') return;
				if (type == 'time') return;
				if (type == 'rating') return;
				if (type == 'continuous') return;
				if (ss.get('series').length == 1 && ss.get('series')[0].data.length == 1) return;

				this.statView = new StatsAnalysisView({ss: ss});
				this.$('.stats-mount').html(this.statView.render().el);
			}, this));
	},

	/**
	Sets the new alpha property and re-renders the graph.
	@method changeAlpha
	@param {Number} alpha The new alpha value
	*/
	changeAlpha: function(alpha) {
		this.alpha = alpha;
		this.renderChart(this.alpha);
	},

	/**
	Basic close. Except it also triggers a 'close' event, which it probably shouldn't be. TODO.
	@method close
	*/
	close: function() {
		this.remove();
	},

	qt: {
		1: { // categorical
			prsTpl: $('#QuestionView-prs-tpl').html(),
			editPrsTpl: $('#QuestionView-categorical-edit-prs-tpl').html(),
			renderPrs: function(self) {
				var prs = self.prs.toTpl();
				var view = {};
				
				view.prs = _.map(prs, function(pr) {
					var response;
					if (+pr.is_empty === 1) {
						response = 'Empty';
					} else if (+pr.is_response === 0) {
						response = 'No response';
					} else {
						response = pr.response;
					}
					return response;
				});
				
				view.panel_controls = [
					{
						loner: true,
						label: 'Edit',
						class_name: 'edit-prs'
					}
				];
				self.$('.possible-responses').html(
					Mustache.render(this.prsTpl, view, app.partials())
				);
			},
			renderEditPrs: function(self) {
				var prs = self.prs.toTpl();
				var view = {};
				view.isConvertingToRating = self.isConvertingToRating;
				countPrs = 1;
				console.log(self.isConvertingToRating);
				view.prs = _.map(prs, function(pr) {
					if (+pr.is_empty === 1) {
						pr.disabled = true;
						pr.response = 'Empty';
					} else if (+pr.is_response === 0) {
						pr.disabled = true;
						pr.response = 'No response';
					} else if (self.isConvertingToRating){
						pr.newresponse = countPrs;
						countPrs++;
					}

					return pr;
				});
				view.panel_title = 'Possible Responses';
				if(self.isConvertingToRating){
					view.panel_title = view.panel_title + ' (enter 999 to remove possible response)';
				}
				view.panel_controls = [
					{
						loner: true,
						label: 'Cancel',
						class_name: 'cancel-edit-prs',
						btn_type: 'link'
					},
					{
						loner: true,
						label: 'Save',
						class_name: 'save-edit-prs',
						btn_type: 'primary'
					}
				];
				self.$('.possible-responses').html(
					Mustache.render(this.editPrsTpl, view, app.partials())
				);
			}
		},
		2: { // rating
			prsTpl: $('#QuestionView-prs-tpl').html(),
			renderPrs: function(self) {
				var prs = self.prs.toTpl();

				var minVal = d3.min(prs, function(pr) {
					var isEmpty = +pr.is_empty == 1 ? true : false;
					var notResponse = +pr.is_response === 0 ? true : false;
					return isEmpty || notResponse ? undefined : +pr.response;
				});
				var maxVal = d3.max(prs, function(pr) {
					return Number(pr.response);
				});

				var view = {};
				view.prs = [minVal + '\u2013' + maxVal, 'Empty', 'No response'];
				view.panel_title = 'Possible Responses';
				self.$('.possible-responses').html(
					Mustache.render(this.prsTpl, view, app.partials())
				);
			},
		},
		3: { // time
		},
		4: { // open
			rsTpl: $('#QuestionView-open-rs-tpl').html(),
			renderRs: function(self) {
				var view = {};
				view.responses = self.rs.map(function(res) {
					return {
						text: res.get('text') ? res.get('text') : '\u2014'
					};
				});

				self.$('.responses').html(
					Mustache.render(this.rsTpl, view, app.partials())
				);
			}
		},
		5: { // continuous
		},
	},

	/**
	Handle excel data being passed up from the graph (GColView).
	@method addGraphToExcel
	@param {Object} data  Plain object passed up by GColView that just needs to get wrapped and
		passed up to the parent view that handles adding things to excel.
	@param {String} type  The type of graph, currently takes 'vertical' or 'horizontal' but I
		don't think it makes a difference right now.
	*/
	addGraphToExcel: function(data, type, properties) {
		var jsonData = {
			name: this.q.get('name'),
			type: 'graph',
			data: data,
			graph_type: type,
			properties: properties,
			graph_theme_id: this.project.get('theme_id')
		};
		app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	@method addDataToExcel
	@param {event} [e] Potentially passed the triggering event.
	*/
	addDataToExcel: function(e) {
		if (e) e.preventDefault();

		this.q.toSeriesSet({parts: this.parts, prs:this.prs, rs:this.rs})
			.then(_.bind(function(ss){ 
				console.log(ss);
				return ss.toExcelData({parts: this.parts}); 
			}, this))
			.then(function(eData){ app.ge.trigger('addtoexcel', eData); });
	},

	editQuestion: function() {
		var view            = {};
		view.title          = this.title;
		view.prevTitle      = this.prevTitle;
		view.panel_controls = [
			{
				loner: true,
				label: 'Cancel',
				class_name: 'cancel-edit-question',
				btn_type: 'link'
			}, {
				loner: true,
				label: 'Save',
				class_name: 'save-edit-question',
				btn_type: 'primary'
			}
		];
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);

		this.renderEditInfo();
	},

	cancelEditQuestion: function() {
		this.renderNavHeader();
		this.renderInfo();
	},

	saveEditQuestion: function() {
		var data = _.reduce($('#edit-question-form').serializeArray(), function(memo, val) {
			memo[val.name] = val.value;
			return memo;
		}, {});

		this.q.save(data, {
			wait: true,
			success: _.bind(function(model, res, opts) {}, this),
			error: function(model, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem saving the edited question',
					type: 'alert-danger'
				}));
			}
		});
	},

	editPrs: function() {
		this.renderEditPrs();
	},

	saveEditPrs: function() {
		var data = $('#edit-prs-form').serializeArray();
		var saveProms = [];

		_.each(data, function(update) {
			var pr = this.prs.get(update.name);
			if (update.value != pr.get('response')) {
				saveProms.push(pr.save({response: update.value}, {wait:true}));
			}
		}, this);

		$.when.apply(this, saveProms)
		.done(_.bind(function() {
			if(this.isConvertingToRating){
				this.q.save({'type_id': 2});
				var nonApplicablePR = this.prs.where({response : '999'})[0];
				if(!_.isUndefined(nonApplicablePR)){
					var nonApplicableRS = this.rs.where({possible_response_id : nonApplicablePR.get('id')})[0];
					nonApplicablePR.destroy();
				
				}
				Backbone.history.loadUrl();
			}
		},this))
		.fail(_.bind(function() {
			this.trigger('alert', new AlertView({
				m: 'Problem saving possible responses',
				type: 'alert-danger'
			}));
		}, this));

	},

	/**
	Moves you into a ExpandQuestionView for the current question.
	@method expandQuestion
	@param {event} [e] The initiating event
	*/
	expandQuestion: function(e) {
		if (e) e.preventDefault();
		this.trigger('navigateinto', new ExpandQuestionView({
			q: this.q,
			prs: this.prs,
			projID: this.projID,
			allQs: this.allQs
		}));
	},

	convertToRating: function(e){
		if (e) e.preventDefault();
		this.isConvertingToRating = true;
		console.log(this.isConvertingToRating);
		this.typeMethods.renderEditPrs(this);
		/*this.q.save({'type_id': 2});
		var nonApplicablePR = this.prs.where({response : '999'})[0];
		console.log(this.prs);
		var nonApplicableRS = this.rs.where({possible_response_id : nonApplicablePR.get('id')})[0];
		console.log(nonApplicablePR, nonApplicableRS);
		nonApplicablePR.destroy();
		this.q.toTpl().reset();*/

	}
}));