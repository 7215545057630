/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Analytic             = require('./Analytic.js'),
		Participants         = require('../collections/Participants.js'),
		SeriesSet            = require('./SeriesSet.js'),
		CalcMixin            = require('../mixins/CalculatorMixin.js');

/**
@class CrosstabAnalytic
@constructor
@extends Analytic
@module Models
*/
module.exports = Analytic.extend(
	_.extend({}, CalcMixin, {
	defaults: {
		id                   : null,
		target_question_id   : null,
		target_question_type : '',
		description          : '',
		filters              : {},
		filtering_questions  : {},
		type                 : 'crosstab',
		name                 : '',
		project_id           : null,
		calc_type            : null,
		ci_calc_type         : null
	},

	getters: {
		/**
		@method get_nested
		@return {Boolean} Whether the crosstab has two levels or not
		*/
		nested: function() {
			return _.isObject(_.sample(this.get('filters')));
		}
	},

	/**
	Applies the appropriate filters to the crosstab and adds a `participants` property that is
	similar to the `filters` property but maps to an array of matching participants rather than
	to filter ids.

	@method applyFilters
	@param {Filters} filters
	@param {Participants} parts
	@param {PossibleResponses} prs
	@param {Responses} rs
	*/
	applyFilters: function(filters, parts, prs, rs) {
		var proms  = [],
				mparts = JSON.parse(JSON.stringify(this.get('filters'))),
				dfd    = $.Deferred(),
				p;

		parts = parts.toTpl();
		prs   = prs.toTpl();
		rs    = rs.toTpl();

		_.each(this.get('filters'), function(filterID, outerPrID) {

			// if the Crosstab is nested then filterID is actually an object
			if (_.isObject(filterID)) {
				_.each(filterID, function(filterID, innerPrID) {
					p = filters.get(filterID).applyFilter(parts, prs, rs);
					p.done(function(parts){
						mparts[outerPrID][innerPrID] = parts;
					});
					proms.push(p);
				});
			}

			// if it's just a single level deep
			else {
				p = filters.get(filterID).applyFilter(parts, prs, rs);
				p.done(function(parts){
					mparts[outerPrID] = parts;
				});
				proms.push(p);
			}
		});

		$.when.apply(null, proms)
			.done(_.bind(function(){
				this.set('participants', mparts);
				dfd.resolve();
			}, this));

		return dfd;
	},

	/**
	Extracts the target model from the appropriate collection
	@method getTarget
	@param {Object} args Hash of Questions, SmartQuestions, Comparisons
	*/
	getTarget: function(args) {
		var id = this.get('target_question_id');
		switch (this.get('target_question_type')) {
			case 'question':
				return args.qs.get(id);

			case 'smart_question':
				return args.smartQs.get(id);

			case 'comparison':
				return args.comps.get(id);

			default:
				throw new Error('Unable to find target');
		}
	},

	/**
	Generates the appropriate SeriesSet(s).
	@method toSeriesSetArray
	*/
	toSeriesSetArray: function(args) {
		// set up variables
		var qs       = args.qs,
				smartQs  = args.smartQs,
				parts    = args.parts,
				prs      = args.prs,
				smartPrs = args.smartPrs,
				rs       = args.rs,
				filters  = args.filters,
				comps    = args.comps,
				dfd      = $.Deferred(),
				ssArray  = [];

		// get the target's model
		var target = this.getTarget({qs:qs, smartQs:smartQs, comps:comps});

		// get the target's SeriesSet
		var targetProm = target.toSeriesSet({
			parts    : parts,
			prs      : prs,
			rs       : rs,
			smartPrs : smartPrs,
			filters  : filters,
			qs       : qs,
			smartQs  : smartQs,
			comps    : comps
		});

		// now go through each filter and create the corresponding SeriesSet
		if (this.get_nested()) {
			throw new Error('Nested crosstabs are not yet supported');
		} else {
			// once the target has created its SeriesSet...
			targetProm.done(_.bind(function(targetSS) {
				// flag for whether things can be merged together or not
				var condensable = targetSS.get('series').length === 1;

				// loop over each filtering-by bucket
				_.each(this.get('participants'), function(mparts, prID) {
					// determine if we need to start a new SeriesSet or if the data can
					// fit into an existing one
					var newSS;
					// just starting out, make a totally new SeriesSet
					if (ssArray.length === 0) {
						newSS = new SeriesSet({
							series : [],
							name   : '',
							type   : targetSS.get('type')
						});
					}
					// can we reuse the existing one?
					else if (condensable) {
						newSS = ssArray[0];
					}
					// nope
					else {
						newSS = new SeriesSet({
							series : [],
							name   : '',
							type   : targetSS.get('type')
						});
					}

					// name the SeriesSet if it isn't already
					if (!newSS.get('name')) {
						if (condensable) {
							newSS.set('name', this.get('name'));
						} else {
							switch (this.get('filtering_questions')[0].type) {
								case 'question':
									newSS.set('name', prs.get(prID).get('response'));
									break;
								case 'smart_question':
									newSS.set('name', smartPrs.get(prID).get('response'));
									break;
							}
						}
					}

					// extract IDs of participants in this bucket
					var bucketIDs = _.map(mparts, function(mpart){ return +mpart.id; });

					// loop over each DataSeries in the target
					_.each(targetSS.get('series'), function(dataSeries) {

						// clone the data series so that we can work with copies of each DataPoint
						dataSeries = JSON.parse(JSON.stringify(dataSeries));

						// modify the DataSeries to reflect the current filtering question/bucket
						if (targetSS.get('series').length === 1) {
							switch (this.get('filtering_questions')[0].type) {
								case 'question'       : dataSeries.name = prs.get(prID).get('response'); break;
								case 'smart_question' : dataSeries.name = smartPrs.get(prID).get('response'); break;
							}
						}

						// now begin the process of creating new DataPoints with properly filtered
						// responses, sample_size, etc.
						_.each(dataSeries.data, function(dataPoint) {
							// remove responses by those not in this bucket
							dataPoint.responses = _.map(dataPoint.responses, function(r) {
								if (_.indexOf(bucketIDs, +r.part_id) < 0) {
									r.is_response = false;
									r.is_empty    = false;
									r.response    = null;
								}
								return r;
							});
						}, this); // done looping over DataPoints for this DataSeries

						// add the modified DataSeries to the new SeriesSet
						newSS.get('series').push(dataSeries);
					}, this); // done looper over DataSeries for this bucket

					// check if this series is already in ssArray
					if (!condensable || ssArray.length === 0) {
						ssArray.push(newSS);
					}
				}, this); // done looping over this bucket

				// now resolve with the new SeriesSet
				dfd.resolve(ssArray);
			}, this));
		} // end non-nested logic

		return dfd;
	}
}));