/* global require, module */

var _ = require('underscore');

/**
Set of handy functions for working with SeriesSet data.

@class SeriesSetUtilities
@static
@module Models
*/
var SeriesSetUtilities = {
	/**
	Reads the list of unique participants from a DataPoint
	@method getParts
	@static
	@param {DataPoint} datapoint
	@return Array<Number> All the participant IDs in the DataPoint
	*/
	getParts: function(dataPoint) {
		return _.chain(dataPoint.responses).pluck('part_id').uniq().value();
	},

	/**
	@method getIncludedPartIDs
	@param {DataPoint} dataPoint
	@return Array<Number> The unique list of included participants for the DataPoint
	*/
	getIncludedPartIDs: function(dataPoint) {
		var partIDs = dataPoint.responses.reduce(function(memo, r){
			if (!r.is_excluded) memo.push(r.part_id);
			return memo;
		}, []);

		return _.uniq(partIDs);
	},

	/**
	@method getSSIncludedPartIDs
	@param {SeriesSet} seriesSet
	@return Array<Number> A unique list of the included participant IDs for the SeriesSet
	*/
	getSSIncludedPartIDs: function(seriesSet) {
		var parts = seriesSet.get('series').map(function(ds){
			return ds.map(function(dp){
				return SeriesSetUtilities.getIncludedPartIDs(dp);
			});
		});

		return _.chain(parts).flatten().uniq().value();
	},

	/**
	Reads the list of unique participant IDs that were included and had useful responses.
	@method getNonMetaPartIDs
	@param {DataPoint} dataPoint
	@return Array<Number> All the participant IDs that were included and had is_empty=FALSE and
		is_response=TRUE responses
	*/
	getNonMetaPartIDs: function(dataPoint) {
		return _.chain(dataPoint.responses)
			.filter(function(r){ return r.is_response && !r.is_empty && !r.is_excluded; })
			.pluck('part_id')
			.uniq()
			.value();
	},

	/**
	Get an array of the non-empty response values from included participants.
	@method getRs
	@static
	@param {DataPoint} dataPoint
	@return Array<Number|String> Returns an array of all the responses in the DataPoint
	*/
	getRs: function(dataPoint) {
		return dataPoint.responses.reduce(function(memo, r){
			if (!r.is_empty && r.is_response && !r.is_excluded && !_.isUndefined(r.response)) memo.push(r.response);
			return memo;
		}, []);
	},

	/**
	Get the number of presentations for the given dataPoint
	@method getPresentations
	@param {DataPoint} dataPoint
	@return Number The number of presentations
	*/
	getPresentations: function(dataPoint) {
		var parts = {};
		return dataPoint.responses.reduce(function(memo, r) {
			if (!r.is_excluded && r.is_response && !(r.part_id in parts)) {
				memo++;
				parts[r.part_id] = true;
			}
			return memo;
		}, 0);
	}
};

module.exports = SeriesSetUtilities;