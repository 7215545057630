/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		d3 = require('d3'),
		st = require('../st.js'),

		SSUtils  = require('./SeriesSetUtilities.js'),
		Calculator = require('./Calculator.js');

/**
@class MeanCalculator
@constructor
@extends Calculator
@module Models
*/
module.exports = Calculator.extend({
	defaults: function() {
		return _.extend(Calculator.prototype.defaults(), {type: 'mean', name: 'Mean'});
	},


	/**
	Compute the single graph value for a DataPoint
	@method computeValue
	@param {DataPoint} dp
	*/
	computeValue: function(dp) {
		var raw = SSUtils.getRs(dp);
		return st.mean(raw);
	},

	/**
	Figure out the correct value to use as the minimum in the graph axis based on a SeriesSet. The
	smallest value from any DataPoint will be returned, if no DataPoint has a finite min set it will
	return `null`.
	@method computeMin
	@param {SeriesSet} ss
	@return Number|null
	*/
	computeMin: function(ss) {
		var mins = [];
		ss.get('series').forEach(function(dataSeries) {
			dataSeries.data.forEach(function(dataPoint) {
				if (_.isFinite(dataPoint.min)) mins.push(dataPoint.min);
			});
		});

		if (mins.length > 0){ return _.min(mins); } else { return null; }
	},

	/**
	Figure out the correct value to use as the maximum in the graph axis based on a SeriesSet. The
	largest value from any DataPoint will be returned, if no DataPoint has a finite max set it will
	return `null`
	@method computeMax
	@param {SeriesSet}
	@return Number|null
	*/
	computeMax: function(ss) {
		var maxes = [];
		ss.get('series').forEach(function(dataSeries) {
			dataSeries.data.forEach(function(dataPoint) {
				if (_.isFinite(dataPoint.max)) maxes.push(dataPoint.max);
			});
		});

		if (maxes.length > 0){ return _.max(maxes); } else { return null; }
	},

	/**
	Figure out the best format to display the given array of values
	@method getFormatString
	@param {Array<Number>} values
	@return String a D3.js format string
	*/
	getFormatString: function(values) {
		if (values.length < 1) throw new Error('Must pass at least one value');

		if (_.min(values) > 50) {
			return ',.0f';
		} else {
			return '.1f';
		}
	}
});