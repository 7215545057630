/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		app = require('../app.js'),
		ProfileView             = require('./ProfileView.js'),
		LicenseView             = require('./LicenseView.js'),
		PermissionsView             = require('./PermissionsView.js'),
		AlertView               = require('./AlertView.js');

module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',
	className: 'container',

	tpl: _.template( $('#AccountPageView-tpl').html() ),

	events: {
		'click .profile'        : 'profile',
		'click .license'        : 'license',
		'click .permissions'    : 'permissions',
	},

	initialize: function(opts) {
		this._navigatedViews = [];
		this.$featureMount = null;

	},

	render: function() {
		document.title = 'My Account';
		this.$el.html(this.tpl);
		// mount the page
		app.$mount.html(this.el);

		$('.account-name').html(app.user.get('first_name') + ' ' + app.user.get('last_name'));
		this.profile();
	},

	profile: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.changeViewTo('profile');
		this.startNavigationWith(new ProfileView());
		this.activeViewName = 'profile';
	},

	license: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.changeViewTo('license');
		this.startNavigationWith(new LicenseView());
		this.activeViewName = 'license';
	},

	permissions: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.changeViewTo('permissions');
		this.startNavigationWith(new PermissionsView());
		this.activeViewName = 'permissions';
	},

	changeViewTo: function(toThis) {

		// switch the active item in the nav
		this.$('a.list-group-item.active').removeClass('active');
		$('.' + toThis).addClass('active');

		// close the current view
		//this.closeNavigationStack();

	},

	startNavigationWith: function(view) {
		this._navigatedViews.push({v:view, top:0});
		this.listenTo(view, 'navigateinto', this.navigateIntoView);
		console.log(view.render().el,this.featureMount);
		$('#accountMount').html(view.render().el);
	},

	close: function() {
		_.each(this.colViews, function(c) {
			c.close();
		});
		this.stopListening();
		this.remove();
	},

});