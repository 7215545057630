/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
 * Represents a single response from a participant. No further information is given (i.e., the
 * viewer can't drill down any further than this).
 *
 * @class ResponseItemView
 */
module.exports = Backbone.View.extend({
	//row HTML tag name
	tagName: 'tr',

	//additional HTML classes to add
	className: 'q-row',

	//standard template for this view
	tpl: $('#ResponseItemView-tpl').html(),

	events: {
		//subscribe to no events
	},

	initialize: function(opts) {
		this.prs = opts.prs;
		this.qs  = opts.qs;
		this.listenTo(this.model, 'change', this.render);
	},

	/**
	 * Standard rendering behavior, loads Mustache template for this response.
	 *
	 * @method render
	 * @return {Object} this object, for passing up the renderstack
	 */
	render: function() {
		var view = this.model.toTpl();
		view.prompt = this.model.get('name');

		var pr = this.prs.filter(function(pr1) {
			return +pr1.id == +this.model.get('possible_response_id');
		}, this)[0];

		var q = this.qs.filter(function(q1) {
			return +q1.id == +pr.get('question_id');
		}, this)[0];

		//if not is_response return not seen
		//if has_text
		//  if no text return left blank
		//  else return text
		//if has_value
		//  if value = 99999 return left blank
		//  else return value

		if(q && pr){
			view.type = app.qTypeHash[+q.get('type_id')];
			view.prompt = q.get('name');

			if(pr.get('is_response') == '0') {
				view.response = 'Not seen';
			} else {
				if(pr.get('has_text') == '1') {
					view.response = this.model.get('text');
					if(view.response === '') {
						view.response = '[left blank]';
					}
				}
				if(pr.get('has_value') == '1') {
					view.response = +this.model.get('value');
					if(view.response === 99999) {
						view.response = '[left blank]';
					}
				}
				else {
					view.response = pr.get('response');
					if(pr.get('is_empty') == '1') {
						view.response = '[left blank]';
					}
				}
			}

			if(view.response == 'Not seen') {
				this.$el.addClass('hidden'); //hide responses that were not seen by the user
			}
		}else{
			this.$el.addClass('hidden'); //hide responses that were not seen by the user
		}

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	/**
	 * Standard behavior Closes (destroys, deletes) this view.
	 *
	 * @method close
	 */
	close: function() {
		this.stopListening();
		this.remove();
	},

});