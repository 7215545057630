/* global require, module */

var Backbone = require('backbone'),
	SUPRQWebsite = require('../models/SUPRQWebsite.js');

module.exports = Backbone.Collection.extend({
	model: function(attrs, opts) {
		return new SUPRQWebsite(attrs, opts);
	},

	url: function() {
		return '/api1/suprqwebsites/all/';
		
	},

	initialize: function(models, options) {
	},

	parse: function(res) {
		console.log(res);
		if (res.data) return res.data;
		return res;
	}
});