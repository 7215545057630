/* global require, module */

var Backbone = require('backbone'),
		Response = require('../models/Response.js');

module.exports = Backbone.Collection.extend({
	model: Response,
	url: function() {
		if (this.qID)        return '/api1/response/all_for_question/' + this.qID;
		if (this.partID)     return '/api1/response/all_for_participant/' + this.partID;
		if (this.compID)     return '/api1/response/all_for_comparison/' + this.compID;
		if (this.smartQID)   return '/api1/response/all_for_smart_question/' + this.smartQID;
		if (this.projID)     return '/api1/response/all_for_project/' + this.projID;
		if (this.analyticID) return '/api1/response/all_for_analytic/' + this.analyticID;
	},

	initialize: function(models, opts) {
		this.qID        = opts.qID || null;
		this.projID     = opts.projID || null;
		this.partID     = (opts.participantID || opts.partID) || null;
		this.compID     = opts.compID || null;
		this.smartQID   = opts.smartQID || null;
		this.analyticID = opts.analyticID || null;
	},

	parse: function(res) {
		return res.data;
	}
});