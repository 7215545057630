/* global module, require */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		d3 = require('d3'),
		app = require('../app.js'),
		st = require('../st.js');

/**
Draws a slanted table and handles the highlighting of interesting cells. Pass data in according to
the format:

	[
		[null, 'Label one', 'label two', ...],
		['label one', null, 0.987, ...],
		...
	]

Note that you can pass in either a value or an object of the form `{value:value, markAs:String}`
that can mark individual cells as `'mildy'` or `'really'` interesting. Behind the scenes every cell
gets converted to this, except the header row and column.

@class SlantedTableView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({

	tagName: 'div',
	className: 'SlantedTable',

	events: {
		'mouseenter .SlantedTable__cell': 'enterCell',
		'mouseleave .SlantedTable__cell': 'leaveCell'
	},

	/**
	@property tpl
	@type String
	@default Skeleton dom structure
	@final
	*/
	tpl: $('#SlantedTableView-tpl').html(),

	/**
	@property headerTpl
	@type String
	@default li for header items
	@final
	*/
	headerTpl: $('#SlantedTableView-header-item-tpl').html(),

	/**
	@property rowTpl
	@type String
	@default Skeleton container for a row
	@final
	*/
	rowTpl: $('#SlantedTableView-row-tpl').html(),

	/**
	@property cellTpl
	@type String
	@default Little li wrapper for a cell
	@final
	*/
	cellsTpl: $('#SlantedTableView-cells-tpl').html(),

	/**
	Takes the passed in data and processes it to the standard format.
	@method initialize
	@param {Object} opts Hash of options, only the data element is required now
	*/
	initialize: function(opts) {
		var f = d3.format('0>4.3f');
		this.data = _.map(opts.data, function(row, index){
			// leave the header row alone
			if (index === 0) return row;

			return _.map(row, function(cell, index) {
				// leave the label cells alone
				if (index === 0) return cell;

				// replace null values
				cell = cell ? cell : '-';

				// format numbers
				if (_.isObject(cell)) {
					cell.value = st.lowBound(cell.value, 0.001);
					cell.value = f(cell.value);
				} else if (_.isFinite(cell)) {
					cell = st.lowBound(cell, 0.001);
					cell = f(cell);
				}

				return _.isObject(cell) ? cell : {value:cell, markAs:false};
			});
		});
	},

	/**
	Sets up the page structure and draws the data into it.
	@method render
	@chainable
	*/
	render: function() {
		// fill in the skeleton
		var width = ((this.data.length - 1) * 50) + 160;
		this.$el.html(Mustache.render(this.tpl, {width:width}, app.partials()));

		// build the header from the first row of data
		var $headerFrag = $(document.createDocumentFragment());
		_.chain(this.data[0])
			.rest()
			.each(function(label) {
				$headerFrag.append(Mustache.render(this.headerTpl, {label:label}, app.partials()));
			}, this);

		// build the rows from the remaining data
		var $rowsFrag = $(document.createDocumentFragment());
		_.chain(this.data)
			.rest()
			.each(function(row) {
				var $rowFrag = $(document.createDocumentFragment());
				var data = {
					label: _.first(row),
					cells: _.rest(row)
				};
				$rowFrag.append(Mustache.render(this.rowTpl, data, app.partials({cells:this.cellsTpl})));
				$rowsFrag.append($rowFrag);
			}, this);

		this.$('.SlantedTable__header').html($headerFrag);
		this.$('.SlantedTable__rows').html($rowsFrag);
		return this;
	},

	/**
	Handler for `mouseenter` event, highlights row/col
	@method enterCell
	@param {event} e
	*/
	enterCell: function(e) {
		var $cell = $(e.target);

		// mark the target cell as hovered
		$cell.addClass('SlantedTable__cell--hover');

		// mark the row as highlighted
		$cell.prevAll('.SlantedTable__cell').addClass('SlantedTable__cell--highlight');

		// mark the column as highlighted
		var hoverIndex = $cell.index()+1;
		$cell.parents('.SlantedTable__row')
			.prevAll()
			.find('.SlantedTable__cell:nth-of-type('+hoverIndex+')')
			.not('.SlantedTable__cell--hover')
			.addClass('SlantedTable__cell--highlight');

	},

	/**
	Handler for `mouseleave` event, removes corresponding highlight
	@method leaveCell
	@param {event} e
	*/
	leaveCell: function(e) {
		var $cell = $(e.target);

		// unmark this cell
		$cell.removeClass('SlantedTable__cell--hover');

		// unmark it's siblings
		$cell.siblings('.SlantedTable__cell').removeClass('SlantedTable__cell--highlight');

		// unmark the column
		var hoverIndex = $cell.index();
		this.$('.SlantedTable__cell')
			.filter(function(i, el){ return $(el).index() == hoverIndex; })
			.removeClass('SlantedTable__cell--highlight');
	}
});