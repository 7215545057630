/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		app = require('../app.js');

module.exports = Backbone.View.extend({
	tagName: 'div',
	className: 'panel panel-default',
	tpl: _.template($('#DownloadView-tpl').html()),
	itemTpl: _.template($('#DownloadView-item-tpl').html()),

	events: {
		'click .download-file': 'download',
		'click .clear': 'close'
	},

	initialize: function(opts) {
		this.items = [];

		if (opts.hasOwnProperty('sheet')) {
			this.items.push(opts.sheet);
		}
	},

	render: function() {
		this.$el.html(this.tpl({}));
		this.addAllItems();
		return this;
	},

	close: function() {
		app.ge.trigger('clearexcel');
		this.items = [];
		this.remove();
	},

	addItem: function(item) {
		this.items.push(item);
		this.$('.item-mount').append(this.itemTpl(item));
	},

	addAllItems: function(items) {
		if (_.isArray(items)) {
			this.items = this.items.concat(items);
		}
		var frag = document.createDocumentFragment();
		_.each(this.items, function(item) {
			$(frag).append(this.itemTpl(item));
		}, this);
		this.$('.item-mount').append(frag);
	},

	download: function() {
		var d = this.items;
		$.ajax({
			url: '/api1/create_file',
			method: 'POST',
			data: JSON.stringify(d),
			success: function(xhr, res, three) {
				window.location.href = '/api1/download/' + xhr.filename;
			},
			error: function(xhr, data) {
				console.log(xhr);
				console.log(data);
			},
		});
	}
});
