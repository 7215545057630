/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery');

/**
@class SUPRQFullAnalytic
@constructor
@extends Analytic
@module Models
*/
module.exports = Backbone.Model.extend({
	url: function() {
		if (this.analyticID) return '/api1/response/all_for_analytic/' + this.analyticID;
	},
	initialize: function(models, opts) {
		this.analyticID = this.get('analyticID') || null;
	},
	defaults: {
		name                   : '',
		type                   : 'sus',
		project_id             : null,

		q1_id                  : null,
		q2_id                  : null,
		q3_id                  : null,
		q4_id                  : null,
		q5_id                  : null,
		q6_id                  : null,
		q7_id                  : null,
		q8_id                  : null,
		q9_id                  : null,
		q10_id                 : null,

		overall_q_id           : null,
		usability_q_id         : null,
		learnability_q_id      : null,
		subscale_comparison_id : null,

		description            : '',
		is_positive            : 1
	}
});