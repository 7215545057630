/* global require, module */

var ListItemView = require('./ListItemView.js'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		AlertView = require('./AlertView'),
		app = require('../app.js');

/**
Implementation of ListItemView for rendering a Question model as a tr. Overrides the selectedClass
string to match bootstrap requirements.
@class QuestionItemView
@constructor
@extends ListItemView
@module Views
*/
module.exports = ListItemView.extend({
	className: 'q-row QuestionItemRow',

	tpl: $('#QuestionItemView-tpl').html(),

	events: {
		'click': 'clicked'
	},

	/**
	Calls super, then overrides the selectedClass and listens for changes to the model
	@method initialize
	*/
	initialize: function() {
		ListItemView.prototype.initialize.apply(this, arguments);

		/**
		Override the default selected class to fit with bootstrap
		@property selectedClass
		@type string
		@default 'info'
		*/
		this.selectedClass = 'info';

		/**
		Override the super class to allow row hiding
		@property hidableRows
		@type boolean
		@default true
		*/
		this.hidableRows = true;

		this.listenTo(this.model, 'change', this.render);
	},

	/**
	Renders the question as a row in the ListView, turns the type_id into an icon for type.
	@method render
	@chainable
	*/
	render: function() {
		var view = this.model.toTpl();
		view.is_categorical = this.model.get('type_id') == 1;
		view.is_rating = [2, 3, 5].indexOf(+this.model.get('type_id')) != -1;
		view.is_open_ended = this.model.get('type_id') == 4;
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		if (+this.model.get('is_hidden')) {
			this.$el.toggleClass('active GT--display-none', true);
		} else {
			this.$el.toggleClass('active GT--display-none', false);
		}
		return this;
	},

	/**
	Handles click events, triggering the 'pick' event.
	@method clicked
	@param {event} e The initiating event
	*/
	clicked: function(e) {
		this.trigger('pick', this.model, this, {shiftKey: e.shiftKey});
	},

	/**
	Override parent method to handle the model
	@method show
	*/
	show: function(){ this.model.save('is_hidden', false, {
			error: function(mod, res) {
				this.model.set('is_hidden', true);
				app.ge.trigger('alert', new AlertView({
					m: 'Problem updating question: '+res.responseJSON.m,
					type: 'alert-danger'
				}));
			}.bind(this)
		});
	},

	/**
	Override the parent method to handle the model
	@method hide
	*/
	hide: function(){ this.model.save('is_hidden', true, {
			error: function(mod, res) {
				this.model.set('is_hidden', false);
				app.ge.trigger('alert', new AlertView({
					m: 'Problem updating question: '+res.responseJSON.m,
					type: 'alert-danger'
				}));
			}.bind(this)
		});
	}
});