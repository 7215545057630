/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		app = require('../app.js'),
		AlertView               = require('./AlertView.js');

module.exports = Backbone.View.extend({
	tagName: 'div',

	tpl: $('#AddUserView-tpl').html(),

	initialize: function(opts) {
	},

	render: function() {
		var view = {};
		view.username = app.user.get('username');
		this.$el.html(this.tpl);
		console.log(this.$el);
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

});