/* global require, module */

var Backbone = require('backbone'),
		Mustache = require('mustache'),
		_ = require('underscore'),
		$ = require('jquery'),
		app = require('../app.js');

/**
This class encapsulates the basic functionality around rendering and interacting with a list of
of items. See the README for an overview of how the system works.

This class must be subclassed as it only provides the basic scaffolding for the view. It's `el` is
a `panel` tag which contains a table and it manages rendering a header into the `thead` and list
items into the `tbody`. By default it doesn't expect a panel header, but if you override the tplData
property and set `has_header` to true it will render a GT_nav_panel with data from tplData at the
top.

Some things you might change in your subclass:

	- The className of the `el` (use a function that extends super)
	- The initialize method to set up the collection you'll display, fetch it, handle errors, etc.
	(you should use ListView.prototype.initialize.apply to call the super constructor)
	- The has_header property in the tplData

There's some built in functionality the ListView provides including handling selections of items
and showing/hiding items.

**Selecting Items**<br />
You can call the `enableSelection` method and the list view will start using the 'pick' event on
child views to maintain a list of selected items (note that any custom handlers set by
`registerPickHandler` will be ignored in this mode). If an item is already select a second 'pick'
will deselect it. It also support shift+clicking to select several adjacent items. It calls the
`select` and `deselect` methods on the child views so they can define the visual aspects of those
states.

At any point you can call `getCurrentSelection` to get an array of the selected models and an array
of the selected views. Calling `disableSelection` will return the same thing but also will restore
every child view to deselected state, clear the internal selection storage, and stop intercepting
'pick' events.

**Showing/Hiding Items**<br />
You can also opt into the ability to show/hide items by including the clickable items with the
'show-hidden' and 'hide-items' classes. 'hide-items' will enable selection and call the `hide`
method on each child when exiting the mode. `show-hidden` will toggle the visibility of current
children and enable selection for unhiding items.

@class ListView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({

	/**
	@property tagName
	@type string
	@default 'table'
	*/
	tagName: 'div',

	/**
	@property className
	@type string
	@default 'panel panel-default'
	*/
	className: 'panel panel-default',

	tpl: $('#ListView-tpl').html(),
	emptyTpl: $('#ListView-empty-tpl').html(),

	/**
	A function returning a basic set of events.
	@property events
	@type ()=>object
	*/
	events: function() {
		return {
			'click .cancel-selection': 'cancelSelection',

			'click .show-hidden': 'showHidden',
			'click .hide-hidden': 'hideHidden',
			'click .hide-items': 'startHiding',
			'click .hide-selected': 'hideSelected',
			'click .unhide-selected': 'unhideSelected'
		};
	},

	/**
	Sets up the ListView. If any existing ListItemViews are set they will be rendered during the call
	to `render`, otherwise it will leave a loading element in the `tbody`. A ListHeaderView can
	optionally be set, if it's not the `thead` will just be left empty.

	@method initialize
	@param {object} opts A hash of options to set. Right now you can bootstrap it by passing in an
		array of existing ListItemViews under the key 'itemViews' and you can set the ListHeaderView
		with the key 'headerView'.
	*/
	initialize: function(opts) {
		/**
		Array containing all the child views
		@property itemViews
		@type array<ListItemView>
		@default []
		*/
		this.itemViews = (opts.itemViews) || [];

		/**
		The view to draw in the thead
		@property headerView
		@type ListHeaderView|null
		@default null
		*/
		this.headerView = null;

		/**
		Whether to allow selection of contained ListItemViews
		@property selectable
		@type boolean
		@readOnly
		*/
		this.selectable = false;

		/**
		Storage for the models of currently selected child views.
		@property _selectedModels
		@type array<Backbone.Model>
		@private
		*/
		this._selectedModels = [];

		/**
		Storage for the currently selected child views
		@property _selectedViews
		@type array<ListItemView>
		@private
		*/
		this._selectedViews = [];

		/**
		Index of the most recently selected child view in the `this.itemViews` array. Useful for
		multi-select.
		@property _lastSelectedIndex
		@type number|null
		@default null
		@private
		*/
		this._lastSelectedIndex = null;

		/**
		Collection of handlers to call when a child has been 'pick'ed
		@property _pickHandlers
		@type array<function>
		@private
		*/
		this._pickHandlers = [];

		/**
		@property tplData
		@type {object}
		@default {has_header: false, hoverable: false, cursorable: false}
		*/
		this.tplData = {has_header: false, hoverable: false, cursorable: false};
	},

	/**
	Close all child views, clean up references to other objects that might cause things to persist
	longer than necessary.
	@method close
	*/
	close: function() {
		this.unregisterAll();
		_.each(this.itemViews, function(iview){ iview.close(); });
		this._selectedModels = [];
		this._selectedViews = [];
		this.remove();
	},

	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Various rendering methods

	/**
	Draws the basic page skeleton into the page. If a ListHeaderView is set it's `el` will be rendered
	and inserted into the `thead`. If there are ListItemViews in this.itemViews they will be rendered
	as well.
	@method render
	@chainable
	*/
	render: function() {
		this.$el.toggleClass(this.className, true);
		this.$el.html(Mustache.render(this.tpl, this.tplData, app.partials()));
		if (this.headerView) this.$('thead').html(this.headerView.render().el);
		if (this.itemViews.length > 0) this.renderItems();
		return this;
	},

	/**
	Render all the ListItemViews to a fragment and insert them into the `tbody`. Puts the `emptyTpl`
	in if there aren't any views, which by default just says 'No Items'.
	@method renderItems
	*/
	renderItems: function() {
		if (this.itemViews.length > 0) {
			this.$('tbody').html(app.renderViewsToFrag(this.itemViews));
		} else {
			this.$('tbody').html(Mustache.render(this.emptyTpl, {}, app.partials()));
		}
	},

	/**
	If the list has existing items, just render the last one in the array and append it to the page,
	otherwise it will just delegate to renderItems.
	@method renderNewestItem
	*/
	renderNewestItem: function() {
		if (this.itemViews.length > 0) {
			this.$('tbody').append(_.last(this.itemViews).render().el);
		} else {
			this.renderItems();
		}
	},

	/**
	Re-render the panel-heading with the default controls and title specified in this.tplData
	@method restoreHeader
	*/
	restoreHeader: function(){ this.renderHeader(this.tplData); },

	/**
	@method renderHeader
	@param {object} headerData An object with keys that match the GT_nav_panel partial
	*/
	renderHeader: function(headerData) {
		if (!this.tplData.has_header) throw new Error('Cannot render header if no header set');

		this.$('.panel-heading')
			.replaceWith(Mustache.render('{{> GT_nav_panel}}', headerData, app.partials()));
	},

	/**
	Basic handler that disabled selection and restores the header
	@method cancelSelection
	@param {Event} [e] The initiating event
	*/
	cancelSelection: function(e) {
		if (e) e.preventDefault();

		this.disableSelection();
		this.restoreHeader();
	},



	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Manage the child views

	/**
	Populate the list with the provided array of ListItemViews. Starts listening to the 'pick' event
	on each child with the `pickedChild` handler. Calls `renderItems` at the end to draw them into
	the page.
	@method populate
	@param {array<ListItemViews>} children The array of ListItemViews to populate with
	*/
	populate: function(children) {
		_.each(children, function(child) {
			this.listenTo(child, 'pick', this._pickedChild);
			this.itemViews.push(child);
		}, this);
		this.renderItems();
	},

	/**
	Add a view to the internal list and render it
	@method addView
	@param {ListItemView} view
	*/
	addView: function(view) {
		this.listenTo(view, 'pick', this._pickedChild);
		this.itemViews.push(view);
		this.renderNewestItem();
	},


	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Picking/Selection handlers

	/**
	Initial handler for the 'pick' event on children. Properly dispatches the event based on the
	current ListView settings.
	@method _pickedChild
	@param {Backbone.Model} model The model of the picked child
	@param {ListItemView} view The picked view
	@param {object} props Additional information about the 'pick' event.
	@private
	*/
	_pickedChild: function(model, view, props) {
		if (this.selectable) {
			this._selectedChild(model, view, props);
		} else {
			_.each(this._pickHandlers, function(handler) {
				handler.bind(null)(model, view, props);
			});
		}
	},

	/**
	Handles 'pick' events when the ListView has selection enabled
	@method _selectedChild
	@param {Backbone.Model} model The selected model
	@param {ListItemView} view The selected view
	@param {object} props The properties of the 'pick' event
	@private
	*/
	_selectedChild: function(model, view, props) {
		if (!this.selectable) throw new Error('Tried to select element when not selectable');

		var selectedIndex = _.indexOf(this.itemViews, view);
		if (selectedIndex < 0) throw new Error('Tried to select element not in ListView.itemViews');

		// handle the basic case of a single select/deselect
		if (!view.isSelected) {
			this._selectedModels.push(model);
			this._selectedViews.push(view);
			view.select();
		} else {
			this._selectedModels = _.without(this._selectedModels, model);
			this._selectedViews = _.without(this._selectedViews, view);
			view.deselect();
		}

		// if the view wasn't already selected, we have a previous index, the shift key was down, and
		// the new item isn't the same as the previous item this is a multi-select
		if (
			view.isSelected &&
			!_.isNull(this._lastSelectedIndex) &&
			props.shiftKey &&
			selectedIndex != this._lastSelectedIndex
		) {
			this._handleMultiSelect(selectedIndex);
		}

		this._lastSelectedIndex = selectedIndex;
	},

	/**
	Conducts a multi-select with the given selectedIndex and this._lastSelectedIndex. Does nothing
	with the most recently selected view.
	@method _handleMultiSelect
	@param {number} selectedIndex The index of the most recent selection
	@private
	*/
	_handleMultiSelect: function(selectedIndex) {
		// build the range that needs to be selected
		var start, stop;
		if (selectedIndex > this._lastSelectedIndex) {
			// going previous -> recent DOWN the page
			start = this._lastSelectedIndex+1;
			stop = selectedIndex;
		} else {
			// going recent -> previous DOWN the page
			start = selectedIndex+1;
			stop = this._lastSelectedIndex;
		}

		_.map(_.range(start, stop), function(index) {
			this._selectedModels.push(this.itemViews[index].model);
			this._selectedViews.push(this.itemViews[index]);
			this.itemViews[index].select();
		}, this);
	},

	/**
	Add a handler to the list of functions to call upon a pick event. Note that the handler has it's
	`this` value set to null, so bind the value you want to the function before registering it if
	that's a dependency.
	@method registerPickHandler
	@param {function} fn The handler
	*/
	registerPickHandler: function(fn){ this._pickHandlers.push(fn); },

	/**
	Remove all registered handlers. I think this needs to be called to prevent zombie views.
	@method unregisterAll
	*/
	unregisterAll: function(){ this._pickHandlers = []; },

	/**
	The ListView will mark child view as selected/deselected as appropriate and maintain an
	(unordered) list of the models associated with currently selected views. Disables all other
	handlers for the 'pick' event.
	@method enableSelection
	*/
	enableSelection: function(){ this.selectable = true; },

	/**
	Restore all child views to their delected state and return the array of currently selected models
	and views. The internal storage arrays are then reset to empty.
	@method disableSelection
	@return array<array<Backbone.Model>, array<ListItemView>>
	*/
	disableSelection: function() {
		this.selectable = false;
		_.each(this.itemViews, function(child){ child.deselect(); });
		var copy = this._selectedModels.slice();
		var viewCopy = this._selectedViews.slice();
		this._selectedModels = [];
		this._selectedViews = [];
		return [copy, viewCopy];
	},

	/**
	Returns an array of currently selected models and views
	@method getCurrentSelection
	@return array<array<Backbone.Model>, array<ListItemView>>
	*/
	getCurrentSelection: function(){ return [this._selectedModels.slice(), this._selectedViews.slice()]; },


	//////////////////////////////////////////////////////////////////////////////////////////////////
	// showing/hiding methods

	/**
	Render a header with instructions/UI for unhiding questions, toggle visibility, and enable
	selection.
	@method showHidden
	@param {Event} [e] The initiating event
	*/
	showHidden: function(e) {
		if (e) e.preventDefault();
		this.itemViews.forEach(function(view){ view.toggleVisibility(); });

		this.renderHeader({
			title: 'Select the items you wish to unhide',
			panel_controls: [{
				loner      : true,
				label      : 'Cancel',
				class_name : 'hide-hidden',
				btn_type   : 'link'
			}, {
				loner      : true,
				label      : 'Unhide selected items',
				class_name : 'unhide-selected',
				btn_type   : 'primary'
			}]
		});
		this.enableSelection();
	},

	/**
	Stop selection, restore the header and go back to showing visible items.
	@method hideHidden
	@param {Event} [e] The initiating event
	*/
	hideHidden: function(e) {
		if (e) e.preventDefault();

		this.disableSelection();
		this.itemViews.forEach(function(view){ view.toggleVisibility(); });
		this.restoreHeader();
	},

	/**
	Enables selection and renders the instructions and UI for hiding in the header
	@method startHiding
	@param {Event} [e] The initiating event
	*/
	startHiding: function(e) {
		if (e) e.preventDefault();

		this.enableSelection();
		this.renderHeader({
			title: 'Select the questions you wish to hide',
			panel_controls: [
				{loner: {label: 'Cancel', btn_type: 'link', class_name: 'cancel-selection'}},
				{loner: {label: 'Hide selected', btn_type: 'primary', class_name: 'hide-selected'}}
			]
		});
	},

	/**
	Hides the currently selected models by calling the `hide()` method on each selected child view.
	Also closes the hide mode.
	@method hideSelected
	@param {Event} [e] The initiating event
	*/
	hideSelected: function(e) {
		if (e) e.preventDefault();

		var selection = this.disableSelection();
		selection[1].forEach(function(view){ view.hide(); });
		this.cancelSelection();
	},

	/**
	Stops selection, goes back to displaying the visible questions, and calls `show()` on the selected
	child views.
	@method unhideSelected
	@param {Event} [e] The initiating event
	*/
	unhideSelected: function(e) {
		if (e) e.preventDefault();

		var selection = this.disableSelection();
		this.hideHidden();
		selection[1].forEach(function(view){ view.show(); });
		this.cancelSelection();
	}

});