/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		CalcMixin = require('../mixins/CalculatorMixin.js'),

		app = require('../app.js');

module.exports = Backbone.Model.extend(
	_.extend({}, CalcMixin, {
	urlRoot: '/api1/question',

	defaults: function() {
		return {
			name               : 'Untitled Question',
			prompt             : '',
			type_id            : null,
			source             : null,
			source_id          : null,
			native_id          : null,
			project_id         : null,
			sample_size_method : 3,
			is_hidden          : false,
			num_participants   : null,
			num_presentations  : null,
			num_responses      : null,
			calc_type          : null,
			ci_calc_type       : null
		};
	},

	getters: {
		sample_size: function() {
			return this.get(app.sampleMethodHash[+this.get('sample_size_method')].key);
		},

		type_name: function() {
			return app.qTypeHash[+this.get('type_id')];
		}
	}
}));