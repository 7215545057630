/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),

		AlertView = require('./AlertView.js'),
		SourcePossibleResponsesTableView = require('./SourcePossibleResponsesTableView.js'),
		CombineQuestionsListView = require('./CombineQuestionsListView.js'),
		SourcePossibleResponses = require('../collections/SourcePossibleResponses.js'),

		app = require('../app.js');

module.exports = Backbone.View.extend({

	tagName: 'div',

	tpl            : $('#SourceQuestionView-tpl').html(),
	extraTpl       : $('#SourceQuestionView-extra-tpl').html(),
	combineWithTpl : $('#SourceQuestionView-CombineWith-tpl').html(),

	events: {
		'click .back': 'navigateBack',

		'click .save-q': 'saveQ',
		'click .combine-q': 'combineQ',
		'click .close': 'clickedToRemove',

		'change .q-type': 'changeType'
	},

	initialize: function(opts) {
		this.srsPrViews = [];
		this.extraQs    = [];

		this.title      = 'Edit & Save Source Question';
		this.prevTitle  = 'Back';

		this.typeLabels = {
			1: 'Possible responses to this question:',
			2: 'Valid points on the scale:',
			3: 'A sampling of participant times:',
			4: 'This question has open ended responses'
		};
	},

	render: function() {
		var view = this.model.toJSON();
		view = {
			title          : this.title,
			prevTitle      : this.prevTitle,

			source_id      : this.model.get('id'),

			uz_id          : this.model.get('uz_id'),
			qTypes         : app.qTypeHashMustache,
			prompt         : view.prompt.replace(/&lt;(?:.|\n)*?&gt;/gm, ''),
			panel_controls	: [
				{
					loner      : true,
					class_name : 'combine-q btn-default',
					label      : 'Combine',
				}, {
					loner      : true,
					class_name : 'save-q btn-primary',
					label      : 'Save',
				}
			]
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		var t = new SourcePossibleResponsesTableView({
			collection : new SourcePossibleResponses([], {srcQID : this.model.get('id')}),
			primaryQ   : true,
			qID        : this.model.get('id')
		});
		this.srsPrViews.push(t);

		this.listenTo(t, 'all:original', this.markAllOrig);
		this.listenTo(t, 'all:clear', this.clearAll);
		this.listenTo(t, 'typeguess', this.guessAtQType);
		this.$('.primary-question .panel-body').after(t.render().$el);

		return this;
	},

	close: function() {
		_.each(this.srsPrViews, function(table) {
			table.close();
		});
		this.stopListening();
		this.remove();
	},

	getTitle: function() {return this.title;},
	setPrevTitle: function(prev) {this.prevTitle = prev;},
	hide: function() {this.$el.hide();},
	show: function() {this.$el.show();},

	changeType: function(e) {
		var type = parseInt(this.$('.q-type').val(), 10);
		this.$('.responses-label').html(this.typeLabels[type]);

		_.each(this.srsPrViews, function(srcPrView) {
			srcPrView.changeType(type);
		});
		this.type = type;
	},

	markAllOrig: function() {
		_.each(_.rest(this.srsPrViews), function(tv) {
			tv.markAllOrig();
		});
	},

	clearAll: function() {
		if (window.confirm('Are you sure you want to clear all?')) {
			_.each(_.rest(this.srsPrViews), function(tv) {
				tv.clearAll();
			});
		}
	},

	guessAtQType: function(type) {
		this.$('select').val(type);
		this.$('.responses-label').html(this.typeLabels[type]);
		this.type = type;
	},

	combineQ: function() {
		var selectedIDs = _.map(this.extraQs, function(q) {
			return q.get('id');
		});
		var combineView = new CombineQuestionsListView({
			collection: this.collection,
			selectedIDs: selectedIDs,
			primaryQID: this.model.get('id')
		});
		this.listenTo(combineView, 'combochosen', this.integrateCombo);
		this.trigger('navigateinto', combineView);
	},

	integrateCombo: function(allAdditionalIDs) {
		this.extraQs = this.collection.filter(function(q) {
			if (_.contains(allAdditionalIDs, q.get('id'))) {
				return true;
			} else {
				return false;
			}
		});

		this.renderAdditionalQuestions();
	},

	removeAdditionalQuestion: function(id) {
		this.extraQs = _.filter(this.extraQs, function(q) {
			return q.get('id') != id;
		});

		this.srsPrViews = _.filter(this.srsPrViews, function(v) {
			return v.qID != id;
		});

		this.renderAdditionalQuestions();
	},

	clickedToRemove: function(e) {
		e.preventDefault();
		this.removeAdditionalQuestion($(e.target).data().qId);
	},

	renderAdditionalQuestions: function() {
		this.$('.extra-panel').remove();
		_.each(this.extraQs, function(extraQ) {
			var t = new SourcePossibleResponsesTableView({
				qID        : extraQ.get('id'),
				uz_id      : extraQ.get('id'),
				primaryQ   : false,
				type       : this.type,
				collection : new SourcePossibleResponses([], {srcQID: extraQ.get('id')})
			});
			var view = {
				id       : extraQ.get('id'),
				uz_id    : extraQ.get('id'),
				primaryQ : false,
				type     : this.type,
				prompt   : extraQ.get('prompt')
			};
			this.listenTo(t, 'remove', this.removeAdditionalQuestion);
			this.srsPrViews.push(t);
			$(Mustache.render(this.extraTpl, view, app.partials()))
				.insertBefore(this.$('.view-footer'))
				.find('.panel-body')
				.after(t.render().el);
		}, this);
	},

	saveQ: function() {
		// create a new question
		var q = {
			source             : 'uz',
			source_id          : this.model.get('id'),
			name               : $('.q-name').val(),
			prompt             : $('.q-prompt').val(),
			type_id            : $('.q-type option:selected').val(),
			project_id         : this.model.get('project_id'),
			sample_size_method : this.$('.sample-size .active input').val()
		};

		if(q.name.length <= 0) {
			app.ge.trigger('alert', new AlertView({
				m: 'You must insert a name.',
				type: 'alert-warning'
			}));
			return;
		}

		// create a new set of possible responses
		var prs = _.flatten(_.map(this.srsPrViews, function(table) {
			return table.buildCanonical();
		}));

		// make sure all presponses are valid
		if (_.indexOf(prs, false) != -1) {
			app.ge.trigger('alert', new AlertView({
				m: 'You must save or discard all responses',
				type: 'alert-warning'
			}));
			return;
		}
		prs = _.compact(prs);

		var srcQIds = _.map(this.extraQs, function(q) {
			return q.get('id');
		});
		srcQIds.push(this.model.get('id'));

		var data = {
			question: q,
			source_question_ids: srcQIds,
			possible_responses: prs
		};

		app.ge.trigger('alert', new AlertView({
			m    : 'Saving... ',
			type : 'alert-info'
		}));
		this.trigger('navigateback');

		$.ajax({
			url     : '/api/save_questions_with_possible_responses',
			type    : 'POST',
			data    : JSON.stringify(data),
			success : _.bind(function(res, status, xhr) {
				_.each(this.extraQs, function(q) {
					q.set('is_imported', 1);
				});
				this.model.set('is_imported', 1);

				$('.alert').remove();
			}, this),
			error   : _.bind(function(xhr, status, err) {
				console.log(xhr);
				console.log(xhr.responseText);
				console.log(err);
				app.ge.trigger('alert', new AlertView({
					m    : 'Problem saving question: ' + xhr.responseText,
					type : 'alert-danger'
				}), this);
			}, this)
		});
	},

	navigateBack: function(e) {
		if (e) {
			e.preventDefault();
		}
		this.trigger('navigateback');
	}

});