/* global require, module */

var _ = require('underscore'),
		$ = require('jquery'),

		Comparison = require('./Comparison.js'),
		SeriesSet = require('./SeriesSet.js'),
		app = require('../app.js');

/**
@class ContinuousComparison
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Comparison.extend({
	urlRoot: '/api1/comparison',

	getters: {
		fmt_type: function(){ return 'Basic'; },
		data_type: function(){ return 'Continuous'; },
		full_type: function(){ return 'Continuous'; },
	},

	toSeriesSet: function(args) {
		var compName   = this.get('name'),
				compID     = +this.get('id'),

				parts      = args.parts,
				prs        = args.prs,
				rs         = args.rs,

				smartPrs   = args.smartPrs,
				filters    = args.filters,

				qs         = args.qs,
				smartQs    = args.smartQs,

				dfd        = $.Deferred();

		// go through each relation and get the DataPoint we need
		$.when.apply(null, this.get('comparing').map(function(rel) {
			// get the SeriesSet that contains the target DataPoint
			var item, relID = +rel.related_id;
			switch (rel.related_to) {
				case 'possible_response':
					var pr = prs.get(relID), qID = +pr.get('question_id');
					item = qs.get(qID);
					break;

				case 'smart_possible_response':
					var spr = smartPrs.get(relID), sqID = +spr.get('smart_question_id');
					item = smartQs.get(sqID);
					break;

				default: throw new Error('Unexpected relation type');
			}

			return item.toSeriesSet(args);
		}))
		.then(function(/* SeriesSets */) {
			var seriesSets = _.toArray(arguments), dataPoints = [];

			this.get('comparing').forEach(function(rel, index) {
				var ss = seriesSets[index], relID = +rel.related_id;

				if (ss.get('series').length != 1) throw new Error('Expected exactly 1 DataSeries');

				// Double check the DataPoint matches what we'd expect
				var dp = _.find(ss.get('series')[0].data, function(dp) {
					return dp.source_type == rel.related_to && dp.source_id == relID;
				});

				// modify it to fit the SeriesSet we want to return
				dp.label = rel.label;
				dataPoints.push(dp);
			});

			var dataSeries = {
				name : compName,
				seq: null,
				type: 'continuous',
				data: dataPoints
			};

			dfd.resolve(new SeriesSet({
				series : [dataSeries],
				name   : compName,
				type   : 'continuous'
			}));
		}.bind(this));


		return dfd;
	},

	comparing_readable: function(args) {
		var qs       = args.qs;				//questions
		var prs      = args.prs;			//possible responses
		var smartQs  = args.smartQs;	//smart questions
		var smartPrs = args.smartPrs;	//smart [question] possible responses

		var kids   = this.get('comparing');
		var series = { info: '', members: []};

		_.each(kids, function(item) {
			var obj = {};
			if (item.related_to == 'possible_response') {
				series.info = 'This question compares questions';
				var pr = prs.get(item.related_id);
				var q  = qs.get(pr.get('question_id'));

				obj = {
					'response'     : 'Decimal Value',
					'response_raw' : '--',
					'prompt'       : q.get('name'),
					'label'        : item.label
				};
				series.members.push(obj);
			}
			else if (item.related_to == 'smart_possible_response') {
				series.info = 'This question compares smart questions';
				var smartPr = smartPrs.get(item.related_id);
				var smartQ  = smartQs.get(smartPr.get('smart_question_id'));

				obj = {
					'response'     : 'Decimal Value',
					'response_raw' : '--',
					'prompt'       : smartQ.get('name'),
					'label'        : item.label
				};
				series.members.push(obj);
			}
		});

		return series;
	}

});