/* global require, module */

var Backbone = require('backbone'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
A base class for the header of a ListView. You need to subclass this to provide a template (in the
tpl key) for the render method.

@class HeaderView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({

	/**
	@property tagName
	@type string
	@default 'thead'
	*/
	tagName: 'tr',

	/**
	Draws the template into `el`, no model used.
	@method render
	*/
	render: function() {
		this.$el.html(Mustache.render(this.tpl, {}, app.partials()));
		return this;
	}

});