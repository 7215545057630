/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		Collections = require('../collections/Collections.js'),
		Project     = require('../models/Project.js'),
		AlertView   = require('./AlertView.js'),
		app         = require('../app.js');


module.exports = Backbone.View.extend({
	tagName: 'div',
	className: 'container',

	tpl: $('#NewProjectPageView-tpl').html(),
	loadTpl: $('#NewProjectPageView-load-tpl').html(),

	events: {
		'click .save-project': 'saveProject'
	},

	initialize: function(opts) {
		this.target = opts.target;

		this.collections = new Collections();
		this.collections.fetch({
			reset: true,
			success: _.bind(function(col, res, opts) {
				this.fillCollections();
			}, this),
			error: function(col, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem getting collections: ' + res.status,
					type: 'alert-danger'
				}));
			}
		});
	},

	render: function() {
		var view = {};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		$('#mount-point').html(this.el);
		return this;
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	fillCollections: function() {
		this.$('.form-control-static').html(this.collections.get(this.target).get('name'));
	},

	saveProject: function(e) {
		if (e) e.preventDefault();

		// Get the entered form data. Note that we'll have extraneous keys because we can't delete
		// existing keys when we move the other parameters into a project object.
		var fdata = new FormData($('#new-project-form')[0]);

		var projName     = this.$('input[name="project-name"]').val();
		var collectionID = this.target;

		var newProj = new Project({
			name: projName,
			collection_id: collectionID
		});

		fdata.append('project', JSON.stringify(newProj.toTpl()));


		// replace the form with a loading message
		var $section = $('section');

		var view = {
			percent: 0,
			crunching: false
		};

		var tpl = this.loadTpl;
		$section.html(
			Mustache.render(tpl, view, app.partials())
		);

		// do an ajax upload
		$.ajax('/api1/upload/create_from_userzoom', {
			processData: false,
			contentType: false,
			data: fdata,
			type: 'POST',
			xhr: function() {
				var xhr = $.ajaxSettings.xhr();
				xhr.upload.addEventListener('progress', function(e) {
					if (e.lengthComputable) {
						var percent = e.loaded / e.total * 100;
						var progress = Mustache.render(
							tpl,
							{percent: percent, crunching: percent > 99},
							app.partials()
						);
						$section.html(progress);
					}
				}, false);
				return xhr;
			},
			error: function(xhr, textStatus, thrownError) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem uploading the file: ' + xhr.status,
					type: 'alert-danger'
				}));
			},
			success: function(data, textStatus, xhr) {
				app.routes.navigate('project/' + data.data.id, {trigger: true});
			}
		});
	}
});