/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		multiselect = require('multiselect'),
		NavCompMixin      = require('../mixins/NavigationComponentMixin.js'),
		
		Mustache = require('mustache'),
		app = require('../app.js');

/**
 */
module.exports = Backbone.View.extend(
	_.extend({}, NavCompMixin, {
	/**
	 * The standard element tag name
	 *
	 * @property tagName
	 * @type {String}
	 * @default 'div'
	 */
	tagName: 'div',

	/**
	 * The main view template. From html Mustache
	 *
	 * @property tpl
	 * @type {String}
	 */
	tpl: $('#SUPRQWebsitesView-tpl').html(),
	tplColumns: $('#SUPRQWebsitesView-columns-tpl').html(),
	tplValues: $('#SUPRQWebsitesView-values-tpl').html(),

	events: {
		'click .checkbox' : 'filterTable',
	},

	/**
	 * Standard behavior, sets variables
	 *
	 * @method initialize
	 * @param  {object} opts Constructor options
	 */
	initialize: function(opts) {
		this.setElement($('.webiste-score-mount'));
		this.websites = opts.websites;
		this.filteredWebsites = opts.websites;
		this.columns = [];

	}, //initialize

	/**
	 * Standard behavior. Loads Mustache template into view HTML. If a duration is set use setTimeout
	 * to automatically close the view.
	 *
	 * @method render
	 * @return {Object} This view, for passing back up the render stack.
	 */
	render: function() {
		var view = {
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		this.columns = [];

		columnCount = 0;
		for(var attr in this.websites[0]){
			attributeUniqueValues = this.getUniqueValues(attr, this.websites);
			if(attr != 'id') this.columns.push({'name' : attr, 'selectValues' : attributeUniqueValues, 'displayFilter' : (columnCount < 4)});

			columnCount++;
		}
		var columnView = {
			columns: this.columns
		};
		console.log(this.columns);
		this.$('.websites').append(Mustache.render(this.tplColumns, columnView, app.partials()));


		var valueView = {
			websites: this.filteredWebsites
		};
		this.$('.websites').append(Mustache.render(this.tplValues, valueView, app.partials()));

		console.log(this.$('.checkbox'));
		
		this.$('.multiselect-dropdown').multiselect({
			onChange: _.bind(function(element, checked) {
				this.filterTable(element, checked);
			},this)
		});

		this.delegateEvents();
		return this;
	},

	getUniqueValues: function(attribute, objects){
		var flags = [], output = [], l = objects.length, i;
		for( i=0; i<l; i++) {
			if( flags[objects[i][attribute]]) continue;
		    flags[objects[i][attribute]] = true;
		    output.push({'value' : objects[i][attribute]});
		}
		return output;

	},

	filterTable: function(element, checked){
		var attributeToFilter = element.closest('.column-name').html();
		var attributValue = element.val();
		var filter = {};
		
		if ($('.filter input[type="checkbox"]:checked').length == 0){
			this.filteredWebsites = this.websites;

		}else{
			$('.filter input[type="checkbox"]:checked').each(_.bind(function (index, thisElem) {

				if(!filter[$(thisElem.closest('.filter')).prev().html()]){
					filter[$(thisElem.closest('.filter')).prev().html()] = {values : []};

				}

				if(thisElem.checked) filter[$(thisElem.closest('.filter')).prev().html()].values.push($(thisElem).val());
			},filter));

			this.filteredWebsites = [];
			for( i=0; i<this.websites.length; i++) {
				attributesMatched = 0;
				for(attr in this.websites[i]){
					if(filter[attr] && filter[attr].values.indexOf(this.websites[i][attr]) > -1){
						attributesMatched = filter[attr].values.length;
					
					}

				}
				if(attributesMatched == $('.filter input[type="checkbox"]:checked').length) this.filteredWebsites.push(this.websites[i]);

			}

		}
		
		var valueView = {
			websites: this.filteredWebsites
		};
		this.$('.websites tbody').remove();
		this.$('.websites').append(Mustache.render(this.tplValues, valueView, app.partials()));


	}
}));