/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Questions = require('../collections/Questions.js'),
		AlertView = require('./AlertView.js'),

		app = require('../app.js');

/**
@class ExpandPipedQuestionsView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#ExpandPipedQuestionsView-tpl').html(),

	events: {
		'click .back': 'navigateBack',
		'click .save-expansion': 'saveExpansion'
	},

	/**
	Sets up the instance properties, starts loading up the questions, smart_questions, and
	comparisons.
	@method initialize
	@param {object} opts The constructor options
	*/
	initialize: function(opts) {
		/**
		@property title
		@type string
		*/
		this.title = 'Unpipe Questions';

		/**
		@property prevTitle
		@type string
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		The original question to expand out
		@property origQs
		@type Questions
		*/
		this.origQs = new Questions(opts.qs, {});

		/**
		The collection of all questions in the project
		@property allQs
		@type Questions
		*/
		this.allQs = opts.allQs;
	},

	/**
	Sticks the dom into the el, also makes a guess as to which was the piped question
	@method render
	@chainable
	*/
	render: function() {
		var view = {
			title: this.title,
			prevTitle: this.prevTitle,
			original_questions: this.origQs.toTpl(),
			all_questions: this.allQs.toTpl(),
			panel_controls: [
				{
					loner      : true,
					label      : 'Cancel',
					class_name : 'back',
					btn_type   : 'link'
				}, {
					loner      : true,
					label      : 'Unpipe questions',
					class_name : 'save-expansion',
					btn_type   : 'primary'
				}
			]
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		// guess at the piped question
		var prompt = this.origQs.at(0).get('prompt');
		var matches = prompt.match(/#_(\w+)_#/);
		if (matches && matches.length >= 2) {
			var fullID = matches[1];
			var match = this.allQs.find(function(q){ return fullID.indexOf(q.get('native_id')) > 0; });
			if (match) {
				this.$('select[name="piped_question"]').val(match.get('id'));
			}
		}

		return this;
	},

	/**
	Make a call to the server to expand the piped questions
	@method saveExpansion
	@param {event} [e] The initiating event
	*/
	saveExpansion: function(e) {
		if (e) e.preventDefault();
		this.$('.save-expansion').button('loading');

		var data = {
			target_question_ids: this.origQs.map(function(q){ return q.get('id'); }),
			piped_question_id: this.$('select[name="piped_question"]').val()
		};

		$.ajax({
			url: '/api1/question/expand_piped',
			method: 'POST',
			data: JSON.stringify(data),
			success: function(res){
				this.allQs.add(res.data.questions);
				this.origQs.each(function(model){ model.save('is_hidden', true); });
				this.trigger('navigatetofront');
			}.bind(this),
			error: function(xhr){
				app.ge.trigger('alert', new AlertView({
					m: 'Problem saving the expansion: '+xhr.responseJSON.m,
					type: 'alert-dange'
				}));
			}
		});
	}
}));