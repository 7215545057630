/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),
		OriginalPossibleResponseSelector = require('./OriginalPossibleResponseSelector.js'),
		app = require('../app.js');

/**
Container that lets a user select which original possible_responses should go into a new
possible_response for a combined question.
@class NewPossibleResponseView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	tagName: 'div',
	className: 'panel panel-default',
	tpl: $('#NewPossibleResponseView-tpl').html(),

	events: {
		'click .GTPanelCloseButton': 'removed',
		'click .add-selector': 'addSelector'
	},

	/**
	Set up a isSelected property, the default selectedClass
	@method initialize
	@param {object} opts Requires qs & prs keys, first is optional
	*/
	initialize: function(opts) {
		/**
		The collection of questions to be combined
		@property qs
		@type Questions
		*/
		this.qs = opts.qs;

		/**
		The collection of possible_responses
		@property prs
		@type PossibleResponses
		*/
		this.prs = opts.prs;

		/**
		Whether this view is the first in the list (mainly so that it won't get a close button)
		@property first
		@type boolean
		@default false
		*/
		this.first = opts.first || false;

		/**
		Collection of views responsible for selecting individual original possible_response IDs
		@property originalSelector
		@type array<OriginalPossibleResponseSelector>
		*/
		this.originalSelectors = [
			new OriginalPossibleResponseSelector({qs: this.qs, prs: this.prs, first: true})];
	},

	/**

	@method render
	@chainable
	*/
	render: function() {
		this.$el.html(Mustache.render(this.tpl, {is_first: this.first}, app.partials()));
		this.$('.original-possible-responses').html(app.renderViewsToFrag(this.originalSelectors));
		return this;
	},

	/**
	Default implementation
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Pass along event signifying that this view should be removed
	@method removed
	@param {event} [e] The initiating event
	*/
	removed: function(e) {
		if (e) e.preventDefault();
		/**
		Triggered when the view should be removed from the list of NewPossibleResponses
		@event remove
		@param {NewPossibleResponseView} view
		*/
		this.trigger('remove', this);
	},

	/**
	Returns a JSON object representing the complete new possible_response, **plus** a 'original_ids'
	key that contains the original possible_responses that should populate it.
	@method serializePossibleResponse
	*/
	serializePossibleResponse: function() {
		return {
			response     : this.$('input[name="response"]').val(),
			has_value    : this.originalSelectors.every(function(s){ return s.hasValue(); }),
			has_text     : this.originalSelectors.every(function(s){ return s.hasText(); }),
			is_empty     : false,
			is_response  : true,
			question_id  : null,
			description  : null,
			source       : 'gt',
			source_id    : null,
			native_id    : null,
			original_ids : this.originalSelectors.map(function(s){ return s.originalID(); })
		};
	},

	/**
	Adds another OriginalPossibleResponseSelector to the list (and renders it)
	@method addSelector
	*/
	addSelector: function() {
		var view = new OriginalPossibleResponseSelector({qs: this.qs, prs: this.prs});
		this.listenTo(view, 'remove', this.removeSelector);
		this.originalSelectors.push(view);
		this.$('.original-possible-responses').append(view.render().el);
	},

	/**
	Removes the passed OriginalPossibleResponseSelector from the page
	@method removeSelector
	@param {OriginalPossibleResponseView} view
	*/
	removeSelector: function(view) {
		this.stopListening(view);
		this.originalSelectors = _.without(this.originalSelectors, view);
		view.close();
	},

	/**
	Adjust the NewPossibleResponseView to automatically select the correct options for the given
	preset. There are two options right now:

		1. Multi-select

			In this case the new pr must select a single original question corresponding to the index
			provided and then select the 'yes' (or equivalent) possible_response for it

	@method activatePreset
	@param {String} newPreset 'multiselect'|'samelogical'
	@param {Number} index The index of this new possible_response in the list of those to create
	*/
	activatePreset: function(newPreset, index) {
		switch (newPreset) {
			case 'multiselect':
				var q = this.qs.at(index);

				// set the response value for the new possible_response
				// first try to parse out the value of this option from the question name
				var response = q.get('name'),
						sepIndex = response.indexOf('-');
				if (sepIndex > 0) response = response.slice(sepIndex+1).trim();
				this.$('input[name="response"]').val(response);

				// close all OriginalPossibleResponseSelectors
				while (this.originalSelectors.length > 1) {
					this.removeSelector(_.last(this.originalSelectors));
				}

				// delegate to the remaining selector
				var qID = q.get('id');
				var prID = this.prs
					.find(function(pr) {
						return pr.get('question_id') == qID && pr.get('response').toLowerCase() == 'yes';
					})
					.get('id');

				this.originalSelectors[0].makeSelection(qID, prID);
				break;


			case 'samelogical':
				// close extra OriginalPossibleResponseSelectors (we're making one for each question)
				while (this.originalSelectors.length > this.qs.length) {
					this.removeSelector(_.last(this.originalSelectors));
				}

				// if we need additional selector add them
				while (this.originalSelectors.length < this.qs.length) {
					this.addSelector();
				}

				// now go through all the selectors and have them pick the correct combination
				this.originalSelectors.forEach(function(os, prIndex) {
					var qID = this.qs.at(prIndex).get('id');
					var prID = this.prs
						.filter(function(pr){ return pr.get('question_id') == qID; })
						[index]
						.get('id');

					this.$('input[name="response"]').val(this.prs.get(prID).get('response'));

					os.makeSelection(qID, prID);
				}.bind(this));
				break;
		}
	}
});