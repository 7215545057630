/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		app = require('../app.js'),
		AlertView = require('./AlertView.js'),
		HeaderView = require('./HeaderView.js'),
		Mustache = require('mustache');

/**
The basic login view that displays a form.
@class LoginPageView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	tagName: 'div',
	className: 'container',

	tpl: $('#LoginPageView-tpl').html(),

	events: {
		'click .submit' : 'attemptLogin'
	},

	initialize: function(opts) {

	},

	render: function() {
		var view = {};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		app.$mount.html(this.el);
		return this;
	},

	close: function(){ this.remove(); },

	attemptLogin: function(e) {
		if (e) e.preventDefault();

		var
			form   = this.$('.login-form').serializeArray(),
			keys   = _.pluck(form, 'name'),
			values = _.pluck(form, 'value'),
			data   = _.object(keys, values);

			app.user.attemptSignIn(data.email_username, data.password)
				.done(function(){ 
					window.location = '/projects';
				})
				.fail(_.bind(function(){ 
					this.$el.prepend(
					new AlertView({
						m: 'Error signing in:' + arguments[0].responseJSON.m ,
						type: 'alert-danger'
					}).render().el);
					
				},this));

	}
});