/* global require, module */

var Backbone = require('backbone'),
		PossibleResponse = require('../models/PossibleResponse.js');

module.exports = Backbone.Collection.extend({
	model: PossibleResponse,
	url: function() {
		if (this.qID) {
			return '/api1/possible_response/all_for_question/' + this.qID;
		}

		if (this.projID) {
			return '/api1/possible_response/all_for_project/' + this.projID;
		}

		if (this.projIDs) {
			return '/api1/possible_response/all_for_projects/' + this.projIDs;
		}
	},

	initialize: function(models, opts) {
		this.qID = opts.qID || null;
		this.projID = opts.projID || null;
		this.projIDs = opts.projIDs || null;
	},

	comparator: function(pr) {
		var isEmpty = parseInt(pr.get('is_empty'), 10) === 1;
		var isResponse = parseInt(pr.get('is_response'), 10) === 1;

		return isEmpty || !isResponse ? 1 : 0;
	},
});