/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
 * An important alert to the user that is persistent until dismissed.
 *
 * @class AlertView
 * @constructor
 * @extends Backbone.View
 * @module Views
 */
module.exports = Backbone.View.extend({
	/**
	 * The standard element tag name
	 *
	 * @property tagName
	 * @type {String}
	 * @default 'div'
	 */
	tagName: 'div',

	/**
	 * Additional classes to add to the top-level view element
	 *
	 * @property className
	 * @type {String}
	 * @default 'alert fade in'
	 */
	className: 'alert fade in',

	/**
	 * The main view template. From html Mustache
	 *
	 * @property tpl
	 * @type {String}
	 */
	tpl: $('#AlertView-tpl').html(),

	/**
	 * Standard behavior, sets variables
	 *
	 * @method initialize
	 * @param  {object} opts Constructor options
	 */
	initialize: function(opts) {
		this.message = opts.m;
		this.type = opts.type;
		this.duration = opts.duration ? opts.duration : null;
		this.$el.addClass(this.type);
	}, //initialize

	/**
	 * Standard behavior. Loads Mustache template into view HTML. If a duration is set use setTimeout
	 * to automatically close the view.
	 *
	 * @method render
	 * @return {Object} This view, for passing back up the render stack.
	 */
	render: function() {
		var view = {
			message: this.message
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		if (this.duration) {
			window.setTimeout(_.bind(function(){
				this.$el.fadeOut(400, _.bind(function(){ this.close(); }, this));
			}, this), this.duration);
		}
		return this;
	},

	/**
	 * Standard behavior. Cleans up after this view and shuts down listeners.
	 *
	 * @method close
	 */
	close: function() {
		this.stopListening();
		this.remove();
	}
});