/* global require, module */

var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
	urlRoot: '/api1/possible_response',

	getters: {
		response_text: function() {
			if (+this.get('is_empty') === 1) {
				return 'Empty';
			}
			if (+this.get('is_response') === 0) {
				return 'No response';
			}
			if (+this.get('has_value') === 1) {
				return 'Value';
			}
			if (+this.get('has_text') === 1) {
				return 'Open ended';
			}
			return this.get('response');
		},

		/**
		Returns true if the possible_response is_empty=FALSE and is_response=TRUE
		@method get_should_include
		@return boolean
		*/
		should_include: function() {
			return this.get('is_empty') != 1 && this.get('is_response') == 1;
		}
	}
});