/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Questions                = require('../collections/Questions.js'),
		SmartQuestions           = require('../collections/SmartQuestions.js'),
		Comparisons              = require('../collections/Comparisons.js'),
		CrosstabAnalytic         = require('../models/CrosstabAnalytic.js'),
		AlertView                = require('./AlertView.js'),

		app = require('../app.js');

/**
@class CreateCrosstabAnalyticView
@constructor
@extends Backbone.View
@uses NavigationComponentMixin
@module Views
*/
module.exports = Backbone.View.extend(_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#CreateCrosstabAnalyticView-tpl').html(),
	optsTpl: $('#CreateCrosstabAnalyticView-opts-tpl').html(),

	events: {
		'click .back'             : 'navigateBack',
		// 'click .add-filtering'    : 'addFilteringQuestion',
		// 'click .remove-filtering' : 'removeFilteringQuestion',
		'click .create-crosstab'  : 'saveCrosstab',
		'change select[name="target_question"]' : 'updatedTargetQ'
	},

	/**
	@method initialize
	*/
	initialize: function(opts) {
		/**
		@property title
		@type String
		@default 'New Smart Question'
		@final
		*/
		this.title = 'New Crosstab';

		/**
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		Must be passed in.
		@property projID
		@type Number
		*/
		this.projID = opts.projID;

		/**
		@property numFiltering
		@type Number
		@default 1
		@private
		*/
		this.numFiltering = 1;

		/**
		@property as
		@type Analytics
		*/
		this.as = opts.as;

		/**
		@property qs
		@type Questions
		*/
		this.qs = new Questions([], {projID: this.projID});

		/**
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs = new SmartQuestions([], {projID: this.projID});

		/**
		@property comps
		@type Comparisons
		*/
		this.comps = new Comparisons([], {projID: this.projID});

		$.when(this.qs.fetch(), this.smartQs.fetch(), this.comps.fetch())
			.done(_.bind(this.renderControls, this));
	},

	close: function(){ this.remove(); },

	render: function() {
		var view            = {};
		view.title          = this.title;
		view.prevTitle      = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				label      : 'Cancel',
				btn_type   : 'link',
				class_name : 'back'
			}, {
				loner      : true,
				label      : 'Save',
				btn_type   : 'primary',
				class_name : 'create-crosstab'
			}
		];
		view.sample_size_methods = [{
			is_selected: true,
			method_id: 'bucket',
			method_name: 'Bucket'
		}, {
			method_id: 'parent',
			method_name: 'Parent',
			disabled: true
		}];
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	renderControls: function() {
		var view       = {};
		view.optgroups = [
			{
				label: 'Questions',
				options: this.qs.chain()
					.reject(function(q){ return _.contains([4], +q.get('type_id')); })
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Smart Questions',
				options: this.smartQs.map(function(q) {
					return {
						id      : q.get('id'),
						type    : 'smart_question',
						name    : q.get('name'),
						type_id : q.get('type_id')
					};
				})
			}, {
				label: 'Comparisons',
				options: this.comps.map(function(c) {
					return {
						id     : c.get('id'),
						type   : 'comparison',
						flavor : c.get('type'),
						name   : c.get('name')
					};
				})
			}
		];
		this.$('select.target-question').html(Mustache.render(this.optsTpl, view, app.partials()));
		this.updatedTargetQ();

		// reset optgroups with valid filtering questions only
		view.optgroups = [
			{
				label: 'Questions',
				options: this.qs.chain()
					.reject(function(q){ return _.contains([2,3,4], +q.get('type_id')); })
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Smart Questions',
				options: this.smartQs.chain()
				.reject(function(q){ return _.contains([2,3,4], +q.get('type_id')); })
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'smart_question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}
		];
		this.$('select.first-filtering-question')
			.html(Mustache.render(this.optsTpl, view, app.partials()));
	},

	addFilteringQuestion: function(e) {
		if (e) e.preventDefault();

		var view = {};
		view.optgroups = [
			{
				label: 'Questions',
				options: this.qs.chain()
					.reject(function(q){ return _.contains([2,3,4], +q.get('type_id')); })
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}, {
				label: 'Smart Questions',
				options: this.smartQs.chain()
				.reject(function(q){ return _.contains([2,3,4], +q.get('type_id')); })
					.map(function(q) {
						return {
							id      : q.get('id'),
							type    : 'smart_question',
							name    : q.get('name'),
							type_id : q.get('type_id')
						};
					})
					.value()
			}
		];

		this.$('a.add-filtering').parent('.help-block').hide();
		this.$('select.second-filtering-question')
			.html(Mustache.render(this.optsTpl, view, app.partials()))
			.parents('.hidden.form-group')
			.removeClass('hidden');

		this.numFiltering = 2;
	},

	removeFilteringQuestion: function(e) {
		if (e) e.preventDefault();

		this.$('a.add-filtering').parent('.help-block').show();
		this.$('select.second-filtering-question')
			.parents('.form-group')
			.addClass('hidden');

		this.numFiltering = 1;
	},

	updatedTargetQ: function(e) {
		var targetQDetails = this.$('select[name="target_question"]').val().split('::');
		var targetType = targetQDetails[0];

		var target;
		switch (targetType) {
			case 'question'       : target = this.qs.get(targetQDetails[1]); break;
			case 'smart_question' : target = this.smartQs.get(targetQDetails[1]); break;
			case 'comparison'     : target = this.comps.get(targetQDetails[1]); break;
			default               : target = null;
		}

		var isCat;
		if (target && target.get('type_id')) isCat = target.get('type_id') == 1;
		if (target && target.get('type')) isCat = target.get('type').indexOf('categorical') >= 0;

		if (target && isCat) {
			this.enableParent();
		} else {
			this.disableParent();
		}
	},

	disableParent: function() {
		this.$('input[value="bucket"]')
			.prop('checked', true)
			.parent('label')
			.addClass('active');
		this.$('input[value="parent"]')
			.prop('disabled', true)
			.prop('checked', false)
			.parent('label')
			.addClass('disabled')
			.removeClass('active');
	},

	enableParent: function() {
		this.$('input[value="parent"]')
			.prop('disabled', false)
			.parent('label')
			.removeClass('disabled');
	},

	saveCrosstab: function(e) {
		if (e) e.preventDefault();

		// collect and bundle up the data
		var data = this.$('#create-crosstab-form').serializeArray();
		data = _.object(_.pluck(data, 'name'), _.pluck(data, 'value'));
		if (this.numFiltering == 1) delete data.second_filtering_question;
		data.filtering_questions = [data.first_filtering_question, data.second_filtering_question];
		data.filtering_questions = _.compact(data.filtering_questions);

		// do a little validation
		if (data.name.length > 120 || data.name.length < 1) {
			app.ge.trigger('alert', new AlertView({
				m: 'A name is required and must be less than 120 characters',
				type: 'alert-danger'
			}));
			return;
		}




		var crosstab = new CrosstabAnalytic();

		crosstab.save({
			name                 : data.name,
			description          : data.description,
			project_id           : this.projID,
			type                 : 'crosstab',
			sample_size_method   : data.sample_size_method,
			target_question_id   : data.target_question.split('::')[1],
			target_question_type : data.target_question.split('::')[0],
			filtering_questions  : _.map(data.filtering_questions, function(q, index) {
				return {type: q.split('::')[0], id: q.split('::')[1], seq: index};
			})
		}, {
			wait: true,
			success: _.bind(function(mod, res, opts){
				mod.set({filters:res.data.extras.filter_ids});
				this.as.add(mod);
				this.navigateBack();
			}, this),
			error: function(mod, res, opts) {
				app.ge.trigger(
					'alert',
					new AlertView({m: 'Problem saving crosstab', type: 'alert-danger'})
				);
			}
		});
	}
}));