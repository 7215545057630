/* global require, module */

var Backbone = require('backbone'),
		_        = require('underscore'),
		$        = require('jquery'),
		User     = require('./models/User.js');

module.exports = {
	$mount: $('#mount-point'),

	qTypeHash: {
		1: 'Categorical',
		2: 'Rating Scale',
		3: 'Time',
		4: 'Open End',
		5: 'Continuous (Decimal) Values'
	},

	qTypeHashMustache: [
		{
			key: "1",
			type:'Categorical',
		}, {
			key: "2",
			type:'Rating Scale',
		}, {
			key: "3",
			type:'Time',
		}, {
			key: "4",
			type:'Open End',
		}, {
			key: "5",
			type:'Continuous (Decimal) Values',
		}
	],

	sourceHash: { uz: 'UserZoom' },

	sampleMethodHash: {
		1: {
			key: 'num_participants',
			name: 'Participants'
		},
		2: {
			key: 'num_responses',
			name: 'Responses'
		},
		3: {
			key: 'num_presentations',
			name: 'Presentations'
		}
	},

	statsMethodHash: {
		n1twoprop     : 'N-1 Two Proportion',
		twosamplet    : '2-Sample t',
		mcnemarexact  : 'McNemar Exact Test',
		pairedt       : 'Paired t'
	},

	ge: _.extend({}, Backbone.Events),
	currentPage: null,
	routes: null,

	user: new User({}),


	// METHODS
	// build a library of mustache partials
	partials: (function() {
		var general_partials = $('[id^="GT_"][id$="_tpl"]').toArray();
		var _tpls = _.reduce(general_partials, function(memo, tpl) {
			var $tpl = $(tpl);
			memo[$tpl.attr('id').slice(0, -4)] = $tpl.html();
			return memo;
		}, {});

		return function(props) {
			return $.extend({}, _tpls, props);
		};
	})(),

	worker: function(file){ return '/assets/js/app/workers/' + file; },

	clearCurrentPage: function() {
		if (this.currentPage) {
			this.currentPage.close();
			this.currentPage = null;
		}
	},

	/**
	Converts a d3 format string into a version accepted by excel. Currently supports a minimal subset
	of the d3 features and can be screwed up by more advanced usage.

	- comma [,]: Presence of a comma anywhere in the string activates a comma as a thousands separater
	- precision: Number of zeros to display after the decimal point
	- type: Only supports 'f' and '%' right now

	For reference see the [Microsoft guide to formatting in excel](http://office.microsoft.com/en-gb/excel-help/create-or-delete-a-custom-number-format-HP005199500.aspx?redir=0),
	the [Xlsx_Writer docs on the matter](https://xlsxwriter.readthedocs.org/format.html#format-set-num-format),
	and the [d3 docs](https://github.com/mbostock/d3/wiki/Formatting#d3_format).

	@method convertD3FormatToExcel
	@param {string} format The original d3 format string
	@return {string} The excel formatter string
	*/
	convertD3FormatToExcel: function(format){
		format = format || '';
		var excelFormat = '';

		// if adds comma separaters, start with that
		if (format.indexOf(',') != -1) excelFormat += '#,##0';
		// otherwise make sure there's always a zero
		else excelFormat += '0';

		// a certain precision was specified
		if (format.indexOf('.') != -1) {
			var precision = format.match(/\.(\d+)/);
			if (precision && precision.length  > 1) {
				// get the second match because the first one includes the period
				precision = Number(precision[1]);
				if (!_.isFinite(precision)) throw new Error('Expected finite precision');
				if (precision > 0) excelFormat += '.';
				for (var i = 0; i < precision; i++) {
					excelFormat += '0';
				}
			}
		}

		// percentage or not
		if (format.indexOf('%') != -1) excelFormat += '%';

		return excelFormat;
	},

	/**
	Convenience method that calls render() on each view, appends the resulting `el` to a document
	fragment, then returns it
	@method renderItemsToFrag
	@param {array<Backbone.View>} views The array of views to render into the frag
	*/
	renderViewsToFrag: function(views) {
		var frag = document.createDocumentFragment();
		_.each(views, function(view) {
			$(frag).append(view.render().el);
		});
		return frag;
	}
};