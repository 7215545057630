/* global require, module */

var Backbone = require('backbone'),
		Filter = require('../models/Filter.js');

module.exports = Backbone.Collection.extend({
	model: Filter,
	url: function() {
		if (this.smartQID) {
			return '/api1/filter/all_for_smart_question/' + this.smartQID;
		}

		if (this.projID) {
			return '/api1/filter/all_for_project/' + this.projID;
		}

		if (this.projIDs) {
			return '/api1/filter/all_for_projects/' + this.projIDs;
		}

		if (this.smartPrID) {
			return '/api1/filter/for_smart_possible_response/' + this.smartPrID;
		}
	},

	initialize: function(models, opts) {
		this.smartQID = opts.smartQID || null;
		this.projID = opts.projID || null;
		this.projIDs = opts.projIDs || null;
		this.smartPrID = opts.smartPrID || null;
	},

	parse: function(res, opts) {
		return res.data;
	}
});