/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore');

/**
@class GraphTheme
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend({
	urlRoot: '/api1/graph_theme',

	getters: {
		get_fonts: function(){
			var validFonts = ['Corbel', 'Century Gothic', 'Arial'];
			return _.map(validFonts, function(item){
				return {font: item, isSelected: this.get('font') == item};
			}, this);
		},

		get_label_positions: function(){
			var validPositions = [
				'inside_base',
				'inside_end',
				'outside_end',
				'center'
			];

			return _.map(validPositions, function(item){
				var formatted = item.charAt(0).toUpperCase() + item.replace('_', ' ').slice(1);
				return {value: item, name: formatted, isSelected: this.get('data_labels').position == item};
			}, this);
		},

		get_legends: function(){
			var validLegends = [
				'none',
				'right',
				'left',
				'top',
				'bottom',
				'overlay_left',
				'overlay_right'
			];

			var validNames = [
				'No legend',
				'Right',
				'Left',
				'Top',
				'Bottom',
				'Overlay on the left',
				'Overlay on the right'
			];

			return _.map(validLegends, function(item, index){
				return {value: item, name: validNames[index], isSelected: this.get('legend') == item};
			}, this);
		}
	},

	defaults: {
		colors              : ['#5078af', '#749fd5', '#5ccee0', '#41eca3', '#eae980', '#f1ca53',
												   '#f79423', '#db643b', '#be4c4c', '#8f3468', '#46338c', '#32568a'],
		error_bar_end_style : 0,
		data_labels         : {
			type     : 'values',
			position : 'inside_base'
		},
		legend          : 'right',
		chart_border    : false,
		plot_border     : false,
		font            : 'Corbel',
		major_gridlines : false,

		name          : '',
		team_id       : null,
		collection_id : null
	}
});