/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		PossibleResponses        = require('../collections/PossibleResponses.js'),
		SmartPossibleResponses   = require('../collections/SmartPossibleResponses.js'),

		app = require('../app.js');

/**
 * @className ExcludeQuestionItemView
 * @constructor
 */
module.exports = Backbone.View.extend({
	/**
	 * This item goes in a table.
	 *
	 * @property tagName
	 * @type {String}
	 */
	tagName: 'tr',

	tpl         : $('#ExcludeQuestionItemView-tpl').html(),
	dropdownTpl : $('#ExcludeQuestionItemView-dropdown-tpl').html(),
	freeformTpl : $('#ExcludeQuestionItemView-freeform-tpl').html(),

	events: {
		'click .check'          : 'toggleChecked',
		'click .set-expected'   : 'setExpected',
		'keyup .expected-value' : 'keyExpected',
		'click .save-expected'  : 'saveExpected'
	},

	initialize: function() {
		this.checked = false;
	},

	render: function() {
		var view = {
			name: this.model.get('name'),
			num_responses: this.model.get('num_responses'),
			type: app.qTypeHash[this.model.get('type_id')]
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.updateOperator();
		return this;
	},

	/**
	 * Select the proper operators to be shown in the drop-down box based on qeustion type.
	 *
	 * @method updateOperator
	 */
	updateOperator: function() {
		var QTypesWithNoComparators = [1];
		var QTypesWithDropdown = [1,2];

		var q = this.model;

		if(q !== null) {
			var view;
			if (_.contains(QTypesWithNoComparators, +q.get('type_id'))) {
				this.$('#operator option[value="greaterthan"]').attr('disabled', 'disabled');
				this.$('#operator option[value="lessthan"]').attr('disabled', 'disabled');
			} else {
				this.$('#operator option').removeAttr('disabled');
				view = {};
			}

			if (_.contains(QTypesWithDropdown, +q.get('type_id'))) {
				var value = this.$('#operator').val();
				if (value == 'greaterthan' || value == 'lessthan' || !value) {
					this.$('#operator').val('==');
				}

				var prs = new PossibleResponses([], {qID : q.get('id')});
				var prsProm = prs.fetch({
					reset: true,
					error: _.bind(function(one, two, three) {
						console.log(one);
						console.log(two);
						console.log(three);
						console.log(two.responseText);
					}, this),
				});

				$.when(prsProm).then(_.bind(function() {
					console.log(prs);
					view = {
						possible_responses : prs.reduce(function(memo, pr) {
							if(+pr.get('is_response') == 1) {
								memo.push({
									value: pr.get('response'),
									name: pr.get('response')
								});
							}
							return memo;
						}, [])
					};
					console.log(view);
					this.$('.expected-value-field').html(Mustache.render(this.dropdownTpl, view, app.partials()));
				}, this));
			} else { //no dropdown, so freeform text input field
				this.$('.expected-value-field').html(Mustache.render(this.freeformTpl, view, app.partials()));
			}
		}
	},

	toggleChecked: function() {
		this.checked = !this.checked;
		this.$el.toggleClass('checked', this.checked);
		this.trigger('checkchange', this.model.get('id'), this.checked);
	},

	check: function() {
		this.checked = true;
		this.$el.toggleClass('checked', this.checked);
		this.$('.check').prop('checked', this.checked);
		this.trigger('checkchange', this.model.get('id'), this.checked);
	},

	uncheck: function() {
		this.checked = false;
		this.$el.toggleClass('checked', this.checked);
		this.$('.check').prop('checked', this.checked);
		this.trigger('checkchange', this.model.get('id'), this.checked);
	},

	setExpected: function() {
		this.$('.set-expected').addClass('hidden');
		this.$('.form-inline').removeClass('hidden').find('input').focus();
		this.$('.expect').addClass('hidden');
	},

	keyExpected: function(e) {
		if (e.type == 'focusout') {
			this.showExpected(false);
			return;
		}

		var isEsc = e.which === 27;
		var isEnter = e.which === 13;
		var val = this.$('.expected-value').val();

		if (isEsc) {
			this.showExpected(false);
		}

		var valIsCode = _.contains(['>', '<', '!=', '=='], val);
		var valMap = {
			'>': 'greaterthan',
			'<': 'lessthan',
			'!=': 'notequals',
			'==': 'equals'
		};
		if (valIsCode) {
			this.$('select').val(valMap[val]);
			this.$('.expected-value').val('');
		}

		if (isEnter) {
			if (_.isFinite(val)) {
				this.showExpected(val);
			}
		}
	},

	saveExpected : function(e) {
		console.log(this.$('.expected-value').val());
		this.showExpected(this.$('.expected-value').val());
	},

	showExpected: function(expected) {
		if (expected !== false) {
			var valMap = {
				'greaterthan': '>',
				'lessthan': '<',
				'notequals': '!=',
				'equals': '=='
			};
			var compStr = this.$('select').val();
			var comparison = valMap[compStr];
			var str =  comparison + ' ' + expected;

			this.form = this.$('.form-inline').addClass('hidden');
			this.$('.set-expected').toggleClass('hidden', true);

			this.$('.expect').html(str);

			this.model.set('comparison', compStr);
			this.model.set('expected', expected);

			console.log(compStr);
			console.log(expected);
			this.$('.set-expected').removeClass('hidden');
			this.$('.expect').removeClass('hidden');
		} else {
			this.$('.form-inline').addClass('hidden');
			this.$('.set-expected').removeClass('hidden');
		}
	}
}); //ExcludeQuestionItemView