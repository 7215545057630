/* global require, module */

var _ = require('underscore'),
		$ = require('jquery'),
		d3 = require('d3'),

		SmartQuestion = require('./SmartQuestion.js'),
		SeriesSet = require('./SeriesSet.js'),
		app = require('../app.js');

/**
@class RatingSmartQuestion
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = SmartQuestion.extend({
	urlRoot: '/api1/smart_question',

	getters: _.extend(SmartQuestion.prototype.getters, {
		// only for categorical
		is_pr_editable: function(){ return false; }
	}),

	/**
	@method toSeriesSet
	@param {object} args Requires parts, prs, rs, smartPrs, and filters
	@return SeriesSet Promise
	*/
	toSeriesSet: function(args) {
		var qName = this.get('name'),
				qID   = +this.get('id'),

				smartPrs = args.smartPrs.where({smart_question_id: qID.toString()}),
				smartPr  = smartPrs[0],
				targetID = +smartPr.get('target_question_id'),
				prs      = args.prs,
				parts    = args.parts,
				rs       = args.rs,
				filters  = args.filters,

				// maps sprID => object of matching partIDs
				matchingParts = {},
				dfd    = $.Deferred();

		if (smartPrs.length !== 1) throw new Error('Expected exactly one smart_possible_response');

		filters
			.get(smartPr.get('filter_id'))
			.applyFilter(parts.toTpl(), prs.toTpl(), rs.toTpl())
			.then(function(matchingParts) {
				// store the matching participants for each smart_possible_response
				matchingParts = matchingParts.reduce(function(memo, part) {
					if (part.id in memo) throw new Error('Expected unique participants');
					memo[part.id] = true;
					return memo;
				}, {});

				var rsToTarget      = rs.where({question_id: targetID.toString()}),
						prsToTarget     = prs.where({question_id: targetID.toString()}),
						nonResponsePrID = _.find(prsToTarget, function(pr){ return !+pr.get('is_response'); })
																.get('id'),
						emptyPrID       = _.find(prsToTarget, function(pr){ return !!+pr.get('is_empty'); })
																.get('id');

				// Make a map that keys partID => prID, expect each part only once.
				var indexedRs = rsToTarget.reduce(function(memo, r) {
					var partID = r.get('participant_id'),
							prID   = r.get('possible_response_id');

					if (partID in memo) throw new Error('RatingQuestion expects unique responses: '+partID);
					memo[partID] = prID;
					return memo;
				}, {});

				// Make a map of prID => response value
				var prMap = prsToTarget.reduce(function(memo, pr) {
					if (!!+pr.get('is_empty') || !+pr.get('is_response')) return memo;

					var response = +pr.get('response');
					if (!_.isFinite(response)) throw new Error('A response value was not numeric: '+pr.get('response'));
					memo[pr.get('id')] = +pr.get('response');
					return memo;
				}, {});

				var responses = parts.map(function(part) {
					var partID = +part.get('id'),
							prIdForPart = indexedRs[partID],
							hadResponse = nonResponsePrID != prIdForPart,
							hadEmptyResponse = emptyPrID == prIdForPart,
							matchedFilter = partID in matchingParts;

					return {
						part_id     : partID,
						is_excluded : !!+part.get('excluded'),
						is_empty    : matchedFilter ? hadEmptyResponse : false,
						is_response : matchedFilter ? hadResponse : false,
						response    : matchedFilter && hadResponse && !hadEmptyResponse ? prMap[prIdForPart] : null
					};
				});

				var dataPoint = {
					label       : qName,
					source_type : 'smart_question',
					source_id   : qID,
					parent_id   : null,
					type        : 'rating',
					min         : _.min(prMap),
					max         : _.max(prMap),
					seq         : null,
					responses   : responses
				};

				var dataSeries = {
					name : qName,
					seq  : null,
					type : 'categorical',
					data : [dataPoint]
				};

				dfd.resolve(new SeriesSet({
					series : [dataSeries],
					name   : qName,
					type   : 'rating'
				}));
			});

		return dfd;
	},

	generatePrsList: function(smartPrs, prs) {
		return smartPrs.map(function(spr){
			if (+spr.get('is_empty') === 1) return {id: spr.get('id'), response:'Empty'};
			if (+spr.get('is_response') === 0) return {id: spr.get('id'), response:'No response'};

			var sprID = spr.get('target_question_id');
			prs = prs.filter(function(pr){
				return pr.get('question_id') == sprID;
			});
			var minVal = d3.min(prs, function(pr) {
				var isEmpty = +pr.get('is_empty') == 1;
				var notResponse = +pr.get('is_response') === 0;
				return (isEmpty || notResponse) ? undefined : +pr.get('response');
			});
			var maxVal = d3.max(prs, function(pr){ return Number(pr.get('response')); });
			return {id: spr.get('id'), response: minVal + '\u2013' + maxVal};
		});
	}
});