/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		app = require('../app.js');

/**
@class Filter
@constructor
@extends Backbone.Model
@module Models
@example
	// Note the children array can also be populated with elements that match the top level
	// structure, although never both.
	{
		id: String (always numeric though),
		parent_filter_container: String (numeric id)|null (for top level filter),
		operator: String 'AND'|'OR',
		children: Array [
			{
				id: String (always numeric),
				parent_filter_container: String (numeric id),
				operator: String '=='|'!='|'>'|'<',
				question_id: String (always numeric),
				value: String (typically numeric)|null (if only compared to possible response),
				possible_response_id: String (always numeric)|null (if compared with value),
				question_type: String 'question'|'smart_question'
			}, {
				...
			}
		]
	}
*/
module.exports = Backbone.Model.extend({
	urlRoot: '/api1/filter',

	parse: function(res, opts) {
		if (res.hasOwnProperty('data')) {
			return res.data;
		}
		return res;
	},

	applyFilter: function(parts, prs, rs) {
		var
			start  = new Date().getTime(),
			parser = new Worker(app.worker('parseFilter.js')),
			d      = $.Deferred();
		parser.onmessage = function(res) {
			var end = new Date().getTime();
			// console.log('async ', end-start);
			// console.log(res.data);
			d.resolve(res.data);
			// that.trigger('applied', res.data);
		};
		var filter = this.toJSON();
		parser.postMessage({f:filter, p1:parts, p2:prs, r:rs});
		return d;
	},

	applyFilterSync: function(parts, prs, rs) {
		var start = new Date().getTime();
		var
			prom = this.applyFilterAsync(parts, prs, rs),
			matchingParts;

		prom.done(function(d){ console.log(prom.state()); });
		// while (prom.state() == 'pending'){}
		for (var i = 0; i < 100000; i++) {
			console.log(prom.state());
		}

		var end = new Date().getTime();
		console.log('sync ', end-start);
		return matchingParts;
	},

	_matchesFilter: function(filter, part, prs, rs) {
		var children = [],
			child;
		filter = JSON.parse(JSON.stringify(filter));
		children = children.concat(filter.children);

		var filterFunc = function(r) {
			return _.some(prsForQ, function(pr) {
				return r.possible_response_id == pr.id;
			});
		};

		while (children.length > 0) {
			child = children.pop();
			if (!child.hasOwnProperty('discovered')) {
				child.discovered = true;
				if (child.hasOwnProperty('children')) {
					children = children.concat(child.children);
				} else {
					var prsForQ = _.where(
						prs,
						{question_id: child.question_id.toString()}
					);

					var rsForPartForQ = _.filter(rs, filterFunc);

					if (rsForPartForQ.length === 0) {
						child.matches = false;
						continue;
					}

					var compareTo;
					var value;
					if (child.value !== null) {
						compareTo = +child.value;
						value = rsForPartForQ[0].value;
					} else {
						if (child.operator == '>' || child.operator == '<') {
							var childPr = _.findWhere(
								prs,
								{id: child.possible_response_id}
							);
							compareTo = +childPr.response;
							var rPr = _.findWhere(
								prs,
								{id: rsForPartForQ[0].possible_response_id}
							);
							value = parseInt(rPr.response, 0);
						} else {
							compareTo = child.possible_response_id;
							value = rsForPartForQ[0].possible_response_id;
						}
					}

					if (!_.isFinite(value) || !_.isFinite(compareTo)) {
						child.matches = false;
					} else {
						switch (child.operator) {
							case '==':
								child.matches = value == compareTo;
								break;
							case '!=':
								child.matches = value != compareTo;
								break;
							case '>':
								child.matches = value > compareTo;
								break;
							case '<':
								child.matches = value < compareTo;
								break;
						}
					}
				}
			}
		}
	}
});