/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		app = require('../app.js');

module.exports = Backbone.View.extend({

	tagName: 'tr',
	className: 'q-row',

	tpl: $('#CompItemView-tpl').html(),

	events: {
		'click': 'viewMe'
	},

	initialize: function() {
		this.listenTo(this.model, 'change', this.render);
		this.listenTo(this.model, 'destroy', this.destroy);
	},

	render: function() {
		this.$el.html(
			Mustache.render(this.tpl, this.model.toTpl(), app.partials())
		);
		return this;
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	destroy: function() {
		this.trigger('deleted', this);
		this.close();
	},

	viewMe: function(e) {
		e.preventDefault();
		this.trigger('view', this.model);
	}

});