/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),

		app = require('../app.js'),
		Collection = require('../models/Collection.js'),
		Collections = require('../collections/Collections.js'),
		Projects = require('../collections/Projects.js'),
		CollectionView = require('./CollectionView.js');


module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName   : 'div',
	className : 'container',

	tpl       : _.template( $('#ProjectsPageView-tpl').html(), {} ),
	addColTpl : _.template( $('#ProjectsPageView-button-tpl').html(), {} ),
	blankTpl  : _.template( $('#ProjectsPageView-blank-tpl').html(), {} ),

	events : {
		'click .addCollection' : 'addCollection'
	},


	initialize: function() {
		this.colViews = [];

		this.listenTo(app.ge, 'alert', this.showAlert);

		this.collections = new Collections();
		var colProm  = this.collections.fetch({reset: true});
		$.when(colProm).done(_.bind(this.dataLoaded, this));
	},

	render: function() {
		this.$el.html(this.tpl);
		// mount the page
		app.$mount.html(this.el);
		return this;
	},

	close: function() {
		_.each(this.colViews, function(c) {
			c.close();
		});
		this.stopListening();
		this.remove();
	},

	dataLoaded: function() {
		var frag  = document.createDocumentFragment();
		var first = true;
		this.collections.each(function(c) {
			var colView = new CollectionView({
				collection_id : c.get('id'),
				model: c,
				open: first,
				collections: this.collections
			});
			this.colViews.push(colView);
			this.listenTo(colView, 'deleted', this.deletedCollection);
			this.listenTo(colView, 'projectMoving', this.projectMoving);
			$(frag).append(colView.render().el);
			first = false;
		}, this);

		if(this.collections.length > 0) {
			$(frag).append(this.addColTpl);
		} else {
			$(frag).append(this.blankTpl);
		}

		this.$('#collections-container').html(frag);
	},

	showAlert: function(alertView) {
		this.$el.prepend(alertView.render().el);
	},

	/**
	 * Adds an new collection and displays it in the list.
	 *
	 * @method addCollection
	 * @param {Event} e The event information, if any exists.
	 */
	addCollection: function(e) {
		var colView = new CollectionView({
			projects: [],
			model: new Collection({})
		});
		this.colViews.push(colView);
		this.listenTo(colView, 'deleted', this.deletedCollection);
		var toAdd = colView.render().el;

		this.$('.addCollectionContainer').remove();

		this.$('.panel-collapse.in').collapse('hide');
		this.$('#collections-container').append(toAdd);
		$(toAdd).find('.panel-collapse').collapse('show');
		$(toAdd).find('.finalizeCollectionRename').html(
			'<span class="glyphicon glyphicon-plus"></span> Create');

		this.$('#collections-container').append(this.addColTpl);

		$(toAdd).find('.renameCollection').trigger('click');
	},

	/**
	 * To ensure that if last collection is deleted, we revert back to blank template.
	 *
	 * @method deletedCollection
	 * @param {CollectionView} view the CollectionView to remove.
	 */
	deletedCollection: function(view) {
		this.colViews = _.reject(this.colViews, function(civ) { return civ == view; });

		this.$('.addCollectionContainer').remove();
		if(this.collections.length > 0) {
			this.$('#collections-container').append(this.addColTpl);
		} else {
			this.$('#collections-container').append(this.blankTpl);
		}
	},

	/**
	 * A collection has triggered an event, signaling that a project just left it on its way to
	 * another collection. We listen to the traveling project and when it sends an "added" event,
	 * we check its new collection id and place it in the proper collection.
	 *
	 * @method projectMoving
	 * @param {Project} proj The traveling project
	 */
	projectMoving: function(proj) {
		this.proj = proj;
		_.each(this.colViews, function(cv) {
			if(+cv.id == +this.proj.get('collection_id')) {
				cv.addProject(this.proj);
			}
		}, this);
	}

});