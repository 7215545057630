/* global require, module */

var Backbone = require('backbone'),
		User = require('../models/User.js');

module.exports = Backbone.Collection.extend({
	model: User,
	url: function() {
		return '/api1/user/all/';

	},

	// see http://stackoverflow.com/questions/11510490/sort-backbone-collection-based-on-model-attributes for sorting details
	initialize: function(opts) {
	}
});