/* global require, module */

var Backbone = require('backbone'),
		Participant = require('../models/Participant.js');

module.exports = Backbone.Collection.extend({
	model: Participant,
	url: function() {
		if (this.projID){
			return '/api1/participant/all_for_project/' + this.projID;

		}else if(this.projIDs){
			return '/api1/participant/all_for_projects/' + this.projIDs;

		}

		throw new Error('projID not set');

	},

	initialize: function(models, options) {
		if (options) {
			this.projID = options.projID;
			this.projIDs = options.projIDs;
		}
	}
});