/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),

		SmartPossibleResponses   = require('../collections/SmartPossibleResponses.js'),
		Filters                  = require('../collections/Filters.js'),
		Responses                = require('../collections/Responses.js'),
		Participants             = require('../collections/Participants.js'),
		PossibleResponses        = require('../collections/PossibleResponses.js'),
		Responses                = require('../collections/Responses.js'),

		GColView                 = require('./GColView.js'),
		AlertView                = require('./AlertView.js'),
		FilterView               = require('./FilterView'),
		StatsAnalysisView        = require('./StatsAnalysisView.js'),

		app                      = require('../app.js');

/**
@class SmartQuestionView
@constructor
@extends Backbone.View
@module Views
 */
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl         : $('#SmartQuestionView-tpl').html(),
	infoTpl     : $('#SmartQuestionView-info-tpl').html(),
	editInfoTpl : $('#SmartQuestionView-edit-info-tpl').html(),
	prsTpl      : $('#SmartQuestionView-prs-tpl').html(),
	editPrsTpl  : $('#SmartQuestionView-edit-prs-tpl').html(),

	events: {
		'click .back'                 : 'navigateBack',
		'click .edit-question'        : 'editQuestion',
		'click .cancel-edit-question' : 'cancelEditQuestion',
		'click .save-edit-question'   : 'saveEditQuestion',
		'click .delete-question'      : 'deleteQuestion',
		'change input[name="sample_size_method"]' : 'changeMethod',

		'click .data-download'        : 'addDataToExcel',

		'click .edit-prs'             : 'editPrs',
		'click .cancel-edit-prs'      : 'cancelEditPrs',
		'click .save-edit-prs'        : 'saveEditPrs',

		'click .inspect-filter'       : 'inspectFilter'
	},

	/**
	Sets up properties, kicks off ajax requests for a bunch of associated data (see properties),
	render the Possible Responses when they come in and the chart when a whole bunch of other
	data comes in.

	Set up listeners to update the view when underlying data changes.
	@method initialize
	@param {object} opts Configuration object following the format:

		{
			smartQ  : SmartQuestion you've viewing,
			qs      : Questions,
			smartQs : SmartQuestions,
			[alpha] : alpha for confidence level, defaults to 0.1
		}

	Only alpha is optional
	*/
	initialize: function(opts) {
		/**
		@property project
		@type Project
		*/
		this.project = opts.project;

		/**
		@property smartQ
		@type SmartQuestion
		*/
		this.smartQ = opts.smartQ;

		/**
		@property projID
		@type String (numeric)
		*/
		this.projID = this.project.get('id');

		/**
		Taken from smartQ
		@property title
		@type String
		*/
		this.title = this.smartQ.get('name');

		/**
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		Set's the confidence level to use when viewing the graph and computing the confidence interval.
		@property alpha
		@type Number
		@default 0.1
		*/
		this.alpha = opts.alpha || 0.1;

		/**
		Initialized by self
		@property smartPrs
		@type SmartPossibleResponses
		*/
		this.smartPrs = new SmartPossibleResponses([], {smartQID: this.smartQ.get('id')});

		/**
		Initialized by self
		@property filters
		@type Filters
		*/
		this.filters = new Filters([], {smartQID: this.smartQ.get('id')});

		/**
		Initialized by self
		@property parts
		@type Participants
		*/
		this.parts = new Participants([], {projID: this.projID});

		/**
		Initialized by self
		@property prs
		@type PossibleResponses
		*/
		this.prs = new PossibleResponses([], {projID: this.projID});

		/**
		Initialized by self
		@property rs
		@type Responses
		*/
		this.rs = new Responses([], {smartQID: this.smartQ.get('id')});

		/**
		Passed in
		@property qs
		@type Questions
		*/
		this.qs = opts.qs;

		/**
		Passed in
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs = opts.smartQs;

		/**
		Holds promises from applying filters
		@property filterProms
		@type Array
		*/
		this.filterProms = [];

		/**
		Flag for graph rendering calls to inspect telling them whether the dom is ready.
		@property hidden
		@type Boolean|null
		@default null
		*/
		this.hidden = null;

		var smartPrsProm = this.smartPrs.fetch();
		var filtersProm  = this.filters.fetch();
		var partsProm    = this.parts.fetch();
		var prsProm      = this.prs.fetch();
		var rsProm       = this.rs.fetch();

		$.when(smartPrsProm, prsProm).then(_.bind(function(){
			this.renderPrs();
			this.renderInfo();
		}, this));

		$.when(filtersProm, partsProm, prsProm, rsProm, smartPrsProm)
			.then(_.bind(function(){
				this.renderStats();
				this.renderChart(this.alpha);
			}, this));

		this.listenTo(this.smartQ, 'change', function() {
			this.renderInfo();
			this.renderNavHeader();
		});

		this.listenTo(this.smartQ, 'change:sample_size_method', function(){
			this.renderChart(this.alpha);
		});

		this.listenTo(this.smartPrs, 'change:response', _.debounce(function() {
			this.renderPrs();
			this.renderChart(this.alpha);
		}, 50));

		this.listenTo(this.filters, 'change', _.bind(function() {
			this.renderChart(this.alpha);
		}, this));
	},

	/**
	If the view is currently hidden, call instead on the next showing.

	Otherwise, turn the question into a data series, go to chart data, and render a new chart.
	@method renderChart
	@param {Number} alpha Must be between (0, 1) exclusive.
	*/
	renderChart: function(alpha) {
		if (this.hidden) {
			this.listenToOnce(this, 'showing', _.partial(this.renderChart, alpha));
			return;
		}
		this.smartQ.toSeriesSet({
			smartPrs : this.smartPrs,
			prs      : this.prs,
			rs       : this.rs,
			parts    : this.parts,
			filters  : this.filters
		})
		.then(function(seriesSet){
			return seriesSet.toChartData(this.smartQ.getCalculator(), this.smartQ.getCICalculator(), alpha);
		}.bind(this))
		.then(_.bind(function(chartData) {
			console.log(chartData);
			if (!this.chart) {
				chartData.el = '.graph-mount';
				chartData.alpha = alpha;
				this.chart   = new GColView(chartData);

				this.listenTo(this.chart, 'changealpha', this.changeAlpha);
				this.listenTo(this.chart, 'GColView:addtoexcel', this.addGraphToExcel);
				this.chart.render();
			} else {
				this.chart.setData(chartData.data).setAlpha(alpha).reRender();
			}
		}, this));
	},

	renderStats: function() {
		this.smartQ.toSeriesSet({
			smartPrs : this.smartPrs,
			prs      : this.prs,
			rs       : this.rs,
			parts    : this.parts,
			filters  : this.filters
		})
		.then(_.bind(function(ss){
			var type = ss.get('type');
			if (type == 'open_end') return;
			if (ss.get('series').length == 1 && ss.get('series')[0].data.length == 1) return;
			console.log('hey there');
			this.statView = new StatsAnalysisView({ss: ss});
			this.$('.stats-mount').html(this.statView.render().el);
		}, this));
	},

	/**
	Sets the new alpha property, re-renders graph.
	@method changeAlpha
	@param {Number} alpha The new alpha value
	*/
	changeAlpha: function(alpha) {
		this.alpha = alpha;
		this.renderChart(this.alpha);
	},

	/**
	Basic close
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Sets up the page structure and calls function to render the navigation and question info based on
	the passed-in Smart Question.
	@method render
	@chainable
	*/
	render: function() {
		var view = this.smartQ.toTpl();
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.renderNavHeader();
		return this;
	},

	/**
	Replaces the navigation header with titles, controls, etc.
	@method renderNavHeader
	*/
	renderNavHeader: function() {
		var view = this.smartQ.toTpl();
		view.title = this.title;
		view.prevTitle = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				label      : 'Add to Excel',
				class_name : 'data-download'
			},
			{
				loner      : true,
				label      : 'Edit',
				class_name : 'edit-question'
			}
		];
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);
	},

	/**
	 * Displays generic information about this question by loading the info display template.
	 *
	 * @method renderInfo
	 */
	renderInfo: function() {
		var view = this.smartQ.toTpl();

		if (+view.type_id == 3) { // time question, has target_question
			var targetQID = this.smartPrs.findWhere({has_value:"1"}).get('target_question_id');
			view.target_question = this.qs.get(targetQID).get('name');
		}

		this.$('.panel-nav .panel-body').html(
			Mustache.render(this.infoTpl, view, app.partials())
		);
	},

	/**
	 * Displays a form that allows the question's details to be edited. The form replaces the
	 * generic information display.
	 *
	 * @method renderEditInfo
	 */
	renderEditInfo: function() {
		var view =  this.smartQ.toTpl();

		view.sample_size_methods = _.map(app.sampleMethodHash, function(method, key) {
			return {
				is_selected : key == +this.smartQ.get('sample_size_method'),
				method_id   : key,
				method_name : method.name,
				disabled    : _.contains([2], +key) // doesn't support responses currently
			};
		}, this);

		view.questions = this.qs.map(function(q) {
			return {
				id          : q.get('id'),
				name        : q.get('name'),
				is_selected : q.get('id') == this.smartQ.get('sample_size_question_id')
			};
		}, this);

		this.$('.panel-nav .panel-body').html(Mustache.render(this.editInfoTpl, view, app.partials()));

		this.changeMethod();
	},

	/**
	Called when changing the sample size method, shows/hides the sample_size_question_id select
	element
	@method changeMethod
	@param {event} [e] Potentially passed user event
	*/
	changeMethod: function(e) {
		var
		newMethod = this.$('input[name="sample_size_method"]:checked').val(),
		type      = this.smartQ.get('type_id');

		if (newMethod == 3 && type == 1) {
			this.$('.form-group.ssize-id').removeClass('hidden');
		} else {
			this.$('.form-group.ssize-id').addClass('hidden');
		}
	},

	/**
	Called when the promise returns. Interogates the smart question for and then renders a list of
	the valid smart possible responses.
	@method renderPrs
	*/
	renderPrs: function() {
		var view = {};
		view.prs = this.smartQ.generatePrsList(this.smartPrs, this.prs);
		view.panel_title = 'Possible Responses';

		if (this.smartQ.get_is_pr_editable()) {
			view.panel_controls = [
				{
					loner: true,
					label: 'Edit',
					class_name: 'edit-prs'
				}
			];
		}

		this.$('.possible-responses').html(Mustache.render(this.prsTpl, view, app.partials()));
	},

	/**
	If a supported question type, this method will render input boxes to change the names of the
	associated possible responses
	@method renderEditPrs
	*/
	renderEditPrs: function() {
		var view = {};
		view.prs = this.smartQ.generatePrsList(this.smartPrs);
		view.panel_title = 'Possible Responses';
		view.disabled = +this.smartQ.get('type_id') === 3;
		view.panel_controls = [
			{
				loner: true,
				label: 'Cancel',
				class_name: 'cancel-edit-prs',
				btn_type: 'link'
			}, {
				loner: true,
				label: 'Save',
				class_name: 'save-edit-prs',
				btn_type: 'primary'
			}
		];

		this.$('.possible-responses').html(Mustache.render(this.editPrsTpl, view, app.partials()));
	},

	/**
	Hides the current view. Part of an interface.

	Note this method will also set the `hidden` property to `true` so that the graph can delay
	re-rendering until the dom is ready.
	@method hide
	*/
	hide: function(){this.hidden = true; this.$el.hide(); },

	/**
	Shows the current view. Part of an interface.

	Note this method will also set the `hidden` property to `false` and trigger the 'showing' event
	so that the graph will know when the dom is ready to draw itself.
	@method show
	*/
	show: function(){
		this.hidden = false;
		this.$el.show();
		/**
		Internal event used to help the graph know when to render
		@event showing
		@private
		*/
		this.trigger('showing');
	},

	/**
	Emits an event causing the parent view to navigate back in the stack. Part of an interface.
	@method navigateBack
	@param {event object} [e] Potentially passed an event object from clicking an a tag or similar.
	*/
	navigateBack: function(e) {
		if (e) { e.preventDefault(); }
		this.trigger('navigateback');
	},

	/**
	Handle excel data being passed up from the graph (GColView).
	@method addGraphToExcel
	@param {Object} data  Plain object passed up by GColView that just needs to get wrapped and
		passed up to the parent view that handles adding things to excel.
	@param {String} type  The type of graph, currently takes 'vertical' or 'horizontal' but I
		don't think it makes a difference right now.
	*/
	addGraphToExcel: function(data, type, properties) {
		var jsonData = {
			name           : this.smartQ.get('name'),
			type           : 'graph',
			data           : data,
			graph_type     : type,
			properties     : properties,
			graph_theme_id : this.project.get('theme_id')
		};
		app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	@method addDataToExcel
	@param {event} [e] Potentially passed the triggering event.
	*/
	addDataToExcel: function(e) {
		if (e) e.preventDefault();

		this.smartQ.toSeriesSet({
			smartPrs : this.smartPrs,
			prs      : this.prs,
			rs       : this.rs,
			parts    : this.parts,
			filters  : this.filters
		})
			.then(_.bind(function(ss){ return ss.toExcelData({parts: this.parts}); }, this))
			.then(function(eData){ console.log(eData); app.ge.trigger('addtoexcel', eData); });
	},

	/**
	Called when the user clicks the edit button, sets up the navigation header controls and calls the
	`renderEditInfo()` method.

	**TODO this should probably delegate the controls stuff to that method.**
	@method editQuestion
	*/
	editQuestion: function() {
		var view = {};
		view.title = this.title;
		view.prevTitle = this.prevTitle;
		view.panel_controls = [
			{
				loner: true,
				label: 'Cancel',
				class_name: 'cancel-edit-question',
				btn_type: 'link'
			}, {
				loner      : true,
				label      : 'Delete',
				class_name : 'delete-question',
				btn_type   : 'danger'
			}, {
				loner: true,
				label: 'Save',
				class_name: 'save-edit-question',
				btn_type: 'primary'
			}
		];

		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);

		this.renderEditInfo();
	},

	/**
	Cancels an edit and discards any changes.
	@method cancelEditQuestion
	*/
	cancelEditQuestion: function() {
		this.renderNavHeader();
		this.renderInfo();
	},

	/**
	Collects the current version of the core smart question data and saves it to the server.
	@method saveEditQuestion
	*/
	saveEditQuestion: function() {
		var data = _.reduce($('#edit-question-form').serializeArray(), function(memo, val) {
			memo[val.name] = val.value;
			return memo;
		}, {});

		// get new num_presentations if you changed the sample_size_question_id for a categorical
		// question
		if (
			data.sample_size_question_id != this.smartQ.get('sample_size_question_id') &&
			this.smartQ.get('type_id') == 1 &&
			data.sample_size_method == 3
		) {
			data.num_presentations = this.qs.get(data.sample_size_question_id).get('num_presentations');
		}

		this.smartQ.save(data, {
			wait    : true,
			success : _.bind(function(mod, res, opts) {}, this),
			error   : function(mod, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m    : 'Problem saving the edited question',
					type : 'alert-danger'
				}));
			}
		});
	},

	/**
	Convenience event handler that initiates prs editing. **TODO probably should be a direct call
	instead**
	@method editPrs
	*/
	editPrs: function(){ this.renderEditPrs(); },

	/**
	Convenience event handler that cancels prs editing. **TODO probably should be a direct call
	instead**
	@method editPrs
	*/
	cancelEditPrs: function(){ this.renderPrs(); },

	/**
	Collect up the new Smart Possible Response names and save them to server
	@method saveEditPrs
	*/
	saveEditPrs: function() {
		var data = this.$('#edit-prs-form').serializeArray();
		var saveProms = [];
		_.each(data, function(update) {
			var spr = this.smartPrs.get(update.name);
			if (update.value != spr.get('response')) {
				saveProms.push(spr.save({response: update.value}, {wait:true}));
			}
		}, this);

		$.when.apply(this, saveProms)
			.fail(_.bind(function() {
				this.trigger('alert', new AlertView({
					m: 'Problem saving smart possible responses',
					type: 'alert-danger'
				}));
			}, this));
	},

	/**
	 * Attempts to delete a smart question from the model after prompting the user.
	 * If dependencies exist, format the error message and display the list of dependencies.
	 * @method deleteQuestion
	 */
	deleteQuestion: function() {
		if (
			window.confirm("Are you sure you want to delete this smart question?\n\n" +
				"This action cannot be undone.")
		) {
			this.smartQ.destroy({
				wait    : true,
				success : _.bind(function(){
					this.navigateBack();
				}, this),
				error   : function(mod, res, opts) {
					if (res.responseJSON.gt_code && res.responseJSON.gt_code == 'sq422') {
						var data = res.responseJSON.data;
						var message = _.reduce(data, function(memo, dep, key) {

							if (dep.length === 0) return memo;

							else if (key == 'smart_questions') {
								memo += '<strong>Smart Questions</strong><ul>';
								memo = _.reduce(dep, function(memo, item) {
									return memo += '<li>' + item.name + '</li>';
								}, memo);
								memo += '</ul>';
							}

							else if (key == 'comparisons') {
								memo += '<strong>Comparisons</strong><ul>';
								memo = _.reduce(dep, function(memo, item) {
									return memo += '<li>' + item.name + '</li>';
								}, memo);
								memo += '</ul>';
							}

							//additional dependencies (i.e. analyses) will go here

							return memo;
						}, '');

						app.ge.trigger('alert', new AlertView({
							m : '<p>Cannot delete because other items depend on this smart questions:</p>' +
								message + '<p>Delete the other items and then you can delete this one',
							type: 'alert-warning'
						}));
					}
				}
			});
		}
	},

	/**
	Navigate into a new FilterView to inspect and possible edit the filter associated with the
	given smart possible response
	@method inspectFilter
	@param {event} [e] Potentially passed an event object from user interaction
	*/
	inspectFilter: function(e) {
		if (e) e.preventDefault();
		var
		id     = $(e.currentTarget).data('id'),
		spr    = this.smartPrs.get(id),
		filter = this.filters.get(spr.get('filter_id'));

		this.trigger('navigateinto', new FilterView({
			filter   : filter,
			qs       : this.qs,
			smartQs  : this.smartQs,
			prs      : this.prs,
			smartPrs : this.smartPrs
		}));
	}
}));