/* global require, module */

var _ = require('underscore'),
		$ = require('jquery'),

		Comparison = require('./Comparison.js'),
		SeriesSet = require('./SeriesSet.js'),
		app = require('../app.js');

/**
@class RatingComparison
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Comparison.extend({
	urlRoot: '/api1/comparison',

	getters: {
		fmt_type: function(){ return 'Basic'; },
		data_type: function(){ return 'Rating'; },
		full_type: function(){ return 'Rating'; }
	},

	toSeriesSet: function(args) {
		var compName   = this.get('name'),
				compID     = +this.get('id'),

				parts      = args.parts,
				prs        = args.prs,
				rs         = args.rs,

				smartPrs   = args.smartPrs,
				filters    = args.filters,

				qs         = args.qs,
				smartQs    = args.smartQs,

				dfd        = $.Deferred();

		// go through each relation and get the DataPoint we need
		$.when.apply(null, this.get('comparing').map(function(rel) {
			// get the SeriesSet that contains the target DataPoint
			var item, relID = +rel.related_id;
			switch (rel.related_to) {
				case 'question':
					item = qs.get(relID);
					break;

				case 'smart_question':
					item = smartQs.get(relID);
					break;

				default: throw new Error('Unexpected relation type');
			}

			return item.toSeriesSet(args);
		}))
		.then(function(/* SeriesSets */) {
			var seriesSets = _.toArray(arguments), dataPoints = [];

			this.get('comparing').forEach(function(rel, index) {
				var ss = seriesSets[index], relID = +rel.related_id;

				if (ss.get('series').length != 1) throw new Error('Expected exactly 1 DataSeries');
				if (ss.get('series')[0].data.length != 1) throw new Error('Expected exactly 1 DataPoint');

				// Double check the DataPoint matches what we'd expect
				var dp = ss.get('series')[0].data[0];

				if (dp.source_type != rel.related_to || dp.source_id != rel.related_id) {
					throw new Error('Unexpected DataPoint type');
				}

				// modify it to fit the SeriesSet we want to return
				dp.label = rel.label;
				dataPoints.push(dp);
			});

			var dataSeries = {
				name : compName,
				seq: null,
				type: 'rating',
				data: dataPoints
			};

			dfd.resolve(new SeriesSet({
				series : [dataSeries],
				name   : compName,
				type   : 'rating'
			}));
		}.bind(this));


		return dfd;
	},

	comparing_readable: function(args) {
		var qs     = args.qs;								//questions

		var kids   = this.get('comparing');
		var series = { info: 'This comparison compares questions', members: []};

		_.each(kids, function(item) {
			if (item.related_to == 'question') {
				var q  = qs.get(item.related_id);
				var obj={
					'response'     : 'Rating',
					'response_raw' : '--',
					'prompt'       : q.get('name'),
					'label'        : item.label
				};
				series.members.push(obj);
			}
		});

		return series;
	}

});