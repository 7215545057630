/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		FilterContainerView = require('./FilterContainerView.js'),
		app = require('../app.js');

/**
@class FilterView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	// the standard page `el`
	tagName: 'div',

	tpl : $('#FilterView-tpl').html(),

	/**
	@property events
	@type Object
	@private
	*/
	events: {
		'click .back'             : 'navigateBack',
		'click .cancel-edit'      : 'navigateBack',
		'click .save-edit-filter' : 'saveEditFilter'
	},

	/**
	Sets up properties, creates new {{#crossLink "FilterContainerView"}}FilterContainerView
	{{/crossLink}}.

	@method initialize
	@param {object} opts Configuration object. Follows the format:

		{
			filter   : {{#crossLink "Filter"}}Filter{{/crossLink}},
			qs       : {{#crossLink "Questions"}}Questions{{/crossLink}},
			prs      : {{#crossLink "PossibleResponses"}}PossibleResponses{{/crossLink}},
			smartQs  : {{#crossLink "SmartQuestions"}}SmartQuestions{{/crossLink}},
			smartPrs : {{#crossLink "SmartPossibleResponses"}}SmartPossibleResponses{{/crossLink}}
		}

	All fields are currently required.
	*/
	initialize: function(opts) {

		/**
		@property filter
		@type Filter
		*/
		this.filter   = opts.filter;

		/**
		@property qs
		@type Questions
		*/
		this.qs       = opts.qs;

		/**
		@property prs
		@type PossibleResponses
		*/
		this.prs      = opts.prs;

		/**
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs  = opts.smartQs;

		/**
		@property smartPrs
		@type SmartPossibleResponses
		*/
		this.smartPrs = opts.smartPrs;

		/**
		@property title
		@type String
		@default 'Filter Detail'
		@final
		*/
		this.title     = 'Filter Detail';

		/**
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		@property filterCont
		@type FilterContainerView
		*/
		this.filterCont = new FilterContainerView({
			qs       : this.qs,
			prs      : this.prs,
			smartQs  : this.smartQs,
			smartPrs : this.smartPrs,
			isTop    : true
		});

		this.listenTo(this.filterCont, 'isnew', this.enableSave);
	},

	/**
	Gets the current {{#crossLink "FilterView/title:property"}}`title`{{/crossLink}} property.

	@method getTitle
	@return String
	*/
	getTitle: function() {return this.title;},

	/**
	Set's the {{#crossLink "FilterView/prevTitle:property"}}`prevTitle`{{/crossLink}} property.

	@method setPrevTitle
	*/
	setPrevTitle: function(prev) {this.prevTitle = prev;},

	/**
	Hides the current view. Part of an interface.

	@method hide
	*/
	hide: function() {this.$el.hide();},

	/**
	Shows the current view. Part of an interface.

	@method show
	*/
	show: function() {this.$el.show();},

	/**
	Emits an event causing the parent view to navigate back in the stack. Part of an interface.

	@method navigateBack
	@param {event object} [e] Potentially passed an event object from clicking an a tag or similar.
	*/
	navigateBack: function(e) {
		if (e) { e.preventDefault(); }

		/**
		Tells a parent view to close this one and navigate back in the stack. Part of an interface.

		@event navigateback
		*/
		this.trigger('navigateback');
	},

	/**
	Closes the view.
	@method close
	*/
	close: function() {this.remove();},

	/**
	Set's `el` to contain the view's DOM structure, then calls `renderNavHeader` and
	`renderEditFilter`.

	@method render
	@chainable
	*/
	render: function() {
		var view = {};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.renderNavHeader();
		this.renderEditFilter();
		return this;
	},

	/**
	Populates the navigation header with `prevTitle` and `title`. Adds controls which consist solely
	of 'Edit' right now.

	@method renderNavHeader
	@param {Array} [panel_controls] Optional array of controls for the navigation header.
	*/
	renderNavHeader: function(panel_controls) {
		var view = {};
		view.title = this.title;
		view.prevTitle = this.prevTitle;
		view.panel_controls = panel_controls || [
			{
				loner      : true,
				label      : 'Cancel',
				class_name : 'cancel-edit',
				btn_type   : 'link'
			}, {
				loner      : true,
				label      : 'Save',
				class_name : 'disabled',
				btn_type   : 'primary'
			}
		];
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);
	},

	/**
	Populates the top level `FilterContainerView` with the contents of the associated filter.
	@method renderEditFilter
	*/
	renderEditFilter: function() {
		this.filterCont.populateWithFilter(this.filter);
		this.$('.edit-filter').html(this.filterCont.render().el);
	},

	/**
	Called when the FilterContainerView emits the 'isnew' event.
	@method enableSave
	*/
	enableSave: function() {
		this.renderNavHeader([{
				loner      : true,
				label      : 'Cancel',
				class_name : 'cancel-edit',
				btn_type   : 'link'
			}, {
				loner      : true,
				label      : 'Save',
				class_name : 'save-edit-filter',
				btn_type   : 'primary'
		}]);
	},

	/**
	@method saveEditFilter
	@param {event} [e] jQuery event potentially passed in.
	*/
	saveEditFilter: function(e) {
		if (e) e.preventDefault();
		var updated = this.filterCont.toJSONGraph();
		this.filter.save({
			children: updated.children,
			operator: updated.operator
		}, {
			success: _.bind(function(mod, res, opts) {
				this.navigateBack();
			}, this),
			error: function(mod, res, opts) {
			}
		});
	}
});
