/* global require, module */

var Backbone = require('backbone'),
		CrosstabAnalytic  = require('../models/CrosstabAnalytic.js'),
		SUPRQLiteAnalytic = require('../models/SUPRQLiteAnalytic.js'),
		SUPRQFullAnalytic = require('../models/SUPRQFullAnalytic.js'),
		SUSAnalytic       = require('../models/SUSAnalytic.js');

/**
@class Analytics
@constructor
@extends Backbone.Collection
@module Collections
*/
module.exports = Backbone.Collection.extend({
	model: function(attrs, opts) {
		switch(attrs.type) {
			case 'crosstab':
				return new CrosstabAnalytic(attrs, opts);
			case 'suprqlite':
				return new SUPRQLiteAnalytic(attrs, opts);
			case 'suprqfull':
				return new SUPRQFullAnalytic(attrs, opts);
			case 'sus':
				return new SUSAnalytic(attrs, opts);
			default:
				return null;
		}
	},

	url: function(){ return '/api1/analytic/all_for_project/' + this.projID; },

	/**
	@method initialize
	@param {Array} models Models to start the collection with
	@param {Object} opts Options to pass in, `projID` is currently required
	*/
	initialize: function(models, opts) {
		this.projID = opts.projID || null;
	},

	/**
	@method parse
	@param {Object} res The ajax data payload
	@param {Object} opts See backbone docs
	@return {Object} Data ready to be set on the model
	*/
	parse: function(res, opts){ return res.data; }
});