/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
The header bar at the top that is static across all pages.
@class HeaderView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({

	tagName   : 'nav',
	className : 'navbar navbar-static-top navbar-default col-md-12',

	tpl       : $('#HeaderView-tpl').html(),

	events : {
		'click .nav-projects': 'gotoProjectsView',
		'click .nav-account': 'gotoAccountView',
		'click .nav-admin': 'gotoAdminView',
		'click .sign-out': 'signOut'
	},

	/**
	Just inserts the requisite DOM nodes into the page
	@method render
	@chainable
	*/
	render: function() {
		this.$el.html(Mustache.render(this.tpl, {userLoggedIn : app.user.get('logged_in')}, app.partials()));
		return this;
	},

	/**
	Invoke the projects route.
	@method gotoProjectsView
	@param {event} [e] The initiating event
	*/
	gotoProjectsView: function(e) {
		if (e) e.preventDefault();
		app.routes.navigate('projects', {trigger: true});
	},

	/**
	Invoke the account route.
	@method gotoAccountsView
	@param {event} [e] The initiating event
	*/
	gotoAccountView: function(e) {
		if (e) e.preventDefault();
		app.routes.navigate('account', {trigger: true});
	},

	/**
	Invoke the account route.
	@method gotoAccountsView
	@param {event} [e] The initiating event
	*/
	gotoAdminView: function(e) {
		if (e) e.preventDefault();
		app.routes.navigate('admin', {trigger: true});
	},

	/**
	Go to the login page.
	@method signOut
	@param {event} [e] The initiating event
	*/
	signOut: function(e) {
		if (e) e.preventDefault();
		app.user.clear();
		$.ajax({
			type: 'POST',
			url: '/api1/authenticate/signout'
		});
		this.$el.html(Mustache.render(this.tpl, {}, app.partials()));
		app.routes.navigate('login', {trigger: true});
	}
});