/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		AlertView                = require('./AlertView.js'),
		ParticipantResponsesView = require('./ParticipantResponsesView.js'),
		app = require('../app.js');

module.exports = Backbone.View.extend({
	tagName: 'div',
	tpl: $('#ExcludeParticipantView-tpl').html(),
	partTpl: _.template($('#ExcludeParticipantView-part-tpl').html()),

	events: {
		'click .back': 'navigateBack',
		'click .next': 'cycleNext',
		'click .prev': 'cyclePrevious',
		'click .exclude-toggle': 'toggleExclusion'
	},

	initialize: function(opts) {
		this.parts = opts.parts;
		this.qs = opts.qs;
		this.rs = null;
		this.prs = null;
		this.projectID = opts.projectID;
		this.title = 'Inspect Participant';
		this.prevTitle = 'Back';
		this.checkedQIDs = opts.checkedQIDs || [];
		this.inspectingIndex = null;

		var rsProm = $.ajax({
			url: '/api1/response/all_for_project/' + this.projectID,
			type: 'GET',
			dataType: 'json'
		});

		rsProm.done(_.bind(function(res, status, xhr) {
			this.rs = res.data;
		}, this)).fail(function(xhr, status, err) {
			console.log(xhr);
			console.log(status);
			console.log(err);
			console.log(status.responseText);
			app.ge.trigger('alert', new AlertView({
				m: 'Problem loading responses',
				type: 'alert-danger'
			}));
		});

		var prsProm = $.ajax({
			url: '/api1/possible_response/all_for_project/' + this.projectID,
			type: 'GET',
			dataType: 'json'
		});

		prsProm.done(_.bind(function(res, status, xhr) {
			this.prs = res;
		}, this)).fail(function(xhr, status, err) {
			app.ge.trigger('alert', new AlertView({
				m: 'Problem loading possible responses',
				type: 'alert-danger'
			}));
		});

		$.when(rsProm, prsProm).done(_.bind(function() {
			this.inspectPart(0);
		}, this));

		$(document).bind('keydown', _.bind(this.keyCycle, this));
	},

	render: function() {

		var view = {
			title          : this.title,
			prevTitle      : this.prevTitle,
			panel_controls : {
				group						: {
					contents				: [
						{
							class_name : 'prev',
							label      : 'Prev'
						},
						{
							class_name : 'exclude-toggle',
							label      : 'Exclude'
						},
						{
							class_name : 'next',
							label      : 'Next'
						}
					]
				}
			}
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	close: function() {
		this.stopListening();
		this.remove();
	},

	getTitle: function() {return this.title;},
	setPrevTitle: function(prev) {this.prevTitle = prev;},
	hide: function() {this.$el.hide();},
	show: function() {this.$el.show();},

	navigateBack: function(e) {
		if (e) {
			e.preventDefault();
		}
		this.trigger('navigateback');
	},

	inspectPart: function(index) {
		// don't do anything if the selected index is out of range
		var max = this.parts.length;
		if (index >= max) {
			return;
		}
		if (index < 0) {
			return;
		}

		// save where we're at
		this.inspectingIndex = index;
		var part = this.parts.at(index);
		var partID = part.get('id');

		var responsesView = new ParticipantResponsesView({
			participantID : +partID,
			projID        : +this.projectID,
			comparator    : function(a, b) {
				if(a.get('type_id') == 4) return -1;
				return 0;
			}
		});

		var questions = this.qs.filter(function(q) {
			return _.contains(this.checkedQIDs, q.get('id'));
		}, this);

		var prsAndRs = _.chain(this.prs)
			.filter(function(pr){
				return _.contains(this.checkedQIDs, pr.question_id);
			}, this)
			.reduce(function(memo, pr){
				var r = _.where(this.rs, {
					participant_id: partID,
					possible_response_id: pr.id
				}, this);
				if (r.length > 0) {
					r = r[0];
					pr.value = r.value;
					pr.text = r.text;
					return memo.concat(pr);
				} else {
					return memo;
				}
			}, [], this)
			.value();

		var data = _.reduce(questions, function(memo, q) {
			var r = _.findWhere(prsAndRs, {question_id: q.get('id')});
			var response;
			if (+r.has_value === 1) {
				response = r.value;
			} else if (+r.has_text === 1) {
				response = r.text;
			} else {
				response = r.response;
			}

			var hasFlag, flag;
			if (q.has('comparison') && +r.has_value === 1) {
				hasFlag = true;
				switch (q.get('comparison')) {
					case 'greaterthan':
						flag = Number(response) <= Number(q.get('expected'));
						break;
					case 'lessthan':
						flag = Number(response) >= Number(q.get('expected'));
						break;
					case 'equals':
						flag = response != q.get('expected');
						break;
					case 'notequals':
						flag = response == q.get('expected');
						break;
				}
			} else {
				hasFlag = false;
			}
			return memo.concat({prompt: q.get('prompt'),
								response: response,
								name: q.get('name'),
								flagged: hasFlag ? flag : false});
		}, [], this);

		var isExcluded = +part.get('excluded') === 1;

		// stick the data in there
		this.$('.part-content').html(this.partTpl({
			participant: part.get('name'),
			numResps: _.reduce(this.rs, function(memo, r) {
				if (r.participant_id == partID) {
					return memo + 1;
				} else {
					return memo;
				}
			}, 0),
			data: data,
			excluded: isExcluded
		}));

		if (isExcluded) {
			this.$('.exclude-toggle').html('Include');
			this.$('.exclusion-state').html(
				'<span class="label label-danger">Excluded</span>'
			);
		}
		else {
			this.$('.exclude-toggle').html('Exclude');
			this.$('.exclusion-state').html(
				'<span class="label label-success">Included</span>'
			);
		}

		// update the UI to reflect state
		if (index === 0) {
			this.$('.prev').toggleClass('disabled', true);
		} else {
			this.$('.prev').toggleClass('disabled', false);
		}
		if (index === max - 1) {
			this.$('.next').toggleClass('disabled', true);
		} else {
			this.$('.next').toggleClass('disabled', false);
		}

		var uiIndex = index + 1;
		this.$('.part-counter').html(' (' + uiIndex + '/' + max + ')');

		this.$('#responses-area').html(responsesView.render().el);

	},

	cycleNext: function(e) {
		if (e) {
			e.preventDefault();
		}
		this.inspectPart(this.inspectingIndex + 1);
	},

	cyclePrevious: function(e) {
		if (e) {
			e.preventDefault();
		}
		this.inspectPart(this.inspectingIndex - 1);
	},

	keyCycle: function(e) {
		var isD = e.which == 68;
		var isA = e.which == 65;
		var isW = e.which == 87;
		var isRarr = e.which == 39;
		var isLarr = e.which == 37;
		var isUarr = e.which == 38;

		if (!_.isNull(this.inspectingIndex)) {
			if (isD || isRarr) {
				this.cycleNext();
			}

			if (isA || isLarr) {
				this.cyclePrevious();
			}

			if (isW || isUarr) {
				this.toggleExclusion();
			}
		}
	},

	toggleExclusion: function() {
		var part = this.parts.at(this.inspectingIndex);
		var isExcluded = +part.get('excluded') === 1;

		if (isExcluded) {
			this.$('.exclude-toggle').html('Exclude');
			this.$('.exclusion-state').html(
				'<span class="label label-success">Included</span>'
			);
			part.save('excluded', 0, {
				success: function(model, res, opts) {

				},
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					}));
				}
			});
		} else {
			this.$('.exclude-toggle').html('Include');
			this.$('.exclusion-state').html(
				'<span class="label label-danger">Excluded</span>'
			);
			part.save('excluded', 1, {
				success: function(model, res, opts) {

				},
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					}));
				}
			});
		}
	}

});