/* global require, module */

var Backbone = require('backbone'),
		Comparison = require('../models/Comparison.js'),
		SingleCategoricalComparison = require('../models/SingleCategoricalComparison.js'),
		RatingComparison = require('../models/RatingComparison.js'),
		TimeComparison = require('../models/TimeComparison.js'),
		ContinuousComparison = require('../models/ContinuousComparison.js'),
		MultipleCategoricalComparison = require('../models/MultipleCategoricalComparison.js'),
		MetaCategoricalComparison = require('../models/MetaCategoricalComparison.js'),
		MetaRatingComparison = require('../models/MetaRatingComparison.js'),
		MetaTimeComparison = require('../models/MetaTimeComparison.js'),
		MetaContinuousComparison = require('../models/MetaContinuousComparison.js');

module.exports = Backbone.Collection.extend({
	model: function(attrs, opts) {
		switch (attrs.type) {
			case 'single_categorical'   : return new SingleCategoricalComparison(attrs, opts);
			case 'rating'               : return new RatingComparison(attrs, opts);
			case 'time'                 : return new TimeComparison(attrs, opts);
			case 'continuous'           : return new ContinuousComparison(attrs, opts);
			case 'multiple_categorical' : return new MultipleCategoricalComparison(attrs, opts);
			case 'meta_categorical'     : return new MetaCategoricalComparison(attrs, opts);
			case 'meta_rating'          : return new MetaRatingComparison(attrs, opts);
			case 'meta_time'            : return new MetaTimeComparison(attrs, opts);
			case 'meta_continuous'      : return new MetaContinuousComparison(attrs, opts);
			default                     : throw new Error('Unexpected comparison type');
		}
	},

	url: function() {
		if (this.qID) {
			return '/api1/possible_response/all_for_question/' + this.qID;
		}

		if (this.projID) {
			return '/api1/comparison/all_for_project/' + this.projID;
		}
	},

	initialize: function(models, opts) {
		this.qID    = opts.qID || null;
		this.projID = opts.projID || null;
	},

	parse: function(res) {
		return res.data;
	},

	getBasic: function() {
		return this.reject(function(comp) {
			var nonBasic = comp.get('type') == 'multiple_categorical' ||
						comp.get('type') == 'meta_categorical' ||
						comp.get('type') == 'meta_rating' ||
						comp.get('type') == 'meta_time';
			return nonBasic;
		});
	},

	getNonBasic: function() {
		return this.filter(function(comp) {
			var nonBasic = comp.get('type') == 'multiple_categorical' ||
						comp.get('type') == 'meta_categorical' ||
						comp.get('type') == 'meta_rating' ||
						comp.get('type') == 'meta_time';
			return nonBasic;
		});
	}
});
