/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery');

/**
Base class for an item in a ListView. Must be subclassed as an actual item will require some
specific implementation details:

- You must implement the 'pick' event, which should be triggered when a user has 'pick'ed the item.
(For example, the user has clicked an item to see more details about it or has selected a checkbox
indicating the current item should be added to a selection.)
- Implement the render function along with a template string probably
- You'll want to override initialize to add listeners to the model for rerendering (if you expect
changes). Note you must call the super initializer.
- Might want to override close if you need to dispose of child views
- You can override the selectedClass property in the initializer if you want a different class to be
set on the `el` when the item is selected (default is just 'selected')


@class ListItemView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({

	/**
	@property tagName
	@type string
	@default 'tr'
	*/
	tagName: 'tr',

	/**
	Set up a isSelected property, the default selectedClass
	@method initialize
	*/
	initialize: function() {
		/**
		Whether the item is currently selected. Read only enforced through a getter
		@property isSelected
		@type boolean
		@default false
		*/
		this._isSelected = false;
		Object.defineProperty(this, 'isSelected', {
			get: function(){ return this._isSelected; }.bind(this),
		});

		/**
		The class name that gets toggled by selection
		@property selectedClass
		@type string
		@default 'selected'
		*/
		this.selectedClass = 'selected';
	},

	/**
	Default pass-thru to remove
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Sets the item to the selected state. Default implementation is to add a 'selected' class to the
	`el`.
	@method select
	*/
	select: function() {
		this._isSelected = true;
		this.$el.addClass(this.selectedClass);
	},

	/**
	Sets the item to the unselected state by reversing the effects of ListItemView#select.
	@method deselect
	*/
	deselect: function() {
		this._isSelected = false;
		this.$el.removeClass(this.selectedClass);
	},

	/**
	Toggles the selected state and corresponding UI
	@method toggleSelect
	*/
	toggleSelect: function() {
		this._isSelected = !this.isSelected;
		this.$el.toggleClass('selected');
	},

	/**
	Adds the GT--display-none class to the el
	@method hide
	*/
	hide: function(){ this.$el.addClass('GT--display-none'); },

	/**
	Removes the GT--display-none class from the el
	@method show
	*/
	show: function(){ this.$el.removeClass('GT--display-none'); },

	/**
	Toggles the presence of the GT--display-none calss on the el
	@method toggleVisibility
	*/
	toggleVisibility: function(){ this.$el.toggleClass('GT--display-none'); }

});