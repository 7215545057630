/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Questions                = require('../collections/Questions.js'),
		SmartQuestions           = require('../collections/SmartQuestions.js'),
		Comparisons              = require('../collections/Comparisons.js'),
		AlertView                = require('./AlertView.js'),
		QuestionItemView         = require('./QuestionItemView.js'),
		SmartQuestionItemView    = require('./SmartQuestionItemView.js'),
		CompItemView             = require('./CompItemView.js'),
		QuestionView             = require('./QuestionView'),
		SmartQuestionView        = require('./SmartQuestionView.js'),
		CompView                 = require('./CompView.js'),
		CreateSmartQuestionView  = require('./CreateSmartQuestionView.js'),
		CreateComparisonView     = require('./CreateComparisonView.js'),
		CreateOuterComparisonView     = require('./CreateOuterComparisonView.js'),
		QuestionsListView        = require('./QuestionsListView.js'),
		QuestionsHeaderView      = require('./QuestionsHeaderView.js'),
		CombineQuestionsView     = require('./CombineQuestionsView.js'),
		CreateQuestionView      = require('./CreateQuestionView.js'),
		UpdateQuestionView      = require('./UpdateQuestionView.js'),
		typeahead = require('typeahead'),
		app = require('../app.js');

/**
@class ProjectQuestionsView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#ProjectQuestionsView-tpl').html(),
	noQsTpl: $('#ProjectQuestionsView-no-qs-tpl').html(),

	events: {
		'click .create-smart': 'createSmart',
		'click .create-comparison': 'createComparison',
		'click .create-outer-comparison': 'createOuterComparison',
		'click .create-question': 'createQuestions',
		'click .update-question': 'updateQuestions',
		'click .combine-questions': 'startCombiningQuestions',
		'click .combine-selected-questions': 'combineSelectedQuestions',
		'click .cancel-combine-questions': 'cancelCombineQuestions'

	},

	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Basic administrative methods

	/**
	Sets up the instance properties, starts loading up the questions, smart_questions, and
	comparisons.
	@method initialize
	@param {object} opts The constructor options
	*/
	initialize: function(opts) {
		/**
		Reference to the current project model
		@property project
		@type Project
		*/
		this.project = opts.project;

		/**
		The current project name
		@property projectName
		@type string
		*/
		this.projectName = this.project.get('name');

		/**
		The current project ID
		@property projID
		@type Number
		*/
		this.projID      = this.project.get('id');

		/**
		@property title
		@type string
		@default 'All Saved Questions'
		@final
		*/
		this.title = 'All Saved Questions';

		/**
		@property qs
		@type Questions
		*/
		this.qs = new Questions([], {projID: this.projID});

		/**
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs = new SmartQuestions([], {projID: this.projID});

		/**
		@property comps
		@type Comparisons
		*/
		this.comps = new Comparisons([], {projID: this.projID});

		this.smartQItemViews = [];
		this.compItemViews   = [];

		/**
		Manages the display and interaction with the list of questions.
		@property questionsListView
		@type QuestionsListView
		*/
		this.questionsListView = new QuestionsListView({
			projID: this.projID,
			collection: this.qs,
		});

		// pass along any new views
		this.listenTo(this.questionsListView, 'navigateinto', function(view) {
			this.trigger('navigateinto', view);
		});

		// view a question when it's picked
		this.questionsListView.registerPickHandler(function(model, view, props) {
			this.trigger('navigateinto', new QuestionView({q: model, project: this.project, allQs: this.qs}));
		}.bind(this));

		// load all the data
		qsProm = this.qs.fetch({reset: true, error: this.handleNetworkError})
		$.when(qsProm).done(_.bind(
			function(qRes){
				var questionNames = _.map(qRes.data,function(q) {
				  return q.name;
				});
				
				this.initializeTypeAhead('search-input',questionNames);
			}
		,this));

		this.smartQs.fetch({
			reset   : true,
			success : _.bind(this.addAllSmartQs, this),
			error   : function(collection, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem loading the smart questions ' + res.status,
					type: 'alert-danger'
				}));
			}
		});
		this.listenTo(this.smartQs, 'add', this.addSmartQ);

		this.comps.fetch({
			reset   : true,
			success : _.bind(this.addAllComps, this),
			error   : function(col, res, opts) {
				app.ge.trigger('alert', new AlertView({
					m: 'Problem loading the comparisons',
					type: 'alert-danger'
				}));
			}
		});
		this.listenTo(this.comps, 'add', this.addComp);
	},

	/**
	Sticks the basic skeleton for the page in the `el`.
	@method render
	@chainable
	*/
	render: function() {
		var view = {
			title: this.title,
			questions: this.defaultQuestionsPanel
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.questionsListView.setElement(this.$('.questions-list')).render();
		return this;
	},

	/**
	Calls close on each child view, removes itself
	@method close
	*/
	close: function() {
		this.questionsListView.close();
		_.each(this.smartQItemViews, function(sq){ sq.close(); });
		_.each(this.compItemViews, function(c){ c.close(); });
		this.remove();
	},

	/**
	Extract the response message and display it in an alert
	@method handleNetworkError
	@param {Backbone.Collection} col,
	@param {object} res The response
	@param {object} opts Backbone options
	*/
	handleNetworkError: function(col, res, opts) {
		app.ge.trigger('alert', new AlertView({m: res.m, type: 'alert-danger'}));
	},


	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Rendering entire collections

	/**
	Called when the SmartQuestions collection finishes loading. Creates a SmartQuestionItemView for
	each model. Builds a fragment and then sticks everything in the DOM at once.
	@method addAllSmartQs
	@param {SmartQuestions} col The full collection
	*/
	addAllSmartQs: function(col) {
		this.$('.smart-q-item-mount .loading').remove();
		var frag = document.createDocumentFragment();
		if (this.smartQs.length > 0) {
			this.smartQs.each(function(q) {
				var smartQItemView = new SmartQuestionItemView({model: q});
				this.smartQItemViews.push(smartQItemView);
				this.listenTo(smartQItemView, 'view', this.viewSmartQ);
				this.listenTo(smartQItemView, 'deleted', this.deletedSmartQItemView);
				$(frag).append(smartQItemView.render().el);
			}, this);
		} else {
			$(frag).append(Mustache.render(this.noQsTpl));
		}

		this.$('.smart-q-item-mount').html(frag);
	},

	/**
	Called when the Comparisons collection finishes loading. Creates a CompItemView for each model,
	builds a fragment out of them and then puts everything in the DOM at once.
	@method addAllComps
	@param {Comparisons} col The full collection
	*/
	addAllComps: function(col) {
		this.$('.comp-item-mount .loading').remove();
		var frag = document.createDocumentFragment();
		if (this.comps.length > 0) {
			this.comps.each(function(comp) {
				var compItemView = new CompItemView({model: comp});
				this.compItemViews.push(compItemView);
				this.listenTo(compItemView, 'view', this.viewComp);
				this.listenTo(compItemView, 'deleted', this.deletedCompItemView);
				$(frag).append(compItemView.render().el);
			}, this);
		} else {
			$(frag).append(Mustache.render(this.noQsTpl));
		}
		this.$('.comp-item-mount').html(frag);
	},


	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Adding and rendering individual models

	/**
	Appends a single SmartQuestionItemView for a new model to the list.
	@method addSmartQ
	@param {SmartQuestion} mod The new model to represent
	*/
	addSmartQ: function(mod) {
		var smartQItemView = new SmartQuestionItemView({model: mod});
		this.smartQItemViews.push(smartQItemView);
		this.listenTo(smartQItemView, 'view', this.viewSmartQ);
		this.listenTo(smartQItemView, 'deleted', this.deletedSmartQItemView);

		if(this.smartQItemViews.length === 1) {
			this.$('.smart-q-item-mount').html(smartQItemView.render().el);
		}
		else {
			this.$('.smart-q-item-mount').append(smartQItemView.render().el);
		}
	},

	/**
	Appends a single CompItemView for a new model to the list.
	@method addComp
	@param {Comparison} mod The new comparison model to represent
	*/
	addComp: function(mod) {
		var compItemView = new CompItemView({model: mod});
		this.compItemViews.push(compItemView);
		this.listenTo(compItemView, 'view', this.viewComp);
		this.listenTo(compItemView, 'deleted', this.deletedCompItemView);

		if(this.smartQItemViews.length === 1) {
			this.$('.comp-item-mount').html(compItemView.render().el);
		}
		else {
			this.$('.comp-item-mount').append(compItemView.render().el);
		}
	},


	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Deleting individual models/views

	/**
	Removes the passed SmartQuestionItemView from the internal array, if the collection is now empty
	render the no-questions template.
	@method deletedSmartQItemView
	@param {SmartQuestionItemView} view The deleted view
	*/
	deletedSmartQItemView: function(view) {
		this.smartQItemViews = _.reject(this.smartQItemViews, function(sqiv){ return sqiv == view; });
		if(this.smartQItemViews.length === 0) {
			this.$('.smart-q-item-mount').html(Mustache.render(this.noQsTpl));
		}
	},

	/**
	Removes the passed CompItemView from the internal array, if the collection is now empty render
	the no-questions template.
	@method deletedCompItemView
	@param {CompItemView} view The deleted view
	*/
	deletedCompItemView: function(view) {
		this.compItemViews = _.reject(this.compItemViews, function(civ){ return civ == view; });
		if(this.smartQItemViews.length === 0) {
			this.$('.comp-item-mount').html(Mustache.render(this.noQsTpl));
		}
	},


	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Viewing individual models

	/**
	Trigger a navigation into a SmartQuestionView
	@method viewSmartQ
	@param {SmartQuestion} q The SmartQuestion model to view
	*/
	viewSmartQ: function(q) {
		this.trigger('navigateinto', new SmartQuestionView({
			smartQ  : q,
			qs      : this.qs,
			smartQs : this.smartQs,
			project : this.project
		}));
	},

	/**
	Trigger a navigation into a CompView
	@method viewComp
	@param {Comparison} comp The Comparison model to view
	*/
	viewComp: function(comp) {
		this.trigger('navigateinto', new CompView({
			comp    : comp,
			comps   : this.comps,
			qs      : this.qs,
			smartQs : this.smartQs,
			project : this.project
		}));
	},

	//////////////////////////////////////////////////////////////////////////////////////////////////
	// Creating new models

	/**
	Trigger the navigation into a CreateSmartQuestionView
	@method createSmart
	*/
	createSmart: function() {
		this.trigger('navigateinto', new CreateSmartQuestionView({
			projID: this.projID,
			qs: this.qs,
			smartQs: this.smartQs
		}));
	},

	/**
	Trigger the navigation into a CreateComparisonView
	@method createComparison
	*/
	createComparison: function() {
		var v = new CreateComparisonView({
			projID: this.projID,
			qs: this.qs,
			smartQs: this.smartQs
		});
		this.listenTo(v, 'comparison:saved', this.addComp);
		this.trigger('navigateinto', v);
	},

	/**
	Trigger the navigation into a CreateOuterComparisonView
	@method createOuterComparison
	*/
	createOuterComparison: function() {
		var v = new CreateOuterComparisonView({
			projID: this.projID,
			qs: this.qs,
			smartQs: this.smartQs
		});
		this.listenTo(v, 'comparison:saved', this.addComp);
		this.trigger('navigateinto', v);
	},


	/**
	Trigger the navigation into a CreateQuestionView
	@method createQuestion
	*/
	createQuestions: function() {
	    app.routes.navigate(window.location.href + '/new');
		this.trigger('navigateinto', new CreateQuestionView({
			projID: this.projID,
			project: this.project
		}));
	},

	/**
	Trigger the navigation into a UpdateQuestionView
	@method updateQuestion
	*/
	updateQuestions: function() {
		this.trigger('navigateinto', new UpdateQuestionView({
			projID: this.projID,
			project: this.project
		}));
	},

	/**
	Begins the startCombiningQuestions mode where you select the questions to combine.
	@method startCombiningQuestions
	@param {event} [e] The initiating event
	*/
	startCombiningQuestions: function(e) {
		if (e) e.preventDefault();

		this.$('.questions-list .panel-heading').replaceWith(Mustache.render(
			'{{> GT_nav_panel}}',
			{
				title: 'Select the questions you wish to combine',
				panel_controls: [
					{loner: {label: 'Cancel', btn_type: 'link', class_name: 'cancel-combine-questions'}},
					{loner: {label: 'Combine Selected', btn_type: 'primary',
							class_name: 'combine-selected-questions'}}
				]
			},
			app.partials()
		));
		this.questionsListView.enableSelection();
	},
	//////////////////////////////////////////////////////////////////////////////////////////////////
	// combining/expanding questions

	initializeTypeAhead : function(id, allQNames){

		$('.'+id).typeahead({
		  hint: true,
		  highlight: true,
		  minLength: 1
		},
		{
		  name: 'questions',
		  displayKey: 'value',
		  source: this.substringMatcher(allQNames)
		}).bind('typeahead:selected', function(obj,datum,name){
			console.log(datum.value);
			$('[title="' + datum.value + '"]').trigger('click');
		});

	},

	substringMatcher : function(strs) {
		return function findMatches(q, cb) {
			var matches, substrRegex;

			// an array that will be populated with substring matches
			matches = [];

			// regex used to determine if a string contains the substring `q`
			substrRegex = new RegExp(q, 'i');

			// iterate through the pool of strings and for any string that
			// contains the substring `q`, add it to the `matches` array
			$.each(strs, function(i, str) {
			if (substrRegex.test(str)) {
			// the typeahead jQuery plugin expects suggestions to a
			// JavaScript object, refer to typeahead docs for more info
			matches.push({ value: str });
			}
			});

			cb(matches);
		}

	},
}));
