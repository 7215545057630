/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavCompMixin           = require('../mixins/NavigationComponentMixin.js'),
		NewQuestionDetailsView = require('./NewQuestionDetailsView.js'),
		AlertView              = require('./AlertView.js'),
		app                    = require('../app.js');

/**
Enables a user to create one or more new questions based on the data from a single original
question. This handles use cases like N/A options being included in rating scales or piped
questions.
@class ExpandQuestionView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavCompMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl: $('#ExpandQuestionView-tpl').html(),

	events: {
		'click .back': 'navigateBack',
		'click .add-new-question': 'addQuestion',
		'click .save-expansion': 'saveExpansion'
	},

	initialize: function(opts) {
		/**
		@property projID
		@type number
		*/
		this.projID = opts.projID;

		/**
		@property title
		@type string
		*/
		this.title = 'Split into new questions';

		/**
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		The original question model that is being expanding into multiple new questions.
		@property origQ
		@type Question
		*/
		this.origQ = opts.q;

		/**
		Reference to the collection of all questions in this project
		@property allQs
		@type Questions
		*/
		this.allQs = opts.allQs;

		/**
		This is the possible_response to the original question
		@property prs
		@type PossibleResponses
		*/
		this.prs = opts.prs;

		/**
		Collection of the NewQuestionDetailsViews used by the page. Starts with a single view
		initialized.
		@property detailViews
		@type array<NewQuestionDetailsView>
		*/
		this.detailViews = [new NewQuestionDetailsView(
			{origQ:this.origQ, first:true, prs:this.prs, projID: this.projID}
		)];
	},

	/**
	Stick the basic view's dom into the `el`.
	@method render
	@chainable
	*/
	render: function() {
		var view = {
			title         : this.title,
			prevTitle     : this.prevTitle,
			name          : this.origQ.get('name'),
			num_responses : this.origQ.get('num_responses'),
			sample_size   : this.origQ.get_sample_size(),
			panel_controls: {
				loner      : true,
				label      : 'Save',
				btn_type   : 'primary',
				class_name : 'save-expansion'
			}
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		var frag = app.renderViewsToFrag(this.detailViews);
		this.$('.new-questions').html(frag);
		return this;
	},

	/**
	Adds a new NewQuestionDetailsView to the list and renders it.
	@method addQuestion
	@param {event} [e] The initiating event
	*/
	addQuestion: function(e) {
		var newView = new NewQuestionDetailsView(
			{origQ: this.origQ, prs: this.prs, projID: this.projID}
		);
		this.listenTo(newView, 'remove', this.removeQuestion);
		this.detailViews.push(newView);
		this.$('.new-questions').append(newView.render().el);
	},

	/**
	Removes a view from the list
	@method removeQuestion
	@param {NewQuestionDetailsView} view The view to remove
	*/
	removeQuestion: function(view) {
		this.stopListening(view);
		this.detailViews = _.without(this.detailViews, view);
		view.close();
	},

	/**
	@method saveExpansion
	@param {Event} [e] The initiating event
	*/
	saveExpansion: function(e) {
		if (e) e.preventDefault();

		var data = {
			questions: this.detailViews.map(function(dv){ return dv.serialize(); }),
			original_question_id: +this.origQ.get('id')
		};

		$.ajax({
			url: '/api1/question/split_to_multiple',
			method: 'POST',
			data: JSON.stringify(data),
			success: function(res){
				var toAdd = data.questions.map(function(q, index) {
					q.id                = res.data.questions[index].id;
					q.num_responses     = res.data.questions[index].num_responses;
					q.num_participants  = res.data.questions[index].num_participants;
					q.num_presentations = res.data.questions[index].num_presentations;
					return q;
				});
				this.allQs.add(toAdd);
				this.origQ.save('is_hidden', true);
				this.trigger('navigatetofront');
			}.bind(this),
			error: function(xhr){
				app.ge.trigger('alert', new AlertView({
					m: 'Problem expanding the question: '+xhr.responseJSON.m,
					type: 'alert-danger'
				}));
			}
		});
	}

}));