/* global require, module */

var Backbone = require('backbone'),
		GraphTheme = require('../models/GraphTheme.js');

/**
@class GraphThemes
@constructor
@extends Backbone.Collection
@module Collections
*/
module.exports = Backbone.Collection.extend({
	/**
	@property model
	@type GraphTheme Constructor
	@final
	*/
	model: GraphTheme,

	/**
	@property url
	@type string
	*/
	url: function(){ return '/api1/graph_theme/all_for_collection/' + this.collID; },

	/**
	Acts as the class constructor, sets instance properties
	@method initialize
	@param {object} opts Constructor parameters
	*/
	initialize: function(opts){
		/**
		The collection_id to get graphs for
		@property collID
		@type Number
		*/
		this.collID = Number(opts.collID);
	},

	/**
	Extracts the values to be `set` on the model from the API response
	@method parse
	@param {Object} res The API response object
	*/
	parse: function(res){
		return res.data;
	}
});