/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		AlertView = require('./AlertView.js'),
		SourcePossibleResponseItemView = require('./SourcePossibleResponseItemView.js'),
		app = require('../app.js');

module.exports = Backbone.View.extend({
	tagName: 'table',
	className: 'table table-hover responses',

	tpl: $('#SourcePossibleResponsesTableView-tpl').html(),

	events: {
		'click .orig-all': 'markAllOrig',
		'click .orig-rem': 'markRemainingOrig',
		'click .discard-all': 'markAllDiscard',
		'click .discard-rem': 'markRemainingDiscard',
		'click .clear-all': 'clearAll',
	},

	initialize: function(opts) {
		this.itemViews = [];
		this.primaryQ = opts.primaryQ || false;
		this.qID = opts.qID;
		this.type = opts.type || null;

		this.displays = {
			1: true,
			2: true,
			3: true,
			4: true
		};

		this.labels = {
			1: [
				{width: 2, text: 'Source Code'},
				{width: 5, text: 'Response'},
				{width: 5, text: 'Save As', showControls: this.primaryQ}
			],
			2: [
				{width: 2, text: 'Source Code'},
				{width: 5, text: 'Response'},
				{width: 5, text: 'Save As', showControls: this.primaryQ}
			],
			3: [
				{width: 6, text: 'Type of Response'},
				{width: 6, text: 'Save As', showControls: this.primaryQ}
			],
			4: [
				{width: 6, text: 'Type of Response'},
				{width: 6, text: 'Save As', showControls: this.primaryQ}
			]
		};

		this.cells = {
			1: [
				{val: 'source_code', showControls: false, center: true},
				{val: 'source_response', showControls: false, explain: true, source: true},
				{showControls: true}
			],
			2: [
				{val: 'source_code', showControls: false, center: true},
				{val: 'source_response', showControls: false, explain: true, source: true},
				{showControls: true}
			],
			3: [
				{val: 'source_response', showControls: false, explain: true, source: true},
				{showControls: true}
			],
			4: [
				{valText: 'Open response', showControls: false, explain: true, source: true},
				{showControls: true}
			]
		};

		this.listenTo(this.collection, 'reset', this.processPrs);
		this.collection.fetch({reset: true});
	},

	render: function() {
		//start with nothing
		this.$el.html('');

		//necessary view data for NavigationController
		var view = {
			title     : this.title,
			prevTitle : this.prevTitle,
		};

		//add header labels to view data if appropriate
		if (this.displays[this.type]) {
			view.headerLabels = this.labels[this.type];
		} else {
			view.headerLabels = [];
		}

		//add to the element
		this.$el.append(Mustache.render(this.tpl, view, app.partials()));

		//additional elements
		if (this.displays[this.type]) {
			this.collection.each(function(pr) {
				var prItemView = new SourcePossibleResponseItemView({
					model: pr,
					cells: this.cells[this.type]
				});
				this.itemViews.push(prItemView);
				this.$('.p-responses-table').append(prItemView.render().el);
			}, this);
		}
		return this;
	},

	processPrs: function() {
		if (!this.primaryQ) {
			var remove = this.collection.filter(function(pr) {
				if (parseInt(pr.get('is_empty'), 10) == 1) {
					return true;
				}
				if (parseInt(pr.get('is_response'), 10) === 0) {
					return true;
				}
				return false;
			});
			this.collection.remove(remove);
		}

		var guessType = 1;
		var first = this.collection.first();
		if (first.get('has_text') == 1) {
			guessType = 4;
		} else if (first.get('has_value') == 1) {
			guessType = 3;
		} else if (_.isFinite(first.get('source_response'))) {
			guessType = 2;
		}

		if (this.primaryQ) {
			this.trigger('typeguess', guessType);
			this.type = guessType;
		} else if (guessType != this.type) {
			app.ge.trigger('alert', new AlertView({
				m: 'It appears that the selected questions ' +
					'are of different types',
				type: 'alert-warning'
			}));
		}
		this.render();
	},

	changeType: function(type) {
		this.type = type;
		_.each(this.itemViews, function(view) {
			view.close();
		});
		this.itemViews = [];
		this.render();
	},

	markAllOrig: function() {
		if (this.primaryQ) {
			this.trigger('all:original');
		}
		_.each(this.itemViews, function(i) {
			i.original();
		});
	},

	markRemainingOrig: function() {
		if (this.primaryQ) {
			this.trigger('all:originalIfBlank');
		}
		_.each(this.itemViews, function(i) {
			if(!i.markedAs) {
				i.original();
			}
		});
	},

	markAllDiscard: function() {
		if (this.primaryQ) {
			this.trigger('all:discard');
		}
		_.each(this.itemViews, function(i) {
			i.discard();
		});
	},

	markRemainingDiscard: function() {
		if (this.primaryQ) {
			this.trigger('all:discardIfBlank');
		}
		_.each(this.itemViews, function(i) {
			if(!i.markedAs) {
				i.discard();
			}
		});
	},

	clearAll: function() {
		if (this.primaryQ) {
			this.trigger('all:clear');
		}
		_.each(this.itemViews, function(i) {
			i.clearFlag();
		});
	},

	buildCanonical: function() {
		return _.map(this.itemViews, function(item) {
			return item.buildCanonical();
		});
	},

	close: function() {
		_.each(this.itemViews, function(i) {
			i.close();
		});
		this.stopListening();
		this.remove();
	}
});