/* global require, module */

var Mustache = require('mustache'),
		app = require('../app.js');

/**
Provides a handy function for rendering the title bar section of a NavigationComponentView.
Accepts arbitrary panel controls to render. Requires the destination object to have `title` and
`prevTitle` properties

@class PanelHeaderMixin
@static
@module Mixins
*/
module.exports = {
	/**
	@method renderNavHeader
	@param {Array} controls Array of control objects that match the format:

		{
			loner      : true,
			label      : 'Add to Excel',
			class_name : 'data-download'
		},
	*/
	renderNavHeader: function(controls) {
		var view = {
			title          : this.title,
			prevTitle      : this.prevTitle,
			panel_controls : controls
		};
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);
	}
};