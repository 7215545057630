/* global require, module */

var Question = require('./Question.js'),
		SeriesSet = require('./SeriesSet.js'),
		SSUtils = require('./SeriesSetUtilities.js'),
		_ = require('underscore'),
		$ = require('jquery');

/**
@class RatingQuestion
@constructor
@extends Question
@module Models
*/
module.exports = Question.extend({
	urlRoot: '/api1/question',

	defaults: function() {
		return _.extend(Question.prototype.defaults(), {
			type_id: 2
		});
	},

	getters: {
		type_name: function(){ return 'Rating Scale'; }
	},

	/**
	@method toSeriesSet
	@param {object} args Hash of the required inputs, parts, prs, rs. Prs/rs can be for the q or proj.
	@return SeriesSet
	*/
	toSeriesSet: function(args) {
		var qName = this.get('name'),
				qID   = +this.get('id'),

				parts = args.parts,
				prs   = args.prs.where({question_id: qID.toString()}),
				rs    = args.rs.where({question_id: qID.toString()}),

				nonResponsePrID = _.find(prs, function(pr){ return !+pr.get('is_response'); }).get('id'),
				emptyPrID       = _.find(prs, function(pr){ return !!+pr.get('is_empty'); }).get('id');

		// Make a map that keys partID => prID, expect each part only once.
		var indexedRs = rs.reduce(function(partToPrMap, r) {
			var partID = r.get('participant_id'),
					prID   = r.get('possible_response_id');

			//if (partID in partToPrMap) throw new Error('RatingQuestion expects unique responses: '+partID);
			partToPrMap[partID] = prID;
			return partToPrMap;
		}, {});

		var prList = [];
		// Make a map of prID => response value
		var prMap = prs.reduce(function(memo, pr) {
			if (!!+pr.get('is_empty') || !+pr.get('is_response')) return memo;

			var response = +pr.get('response');
			if (!_.isFinite(response)){
				//throw new Error('A response value was not numeric: '+pr.get('response'));
			}else{
				memo[pr.get('id')] = +pr.get('response');
				prList.push(pr.get('response'));
			}
			return memo;
		}, {});


		// In this case everything is condensed into a single datapoint
		var dataPoint = {
			label       : qName,
			source_type : 'question',
			source_id   : qID,
			parent_id   : null,
			type        : 'rating',
			responses   : [],
			min         : null,
			max         : null,
			seq         : null
		};

		parts.forEach(function(part) {
			var partID = +part.get('id'),
					prIdForPart = indexedRs[partID],
					hadResponse = nonResponsePrID != prIdForPart,
					hadEmptyResponse = emptyPrID == prIdForPart;

			dataPoint.responses.push({
				part_id: partID,
				is_excluded : !!+part.get('excluded'),
				is_response : hadResponse,
				is_empty    : hadEmptyResponse,
				response    : hadResponse && !hadEmptyResponse ? prMap[prIdForPart] : null
			});
		});

		var responseValues = SSUtils.getRs(dataPoint);
		dataPoint.min = _.min(responseValues);
		dataPoint.max = _.max(responseValues);

		var dataSeries = {
			name: qName,
			seq: null,
			type: 'rating',
			min: 0,
			max: 10,
			data: [dataPoint]
		};

		return $.Deferred().resolve(new SeriesSet({
			series : [dataSeries],
			name   : qName,
			type   : 'rating'
		}));
	}
});