/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),

		app = require('../app.js');

module.exports = Backbone.View.extend({

	tagName: 'tr',
	className: 'source-q-row',

	events: {
		'click': 'editMe'
	},

	tpl: $('#SourceQuestionItemView-tpl').html(),

	initialize: function(opts) {
		if (parseInt(this.model.get('is_imported'), 10) === 1) {
			this.isImported(this.model, 1);
			this.disabled = true;
		} else {
			this.disabled = false;
		}
		this.listenTo(this.model, 'change:is_imported', this.isImported);
	},

	render: function() {
		//original data
		var view = this.model.toJSON();

		// add prompt to this data
		view.prompt = view.prompt.replace(/&lt;(?:.|\n)*?&gt;/gm, '');
		//is imported?
		view.imported = parseInt(view.is_imported, 10) === 1 ? 'Yes' : 'No';

		this.$el.append(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

	editMe: function() {
		if (!this.disabled) {
			this.trigger('edit', this.model);
		}
	},

	isImported: function(model, value, opts) {
		if (parseInt(value, 10) === 1) {
			this.$el.addClass('tr-no-cursor success');
			this.$('.sq-is-imported').html('Yes');
		} else {
			this.$el.removeClass('tr-no-cursor success');
			this.$('.sq-is-imported').html('No');
		}
	},

	alreadyChosen: function(currentQs) {
		if (_.indexOf(currentQs, this.model.get('id')) > -1) {
			this.$el.addClass('disabled');
			this.disabled = true;
		}
	},

	enable: function() {
		this.disabled = false;
		this.$el.removeClass('disabled');
	},

	close: function() {
		this.remove();
	}

});