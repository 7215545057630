/* global require, module */

var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
	urlRoot: '/api1/project',

	defaults: {
		team_id       : null,
		name          : '',
		create_date   : null,
		mod_date      : null,
		create_id     : null,
		mod_id        : null,
		collection_id : null,
		theme_id      : null
	}

});