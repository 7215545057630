/* global require, module */

var Backbone = require('backbone');

/**
@class Analytic
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend({
	/**
	@property urlRoot
	@type String
	@default '/api1/analytic'
	@final
	*/
	urlRoot: '/api1/analytic',

	/**
	@method parse
	@param {Object} res The ajax data payload
	@param {Object} opts See backbone docs
	@return {Object} Data ready to be set on the model
	*/
	parse: function(res, opts) {
		if (res.hasOwnProperty('data')) {
			return res.data;
		}
		return res;
	}
});