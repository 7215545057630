/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		Responses                = require('../collections/Responses.js'),
		ParticipantResponsesView = require('./ParticipantResponsesView.js'),
		ResponseItemView         = require('./ResponseItemView.js'),
		AlertView                = require('./AlertView.js'),
		app                      = require('../app.js');

/**
 * This view displays the question responses from a particular participant.
 *
 * @class ParticipantView
 * @extends Backbone.View
 * @module Views
 */
module.exports = Backbone.View.extend(
	_.extend({}, NavigationComponentMixin, {
	//default element name
	tagName: 'div',

	//main display template
	tpl: $('#ParticipantView-tpl').html(),

	events: {
		//standard nav behavior
		'click .back'             : 'navigateBack',

		//include or exclude user from study
		'click .toggle-exclusion' : 'toggleExclusion'
	},

	initialize: function(opts) {
		this.participantID     = opts.p.id;
		this.projectName       = opts.projectName;
		this.projectID         = opts.projectID;
		this.title             = 'Participant Detail';
		this.prevTitle         = 'Back';

		this.responsesView     = new ParticipantResponsesView({
			participantID : +this.participantID,
			projID        : +this.projectID});
	},

	/**
	 * Renders the main template area and passes itself back up the stack. Adds a list of all
	 * responses from this participant.
	 *
	 * @method render
	 * @return {Object} This object to be passed up the render stack
	 */
	render: function() {
		var isExcluded = +this.model.get('excluded') === 1;
		var view       = {
			title          : this.title,
			panel_controls : {
				label      : isExcluded ? 'Include' : 'Exclude',
				loner      : true,
				class_name : 'toggle-exclusion btn-error'
			},
			prevTitle      : this.prevTitle,
			excluded       : isExcluded,
			id             : this.model.get('id'),
			name           : this.model.get('name')
		};
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		this.$el.append(this.responsesView.render().el);
		return this;
	},

	/**
	 * Standard behavior
	 *
	 * @method navigateBack
	 * @param  {Event} e passed input event
	 */
	navigateBack: function(e) {
		if (e) { e.preventDefault(); }
		this.trigger('navigateback');
	},

	/**
	 * Standard behavior. Removes/destroys this view and triggers the "close" event.
	 *
	 * @method close
	 */
	close: function() {
		this.remove();
		this.trigger('close');
	},
	/**
	 * This method toggles the excluded state of the participant in question. Participants are
	 * included by default.
	 *
	 * If a user is excluded, the are not taken into account in any part of the data analysis. They
	 * essentially become deleted, although they can be restored (hence exclusion, not deletion).
	 *
	 * @method toggleExclusion
	 * @param  {Event} e [description]
	 * @return {[type]}   [description]
	 */
	toggleExclusion: function(e) {
		if (e) { e.preventDefault(); }

		var isExcluded = +this.model.get('excluded') === 1;

		if (isExcluded) {
			this.model.save('excluded', 0, {
				success: function(model, res, opts) {
					this.$('.excluded-state').html('Included');
					this.$('.toggle-exclusion').html('Exclude');
				}, //success
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					}));
				} //error
			});
		} else {
			this.model.save('excluded', 1, {
				success: function(model, res, opts) {
					this.$('.excluded-state').html('Excluded');
					this.$('.toggle-exclusion').html('Include');
				}, //success
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					}));
				} //error
			});
			this.navigateBack();
		}
	} //toggleExclusion
}));