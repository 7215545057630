/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		app = require('../app.js'),
		Users      = require('../collections/Users.js'),
		AlertView               = require('./AlertView.js');

module.exports = Backbone.View.extend({
	tagName: 'div',

	tpl: $('#EditUsersView-tpl').html(),

	initialize: function(opts) {
	},

	render: function() {
		var userReq  = new Users([], {});
		var userProm = userReq.fetch({
			reset: true,
			error: function(one, two, three) {
				app.ge.trigger('alert', new AlertView({
					m: 'Error loading users:' + two.responseText,
					type: 'alert-danger'
				}));
			}
		});
		$.when(userProm).then(_.bind(function() {
			var view = {};
			console.log(userReq.toJSON());
			view.users = userReq.toJSON();
			this.$el.html(this.tpl);
			console.log(this.$el, view);
			this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		}, this));
		return this;
	},

});