/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		app = require('../app.js'),
		AlertView               = require('./AlertView.js');

module.exports = Backbone.View.extend({
	tagName: 'div',

	tpl: $('#LicenseView-tpl').html(),

	initialize: function(opts) {
	},

	render: function() {
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1;
		
		today = mm+'/'+dd+'/2016';

		var view = {};
		view.licenseType = 'Standard';
		view.expirationDate = today;

		this.$el.html(this.tpl);
		console.log(this.$el);
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	},

});