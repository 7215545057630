/* global require, module */

var Question = require('./Question.js'),
		SeriesSet = require('./SeriesSet.js'),
		$ = require('jquery'),
		_ = require('underscore');

/**
Basic extension of the core Question model that implements continuous specific features
@class ContinuousQuestion
@constructor
@extends Question
@module Models
*/
module.exports = Question.extend({
	urlRoot: '/api1/question',

	defaults: function() {
		return _.extend(Question.prototype.defaults(), {
			type_id: 5
		});
	},

	getters: {
		type_name: function(){ return 'Continuous'; }
	},

	/**
	@method toSeriesSet
	@param {object} args Hash of the required inputs, parts, prs, rs. Prs/rs can be for the q or proj.
	@return SeriesSet
	*/
	toSeriesSet: function(args) {
		var qName = this.get('name'),
				qID   = +this.get('id'),

				parts = args.parts,
				prs   = args.prs.where({question_id: qID.toString()}),
				rs    = args.rs.where({question_id: qID.toString()});

		//if (prs.length != 3) throw new Error('Expected exactly 3 possible responses to the question');

		var nonResponsePrID,  emptyPrID, hasValuePrID;
		prs.forEach(function(pr){
			if (!!+pr.get('is_empty')) {
				emptyPrID = +pr.get('id');
			} else if (!+pr.get('is_response')) {
				nonResponsePrID = +pr.get('id');
			} else if (!!+pr.get('has_value')) {
				hasValuePrID = +pr.get('id');
			}
		});

		var excludedParts = parts.reduce(function(memo, part) {
			if (part.get('id') in memo) throw new Error('Expected unique participants');

			if (!!+part.get('excluded')) memo[part.get('id')] = true;
			return memo;
		}, {});


		var responses = rs.map(function(r) {
			var partID     = +r.get('participant_id'),
					prID       = +r.get('possible_response_id'),
					isEmpty    = prID == emptyPrID,
					isResponse = prID != nonResponsePrID,
					response   = +r.get('value');

			if (!_.isFinite(response) && isResponse && !isEmpty) throw new Error('Non numeric response');

			return {
				part_id     : partID,
				is_excluded : partID in excludedParts,
				is_empty    : isEmpty,
				is_response : isResponse,
				response    : !isEmpty && isResponse ? response : null
			};
		});

		var min = +this.get('min'), max = +this.get('max');

		var dataPoint = {
			label       : qName,
			source_type : 'possible_response',
			source_id   : hasValuePrID,
			parent_id   : qID,
			type        : 'continuous',
			responses   : responses,
			seq         : null,
			min         : _.isFinite(min) ? min : null,
			max         : _.isFinite(max) ? max : null
		};

		var dataSeries = {
			name : qName,
			seq  : null,
			type : 'continuous',
			data : [dataPoint]
		};

		return $.Deferred().resolve(new SeriesSet({
			series : [dataSeries],
			name   : qName,
			type   : 'continuous'
		}));
	}

});