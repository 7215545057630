/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),

		AlertView = require('./AlertView.js'),
		app = require('../app.js');

/**
 * A single line item representing a single participant. Can be clicked through to view all
 * responses from this participant, or the participant line item can be excluded/included from the
 * study entirely.
 *
 * @class ParticipantItemView
 */
module.exports = Backbone.View.extend({

	/**
	 * Standard line item element
	 *
	 * @property tagName
	 * @type {String}
	 * @default 'tr'
	 */
	tagName: 'tr',

	/**
	 * Additional class to assign to each element
	 *
	 * @property className
	 * @type {String}
	 * @default 'part-row'
	 */
	className: 'part-row',

	/**
	 * Standard Mustache template for the view.
	 *
	 * @property tpl
	 * @type {String}
	 * @default $('#ParticipantItemView-tpl').html()
	 */
	tpl: $('#ParticipantItemView-tpl').html(),

	/**
	 * A list of events that this view subscribes to, along with the methods called when those
	 * events take place.
	 *
	 * The keys in the collection are the Backbone events to listen for. The values are the name
	 * of the method that are called when those events take place.
	 *
	 * @property events
	 * @type {collection of Strings}
	 */
	events: {
		//view details on this particpant (all responses)
		'click .view-part': 'view',

		//exclude or include this participant from the study
		'click .toggle-exclusion': 'toggleExclusion'
	}, //events

	/**
	 * Basic constructor, sets up model listeners.
	 *
	 * @constructor
	 * @method initialize
	 */
	initialize: function() {
		this.listenTo(this.model, 'change:excluded', this.render);
	}, //initialize

	/**
	 * Renders this view with the appropriate background color, based on whether or not the user
	 * is excluded.
	 *
	 * @method render
	 * @return {[type]} [description]
	 */
	render: function() {
		var isExcluded = parseInt(this.model.get('excluded'), 10) === 0 ? false : true;

		var view = {
			name      : this.model.get('name'),
			title     : this.title,
			prevTitle : this.prevTitle,
			reason    : this.model.get('excluded_reason'),
			excluded  : isExcluded
		};

		//set color based on pre-existing exclusion state
		if (isExcluded) {
			this.$el.addClass('danger');
		} else {
			this.$el.removeClass('danger');
		}

		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		return this;
	}, //render

	/**
	 * Drill down into a participant's details to view all responses. Triggers the "view" event
	 * on the particpant model.
	 *
	 * @method view
	 * @param  {Event} e the input event
	 */
	view: function(e) {
		if (e) { e.preventDefault(); } //only fires if event was called from user input
		this.trigger('view', this.model);
	}, //view

	/**
	 * This method toggles the excluded state of the participant in question. Participants are
	 * included by default.
	 *
	 * If a user is excluded, the are not taken into account in any part of the data analysis. They
	 * essentially become deleted, although they can be restored (hence exclusion, not deletion).
	 *
	 * @method toggleExclusion
	 * @param  {Event} e [description]
	 * @return {[type]}   [description]
	 */
	toggleExclusion: function(e) {
		if (e) { e.preventDefault(); } //only fires if event was called from user input

		var isExcluded = +this.model.get('excluded') === 1;
		if (isExcluded) { //include
			this.model.save('excluded', 0, {
				success: function(model, res, opts) { //successfully included
				}, //success
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					})); //app.ge.trigger
				} //error
			}); //save
		} else { //exclude
			this.model.save('excluded', 1, {
				success: function(model, res, opts) { //successfully excluded
				}, //success
				error: function(model, res, opts) {
					app.ge.trigger('alert', new AlertView({
						m: 'Problem saving exclusion state',
						type: 'alert-danger'
					})); //app.ge.trigger
				} //error
			}); //save
		} //if/else isExcluded
	} //toggleExclusion

}); //app.ParticipantItemView