/* global require, module */

var Backbone = require('backbone');

/**
A base class to build CICalculators that compute the confidence intervals for SeriesSets.

Subclasses must implement a single required function, `computeCI`.

@class CICalculator
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend({
	// defaults defined as function that that it's subclassable
	// see https://github.com/jashkenas/backbone/issues/476#issuecomment-1645302
	defaults: function() {
		return {
			/**
			String identifier for the type of calculator
			@property type
			@type string
			*/
			type: null,

			/**
			Human readable version of the calculator type
			@property name
			@type string
			*/
			name: null
		};
	},

	/**
	@method computeCI
	@param {DataPoint} dp
	@param {Number} alpha
	@return {Array<Number> [Low value, high value] to be graphed, not a distance from the value.
	*/
	computeCI: function(dp, alpha){ throw new Error('Must be subclassed'); }
});