/* global require, module */

/**
Defines standard methods for managing views in a NavigationController inspired manner. Requires
the destination view to define `title` and `prevTitle` properties and the `close()` method.

@class NavigationComponentMixin
@static
@module Mixins
*/
module.exports = {
	/**
	@method getTitle
	@return {String} the current view's title
	*/
	getTitle     : function() {return this.title;},

	/**
	@method setPrevTitle
	@param {String} prev The new title to set in the prevTitle property
	*/
	setPrevTitle : function(prev) {this.prevTitle = prev;},

	/**
	Hides the current view by calling jQuery's `hide()` method
	@method hide
	*/
	hide         : function() {this.$el.hide();},

	/**
	Shows the current view by calling jQuery's `show()` method
	@method show
	*/
	show         : function() {this.$el.show();},

	/**
	Default implementation just delegates to this.remove()
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Triggers a `'navigateback'` event that closes and removes the current view
	@method navigateBack
	@param {event} e The triggering event
	*/
	/**
	Tells the controller view to close and move back in the stack
	@event navigateback
	*/
	navigateBack: function(e){
		if (e) e.preventDefault();
		this.trigger('navigateback');
	}
};