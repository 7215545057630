/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		SSUtils = require('./SeriesSetUtilities.js'),

		ProportionCalculator = require('./ProportionCalculator.js'),
		MeanCalculator = require('./MeanCalculator.js'),
		MeanGeoCalculator = require('./MeanGeoCalculator.js'),

		AdjWaldCICalculator = require('./AdjWaldCICalculator.js'),
		TContCICalculator = require('./TContCICalculator.js'),
		TContGeoCICalculator = require('./TContGeoCICalculator.js');

/**
The SeriesSet class is meant to provide a single common container for data that can be
passed to any other interested view or model (such as a GColView, a parent view that exports
data to excel, a view that runs statistical analysis on data, etc.). The SeriesSet essentially
is used to represent all the data in a graph and follow the heirarchy:

	SeriesSet
	|_ DataSeries
	|  |_ DataPoint
	|  |_ DataPoint
	|  |_ DataPoint
	|
	|_ DataSeries
	   |_ DataPoint
	   |_ DataPoint
	   |_ DataPoint

Note that DataSeries and DataPoint are not currently actual models; instead they're just plain
objects. A SeriesSet conceptually represents an entire single graph, with one or more series of data
that can be drawn simultaneously. A DataSeries is an individual series (i.e. all the same color
bars) which contains one or more bars/points/marks in that series. [A DataSeries potentially could
represent an equation to draw a line on the chart, but that is not supported now and likely beyond
the useful scope of what Gosset would encounter.]

A note on types: Right now it is invalid to have a 'mixed' SeriesSet or DataSeries. This is an
option that can be used in the future if a need arises, but currently all the methods that deal with
SeriesSet models will expect a uniform type throughout the entire model.

**SeriesSet**

	{
		series : Array<DataPoint>,
		name   : String (i.e. the name of the graph),
		type   : String, 'categorical'|'rating'|'time'|'open_ended'|'continuous'|'mixed'
	}

**DataSeries**

	{
		data : Array<DataPoint>,
		name : String, (i.e. the name of the series),
		seq  : Number|null, indicating sequential order in the SeriesSet,
		type : String, 'categorical'|'rating'|'time'|'open_ended'|'continuous'|'mixed'
	}

**DataPoint**

A completely encapsulated bit of data that can be represented as a single value on a graph. For
example, each possible_response to a categorical question is actually a separate DataPoint (they
can only represent binary data). One exception would be open_ended items, which are conceptually
similar but can't be graphed (think of them as a special case of a continuous question that can't
be visualized). DataPoint's can never have a 'mixed' type, they are the most atomic unit of data.

	{
		label       : String (i.e. the name of this bar on the graph),
		source_type : String, 'question'|'smart_question'|'possible_response'|'smart_possible_response',
		source_id   : Number (id of above item),
		parent_id   : Number (id of the q/sq that contains the source)
		type        : String, 'categorical'|'time'|'rating'|'open_ended'|'continous',
		responses   : Array<DataResponse>,
		seq         : Number (indicating sequential order in the DataSeries)
		[min]       : Number (minimum possible value in responses),
		[max]       : Number (maximum possible value in responses),
	}

**DataResponse**

Note that every participant in the study (even excluded people) must have a single DataResponse in
every DataPoint. This allows the data to be kept in sync as people are included/excluded, become
matches for a smart_questions, etc. (These features aren't implemented yet.)

	{
		part_id     : Number, the DB of the participant,
		is_excluded : Boolean,
		is_response : Boolean, whether the participant was capable of making a non-empty response that
									would wind up here,
		is_empty    : Boolean, the participant was capable of making a response, but didn't,
		response    : String|Number|null
	}

In the case of a categorical DataPoint, the response is the value the user responded with. There
can only be one value here, though, because of the atomicity of DataPoints.

@class SeriesSet
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend({
	// defaults defined as function so that it's subclassable
	// see https://github.com/jashkenas/backbone/issues/476#issuecomment-1645302
	defaults: function() {
		return {
			/**
			@property series
			@type Array<DataSeries>
			@default []
			*/
			series: [],

			/**
			@property name
			@type String
			@default 'Untitled SeriesSet'
			*/
			name: 'Untitled SeriesSet',

			/**
			@property type
			@type String
			@default '' (empty string, must be set by subclasses)
			*/
			type: ''
		};
	},

	/**
	Whether or not all the responses in the given DataSeries can be represented in a single excel
	column (i.e. each participant gave at most a single response to the DataSeries)
	@method dataSeriesCanCondense
	@param {Number|String} dataSeries Identify the dataSeries by index or name
	@private
	*/
	dataSeriesCanCondense: function(dataSeries) {
		if (_.isString(dataSeries)) dataSeries = _.findWhere(this.get('series'), {name:dataSeries});
		else if (_.isFinite(dataSeries)) dataSeries = this.get('series')[dataSeries];

		// get an array of just the participant_ids from each DataPoint in the series
		var partsPerDataPoint = _.map(dataSeries.data, function(dp) {
			return SSUtils.getNonMetaPartIDs(dp);
		});

		// look at every combination of the participant sets, if they are all unique this can be
		// combined otherwise there will need to be separate columns
		for (var outerIndex = 0; outerIndex < partsPerDataPoint.length; outerIndex++) {
			var outerParts = partsPerDataPoint[outerIndex];
			for (var innerIndex = outerIndex+1; innerIndex < partsPerDataPoint.length; innerIndex++) {
				var innerParts = partsPerDataPoint[innerIndex];
				if (_.intersection(outerParts, innerParts).length > 0) return false;
			}
		}
		return true;
	},

	/**
	Returns a properly formatted plain object that can be sent up to the server for creating an
	excel file.
	@method toExcelData
	@param {object} [args] You can pass in a Participants collection, otherwise the list of
		participants will be gleaned from the DataPoints.
	@return {$.Deferred} Resolves with an object representing a sheet which can be passed directly to
	the /api1/create_file endpoint (in an array).
	*/
	toExcelData: function(args) {
		// participants = list of all included part_ids in the project
		var participants,
				series         = this.get('series'),
				multipleSeries = series.length > 1,
				shouldCondense = (args.condense !== undefined) ? args.condense : true;

		if (args && args.parts) {
			participants = args.parts.chain()
					.reject(function(part){ return +part.get('excluded') == 1; })
					.pluck('id')
					.value();
		} else {
			participants = SSUtils.getSSIncludedPartIDs(this);
		}

		// map part_id to an array of the values to put in each row
		var participantsMap = _.object(participants, participants.map(function(){ return []; }));
		// array of names to head each column
		var names = [];

		series.forEach(function(dataSeries) {

			// If the DataSeries consists solely of unique responses that fit under the same parent
			// then we can just group all the responses together rather than iterate over the DataPoints
			// individually. Not sure if this is actually faster...
			if (this.dataSeriesCanCondense(dataSeries.name) && shouldCondense) {
				names.push(dataSeries.name);

				// which column we're trying to populate, basically
				var index = _.sample(participantsMap).length;

				dataSeries.data.forEach(function(dp){
					dp.responses.forEach(function(r){
						if (!(r.part_id in participantsMap)) return;

						// add an item for this column if necessary
						if (participantsMap[r.part_id].length == index) participantsMap[r.part_id].push(null);

						var prevResponse = participantsMap[r.part_id][index];

						var response;
						if      (r.is_empty)     response = '';
						else if (!r.is_response) response = 99999;
						else                     response = r.response;

						if (_.isNull(prevResponse)) {
							participantsMap[r.part_id][index] = response;
						} else if (!r.is_empty && r.is_response) {
							participantsMap[r.part_id][index] = response;
						}
					});
				});
			}

			else {
				dataSeries.data.forEach(function(dp) {
					names.push(multipleSeries ? dataSeries.name + '_' + dp.label : dp.label);

					dp.responses.forEach(function(r){
						if (!(r.part_id in participantsMap)) return;

						if      (r.is_empty)     participantsMap[r.part_id].push('');
						else if (!r.is_response) participantsMap[r.part_id].push(99999);
						else                     participantsMap[r.part_id].push(r.response);
					});
				});
			}
		}, this);

		var questionValues = _.reduce(participantsMap, function(memo, row){
			row.forEach(function(cell, index){
				memo[index].push(cell);
			});
			return memo;
		}, names.map(function(){ return []; }));

		return $.Deferred().resolve({
			name         : this.get('name'),
			type         : 'values',
			data         : {
				participants : participants,
				questions    : names.reduce(function(memo, name, index){
					memo.push({question_name: name, values: questionValues[index]});
					return memo;
				}, [])
			}
		});
	},

	/**
	@method getBetweenWithin
	@return String Labeling the type of experiment represented by the SeriesSet. Right now valid
		options are 'between' or 'within'.
	*/
	getBetweenWithin: function() {
		// flatten DataPoints
		var dps = _.reduce(this.get('series'), function(m, ds){ return m.concat(ds.data); }, []);

		// check for within/between subjects
		var master = _.first(dps);
		var masterPartIDs = _.chain(master.responses)
			.pluck('part_id')
			.sortBy(_.identity)
			.uniq(true)
			.value();

		master.responses = _.chain(master.responses)
							.reject(function(val){ return !val.is_response; })
							.value();

		// each DataPoint cannot contain duplicate participants if within
		if (masterPartIDs.length != master.responses.length) return 'between';
		// if all the datapoints refer possible_responses of the same parent question they are between
		if (master.parent_id) {
			var sameParent = _.every(_.rest(dps), function(dp) {
				return dp.parent_id && dp.parent_id == master.parent_id;
			});

			if (sameParent) return 'between';
		}

		// go through the rest
		var isBetween = _.some(_.rest(dps), function(dp){
			// short circuit if lengths are different
			if (dp.responses.length != master.responses.length) return true;

			// check the DataPoint for duplicate participants
			var dpPartIDs = _.chain(dp.responses)
				.pluck('part_id')
				.sortBy(_.identity)
				.uniq(true)
				.value();
			if (dpPartIDs.length != dp.responses.length) return true;

			// now actually check them all
			return _.some(masterPartIDs, function(mrPID, i){ return mrPID != dpPartIDs[i]; });
		});

		return isBetween ? 'between' : 'within';
	},

	/**
	@method getCalculator
	@return Calculator
	*/
	getCalculator: function() {
		switch (this.get('type')) {
			case 'categorical' : return new ProportionCalculator({});

			case 'continuous'  :
			case 'rating'      : return new MeanCalculator({});

			case 'time'        :
				// if any DataPoint has fewer than 25 actual responses use MeanGeo, otherwise Median
				if (this.get('series').some(function(dataSeries) {
					return dataSeries.data.some(function(dataPoint) {
						return dataPoint.responses.length < 26;
					});
				})) {
					return new MeanGeoCalculator({});
				} else {
					// return new MedianCalculator({});
					return new MeanGeoCalculator({});
				}
				break;
		}
	},

	/**
	@method getCICalculator
	@return CICalculator
	*/
	getCICalculator: function() {
		switch (this.get('type')) {
			case 'categorical' : return new AdjWaldCICalculator({});

			case 'continuous'  :
			case 'rating'      : return new TContCICalculator({});

			case 'time'        :
				// if any DataPoint has fewer than 25 actual responses use MeanGeo, otherwise Median
				if (this.get('series').some(function(dataSeries) {
					return dataSeries.data.some(function(dataPoint) {
						return dataPoint.responses.length < 26;
					});
				})) {
					return new TContGeoCICalculator({});
				} else {
					// return new MedianCICalculator({});
					return new TContGeoCICalculator({});
				}
				break;
		}
	},

	/**
	Convert the SeriesSet into a chartData plain object ready for sending over to a GColView.

	The SeriesSet will figure out which default calculator to use based on its contents if `null` is
	passed in, but you can override it by passing in a specific calculator. This works with the
	various Questions, SmartQuestions, and Comparisons because those will store `null` for the
	calculator types unless you explicitly save new ones.

	@method toChartData
	@param {Calculator|null} calc The calculator used to compute values, determine min/max, and the
		format string.
	@param {CICalculator|null} [ciCalc] Leave undefined if you don't want confidence intervals (note
		that `null` will cause a default CICalculator to be used).
	@param {Number} [alpha] The confidence level to use for the confidence interval, defaults to 0.1
	@return Array<ChartData> An array of chart data objects
	*/
	toChartData: function(calc, ciCalc, alpha) {
		calc   = calc   || this.getCalculator();
		ciCalc = ciCalc || this.getCICalculator();
		alpha  = alpha  || 0.1;

		var chartDataPoints = [],
				values = [];

		this.get('series').forEach(function(dataSeries) {
			var series = dataSeries.name;

			dataSeries.data.forEach(function(dataPoint) {
				var value = calc.computeValue(dataPoint),
						ci = _.isUndefined(ciCalc) ? [0, 0] : ciCalc.computeCI(dataPoint, alpha);

				values.push(value);
				dp = {
						value  : value,
						low    : ci[0],
						high   : ci[1],
						n      : ci[2],
						label  : dataPoint.label,
						series : series
					};

				if(ci.length > 3)  dp.sd = ci[3];

				chartDataPoints.push(dp);
			});
		});

		var chartData = {
			data         : chartDataPoints,
			min          : calc.computeMin(this),
			max          : calc.computeMax(this),
			formatString : calc.getFormatString(values),
			title        : this.get('name')
		};

		return chartData;
	}

});