/* global require, module */

var Backbone = require('backbone');

/**
A base class to build calculators that compute graphable values from SeriesSets.

The base class can be instantiated by itself, subclasses must be used that implement the four
required methods:

- computeValue
- computeMin
- computeMax
- getFormatString

@class Calculator
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Backbone.Model.extend({
	// defaults defined as function that that it's subclassable
	// see https://github.com/jashkenas/backbone/issues/476#issuecomment-1645302
	defaults: function() {
		return {
			/**
			String identifier for the type of calculator
			@property type
			@type string 'mean'
			*/
			type: null,

			/**
			Human readable version of the calculator type
			@property name
			@type string
			*/
			name: null
		};
	},

	/**
	Takes a DataPoint and turns it into the single value for a bar on a graph.

	@method computeValue
	@param {DataPoint} dp
	@return number
	*/
	computeValue: function(dp){ throw new Error('Must be subclassed'); },

	/**
	Takes a SeriesSet and returns the smallest `min` value set on it's constituent DataPoints. If no
	DataPoint has a finite `min` it returns `null`.

	@method computeMin
	@param {SeriesSet} ss
	@return Number|null
	*/
	computeMin: function(ss){ throw new Error('Must be subclassed'); },

	/**
	Takes a SeriesSet and returns the largest `max` value set on it's constituent DataPoints. If no
	DataPoint has a finite `max` it returns `null`.

	@method computeMax
	@param {SeriesSet} ss
	@return Number|null
	*/
	computeMax: function(ss){ throw new Error('Must be subclassed'); },

	/**
	Takes an array of every value in a graph from `computeValue` and returns the appropriate D3 format
	string for formatting the data values and axes labels.

	See https://github.com/mbostock/d3/wiki/Formatting for more details on the D3 formatString syntax.

	@method getFormatString
	@param {Array<Number>} values An array of every value in the graph
	@return String
	*/
	getFormatString: function(values){ throw new Error('Must be subclassed'); }
});