/* global require, module */

var Backbone = require('backbone'),
		$ = require('jquery'),
		_ = require('underscore'),
		Mustache = require('mustache'),
		app = require('../app.js');

/**
Container that lets a user select which original possible_responses should go into a new
possible_response for a combined question.
@class OriginalPossibleResponseSelector
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend({
	tagName: 'div',
	className: 'row',
	tpl: $('#OriginalPossibleResponseSelector-tpl').html(),
	prTpl: $('#OriginalPossibleResponseSelector-pr-tpl').html(),

	events: {
		'click .remove-me': 'removed',
		'change select[name="question"]': 'changeQuestion'
	},

	/**
	Set up a isSelected property, the default selectedClass
	@method initialize
	@param {object} opts Requires qs & prs keys, first is optional
	*/
	initialize: function(opts) {
		/**
		The collection of questions to be combined
		@property qs
		@type Questions
		*/
		this.qs = opts.qs;

		/**
		The collection of all PossibleResponses for the project
		@property pr
		@type PossibleResponses
		*/
		this.prs = opts.prs;

		/**
		Whether the selector comes first in the list
		@property first
		@type boolean
		*/
		this.first = opts.first || false;

		this.listenTo(this.prs, 'reset', this.renderAllPrs);
	},

	/**
	Build out the view html, insert all the questions, delegate to renderAllPrs
	@method render
	*/
	render: function() {
		var view = {};
		view.questions = this.qs.map(function(q) {
			return {id: q.get('id'), name: q.get('name')};
		});
		view.is_first = this.first;
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.renderAllPrs();
		return this;
	},

	/**
	Get all the is_empty=FALSE and is_response=TRUE possible_responses to the currently selected
	question, render them into the select element.
	@method renderAllPrs
	*/
	renderAllPrs: function() {
		var view = {};
		view.possible_responses = this.prs.chain()
			.reject(function(pr) {
				if (+pr.get('is_empty') || !+pr.get('is_response')) return true;
				if (pr.get('question_id') != this.$('select[name="question"]').val()) return true;
				return false;
			}, this)
			.map(function(pr) {
				return {id: pr.get('id'), response: pr.get('response')};
			})
			.value();

		this.$('select[name="possible-response"]')
			.html(Mustache.render(this.prTpl, view, app.partials()));
	},

	/**
	Default implementation
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Change handler for picking a new question, simply delegates to renderAllPrs
	@method changeQuestion
	@param {event} e The initiating event
	*/
	changeQuestion: function(e) {
		this.renderAllPrs();
	},

	/**
	Pass along even signifying the view should be removed
	@method removed
	@param {event} [e] The initiating event
	*/
	removed: function(e) {
		if (e) e.preventDefault();
		/**
		Triggered when the view should be removed from the list of OriginalPossibleResponseSelectors
		@event remove
		@param {OriginalPossibleResponseSelector} view
		*/
		this.trigger('remove', this);
	},

	/**
	Whether the currently selected original possible_response has_value=TRUE
	@method hasValue
	@return boolean
	*/
	hasValue: function() {
		var originalPrID = this.$('select[name="possible-response"]').val();
		var originalPr = this.prs.get(originalPrID);
		return Boolean(+originalPr.get('has_value'));
	},

	/**
	Whether the currently selected original possible_response has_text=TRUE
	@method hasText
	@return boolean
	*/
	hasText: function() {
		var originalPrID = this.$('select[name="possible-response"]').val();
		var originalPr = this.prs.get(originalPrID);
		return Boolean(+originalPr.get('has_text'));
	},

	/**
	Returns the id of the currently selected original possible_response
	@method originalID
	@return number
	*/
	originalID: function() {
		return +this.$('select[name="possible-response"]').val();
	},

	/**
	Select the specified question and possible_response
	@method makeSelection
	@param {Number} qID The id of the question to select
	@param {Number} prID The id of the possible_response to select
	*/
	makeSelection: function(qID, prID) {
		this.$('select[name="question"]').val(qID);
		this.renderAllPrs();
		this.$('select[name="possible-response"]').val(prID);
	}
});