/* global require, module */

var Backbone = require('backbone'),
		CategoricalQuestion = require('../models/CategoricalQuestion.js'),
		RatingQuestion = require('../models/RatingQuestion.js'),
		TimeQuestion = require('../models/TimeQuestion.js'),
		OpenEndedQuestion = require('../models/OpenEndedQuestion.js'),
		ContinuousQuestion = require('../models/ContinuousQuestion.js');

module.exports = Backbone.Collection.extend({
	model: function(attrs, opts) {
		switch (+attrs.type_id) {
			case 1: return new CategoricalQuestion(attrs, opts);
			case 2: return new RatingQuestion(attrs, opts);
			case 3: return new TimeQuestion(attrs, opts);
			case 4: return new OpenEndedQuestion(attrs, opts);
			case 5: return new ContinuousQuestion(attrs, opts);

			default: throw new Error('Unrecognized question type');
		}
	},

	url: function() {
		if(this.projID){
			return '/api1/question/all_for_project/' + this.projID;
		}else if(this.qIDs){
			return '/api1/question/all_for_ids/' + this.qIDs;
		}
		return '/api1/question/all_for_project/' + this.projID;
	},

	initialize: function(models, options) {
		this.projID = options.projID;
		this.qIDs = options.qIDs;
	},

	parse: function(res) {
		if (res.data) return res.data;
		return res;
	}
});