/* global require, module */

var Backbone = require('backbone'),
		_ = require('underscore'),
		$ = require('jquery'),
		Mustache = require('mustache'),
		typeahead = require('typeahead'),
		SUSAnalytic = require('../models/SUSAnalytic.js'),
		NavCompMixin      = require('../mixins/NavigationComponentMixin.js'),
		AlertView         = require('./AlertView.js'),
		GColView           = require('./GColView.js'),
		RatingQuestion    = require('../models/RatingQuestion.js'),
		Questions         = require('../collections/Questions.js'),
		PossibleResponses = require('../collections/PossibleResponses.js'),
		Responses         = require('../collections/Responses.js'),
		Participants      = require('../collections/Participants.js'),
		app               = require('../app.js');

/**
@class susFullAnalyticsView
@constructor
@extends Backbone.View
@module Views
*/
module.exports = Backbone.View.extend(
	_.extend({}, NavCompMixin, {
	// the standard page `el`
	tagName: 'div',

	tpl         : $('#SimpleAnalyticView-tpl').html(),
	infoTpl     : $('#SimpleAnalyticView-info-tpl').html(),
	editInfoTpl : $('#SimpleAnalyticView-edit-tpl').html(),

	/**
	 * Special template for options in the question list.
	 *
	 * @property optionTpl
	 * @type {String}
	 */
	optionTpl : $('#CreateSimpleAnalyticView-option-tpl').html(),

	events: {
		'click .back'                 : 'navigateBack',
		
		'click .edit-analytic'        : 'editAnalytic',
		'click .data-download'        : 'addDataToExcel',
		'click .cancel-edit-analytic' : 'cancelEditAnalytic',
		'click .save-edit-analytic'   : 'saveEditAnalytic',

		'click .delete-analytic'      : 'deleteAnalytic',
	},

	/**
	@method initialize
	@param {object} opts Config object following the format:
	*/
	initialize: function(opts) {
		/**
		The Analytic model that is being viewed
		@property sus
		@type Analytic
		*/
		this.sus = opts.sus;
		
		/**
		Object for storing data for export to xls
		@property eData
		@type generic js object
		*/
		this.eData = {};

		/**
		Taken from sus.
		@property projID
		@type String/Number
		*/
		this.projID = this.sus.get('project_id');

		/**
		 * All questions for this project
		 * @property qs
		 * @type Collection
		 */
		this.qs     = new Questions([], {projID : this.projID});

		/**
		Title for the page.
		@property title
		@type String
		@default The question name
		@final
		*/
		this.title = this.sus.get('name');

		/**
		Previous page title
		@property prevTitle
		@type String
		@default 'Back'
		*/
		this.prevTitle = 'Back';

		/**
		Set's the confidence level to use when viewing the graph and computing the confidence interval.
		@property alpha
		@type Number
		@default 0.1
		*/
		this.alpha     = opts.alpha || 0.1;

		/**
		@property inserted
		@type jQuery deferred
		*/
		this.inserted = $.Deferred();

		var view = this.sus.toTpl();

		this.qsProm = this.qs.fetch({
			reset: true,
			error: function(one, two, three) {
				console.log(one);
				console.log(two);
				console.log(three);
				app.ge.trigger('alert', new AlertView({
					m: 'Error loading questions:' + two.responseText,
					type: 'alert-danger'
				}));
			}
		});

		$.when(this.qsProm).then(_.bind(function() {
			this.renderInfo();
			this.listenTo(this.sus, 'change', function() {
				console.log('change');
				this.renderInfo();
				this.renderNavHeader();
			});
		}, this));
	},

	/**
	Set up the page structure, call `renderNavHeader()` (`renderInfo()` must be called deferred)
	@method render
	@chainable
	*/
	render: function() {
		var view = this.sus.toTpl();
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));
		this.renderNavHeader();

		return this;
	},

	/**
	Basic NavHeader rendering stuff
	@method renderNavHeader
	*/
	renderNavHeader: function() {
		var view       = {};
		view.title     = this.title;
		view.prevTitle = this.prevTitle;

		view.panel_controls = [
			{
				loner      : true,
				label      : 'Edit',
				class_name : 'edit-analytic'
			}
		];

		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);
	},

	/**
	Fill out the information panel based on the current question model state
	@method renderInfo
	*/
	renderInfo: function() {
		var view = this.sus.toTpl();

		view.questions = [
			{
				name : 'Q1 : I think that I would like to use this system frequently.',
				id   : 'q1',
				qid  : this.sus.get('q1_id')
			}, {
				name : 'Q2 : I found this system unnecessarily complex.',
				id   : 'q2',
				qid  : this.sus.get('q2_id')
			}, {
				name : 'Q3 : I thought this system was easy to use.',
				id   : 'q3',
				qid  : this.sus.get('q3_id')
			}, {
				name : 'Q4 : I think that I would need the support of a technical person to use this system.',
				id   : 'q4',
				qid  : this.sus.get('q4_id')
			}, {
				name : 'Q5 : I found the various functions in this system were well integrated.',
				id   : 'q5',
				qid  : this.sus.get('q5_id')
			}, {
				name : 'Q6 : I thought there was too much inconsistency in this system.',
				id   : 'q6',
				qid  : this.sus.get('q6_id')
			}, {
				name : 'Q7 : I would imagine that most people would learn to use this system very quickly.',
				id   : 'q7',
				qid  : this.sus.get('q7_id')
			}, {
				name : 'Q8 : I found this system very awkward to use.',
				id   : 'q8',
				qid  : this.sus.get('q8_id')
			}, {
				name : 'Q9 : I felt very confident using this system.',
				id   : 'q9',
				qid  : this.sus.get('q9_id')
			}, {
				name : 'Q10 : I needed to learn a lot of things before I could get going with this system.',
				id   : 'q10',
				qid  : this.sus.get('q10_id')
			}
		];

		var allowed    = [2];
		var allQuestionsInfo = {
			options: this.qs.chain()
				.filter(function(q) {
					return _.contains(allowed, +q.get('type_id'));
				})
				.map(function(q) {
					return {
						id      : +q.get('id'),
						type    : 'question',
						name    : q.get('name'),
						native_id : q.get('native_id'),
						type_id : q.get('type_id')
					};
				})
				.value()
		};

		var qToRSMap = {};

		//create promise to wait for all questions to complete processing
		(_.bind(function (){
			var deferred = $.Deferred();
			//loop through SUPRQ questions getting data and rendering carts
			i = 0;
			for(var question in view.questions) {

				var qID = view.questions[question].qid,
					id = view.questions[question].id,
					q = new RatingQuestion({id : qID}),
					prs = new PossibleResponses([], {qID: qID}),
					rs = new Responses([], {qID: qID}),
					parts = new Participants([], {projID: this.projID});

				//get data
				qProm = q.fetch({reset: true});
				prsProm = prs.fetch({reset: true});
				rsProm = rs.fetch({reset: true});
				partsProm = parts.fetch({reset: true});

				//when all data has been returned create question to responses map
				//and render chart
				$.when(qProm, partsProm, prsProm, rsProm, id, qID).done(_.bind(
					function(qRes, partsRes, prsRes, rsRes, idRes, qIDRes){
						var q = new RatingQuestion(qRes[0]),  
							parts = new Participants(partsRes[0],{qID : qIDRes}), 
							prs = new PossibleResponses(prsRes[0],{qID : qIDRes}), 
							rs = new Responses(rsRes[0].data,{qID : qIDRes});

						qToRSMap[qIDRes] = q;
						qToRSMap[qIDRes].rs = rs;
						qToRSMap[qIDRes].prs = prs;
						qToRSMap.parts = parts;

						this.renderChart(this.alpha, q, parts, prs, rs, '#graph-mount-' + idRes);

						//count questions processed and call complete when all have finished processing
						i++;
						if(i == view.questions.length) deferred.resolve();

					}, this));
				var theID = view.questions[question].qid;

				//fill each option box with the list of questions
				view.questions[question].chosenQuestion =
					_.where(
						allQuestionsInfo.options,
						{id : +theID }
					)[0].name;

				view.questions[question].chosenQuestionNID =
					_.where(
						allQuestionsInfo.options,
						{id : +theID }
					)[0].native_id;

				view.questions[question].chosenQuestionID =
					_.where(
						allQuestionsInfo.options,
						{id : +theID }
					)[0].id;
				
			}
			return deferred.promise();
			
		//done processing questions now create chart
		}, this))().then(_.bind(function(){
			//qToRSMap
			
			var //store iterated questions
				loadedQuestions = [];

			var chartDataPoints = [],
				eDataParts = [],
				eDataQuestions = [];

			//create graph data object
			this.eData.data = {};
			this.eData.name = 'SUS';
			this.eData.type = 'values';
			this.eData.data.participants = [];
			this.eData.data.questions = [];

			//set map of question with particpants with thier responses
			var qToPartsToRS = {};
			for(var question in view.questions) {
				var questionId = view.questions[question].qid;
				var responses = qToRSMap[questionId].rs.models;
				qToPartsToRS[questionId] = {};
				responseCount = 0;
				for(rsKey in responses){
					var responseObj = responses[rsKey];
					var partId = responseObj.get('participant_id');
					var responseVal = responseObj.get('response');
					if(responseVal != null){
						qToPartsToRS[questionId][partId] = responseVal;
						responseCount++;
						
					}
				
				}
				
			}

			//add participants to graph data object
			for(partKey in qToRSMap['parts'].models){
				this.eData.data.participants.push(qToRSMap['parts'].models[partKey].get('id'));
			}

			//create array of questions with responses 
			questionCount = 0;
			for(var question in view.questions) {
				//start counting at 1
				questionCount++;

				var questionId = view.questions[question].qid;	

				//push question data to processed array for using later
				loadedQuestions[questionCount] = qToRSMap[questionId];

			}

			//calculate SUS values
			sums = [];
			maxScore = 65;

			//get question ids
			var q1Id = loadedQuestions[questionCount - 9].get('id');
			var q2Id = loadedQuestions[questionCount - 8].get('id');
			var q3Id = loadedQuestions[questionCount - 7].get('id');
			var q4Id = loadedQuestions[questionCount - 6].get('id');
			var q5Id = loadedQuestions[questionCount - 5].get('id');
			var q6Id = loadedQuestions[questionCount - 4].get('id');
			var q7Id = loadedQuestions[questionCount - 3].get('id');
			var q8Id = loadedQuestions[questionCount - 2].get('id');
			var q9Id = loadedQuestions[questionCount - 1].get('id');
			var q10Id = loadedQuestions[questionCount].get('id');

			//get question responses
			var q1Responses = loadedQuestions[questionCount - 9].rs.models;
			var q2Responses = loadedQuestions[questionCount - 8].rs.models;
			var q3Responses = loadedQuestions[questionCount - 7].rs.models;
			var q4Responses = loadedQuestions[questionCount - 6].rs.models;
			var q5Responses = loadedQuestions[questionCount - 5].rs.models;
			var q6Responses = loadedQuestions[questionCount - 4].rs.models;
			var q7Responses = loadedQuestions[questionCount - 3].rs.models;
			var q8Responses = loadedQuestions[questionCount - 2].rs.models;
			var q9Responses = loadedQuestions[questionCount - 1].rs.models;
			var q10Responses = loadedQuestions[questionCount].rs.models;

			var SUSValues = [],
				usabilityValues = [],
				learnabilityValues = [];

			for(index in q1Responses){
				//get participant id
				var partId = q1Responses[index].get('participant_id');

				//get responses for the participants and questions at hand
				var q1Response = Number.parseInt(qToPartsToRS[q1Id][partId]);
				var q2Response = Number.parseInt(qToPartsToRS[q2Id][partId]);
				var q3Response = Number.parseInt(qToPartsToRS[q3Id][partId]);
				var q4Response = Number.parseInt(qToPartsToRS[q4Id][partId]);
				var q5Response = Number.parseInt(qToPartsToRS[q5Id][partId]);
				var q6Response = Number.parseInt(qToPartsToRS[q6Id][partId]);
				var q7Response = Number.parseInt(qToPartsToRS[q7Id][partId]);
				var q8Response = Number.parseInt(qToPartsToRS[q8Id][partId]);
				var q9Response = Number.parseInt(qToPartsToRS[q9Id][partId]);
				var q10Response = Number.parseInt(qToPartsToRS[q10Id][partId]);

				if(!isNaN(q1Response) 
				&& !isNaN(q2Response)
				&& !isNaN(q3Response)
				&& !isNaN(q4Response)
				&& !isNaN(q5Response)
				&& !isNaN(q5Response)
				&& !isNaN(q6Response)
				&& !isNaN(q7Response)
				&& !isNaN(q8Response)
				&& !isNaN(q9Response)
				&& !isNaN(q10Response)){
					console.log(q5Id);
					console.log(partId, q1Response,  q2Response,  q3Response,  q4Response,  q5Response, q6Response,  q7Response,  q8Response,  q9Response,  q10Response);

					q1Response = q1Response - 1;
					q2Response = 5 - q2Response;
					q3Response = q3Response - 1;
					q4Response = 5 - q4Response;
					q5Response = q5Response - 1;
					q6Response = 5 - q6Response;
					q7Response = q7Response - 1;
					q8Response = 5 - q8Response;
					q9Response = q9Response - 1;
					q10Response = 5 - q10Response;
					
					console.log(partId, q1Response,  q2Response,  q3Response,  q4Response,  q5Response, q6Response,  q7Response,  q8Response,  q9Response,  q10Response);
					susVal = (q1Response + q2Response + q3Response + q4Response + q5Response 
								+ q6Response + q7Response + q8Response + q9Response + q10Response) * 2.5;

					SUSValues.push(susVal);

					usabilityVal = (q1Response + q2Response + q3Response + q5Response 
								+ q6Response + q7Response + q8Response + q9Response) * 3.125;

					usabilityValues.push(usabilityVal);

					learnabilityVal = (q4Response + q10Response) * 12.5;

					learnabilityValues.push(learnabilityVal);

				}
				
			}
			
			console.log(SUSValues);
			console.log(usabilityValues);
			console.log(learnabilityValues);

			susCalcObj = this.calcSUS(SUSValues);
			usabilityCalcObj = this.calcSUS(usabilityValues);
			learnabilityCalcObj = this.calcSUS(learnabilityValues);

			chartDataPoints.push({
					value  : susCalcObj.mean,
					low    : susCalcObj.low,
					high   : susCalcObj.high,
					label  : 'SUS',
					series : 'Series'
				});

			chartDataPoints.push({
					value  : usabilityCalcObj.mean,
					low    : usabilityCalcObj.low,
					high   : usabilityCalcObj.high,
					label  : 'Usability',
					series : 'Series'
				});

			chartDataPoints.push({
					value  : learnabilityCalcObj.mean,
					low    : learnabilityCalcObj.low,
					high   : learnabilityCalcObj.high,
					label  : 'Learnability',
					series : 'Series'
				});

			var chartData = {
				data         : chartDataPoints,
				min          : 0,
				max          : 100,
				formatString : '.1f',
				title        : 'test'
			};

			chartData.el = "#graph-mount";
			chartData.alpha = .1;

			var chart = new GColView(chartData);
			this.listenTo(chart, 'changealpha', this.changeAlpha);
			this.listenTo(chart, 'GColView:addtoexcel', this.addSUPRQGraphToExcel);
			chart.render();
			//this.renderChart(this.alpha, q, parts, prs, rs, '#graph-mount-' + idRes)
		},this));

		this.$('.panel-body').html(
			Mustache.render(this.infoTpl, view, app.partials())
		);
	},
	calcSUS : function(averages){
		var 
			studyMean =  this.average(averages),
			studySD = this.standardDeviation(averages),
			N = averages.length,
			SE = studySD/Math.sqrt(N),
			t = this.scoreFromAlpha(N - 1,.1,2),
			margin = SE * t,
			low = studyMean - margin,
			high = Number.parseFloat(studyMean) + Number.parseFloat(margin);

			console.log(averages);
			console.log('low',low);
			console.log('high',high);
			console.log('studyMean',studyMean);
			console.log('studySD',studySD);
			return {mean : studyMean, low : low, high : high};
	},
	scoreFromAlpha: function(df, alpha, tails) {
		// convert a two-tailed alpha to a single tail
		if (Number(tails) == 2) {
			alpha = alpha/2;
		}

		var z = this.z.scoreFromAlpha(alpha);

		// t distribution matches z distribution at large df
		if (df > 10000) {
			return z;
		}

		var g1 = Math.pow(z, 3) + z;
		g1 = g1/4;

		var g2 = (5 * Math.pow(z, 5)) +
				 (16 * Math.pow(z, 3)) +
				 (3 * z);
		g2 = g2/96;

		var g3 = (3 * Math.pow(z, 7)) +
				 (19 * Math.pow(z, 5)) +
				 (17 * Math.pow(z, 3)) -
				 (15 * z);
		g3 = g3/384;

		var g4 = (79 * Math.pow(z, 9)) +
				 (776 * Math.pow(z, 7)) +
				 (1482 * Math.pow(z, 5)) -
				 (1920 * Math.pow(z, 3)) -
				 (945 * z);
		g4 = g4/92160;

		var t = z +
				(g1/df) +
				(g2/Math.pow(df, 2)) +
				(g3/Math.pow(df, 3)) +
				(g4/Math.pow(df, 4));

		return t;
	},
	z: {
		scoreFromAlpha: function(alpha, tails) {
			// convert a two-tailed alpha to a single tail
			if (Number(tails) == 2) {
				alpha = alpha/2;
			}

			if (alpha == 1) {
				return 3;
			}

			if (alpha > 0.5) {
				alpha = 1 - alpha;
			}

			var t = Math.sqrt( Math.log( 1/Math.pow(alpha, 2) ) );

			var c = [
				2.515517,
				0.802853,
				0.010328
			];
			var d = [
				1.432788,
				0.1898269,
				0.001308
			];

			var num = c[0] + ((c[1] + (c[2] * t)) * t);
			var den = 1 + ((d[0] + (d[1] + (d[2] * t)) * t) * t);

			return t - (num/den);
		},
	},
	erf : function(x){
	    //A&S formula 7.1.26
	    var a1 = 0.254829592,
		    a2 = -0.284496736,
		    a3 = 1.421413741,
		    a4 = -1.453152027,
		    a5 = 1.061405429,
		    p = 0.3275911,
		    x = Math.abs(x),
		    t = 1 / (1 + p * x);
	    //Direct calculation using formula 7.1.26 is absolutely correct
	    //But calculation of nth order polynomial takes O(n^2) operations
	    //return 1 - (a1 * t + a2 * t * t + a3 * t * t * t + a4 * t * t * t * t + a5 * t * t * t * t * t) * Math.Exp(-1 * x * x);

	    //Horner's method, takes O(n) operations for nth order polynomial
	    return 1 - ((((((a5 * t + a4) * t) + a3) * t + a2) * t) + a1) * t * Math.exp(-1 * x * x);
	},
	normdist : function(z){
        sign = 1;
        if (z < 0) sign = -1;
        //console.log(sign, Math.abs(z), Math.sqrt(2));
        return 0.5 * (1.0 + sign * this.erf(Math.abs(z)/Math.sqrt(2)));
    },

	standardDeviation : function(values){
		var avg = this.average(values);

		var squareDiffs = values.map(function(value){
			var diff = value - avg;
			var sqrDiff = diff * diff;
			return sqrDiff;
		});

		var avgSquareDiff = this.average(squareDiffs);

		var stdDev = Math.sqrt(avgSquareDiff);
		return stdDev;

	},

	average : function(data){
		var sum = data.reduce(function(sum, value){
			return sum + value;
		}, 0);

		var avg = sum / data.length;
		return avg;
	},

	/**
	Renders a new chart or updates an existing one.
	@method renderChart
	@param {Number} alpha The confidence level to use
	*/
	renderChart: function(alpha, q, parts, prs, rs, elemName) {
		parts.projID = this.projID;
		q.toSeriesSet({parts: parts, prs: prs, rs: rs})
			.then(function(seriesSet){
				return seriesSet.toChartData(q.getCalculator(), q.getCICalculator(), alpha);
			}.bind(this))
			.then(_.bind(function(chartData) {
					chartData.el = elemName;
					chartData.alpha = alpha;
					//console.log(chartData);
					this.chart = new GColView(chartData);
					this.listenTo(this.chart, 'GColView:addtoexcel', function(data, type, properties) {
																		var jsonData = {
																			name: q.get('name'),
																			type: 'graph',
																			data: data,
																			graph_type: type,
																			properties: properties,
																			graph_theme_id: null
																		};
																		app.ge.trigger('addtoexcel', jsonData);
																	});
					this.chart.render();
			}, this));
	},

	/**
	Handle excel data being passed up from the graph (GColView).
	@method addGraphToExcel
	@param {Object} data  Plain object passed up by GColView that just needs to get wrapped and
		passed up to the parent view that handles adding things to excel.
	@param {String} type  The type of graph, currently takes 'vertical' or 'horizontal' but I
		don't think it makes a difference right now.
	*/
	addSUSGraphToExcel: function(data, type, properties) {
		var jsonData = {
			name: 'SUS',
			type: 'graph',
			data: data,
			graph_type: type,
			properties: properties,
			graph_theme_id: null
		};
		app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	Handle excel data being passed up from the graph (GColView).
	@method addGraphToExcel
	@param {Object} data  Plain object passed up by GColView that just needs to get wrapped and
		passed up to the parent view that handles adding things to excel.
	@param {String} type  The type of graph, currently takes 'vertical' or 'horizontal' but I
		don't think it makes a difference right now.
	*/
	addGraphToExcel: function(data, type, properties) {
		var jsonData = {
			name: 'SUS',
			type: 'graph',
			data: data,
			graph_type: type,
			properties: properties,
			graph_theme_id: null
		};
		app.ge.trigger('addtoexcel', jsonData);
	},

	/**
	Fill out the info panel with editing controls
	@method renderEditInfo
	*/
	renderEditInfo: function() {
		var view       = this.sus.toTpl();
		view.name      = this.sus.get('name');
		view.title     = this.title;
		view.prevTitle = this.prevTitle;
		view.questions = [
			{
				name : 'Q1 : I think that I would like to use this system frequently.',
				id   : 'q1'
			}, {
				name : 'Q2 : I found this system unnecessarily complex.',
				id   : 'q2'
			}, {
				name : 'Q3 : I thought this system was easy to use.',
				id   : 'q3'
			}, {
				name : 'Q4 : I think that I would need the support of a technical person to use this system.',
				id   : 'q4'
			}, {
				name : 'Q5 : I found the various functions in this system were well integrated.',
				id   : 'q5'
			}, {
				name : 'Q6 : I thought there was too much inconsistency in this system.',
				id   : 'q6'
			}, {
				name : 'Q7 : I would imagine that most people would learn to use this system very quickly.',
				id   : 'q7'
			}, {
				name : 'Q8 : I found this system very awkward to use.',
				id   : 'q8'
			}, {
				name : 'Q9 : I felt very confident using this system.',
				id   : 'q9'
			}, {
				name : 'Q10 : I needed to learn a lot of things before I could get going with this system.',
				id   : 'q10'
			}
		];
		this.$('.panel-body').html(Mustache.render(this.editInfoTpl, view, app.partials()));
		this.addAllQs();
	},

	/**
	 * Adds all possible questions to the drop-down lists.
	 * @method addAllQs
	 */
	addAllQs: function() {
		var view = {
			title     : this.title,
			prevTitle : this.prevTitle,
			questions : [
				{
					name : 'Q1 : I think that I would like to use this system frequently.',
					id   : 'q1',
					qid  : this.sus.get('q1_id')
				}, {
					name : 'Q2 : I found this system unnecessarily complex.',
					id   : 'q2',
					qid  : this.sus.get('q2_id')
				}, {
					name : 'Q3 : I thought this system was easy to use.',
					id   : 'q3',
					qid  : this.sus.get('q3_id')
				}, {
					name : 'Q4 : I think that I would need the support of a technical person to use this system.',
					id   : 'q4',
					qid  : this.sus.get('q4_id')
				}, {
					name : 'Q5 : I found the various functions in this system were well integrated.',
					id   : 'q5',
					qid  : this.sus.get('q2_id')
				}, {
					name : 'Q6 : I thought there was too much inconsistency in this system.',
					id   : 'q6',
					qid  : this.sus.get('q6_id')
				}, {
					name : 'Q7 : I would imagine that most people would learn to use this system very quickly.',
					id   : 'q7',
					qid  : this.sus.get('q7_id')
				}, {
					name : 'Q8 : I found this system very awkward to use.',
					id   : 'q8',
					qid  : this.sus.get('q8_id')
				}, {
					name : 'Q9 : I felt very confident using this system.',
					id   : 'q9',
					qid  : this.sus.get('q9_id')
				}, {
					name : 'Q10 : I needed to learn a lot of things before I could get going with this system.',
					id   : 'q10',
					qid  : this.sus.get('q10_id')
				}
			]
		}; //view
		//get the list of rating-type questions
		var allowed    = [2];
		var allQuestionsInfo = {
			options: this.qs.chain()
				.filter(function(q) {
					return _.contains(allowed, +q.get('type_id'));
				})
				.map(function(q) {
					return {
						id      : +q.get('id'),
						type    : 'question',
						name    : q.get('name'),
						type_id : q.get('type_id')
					};
				})
				.map(function(q) {
					return q.get('name') + ' <span class="typeahead-id">ID:' + q.get('id') + '</span>';
				})
				.value()
		};

		//fill each option box with the list of questions
		for(var question in view.questions) {
			this.initializeTypeAhead(view.questions[question].id, questionNames);
			
			this.$('[name="' + view.questions[question].id + '"]').html(
				Mustache.render(this.optionTpl, allQuestionsInfo, app.partials()));

			//select the proper option
			var theID = view.questions[question].qid;
			this.$('[name="' + view.questions[question].id + '"]').val(
				_.where(
					allQuestionsInfo.options,
					{id : theID }
				)[0].name
			);
		}
	},

	initializeTypeAhead : function(id, allQNames){

		$('.'+id).typeahead({
		  hint: true,
		  highlight: true,
		  minLength: 1
		},
		{
		  name: 'questions',
		  displayKey: 'value',
		  source: this.substringMatcher(allQNames)
		}).bind('typeahead:selected', function(obj,datum,name){
			console.log(datum.value);
			var selectValue = datum.value.substring(datum.value.lastIndexOf('ID:'),datum.value.length)
								.replace('ID:','')
								.replace('</span>','');
			var qID = $(this).attr('data-id');
			$('[name="' + qID + '"] [data-id="' + selectValue + '"]').attr('selected','selected');
		});

	},

	substringMatcher : function(strs) {
		return function findMatches(q, cb) {
			var matches, substrRegex;

			// an array that will be populated with substring matches
			matches = [];

			// regex used to determine if a string contains the substring `q`
			substrRegex = new RegExp(q, 'i');

			// iterate through the pool of strings and for any string that
			// contains the substring `q`, add it to the `matches` array
			$.each(strs, function(i, str) {
			if (substrRegex.test(str)) {
			// the typeahead jQuery plugin expects suggestions to a
			// JavaScript object, refer to typeahead docs for more info
			matches.push({ value: str });
			}
			});

			cb(matches);
		}

	},

	/**
	Basic close. Except it also triggers a 'close' event, which it probably shouldn't be.
	@method close
	*/
	close: function() {
		this.remove();
	},

	/**
	 * Begins the editing of this analytic. Switches the nav header to cancel/save buttons.
	 * @medtod editAnalytic
	 */
	editAnalytic: function() {
		var view            = {};
		view.title          = this.title;
		view.prevTitle      = this.prevTitle;
		view.panel_controls = [
			{
				loner      : true,
				label      : 'Save',
				class_name : 'save-edit-analytic',
				btn_type   : 'primary'
			}, {
				loner      : true,
				label      : 'Cancel',
				class_name : 'cancel-edit-analytic',
				btn_type   : 'link'
			}, {
				loner      : true,
				label      : 'Delete',
				class_name : 'delete-analytic',
				btn_type   : 'danger'
			}
		];
		this.$('.panel-nav .panel-heading').replaceWith(
			Mustache.render(app.partials().GT_nav_panel_heading, view, app.partials())
		);

		this.renderEditInfo();
	},

	/**
	 * Cancel edits made to this analytic.
	 * @method cancelEditAnalytic
	 */
	cancelEditAnalytic: function() {
		this.renderNavHeader();
		this.renderInfo();
	},

	/**
	 * Confirm the edits made to this analytic.
	 * @method saveEditAnalytic
	 */
	saveEditAnalytic: function() {
		var data = _.reduce($('#edit-question-form').serializeArray(), function(memo, val) {
			memo[val.name] = val.value;
			return memo;
		}, {});

		var data2 = this.sus.toTpl();

		for (var attrname in data2) { data[attrname] = data2[attrname]; }

		this.sus.destroy( {
			success: _.bind(function(model, res, opts) {
				this.sus.save(data, {
					wait: true,
					success: _.bind(function(model, res, opts) {}, this),
					error: function(model, res, opts) {
						console.log(model);
						console.log(res);
						console.log(opts);
						console.log(res.responseText);
						app.ge.trigger('alert', new AlertView({
							m: 'Problem saving the edited analytic',
							type: 'alert-danger'
						}));
					}
				});
			}, this),
			error: function(model, res, opts) {
				console.log(model);
				console.log(res);
				console.log(opts);
				console.log(res.responseText);
				app.ge.trigger('alert', new AlertView({
					m: 'Problem deleting the analytic before saving a new one',
					type: 'alert-danger'
				}));
			}
		});

		
	},

	/**
	 * Attempts to delete this analytic.
	 * @method deleteAnalytic
	 */
	deleteAnalytic: function() {
		if (
			window.confirm("Are you sure you want to delete this SUS Full?\n\n" +
				"This action cannot be undone.")
		) {
			console.log(this.sus);
			this.sus.destroy({
				wait    : true,
				success : _.bind(function() {
					//this item no longer exists, so go back to list screen
					this.navigateBack();
				}, this), //success
				error   : function(mod, res, opts) {
					var message = "";
					message = "Error " + mod;
					console.log(mod);
					console.log(res);
					console.log(opts);
					console.log(res.responseText);
					app.ge.trigger('alert', new AlertView({
						m: 'Error loading questions:' + res.responseText,
						type: 'alert-danger'
					}));
				} //error
			}); //destroy
		} //confirmation dialog
	}

}));