/* global require, module */

var Backbone = require('backbone'),
		Collection = require('../models/Collection.js');

module.exports = Backbone.Collection.extend({
	model: Collection,
	url: '/api1/collection/all_for_team',

	// see http://stackoverflow.com/questions/11510490/sort-backbone-collection-based-on-model-attributes for sorting details
	initialize: function(options) {
		this.sortKey = 'mod_date';
	},

	comparator: function(a, b) {
		a = a.get(this.sortKey);
		b = b.get(this.sortKey);

		switch (this.sortKey) {
			case 'mod_date':
				return -1 * (Date.parse(a.split(' ').join('T')) - Date.parse(b.split(' ').join('T')));
		}
	}
});