/* global require, module */

var _ = require('underscore'),
		$ = require('jquery'),

		Comparison = require('./Comparison.js'),
		SeriesSet = require('./SeriesSet.js'),
		app = require('../app.js');

/**
@class MetaCategoricalComparison
@constructor
@extends Backbone.Model
@module Models
*/
module.exports = Comparison.extend({
	urlRoot: '/api1/comparison',

	getters: {
		fmt_type: function(){ return 'Meta'; },
		data_type: function(){ return 'Categorical'; },
		full_type: function(){ return 'Meta Categorical'; }
	},

	toSeriesSet: function(args) {
		var compName   = this.get('name'),
				compID     = +this.get('id'),

				parts      = args.parts,
				prs        = args.prs,
				rs         = args.rs,

				smartPrs   = args.smartPrs,
				filters    = args.filters,

				qs         = args.qs,
				smartQs    = args.smartQs,
				comps      = args.comps,

				dfd        = $.Deferred();

		// go through each relation and get the DataSeries we need
		$.when.apply(null, this.get('comparing').map(function(rel) {
			// get the SeriesSet that contains the target DataSeries
			if (rel.related_to != 'comparison') throw new Error('Meta can only relate comparisons');
			return comps.get(rel.related_id).toSeriesSet(args);
		}))
		.then(function(/* SeriesSets */) {
			var seriesSets = _.toArray(arguments), dataSeries = [];

			this.get('comparing').forEach(function(rel, index) {
				var ss = seriesSets[index], relID = +rel.related_id;

				if (ss.get('series').length != 1) throw new Error('Expected exactly one DataSeries');

				var ds = ss.get('series')[0];
				ds.name = rel.label;
				dataSeries.push(ds);
			});

			dfd.resolve(new SeriesSet({
				series : dataSeries,
				name   : compName,
				type   : 'categorical'
			}));
		}.bind(this));


		return dfd;
	},

	comparing_readable: function(args) {
		var comps = args.comps; //comparisons

		var kids   = this.get('comparing');
		var series = { info: '', members: []};

		_.each(kids, function(item) {
			if (item.related_to == 'comparison') {
				series.info = 'This question compares comparisons';
				var comp = comps.get(item.related_id);
				var obj={
					'response'     : 'All possible responses',
					'response_raw' : '--',
					'prompt'       : comp.get('name'),
					'label'        : item.label
				};
				series.members.push(obj);
			}
		});

		return series;
	}

});