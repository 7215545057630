/* global require, module */

var Backbone = require('backbone'),
		_        = require('underscore'),
		$        = require('jquery'),
		Mustache = require('mustache'),

		NavigationComponentMixin = require('../mixins/NavigationComponentMixin.js'),
		PanelHeaderMixin         = require('../mixins/PanelHeaderMixin.js'),

		Questions              = require('../collections/Questions.js'),
		SmartQuestions         = require('../collections/SmartQuestions.js'),
		Filters                = require('../collections/Filters.js'),
		Comparisons            = require('../collections/Comparisons.js'),
		Participants           = require('../collections/Participants.js'),
		PossibleResponses      = require('../collections/PossibleResponses.js'),
		SmartPossibleResponses = require('../collections/SmartPossibleResponses'),
		Responses              = require('../collections/Responses.js'),
		GColView               = require('./GColView.js'),
		AlertView              = require('./AlertView.js'),
		StatsAnalysisView      = require('./StatsAnalysisView.js'),

		app = require('../app.js');

/**
@class CrosstabAnalyticView
@constructor
@extends Backbone.View
@uses NavigationComponentMixin
@module Views
*/
module.exports = Backbone.View.extend(_.extend(
	{}, NavigationComponentMixin, PanelHeaderMixin, {
	/**
	@property tagName
	@type String
	@default 'div'
	@final
	*/
	tagName: 'div',

	/**
	@property tpl
	@type String
	*/
	tpl: $('#CrosstabAnalyticView-tpl').html(),

	infoTpl: $('#CrosstabAnalyticView-info-tpl').html(),
	editInfoTpl: $('#CrosstabAnalyticView-edit-info-tpl').html(),

	events: {
		'click .back'                 : 'navigateBack',
		'click .edit-crosstab'        : 'renderEditInfo',
		'click .cancel-edit-crosstab' : 'cancelEditInfo',
		'click .delete-crosstab'      : 'deleteCrosstab',
		'click .save-edit-crosstab'   : 'saveEditInfo',
		'click .data-download'        : 'addDataToExcel'
	},

	/**
	Sets up properties, kicks off ajax requests, queues additional rendering when that finishes
	@method initialize
	@param {object} opts Variables to start the view with, takes the form:
		{
			crosstab: crosstab [CrosstabAnalytic model],
			projID: ...
		}
	*/
	initialize: function(opts) {
		/**
		@property crosstab
		@type CrosstabAnalytic
		*/
		this.crosstab = opts.crosstab;

		/**
		@property project
		@type Project
		*/
		this.project = opts.project;

		/**
		@property title
		@type String
		@default Name of the crosstab
		*/
		this.title = this.crosstab.get('name');

		/**
		@property projID
		@type String
		*/
		this.projID = this.project.get('id');

		/**
		@property charts
		@type Array of GColViews
		*/
		this.charts = [];

		/**
		@property statViews
		@type Array of StatsAnalysisViews
		*/
		this.statViews = [];

		/**
		@property alpha
		@type Number
		*/
		this.alpha = opts.alpha || 0.1;

		/**
		@property qs
		@type Questions
		*/
		this.qs = new Questions([], {projID: this.projID});

		/**
		@property smartQs
		@type SmartQuestions
		*/
		this.smartQs = new SmartQuestions([], {projID: this.projID});

		/**
		@property filters
		@type Filters
		*/
		this.filters = new Filters([], {projID: this.projID});

		/**
		@property comps
		@type Comparisons
		*/
		this.comps = new Comparisons([], {projID: this.projID});

		/**
		@property parts
		@type Participants
		*/
		this.parts = new Participants([], {projID: this.projID});

		/**
		@property prs
		@type PossibleResponses
		*/
		this.prs = new PossibleResponses([], {projID: this.projID});

		/**
		@property smartPrs
		@type SmartPossibleResponses
		*/
		this.smartPrs = new SmartPossibleResponses([], {projID: this.projID});

		/**
		@property rs
		@type Responses
		*/
		this.rs = new Responses([], {analyticID: this.crosstab.get('id')});


		// listen to changes in the model
		this.listenTo(this.crosstab, 'change', _.bind(function(){
			this.renderNavHeaderDefault();
			this.renderInfo();
		}, this));
		this.listenTo(this.crosstab, 'change:sample_size_method', _.bind(function() {
			this.renderCharts(this.alpha);
		}, this));

		// fetch question-like items
		var qsProm      = this.qs.fetch(),
				smartQsProm = this.smartQs.fetch(),
				compsProm   = this.comps.fetch();
		// render info when its available
		$.when(qsProm, smartQsProm, compsProm).done(_.bind(this.renderInfo, this));

		// fetch items for building the SeriesSets & charts
		var filtersProm  = this.filters.fetch(),
				partsProm    = this.parts.fetch(),
				prsProm      = this.prs.fetch(),
				rsProm       = this.rs.fetch(),
				smartPrsProm = this.smartPrs.fetch();

		// apply the filters
		var crosstabProm = $.when(filtersProm, partsProm, prsProm, rsProm)
			.then(_.bind(function() {
				return this.crosstab.applyFilters(this.filters, this.parts, this.prs, this.rs);
			}, this));

		// turn to
		$.when(crosstabProm, qsProm, smartQsProm, smartPrsProm, compsProm)
			.then(_.bind(function(){
				this.renderCharts(this.alpha);
				this.renderStats();
			}, this));
	},

	/**
	@method close
	*/
	close: function(){ this.remove(); },

	/**
	Creates the basic page skeleton
	@method render
	@chainable
	*/
	render: function() {
		var view = this.crosstab.toTpl();
		this.$el.html(Mustache.render(this.tpl, view, app.partials()));

		this.renderNavHeaderDefault();
		return this;
	},

	/**
	@method renderInfo
	*/
	renderInfo: function() {
		var view = this.crosstab.toTpl();
		view.target_question_name = this.crosstab
			.getTarget({qs: this.qs, smartQs: this.smartQs, comps: this.comps})
			.get('name');

		view.filtering_questions = _.reduce(view.filtering_questions, function(memo, q, index) {
			if (index > 0) memo += ', ';

			if (q.type == 'question') memo += this.qs.get(q.id).get('name');
			if (q.type == 'smart_question') memo += this.smartQs.get(q.id).get('name');

			return memo;
		}, '', this);

		this.$('.panel-nav .panel-body').html(Mustache.render(this.infoTpl, view, app.partials()));
	},

	renderNavHeaderDefault: function() {
		var controls = [
			{
				loner      : true,
				label      : 'Add to Excel',
				class_name : 'data-download'
			},
			{
				loner      : true,
				label      : 'Edit',
				class_name : 'edit-crosstab'
			}
		];
		this.renderNavHeader(controls);
	},

	/**
	@method renderEditInfo
	*/
	renderEditInfo: function() {
		var view = this.crosstab.toTpl();

		var targetType = this.crosstab.get('target_question_type'),
				targetID   = this.crosstab.get('target_question_id'),
				target;
		switch (targetType) {
			case 'question'       : target = this.qs.get(targetID); break;
			case 'smart_question' : target = this.smartQs.get(targetID); break;
			case 'comparison'     : target = this.comps.get(targetID); break;
			default               : target = null;
		}

		var isCat;
		if (target && target.get('type_id')) isCat = target.get('type_id') == 1;
		if (target && target.get('type')) isCat = target.get('type').indexOf('categorical') >= 0;

		view.sample_size_methods = [{
			is_selected: this.crosstab.get('sample_size_method') == 'bucket',
			method_id: 'bucket',
			method_name: 'Bucket'
		}, {
			is_selected: this.crosstab.get('sample_size_method') == 'parent',
			method_id: 'parent',
			method_name: 'Parent',
			disabled: !(target && isCat)
		}];
		this.$('.panel-nav .panel-body').html(Mustache.render(this.editInfoTpl, view, app.partials()));

		var controls = [
			{
				loner      : true,
				label      : 'Cancel',
				class_name : 'cancel-edit-crosstab',
				btn_type   : 'link'
			}, {
				loner      : true,
				label      : 'Delete',
				class_name : 'delete-crosstab',
				btn_type   : 'danger'
			}, {
				loner      : true,
				label      : 'Save',
				class_name : 'save-edit-crosstab',
				btn_type   : 'primary'
			}
		];

		this.renderNavHeader(controls);
	},

	/**
	@method saveEditInfo
	*/
	saveEditInfo: function() {
		var data = _.reduce($('#edit-crosstab-form').serializeArray(), function(memo, val) {
			memo[val.name] = val.value;
			return memo;
		}, {});

		// do a little validation
		if (data.name.length > 120 || data.name.length < 1) {
			app.ge.trigger('alert', new AlertView({
				m: 'A name is required and must be less than 120 characters',
				type: 'alert-danger'
			}));
			return;
		}

		this.crosstab.save(data, {
			wait: true,
			error: function(m, res) { console.log(res.responseText); }
		});
	},

	/**
	@method cancelEditInfo
	@param {event} [e]
	*/
	cancelEditInfo: function(e) {
		if (e) e.preventDefault();
		this.renderNavHeaderDefault();
		this.renderInfo();
	},

	/**
	@method deleteCrosstab
	*/
	deleteCrosstab: function() {
		if (
			window.confirm("Are you sure you want to delete this Crosstab?\n\n" +
				"This action cannot be undone.")
		) {
			this.crosstab.destroy({
				wait: true,
				success: _.bind(function(){ this.navigateBack(); }, this),
				error: function(){ console.log('problem deleting'); }
			});
		}
	},

	/**
	@method renderChart
	*/
	renderCharts: function(alpha) {
		// make the array of SeriesSets
		this.crosstab.toSeriesSetArray({
				qs       : this.qs,
				smartQs  : this.smartQs,
				parts    : this.parts,
				prs      : this.prs,
				smartPrs : this.smartPrs,
				rs       : this.rs,
				filters  : this.filters,
				comps    : this.comps
			}, this)

			// call toChartData on each, package up into a single promise
			.then(function(ssArray){
				var dfd = $.Deferred();
				$.when.apply(null, _.map(ssArray, function(ss){
					return ss.toChartData(this.crosstab.getCalculator(), this.crosstab.getCICalculator(), alpha);
				}.bind(this)))
				.done(function(){ dfd.resolve.apply(null, arguments); });
				return dfd;
			}.bind(this))

			// now actually draw the graphs
			.then(_.bind(function(/* chartDatas */) {
				var chartDataArray = _.toArray(arguments);

				if (this.charts.length === 0) this.$('.graph-mount').html('');

				_.each(chartDataArray, function(chartData, index) {
					if (!this.charts[index]) {
						this.$('.graph-mount').append('<div class="graph-mount-'+index+'"></div>');
						chartData.el = '.graph-mount-' + index;
						chartData.alpha = 0.1;
						chartData.titlesOn = true;
						var chart = new GColView(chartData);
						this.listenTo(chart, 'changealpha', this.changeAlpha);
						this.listenTo(chart, 'GColView:addtoexcel', this.addGraphToExcel);
						this.charts.push(chart);
						chart.render();
					} else {
						this.charts[index].setData(chartData.data).setAlpha(alpha).reRender();
					}
				}, this);
			}, this));
	},

	renderStats: function() {
		// make the array of SeriesSets
		this.crosstab.toSeriesSetArray({
				qs       : this.qs,
				smartQs  : this.smartQs,
				parts    : this.parts,
				prs      : this.prs,
				smartPrs : this.smartPrs,
				rs       : this.rs,
				filters  : this.filters,
				comps    : this.comps
			}, this)

			// create a new StatsAnalysisView with each series set
			.then(_.bind(function(ssArray){
				var $graphs = this.$('.graph-mount > div');
				_.each(ssArray, function(ss, index) {
					var type = ss.get('type');
					// don't display for open end or single DataPoint
					if (type == 'open_end') return;
					if (ss.get('series').length == 1 && ss.get('series')[0].data.length == 1) return;

					var newStatView = new StatsAnalysisView({ss: ss});
					this.statViews.push(newStatView);
					$($graphs.get(index)).after(newStatView.render().el);
				}, this);
			}, this));
	},

	changeAlpha: function(alpha) {
		this.alpha = alpha;
		this.renderCharts(this.alpha);
	},

	/**
	@method addDataToExcel
	@param {event} [e] Potentially passed the triggering event.
	*/
	addDataToExcel: function(e) {
		if (e) e.preventDefault();

		this.crosstab.toSeriesSetArray({
									smartQs 	: this.smartQs,
									qs 			: this.qs,
									smartPrs 	: this.smartPrs,
									filters 	: this.filters,
									comps 		: this.comps,
									parts 		: this.parts, 
									prs 		: this.prs, 
									rs 			: this.rs})
			.then(_.bind(function(ss){ 
				console.log(ss);
				return ss[0].toExcelData({ parts: this.parts, condense : false }); 

			}, this))
			.then(function(eData){ 
				app.ge.trigger('addtoexcel', eData); });
	},

	addGraphToExcel: function(data, type, properties) {
		app.ge.trigger('addtoexcel', {
			name           : this.crosstab.get('name'),
			type           : 'graph',
			data           : data,
			properties     : properties,
			graph_type     : type,
			graph_theme_id : this.project.get('theme_id')
		});
	}

}));