/* global require, module */

var Backbone = require('backbone'),
		_        = require('underscore'),
		$        = require('jquery'),
		SSUtils  = require('./SeriesSetUtilities.js'),
		st       = require('../st.js');

module.exports = Backbone.Model.extend({
	defaults: {
		ss: null,
		method: '',
		mildlyThresh: 0.1,
		reallyThresh: 0.05
	},

	toTable: function() {
		if (!this.get('method')) {
			throw new Error('StatAnalyzer: method prop must be defined before calling toTable');
		}

		return this[this.get('method')+'ToTable']();
	},

	flattenSeriesSet: function() {
		var renamedDataPoints = [];
		_.each(this.get('ss').get('series'), function(dataSeries, dataSeriesIndex, list){
			var dataSeriesName = dataSeries.name;

			_.each(dataSeries.data, function(dataPoint, dataPointIndex){
				var copy = _.clone(dataPoint);
				copy.label = list.length > 1 ? copy.label + ' - ' + dataSeriesName : copy.label;

				// insert at weird indices to maintain the series order as it's presented in the graph
				var insertIndex = dataPointIndex + (dataSeriesIndex * (dataPointIndex+1));

				renamedDataPoints.splice(insertIndex, 0, copy);
			});
		});

		return renamedDataPoints;
	},

	n1twopropToTable: function() {
		var renamedDataPoints = this.flattenSeriesSet();

		// build the basic structure for the return data
		var returnData = [];
		returnData.push( [null].concat(_.pluck(renamedDataPoints, 'label')) );

		// now
		_.each(renamedDataPoints, function(dp1, index1){
			// add the stub of this row
			returnData.push([dp1.label]);

			// handy refs to data
			var x1 = _.chain(dp1.responses).filter(function(r){ return r.response; }).size().value(),
					n1 = SSUtils.getPresentations(dp1);

			_.each(renamedDataPoints, function(dp2, index2){
				// if already analyzed throw a null in there and short circuit
				if (index1 >= index2) {
					_.last(returnData).push(null);
					return;
				}

				// other handy refs
				var x2 = _.chain(dp2.responses).filter(function(r){ return r.response; }).size().value(),
						n2 = SSUtils.getPresentations(dp2);

				// compute pvalue, decide if it needs marking, push on
				var pvalue = st.p.nMinus1TwoProportion(x1, n1, x2, n2);
				if (pvalue <= this.get('reallyThresh')) pvalue = {value: pvalue, markAs:'really'};
				else if (pvalue <= this.get('mildlyThresh')) pvalue = {value:pvalue, markAs:'mildly'};
				_.last(returnData).push(pvalue);
			}, this);
		}, this);

		return returnData;
	},

	twosampletToTable: function() {
		var renamedDataPoints = this.flattenSeriesSet();

		// build the basic structure for the return data
		var returnData = [];
		returnData.push( [null].concat(_.pluck(renamedDataPoints, 'label')) );

		// now
		_.each(renamedDataPoints, function(dp1, index1){
			// add the stub of this row
			returnData.push([dp1.label]);

			// handy refs to data
			var dp1Rs = _.pluck(dp1.responses, 'response');
				var	x1 = st.mean(dp1Rs),
					s1 = st.sd(dp1Rs),
					n1 = _.chain(dp1Rs)
						.reject(function(val){ return val === null; })
						.value().length;

			_.each(renamedDataPoints, function(dp2, index2){
				// if already analyzed throw a null in there and short circuit
				if (index1 >= index2) {
					_.last(returnData).push(null);
					return;
				}

				// other handy refs
				var dp2Rs = _.pluck(dp2.responses, 'response'),
						x2 = st.mean(dp2Rs),
						s2 = st.sd(dp2Rs),
						n2 = _.chain(dp2Rs)
						.reject(function(val){ return val === null; })
						.value().length;

				// compute pvalue, decide if it needs marking, push on
				var pvalue = st.p.twoSampleT(x1, s1, n1, x2, s2, n2);
				if (pvalue <= this.get('reallyThresh')) pvalue = {value: pvalue, markAs:'really'};
				else if (pvalue <= this.get('mildlyThresh')) pvalue = {value:pvalue, markAs:'mildly'};
				_.last(returnData).push(pvalue);
			}, this);
		}, this);

		return returnData;
	},

	pairedtToTable: function() {
		var renamedDataPoints = this.flattenSeriesSet();

		// build the basic structure for the return data
		var returnData = [];
		returnData.push( [null].concat(_.pluck(renamedDataPoints, 'label')) );

		// now
		_.each(renamedDataPoints, function(dp1, index1){
			// add the stub of this row
			returnData.push([dp1.label]);

			// handy refs to data
			var dp1Rs = _.chain(dp1.responses)
				.map(function(r){ r.response = Number(r.response); return r; })
				.indexBy(function(r){ return r.part_id; })
				.value();

			_.each(renamedDataPoints, function(dp2, index2){
				// if already analyzed throw a null in there and short circuit
				if (index1 >= index2) {
					_.last(returnData).push(null);
					return;
				}

				// other handy refs
				var dp2Rs = _.chain(dp2.responses)
					.map(function(r){ r.response = Number(r.response); return r; })
					.indexBy(function(r){ return r.part_id; })
					.value();

				var diffs = _.map(dp1Rs, function(item){
					return item.response - dp2Rs[item.part_id].response;
				});

				var d  = st.mean(diffs),
						sd = st.sd(diffs),
						n  = diffs.length;

				var pvalue = st.p.pairedT(d, sd, n);

				// compute pvalue, decide if it needs marking, push on
				if (pvalue <= this.get('reallyThresh')) pvalue = {value: pvalue, markAs:'really'};
				else if (pvalue <= this.get('mildlyThresh')) pvalue = {value:pvalue, markAs:'mildly'};
				_.last(returnData).push(pvalue);
			}, this);
		}, this);

		return returnData;
	},

	mcnemarexactToTable: function() {
		var renamedDataPoints = this.flattenSeriesSet();

		// build the basic structure for the return data
		var returnData = [];
		returnData.push( [null].concat(_.pluck(renamedDataPoints, 'label')) );

		// now
		_.each(renamedDataPoints, function(dp1, index1){
			// add the stub of this row
			returnData.push([dp1.label]);

			// handy refs to data
			var dp1PartIDs = _.chain(dp1.responses)
				.filter(function(r){ return r.response; })
				.map(function(r){ return Number(r.part_id); })
				.value();

			_.each(renamedDataPoints, function(dp2, index2){
				// if already analyzed throw a null in there and short circuit
				if (index1 >= index2) {
					_.last(returnData).push(null);
					return;
				}

				// other handy refs
				var dp2PartIDs = _.chain(dp2.responses)
					.filter(function(r){ return r.response; })
					.map(function(r){ return Number(r.part_id); })
					.value();

				// taking it on faith that these are within datapoints...
				var b = _.difference(dp1PartIDs, dp2PartIDs).length, // pass 1, fail 2
						c = _.difference(dp2PartIDs, dp1PartIDs).length, // fail 1, pass 2
						x = st.highBound(b, c),
						n = b + c;

				var pvalue = st.p.mcnemarExact(x, n);

				// compute pvalue, decide if it needs marking, push on
				if (pvalue <= this.get('reallyThresh')) pvalue = {value: pvalue, markAs:'really'};
				else if (pvalue <= this.get('mildlyThresh')) pvalue = {value:pvalue, markAs:'mildly'};
				_.last(returnData).push(pvalue);
			}, this);
		}, this);

		return returnData;
	}
});